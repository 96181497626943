import * as Yup from 'yup';
import { useState } from 'react';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Buttons } from '../../../ui/atoms/Button';
import { InputField } from '../../../ui/atoms/InputField';
import { GET_PAYMENT_CHANNEL_BY_ID } from '../../../../queries/PaymentChannelQueries';
import { useDispatch } from 'react-redux';
import { savePaymentChannelId, savePaymentChannelName } from '../../../../redux/rootActions';
import Loader from '../../../../utils/loader';
import { useQuery } from '@apollo/client';

export default function ViewPaymentChannelScreen() {
    const history = useHistory();
    const parameters = useParams();
    const dispatch = useDispatch();
    const [paymentMethod, setPaymentMethod] = useState<string>(null);
    const paymentChannelId = parameters['id'];

    const { data: paymentChannelQueryData, loading: paymentChannelLoading } = useQuery(
        GET_PAYMENT_CHANNEL_BY_ID,
        {
            variables: { paymentChannelId: paymentChannelId },
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: () => {
                dispatch(
                    savePaymentChannelName(paymentChannelQueryData?.paymentChannel?.channelName),
                );
                dispatch(savePaymentChannelId(paymentChannelQueryData?.paymentChannel?.id));
                setPaymentMethod(paymentChannelQueryData?.paymentChannel?.paymentMethod);
            },
        },
    );

    const paymentChannel = paymentChannelQueryData?.paymentChannel;

    const validationSchema = Yup.object({
        paymentId: Yup.string().trim().required('Payment ID is required'),
        name: Yup.string().trim().required('Payment name is required'),
    });

    if (paymentChannelLoading) return <Loader />;

    return (
        <Formik
            initialValues={{
                paymentId: paymentChannel?.channelId,
                name: paymentChannel?.channelName,
                description: paymentChannel?.description,
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={() => {
                history.push('/payment-channels');
            }}
        >
            {({ values, handleChange, handleSubmit }) => (
                <>
                    <div className="h-full flex flex-col justify-between pl-9">
                        <div className="flex items-start justify-between p-5 ">
                            <div className="text-lg font-poppins font-bold  z-30">
                                Add New Payment Channel
                            </div>
                        </div>

                        <div className="-mt-5 flex flex-col">
                            <div className=" flex-wrap flex-col px-4 grid grid-cols-8 gap-4 mt-5">
                                <div className="flex flex-wrap col-start-1 col-end-3 flex-col lg:mb-5">
                                    <InputField
                                        placeHolder="ID"
                                        name="ID"
                                        id="paymentId"
                                        labelWidth="w-28"
                                        readonly={true}
                                        value={values.paymentId}
                                    />
                                </div>
                                <div className="flex flex-wrap flex-col lg:mb-5 col-start-1 col-end-3">
                                    <InputField
                                        placeHolder="Name"
                                        name="Name"
                                        id="name"
                                        readonly={true}
                                        labelWidth="w-28"
                                        value={values.name}
                                    />
                                </div>
                                <div className="flex flex-wrap flex-col relative lg:mb-5 z-20 col-start-1 col-end-3 ">
                                    <div className="text-gray-500 w-16 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Method
                                    </div>
                                    <select
                                        value={paymentMethod}
                                        disabled={true}
                                        className=" selectdropdownIcon p-4 lg:p-3 lg:py-4  rounded-xl outline-none border-2  bg-white border-gray-400"
                                        id="product-type"
                                    >
                                        <option value={null} disabled selected>
                                            Select payment method
                                        </option>
                                        <option value="DCB">DCB</option>
                                        <option value="PSP">PSP</option>
                                    </select>
                                </div>
                                <div className=" col-start-1 col-end-4">
                                    <div className="col-start-1 col-end-9 flex flex-wrap relative">
                                        <div
                                            className={`text-gray-500  w-24 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20`}
                                        >
                                            Description
                                        </div>
                                        <textarea
                                            className="p-4 z-0 min-w-full text-gray-400 rounded-xl outline-none border-2 font-poppins bg-white border-gray-400"
                                            id="groupDescription"
                                            name="groupDescription"
                                            rows={3}
                                            cols={138}
                                            disabled={true}
                                            draggable={false}
                                            maxLength={300}
                                            onInput={handleChange('description')}
                                            value={values?.description}
                                            placeholder="Enter payment description"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex w-10/12 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0">
                            <Buttons
                                name="Edit"
                                type="button"
                                buttonType="secondary-border-black"
                                id="Cancel"
                                size="e-small"
                                other="mr-3"
                                onclick={() => {
                                    history.push(`/payment-channels/edit/${paymentChannelId}`);
                                }}
                            />
                            <Buttons
                                name={'Close'}
                                type="submit"
                                buttonType="primary"
                                id="update"
                                size="e-small"
                                onclick={() => {
                                    handleSubmit();
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
