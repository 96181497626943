import { Modal } from 'react-responsive-modal';
import { Buttons } from '../../ui/atoms/Button';
import { Images } from '../../ui/atoms/Images';
import {
    API_SUPPLIER_TOPUP_CONFIG_CSV_FILE_MISMATCHES_MESSAGE,
    DELETE,
} from '../../../constants/supplier';

interface Props {
    showWarningModal: boolean;
    setShowWarningModal: (value: boolean) => void;
    setContinueWithMismatch: (value: boolean) => void;
}

export default function TopupConfigCsvUploadMismatchModal({
    showWarningModal,
    setShowWarningModal,
    setContinueWithMismatch,
}: Props) {
    const handleClose = () => {
        setShowWarningModal(false);
    };

    const handleSubmit = () => {
        setContinueWithMismatch(true);
        setShowWarningModal(false);
    };

    return (
        <Modal
            open={showWarningModal}
            onClose={() => {
                handleClose();
            }}
            center={true}
            styles={{ modal: { borderRadius: 15, maxWidth: '706px', width: '80%' } }}
            focusTrapped={false}
        >
            <>
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-poppins  font-bold flex m-auto">{DELETE}</h3>
                </div>

                <div className="relative p-6  flex flex-col">
                    <div className="flex m-auto mb-4">
                        <Images
                            width="w-16"
                            height="h-16"
                            src="/images/icons/clarity_error-standard-line.png"
                            alt="success"
                        />
                    </div>
                    <div
                        data-testid="message"
                        className="text-gray-500 font-poppins text-center text-lg flex mb-3 m-auto whitespace-pre-line p-8 relative"
                    >
                        {API_SUPPLIER_TOPUP_CONFIG_CSV_FILE_MISMATCHES_MESSAGE}
                    </div>
                </div>

                <div className="flex flex-row mt-6 justify-end m-4">
                    <>
                        <div className="w-full flex justify-end gap-3">
                            <Buttons
                                name="Cancel"
                                type="submit"
                                buttonType="secondary-main"
                                id="okay"
                                size="small"
                                onclick={() => {
                                    handleClose();
                                }}
                            />
                            <Buttons
                                name="Continue"
                                type="submit"
                                buttonType="primary"
                                id="okay"
                                size="small"
                                onclick={() => {
                                    handleSubmit();
                                }}
                            />
                        </div>
                    </>
                </div>
            </>
        </Modal>
    );
}
