export enum ClientRoles {
    STORE_ADMIN = 'admin',
    PARTNER = 'partner',
    USER = 'user',
}

export enum ClientRolesText {
    STORE_ADMIN = 'admin',
    PARTNER = 'partner',
}

export enum ClientRolesTextBlock {
    STORE_ADMIN = 'Store admin',
    PARTNER = 'Partner',
}
