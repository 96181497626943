import { ReactElement } from 'react';
import { Modal } from 'react-responsive-modal';
import { Buttons } from '../../ui/atoms/Button';
import { Images } from '../../ui/atoms/Images';

interface Props {
    showSuccessModal: boolean;
    setShowSuccessModal: (value: boolean) => void;
    successModalTitle?: string;
    successModalBody?: string;
    setProcessFinishedClicked?: (value: boolean) => void;
}

export default function SuccessMessageModal({
    showSuccessModal,
    setShowSuccessModal,
    successModalTitle,
    successModalBody,
    setProcessFinishedClicked,
}: Props): ReactElement {
    const handleClose = () => {
        setShowSuccessModal(false);
        setProcessFinishedClicked(true);
    };
    return (
        <Modal
            open={showSuccessModal}
            onClose={() => {
                handleClose();
            }}
            center={true}
            styles={{ modal: { borderRadius: 15, maxWidth: '706px', width: '80%' } }}
            focusTrapped={false}
        >
            <>
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-poppins  font-bold flex m-auto">
                        {successModalTitle}
                    </h3>
                </div>

                <div className="relative p-6  flex flex-col">
                    <div className="flex m-auto mb-4">
                        <Images
                            width="w-16"
                            height="h-16"
                            src="/images/icons/cil_check-circle.svg"
                            alt="success"
                        />
                    </div>
                    <div
                        data-testid="message"
                        className="text-gray-500 font-poppins text-center text-lg flex mb-3 m-auto whitespace-pre-line"
                    >
                        {successModalBody}
                    </div>
                </div>

                <div className="flex flex-row mt-6 justify-end m-4">
                    <Buttons
                        name="Okay"
                        type="submit"
                        buttonType="primary"
                        id="okay"
                        size="small"
                        onclick={() => {
                            handleClose();
                        }}
                    />
                </div>
            </>
        </Modal>
    );
}
