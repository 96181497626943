/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
    assignClientRoleToUser,
    createUser,
    getRoles,
    getUserDetailsUsingAdminClient,
    newUserInvite,
    resetPasswordAction,
} from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import UserAddedSuccessModal from '../../templates/modals/UserAddedSuccessModal';
import { Buttons } from '../../ui/atoms/Button';
import { InputField } from '../../ui/atoms/InputField';
import { Images } from '../../ui/atoms/Images';
import SpinLoader from '../../../utils/spinLoader';
import UnsaveChangesWarning from '../../templates/modals/UnsaveChangesWarning';
import {
    USER_ADD_EXIT_CONFIRM_MESSAGE,
    USER_ADD_EXIT_CONFIRM_TITLE,
} from '../../../constants/users';
import { ClientRoles, ClientRolesTextBlock } from '../../../enums/keycloak';

export default function AddNewUserAccountDetails() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [keyClockUpdated, setKeyClockUpdateCompleted] = useState(false);
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [role, setRole] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [roleError, setRoleError] = useState(false);
    const [showAddNewUserSuccess, setShowAddNewUserSuccess] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [createUserError, setCreateUserError] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [rolesValid, setRolesValid] = useState([]);
    const keyCloakUserDetails = useSelector((state: RootState) => state.auth.usersList);
    useEffect(() => {
        dispatch(getRoles());
    }, [dispatch]);

    const keyCloakRequestLoading = useSelector((state: RootState) => state.auth.isLoading);
    const keyCloakCreatedUser = useSelector((state: RootState) => state.auth.createdUser);

    const keyCloakUserCreateError = useSelector(
        (state: RootState) => state.auth.createUserErrorMessage,
    );

    const keyCloakCreateUserSuccess = useSelector(
        (state: RootState) => state.auth.createUserSuccess,
    );

    const roles = useSelector((state: RootState) => state.auth.roles);
    const currentTimeInMille = new Date().valueOf();

    useEffect(() => {
        if (roles?.length) {
            const roleList = roles?.filter(
                (role) => role?.name !== ClientRoles.PARTNER && role?.name !== ClientRoles.USER,
            );
            const rolesNames = roleList?.map((role) => ({
                id: role?.id,
                name:
                    role?.name === ClientRoles?.STORE_ADMIN
                        ? ClientRolesTextBlock.STORE_ADMIN
                        : null,
            }));
            setRolesValid(rolesNames);
        }
    }, [roles, roles?.length]);

    const handleRoleAssign = useCallback(
        (id: any, role: any) => {
            let roleObject = null;
            if (role && roles) {
                roleObject = roles.filter((roleItem) => roleItem.name === role);
            }
            if (roleObject && id) {
                setLoadingMessage('Assigning a role');
                setTimeout(() => {
                    dispatch(assignClientRoleToUser(id, roleObject));
                    setLoadingMessage('You are all set');
                }, 5000);
                setTimeout(() => {
                    setLoading(false);
                    if (keyClockUpdated) {
                        history.push('/users/personal-details-add');
                    }
                }, 10000);
            }
        },
        [dispatch, history, keyClockUpdated, roles],
    );

    const handleFetchCreatedUser = useCallback(() => {
        if (userEmail) {
            setTimeout(() => {
                dispatch(getUserDetailsUsingAdminClient(userEmail));
                dispatch(newUserInvite(userEmail));
                setKeyClockUpdateCompleted(true);
                setRoleError(false);
            }, 5000);
        }
    }, [dispatch, userEmail]);

    useEffect(() => {
        if (role) setRoleError(false);
    }, [role]);

    useEffect(() => {
        if (keyCloakCreatedUser) {
            handleRoleAssign(keyCloakCreatedUser[0].id, role);
        }
    }, [handleRoleAssign, keyCloakCreatedUser, role]);

    useEffect(() => {
        if (keyCloakRequestLoading) {
            setLoadingMessage('Creating a new user');
        }
    }, [keyCloakRequestLoading]);

    useEffect(() => {
        if (keyCloakUserCreateError === 'Oops something went wrong') {
            setCreateUserError(true);
            setLoading(false);
        }
    }, [keyCloakUserCreateError, setCreateUserError]);

    useEffect(() => {
        if (keyCloakCreateUserSuccess) {
            handleFetchCreatedUser();
        }
    }, [keyCloakCreateUserSuccess, handleFetchCreatedUser]);

    useEffect(() => {
        setCreateUserError(false);
    }, []);

    const validationSchema = Yup.object({
        email: Yup.string()
            .trim()
            .email('Email must be a valid email')
            .required('Email address is required'),
    });
    return (
        <Formik
            initialValues={{
                email: '',
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={({ email }) => {
                const keylogUsers = keyCloakUserDetails?.map((user) => ({
                    userId: user?.userId,
                    userName: user?.username,
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    contact: {
                        primaryEmail: user?.email,
                    },
                }));
                const isAlreadyThere = keylogUsers?.some(
                    (user) => user?.contact?.primaryEmail === email,
                );
                if (isAlreadyThere) {
                    setError(true);
                } else {
                    setError(false);

                    if (role) {
                        setLoading(true);
                        setIsDirty(false);
                        setCreateUserError(false);
                        setUserEmail(email);
                        dispatch(createUser(currentTimeInMille, email));
                    }
                }
            }}
        >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                <div className="h-full flex flex-col justify-evens">
                    <div className="text-lg font-poppins font-bold px-8">Account management</div>
                    <div className="grid grid-cols-8 gap-4 mt-8 px-8">
                        <div className="col-start-1 col-end-4 -mt-1">
                            <InputField
                                id="email"
                                placeHolder="Lucy@gmail.com"
                                name="Email address"
                                labelWidth="w-28"
                                onChange={(e) => {
                                    handleChange(e);
                                    setIsDirty(true);
                                }}
                                value={values.email}
                            />
                            {errors.email && touched.email ? (
                                <div className="text-red-500 text-xs mt-1 md:text-sm">
                                    {errors.email}
                                </div>
                            ) : null}
                            {error ? (
                                <div className="text-red-500 text-xs mt-1 md:text-sm">
                                    {'User already exists'}
                                </div>
                            ) : null}
                        </div>

                        <UserAddedSuccessModal
                            setShowAddNewUserSuccess={setShowAddNewUserSuccess}
                            showAddNewUserSuccess={showAddNewUserSuccess}
                        />

                        <div className="col-end-7 col-span-3">
                            <div className="flex flex-wrap flex-col relative -mt-1 ">
                                <div className="text-gray-500 w-30 text-center font-poppins text-sm lg:text-sm h-max bg-white z-50 -mb-3 ml-4 border-opacity-20">
                                    Select user role
                                </div>
                                <select
                                    className="p-4 lg:p-4 rounded-xl outline-none border-2 bg-white border-gray-400 hover:border-purple-500"
                                    defaultValue={'default'}
                                    id="role"
                                    onChange={(event) => {
                                        setRole(event.target.value);
                                        handleChange('role');
                                        setIsDirty(true);
                                    }}
                                    onBlur={() => handleBlur('role')}
                                >
                                    <option key="default" value="default" disabled selected>
                                        Select user role
                                    </option>
                                    {rolesValid?.map((role: any) => {
                                        return (
                                            <option key={role.id} value={role.name}>
                                                {role.name}
                                            </option>
                                        );
                                    })}
                                </select>
                                {roleError ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {'Role should not be empty'}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    {loading && (
                        <div className="flex flex-1 flex-col align-middle items-center justify-center px-8 lg:mt-32 md:mt-28 sm:mt-16">
                            <div className="h-full align-middle my-2">
                                <SpinLoader />
                            </div>
                            <div className="h-full align-middle my-4 text-center font-poppins text-base text-purple-500">
                                Please wait this may take a moment
                            </div>
                            <div className="h-full align-middle my-2 text-center font-poppins text-base text-gray-500">
                                {loadingMessage}
                            </div>
                        </div>
                    )}
                    {createUserError && (
                        <div className="flex flex-1 flex-col align-middle items-center justify-center px-8 lg:mt-32 md:mt-28 sm:mt-16">
                            <div className="h-full align-middle my-2">
                                <Images
                                    width="w-16"
                                    height="h-16"
                                    src="/images/icons/error-svg.svg"
                                    alt="success"
                                />
                            </div>
                            <div className="h-full align-middle my-4 text-center font-poppins text-base text-red-500">
                                Oops something went wrong
                            </div>
                            <div
                                role="presentation"
                                className="h-full align-middle my-4 text-center font-poppins text-base text-purple-500 underline cursor-pointer"
                                onClick={() => {
                                    if (!role) setRoleError(true);
                                    handleSubmit();
                                }}
                            >
                                Try again
                            </div>
                        </div>
                    )}
                    <UnsaveChangesWarning
                        contentText={USER_ADD_EXIT_CONFIRM_MESSAGE}
                        messageTitle={USER_ADD_EXIT_CONFIRM_TITLE}
                        when={isDirty}
                        navigate={(path) => history.push(path)}
                        shouldBlockNavigation={(location) => {
                            if (isDirty) {
                                return true;
                            }
                            return false;
                        }}
                        displayIcon={true}
                        displayIconName={'clarity_error-standard-line-svg.svg'}
                    />
                    <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0">
                        <Buttons
                            name="Cancel"
                            type="button"
                            buttonType="secondary-border-black"
                            id="Cancel"
                            size="e-small"
                            other="mr-3"
                            onclick={() => {
                                setCreateUserError(false);
                                history.push('/users');
                            }}
                        />
                        <Buttons
                            name="Next"
                            type="Next"
                            buttonType="primary"
                            id="next"
                            size="e-small"
                            disabled={loading}
                            onclick={() => {
                                if (!role) setRoleError(true);
                                handleSubmit();
                            }}
                        />
                    </div>
                </div>
            )}
        </Formik>
    );
}
