/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useEffect, useRef, useState } from 'react';
import '../../../styles/dropdown.css';
import '../../../styles/fieldBasedSearch.css';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import * as BsIcons from 'react-icons/bs';
import { pagination } from '../../../constants/config/constants';
import DataTable from '../../ui/organisms/DataTable';

import { useHistory } from 'react-router';
import { INSTANT_EDIT_OPTION_VIEW, INSTANT_EDIT_OPTION_RESTORE } from '../../../constants/product';
import TableTabView from '../productScreens/TableTabView';
import ConfirmationModalWithMessage from '../../templates/modals/ConfirmationModalWithMessage';

import { BULK_ACTION_ORDERS, FETCH_ORDERS } from '../../../queries/OrdersQueries';
import 'react-datepicker/dist/react-datepicker.css';
import {
    BULK_ACTION_MOVE_TO_CANCELED,
    BULK_ACTION_MOVE_TO_CANCELED_SUCCESS,
    BULK_ACTION_MOVE_TO_COMPLETE,
    BULK_ACTION_MOVE_TO_COMPLETE_SUCCESS,
    BULK_ACTION_REFUNDED,
    BULK_ACTION_REFUNDED_SUCCESS,
    BULK_ACTION_VALIDATION_FAILED_CANCELED,
    BULK_ACTION_VALIDATION_FAILED_COMPLETE,
    BULK_ACTION_VALIDATION_FAILED_REFUNDED,
    BULK_ACTION_VALIDATION_FAILED_TITLE_ORDERS,
    CANCELLED_ORDERS,
    COMPLETE_ORDERS,
    EMPTY_SEARCH_RESULT_TEXT,
    PROCESSING_ORDERS,
    REFUNDED_ORDERS,
} from '../../../constants/order';
import WarningMessageModal from '../../templates/modals/WarningMessageModal';
import ViewOneOrder from '../../templates/modals/ViewOneOrder';
import Toast from '../../ui/atoms/Toast';
import {
    NOT_REGISTERED,
    NOT_REGISTERED_WARNING_BODY,
    NOT_REGISTERED_WARNING_TITLE,
    REGISTERED,
} from '../../../constants/profile';
import SearchBar from '../../ui/molecules/SearchBar';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { SORT_DSC } from '../../../constants/common';
import NormalLoader from '../../../utils/normalLoader';
import moment from 'moment';
import Loader from '../../../utils/loader';
import { currencyAndCountryCodes } from '../../../utils/currencies';
import { PurchaseMedium } from '../../../enums/order';
import { Images } from '../../ui/atoms/Images';
import { Dropdown, Icon } from 'semantic-ui-react';
import MultiSelectSearchBar from '../../ui/molecules/MultiSelectSearchBar';
import { FETCH_SUPPLIERS_DROPDOWN } from '../../../queries/SupplierQueries';
import { FETCH_PARTNER_DETAILS_FOR_FILTER } from '../../../queries/PartnerQueries';

export default function OrdersScreen() {
    const [completeQuery, setCompleteQuery] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [instantEditMenuItems, setInstantEditMenuItems] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [searchClicked, setSearchClicked] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [confirmationMessageTitle, setConfirmationMessageTitle] = useState('');
    const [clickedOrder, setClickedOrder] = useState(null);
    const [ordersData, setOrdersData] = useState([]);
    const [bulkActionsMenuItems, setBulkActionsMenuItems] = useState([]);
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const history = useHistory();
    const [selectedOneOrderData, setSelectedOneOrderData] = useState([]);
    const [complete, setComplete] = useState(false);
    const [loadingViewOne, setLoadingViewOne] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [orderDataInitial, setOrderDataInitial] = useState([]);
    const [orderDataUnMutated, setOrderDataMutated] = useState([]);
    const [isFilterClicked, setIsFilterClicked] = useState(false);
    const [orderFilter, setOrderFilter] = useState(false);
    const [message, setMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [confirmationModalYesClicked, setConfirmationModalYesClicked] = useState(false);
    const [confirmationModalNoClicked, setConfirmationModalNoClicked] = useState(false);
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [pageSize, setPageSize] = useState(pagination?.pageSize);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [selectedOrderDateFilter, setSelectedOrderDateFilter] = useState<string>('LAST_WEEK');
    const [showBulkActions, setShowBulkActions] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [bulkAction, setBulkAction] = useState('');
    const [queryError, setQueryError] = useState(false);
    const [warningModalBody, setWarningModalBody] = useState('');
    const [warningModalTitle, setWarningModalTitle] = useState('');
    const [warningModalOkayClicked, setWarningModalOkayClicked] = useState(false);
    const [viewOrderModalVisible, setViewOrderModalVisible] = useState(false);
    const [applyBulkActionClicked, setApplyBulkActionClicked] = useState(false);
    const [createdDateFilterRemoved, setCreatedDateFilterRemoved] = useState(true);
    const [createdDateFilter, setCreatedDateFilter] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [totalCompletedOrders, setTotalCompletedOrders] = useState<number>(0);
    const [totalProcessingOrders, setTotalProcessingOrders] = useState<number>(0);
    const [totalCancelledOrders, setTotalCancelledOrders] = useState<number>(0);
    const [totalRefundedOrders, setTotalRefundedOrders] = useState<number>(0);
    const [totalDeletedOrders, setTotalDeletedOrders] = useState<number>(0);
    const [state, setState] = useState([
        {
            startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
            endDate: new Date(),
            key: 'selection',
        },
    ]);
    const [orderStatus, setOrderStatus] = useState({
        all: 0,
        completed: 0,
        processing: 0,
        cancelled: 0,
        refunded: 0,
        trashed: 0,
    });
    const [sortState, setSortState] = useState<string>(SORT_DSC);
    const [sortField, setSortField] = useState<string>('createdDate');
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [orderDataForSearch, setOrderDataForSearch] = useState([]);
    const [searchField, setSearchField] = useState('transactionId');
    const searchOptions = [
        { key: 'transactionId', text: 'Transaction ID', value: 'transactionId' },
        { key: 'gamerId', text: 'GamerID', value: 'gamerId' },
        { key: 'gamerEmail', text: 'Gamer Email', value: 'gamerEmail' },
        { key: 'itemName', text: 'Item Name', value: 'itemName' },
        { key: 'voucherCode', text: 'Voucher Code', value: 'voucherCode' },
    ];
    const [selectedPartnerId, setSelectedPartnerId] = useState([]);
    const [isSelectedPartnerRemoved, setIsSelectedPartnerRemoved] = useState<boolean>(true);
    const [supplier, setSupplier] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState<string>();
    const [isSelectedSupplier, setIsSelectedSupplier] = useState<boolean>(true);
    const [partners, setPartners] = useState([]);
    const [selectedPartners, setSelectedPartner] = useState([]);
    const [selectedSupplierId, setSelectedSupplierId] = useState([]);
    const [selectedPartnerIds, setSelectedPartnerIds] = useState([]);
    const [clearSelection, setClearSelection] = useState(null);

    const partnerOptions = partners?.map((partners) => {
        return {
            cat: partners.id,
            value: partners.partnerName,
        };
    });

    const [loadOrders, { data: orderData, loading: orderLoading, called }] = useLazyQuery(
        FETCH_ORDERS,
        {
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
            onCompleted: () => {
                setOrdersData(orderData?.orders?.orders);
                setOrderDataMutated(orderData?.orders?.orders);
                setOrderDataInitial(orderData?.orders?.orders);
                setCompleteQuery(true);
                setTotalItems(orderData?.orders?.totalOrders);
                setTotalCompletedOrders(orderData?.orders?.totalCompletedOrders);
                setTotalProcessingOrders(orderData?.orders?.totalProcessingOrders);
                setTotalCancelledOrders(orderData?.orders?.totalCancelledOrders);
                setTotalRefundedOrders(orderData?.orders?.totalRefundedOrders);
                setTotalDeletedOrders(orderData?.orders?.totalDeletedOrders);
            },
        },
    );

    const supplierOptions = supplier?.map((supplier) => supplier.supplierName) || [];

    const [updateOrderList, { error, data }] = useMutation(BULK_ACTION_ORDERS, {
        context: { clientName: ClientsEnum.STORE },
        onCompleted: (data) => {
            setApplyBulkActionClicked(false);
            setQueryError(false);
            setApplyBulkActionClicked(false);
        },
        onError: () => {
            setMessage(error?.message || 'Oops something went wrong');
            setQueryError(true);
            setShowToast(true);
        },
    });

    const { data: supplierQueryData } = useQuery(FETCH_SUPPLIERS_DROPDOWN, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setSupplier(
                supplierQueryData?.suppliers?.filter((supplierItem) => supplierItem?.isActive),
            );
        },
    });

    const { data: partnerDetailsData } = useQuery(FETCH_PARTNER_DETAILS_FOR_FILTER, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setPartners(data.partners);
        },
    });

    const handleBulkAction = (idList: any, status: any) => {
        updateOrderList({
            variables: {
                orderIds: idList,
                orderListInput: { orderStatus: status },
            },
        });
    };

    const validateBulkCompleteActions = (action: string, orders: any) => {
        const currentStatus =
            action === BULK_ACTION_MOVE_TO_COMPLETE ? PROCESSING_ORDERS : COMPLETE_ORDERS;

        return !orders?.some((order) => order.orderStatus !== currentStatus);
    };

    const validateBulkCancelActions = (action: string, orders: any) => {
        const currentStatus =
            action === BULK_ACTION_MOVE_TO_CANCELED ? PROCESSING_ORDERS : CANCELLED_ORDERS;
        return !orders?.some((order) => order.orderStatus !== currentStatus);
    };

    const validateBulkRefundActions = (action: string, orders: any) => {
        const currentStatus = action === BULK_ACTION_REFUNDED ? CANCELLED_ORDERS : REFUNDED_ORDERS;
        return !orders?.some((order) => order.orderStatus !== currentStatus);
    };

    const handleSelectedPartnerValues = (selectedPartnerList: { cat: string; value: string }[]) => {
        setIsSelectedPartnerRemoved(false);

        const selectedPartnerIds = selectedPartnerList.map((partner) => partner.cat);
        setSelectedPartnerId(selectedPartnerIds);
    };

    const handleRemovedPartnerValues = (removedPartnerList: { cat: string; value: string }[]) => {
        const removedPartnerIds = removedPartnerList.map((partner) => partner.cat);

        const partners = selectedPartnerId?.length
            ? selectedPartnerId.filter((partnerId) =>
                  removedPartnerIds?.some((pat) => pat === partnerId),
              )
            : [];

        setSelectedPartnerId(partners);
    };

    const handleSelectedSupplier = (e, newVal, reason) => {
        if (reason == 'reset') {
            setSelectedSupplier(newVal);
        }
    };

    useEffect(() => {
        if (selectedSupplier) {
            const foundSupplier = supplier.find(
                (supplier) => supplier.supplierName === selectedSupplier,
            );
            setSelectedSupplierId(foundSupplier.id);
        } else {
            setSelectedSupplierId(null);
        }
    }, [selectedSupplier]);

    useEffect(() => {
        if (selectedPartners && selectedPartners.length > 0) {
            const selectedPartnerIds = selectedPartners.map((partner) => partner.cat);
            setSelectedPartnerIds(selectedPartnerIds);
        } else {
            setSelectedPartnerIds(null);
        }
    }, [selectedPartners]);

    useEffect(() => {
        if (selectedSupplier) {
            const foundSupplier = supplier.find(
                (supplier) => supplier.supplierName === selectedSupplier,
            );
            setSelectedSupplierId(foundSupplier.id);
        } else {
            setSelectedSupplierId(null);
        }
    }, [selectedSupplier]);

    useEffect(() => {
        if (selectedPartners && selectedPartners.length > 0) {
            const selectedPartnerIds = selectedPartners.map((partner) => partner.cat);
            setSelectedPartnerIds(selectedPartnerIds);
        } else {
            setSelectedPartnerIds(null);
        }
    }, [selectedPartners]);

    const clearSelectionRef = useRef<() => void>();

    const handleClearSelectionCallback = (clearFn: () => void) => {
        clearSelectionRef.current = clearFn;
    };
    useEffect(() => {
        if (selectedPartnerId.length) {
            const filteredPartners = partnerOptions.filter((partner) =>
                selectedPartnerId.some((selectedPartner) => selectedPartner === partner.cat),
            );
            setSelectedPartner(filteredPartners);
        } else {
            setSelectedPartner([]);
        }
    }, [selectedPartnerId]);

    useEffect(() => {
        const orderList = selectedOrders?.slice(0);

        const idList = orderList?.map((order) => order.id);

        if (
            bulkActionsMenuItems.some(
                (action) => action.value === bulkAction && applyBulkActionClicked,
            )
        ) {
            if (
                bulkAction === BULK_ACTION_REFUNDED &&
                selectedOrders.length > 1 &&
                applyBulkActionClicked
            ) {
                if (validateBulkRefundActions(BULK_ACTION_REFUNDED, orderList)) {
                    handleBulkAction(idList, REFUNDED_ORDERS);
                    setMessage(BULK_ACTION_REFUNDED_SUCCESS);
                    setShowToast(true);
                    setShowWarningModal(false);
                } else {
                    setWarningModalBody(BULK_ACTION_VALIDATION_FAILED_REFUNDED);
                    setWarningModalTitle(BULK_ACTION_VALIDATION_FAILED_TITLE_ORDERS);
                    setShowWarningModal(true);
                    setApplyBulkActionClicked(false);
                }
            } else if (
                bulkAction === BULK_ACTION_MOVE_TO_COMPLETE &&
                selectedOrders.length > 1 &&
                applyBulkActionClicked
            ) {
                if (validateBulkCompleteActions(BULK_ACTION_MOVE_TO_COMPLETE, orderList)) {
                    handleBulkAction(idList, COMPLETE_ORDERS);
                    setMessage(BULK_ACTION_MOVE_TO_COMPLETE_SUCCESS);
                    setShowToast(true);
                    setShowWarningModal(false);
                } else {
                    setWarningModalBody(BULK_ACTION_VALIDATION_FAILED_COMPLETE);
                    setWarningModalTitle(BULK_ACTION_VALIDATION_FAILED_TITLE_ORDERS);
                    setShowWarningModal(true);
                    setApplyBulkActionClicked(false);
                }
            } else if (
                bulkAction === BULK_ACTION_MOVE_TO_CANCELED &&
                selectedOrders.length > 1 &&
                applyBulkActionClicked
            ) {
                if (validateBulkCancelActions(BULK_ACTION_MOVE_TO_CANCELED, orderList)) {
                    handleBulkAction(idList, CANCELLED_ORDERS);
                    setMessage(BULK_ACTION_MOVE_TO_CANCELED_SUCCESS);
                    setShowToast(true);
                    setShowWarningModal(false);
                } else {
                    setWarningModalBody(BULK_ACTION_VALIDATION_FAILED_CANCELED);
                    setWarningModalTitle(BULK_ACTION_VALIDATION_FAILED_TITLE_ORDERS);
                    setShowWarningModal(true);
                    setApplyBulkActionClicked(false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bulkAction, selectedOrders, applyBulkActionClicked]);

    useEffect(() => {
        if (clickedOrder?.id) {
            localStorage.setItem('orderId', clickedOrder?.id);
            setViewOrderModalVisible(true);
        }
    }, [clickedOrder?.id]);

    useEffect(() => {
        if (!viewOrderModalVisible) {
            localStorage.removeItem('orderId');
            setClickedOrder(null);
        }
    }, [showConfirmationModal, viewOrderModalVisible]);

    useEffect(() => {
        if (confirmationModalNoClicked) {
            setShowConfirmationModal(false);
            setSelectedMenuItem('');
            setConfirmationModalNoClicked(false);
        }
    }, [confirmationModalNoClicked]);

    useEffect(() => {
        const instantMenuList = [];

        selectedTab === 1
            ? instantMenuList.push({
                  text: 'View',
                  value: INSTANT_EDIT_OPTION_VIEW,
              })
            : instantMenuList.push(
                  {
                      text: 'View',
                      value: INSTANT_EDIT_OPTION_VIEW,
                  },
                  {
                      text: 'Restore',
                      value: INSTANT_EDIT_OPTION_RESTORE,
                  },
              );

        setInstantEditMenuItems(instantMenuList);
    }, [selectedTab]);

    // Function to filter based on selected searchField
    const filterOrders = () => {
        let filteredData = [];

        switch (searchField) {
            case 'transactionId':
                filteredData = orderDataUnMutated
                    .filter((order) => order?.transactionId)
                    .map((order) => order.transactionId);
                break;
            case 'gamerId':
                filteredData = orderDataUnMutated
                    .filter((order) => order?.gamerId)
                    .map((order) => order.gamerId);
                break;
            case 'gamerEmail':
                filteredData = orderDataUnMutated
                    .filter((order) => order?.gamerEmail)
                    .map((order) => order.gamerEmail);
                break;
            case 'itemName':
                filteredData = orderDataUnMutated
                    .filter((order) => order?.item?.itemName)
                    .map((order) => order.item.itemName);
                break;
            case 'voucherCode':
                filteredData = orderDataUnMutated
                    .filter((order) => order?.vouchers[0]?.voucherCode)
                    .map((order) => order.vouchers[0].voucherCode);
                break;
            default:
                break;
        }

        // Remove duplicates using Set
        setOrderDataForSearch([...new Set(filteredData)]);
    };

    // Re-filter data whenever searchField changes
    useEffect(() => {
        filterOrders();
    }, [searchField, orderDataUnMutated]);

    const isRegistered = localStorage.getItem('isRegistered');

    useEffect(() => {
        if (warningModalOkayClicked && isRegistered === REGISTERED) {
            window.location.reload();
        }
    }, [history, warningModalOkayClicked, isRegistered]);

    useEffect(() => {
        if (isRegistered === NOT_REGISTERED && !clicked) {
            setWarningModalBody(NOT_REGISTERED_WARNING_BODY);
            setWarningModalTitle(NOT_REGISTERED_WARNING_TITLE);
            setShowWarningModal(true);
        }
    }, [clicked, isRegistered, showWarningModal]);

    useEffect(() => {
        if (warningModalOkayClicked && isRegistered === NOT_REGISTERED) {
            setClicked(true);
            history.push('/adminProfile');
        }
    }, [history, isRegistered, warningModalOkayClicked]);

    const handleApplyBulkActionBtnClick = () => {
        setApplyBulkActionClicked(true);
    };

    useEffect(() => {
        const filtered = orderDataInitial;

        setSelectedOneOrderData(filtered);
        setFilteredData(filtered); //future need
    }, [pageIndex, pageSize, orderDataInitial, searchTerm, isFilterClicked]);

    useEffect(() => {
        if (selectedOrders?.length > 1) {
            if (selectedTab !== 2 && selectedTab !== 5) {
                setShowBulkActions(true);
            }
        } else {
            setShowBulkActions(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrders]);

    useEffect(() => {
        const bulkActionList = [];
        if (selectedTab === 1) {
            bulkActionList.push(
                {
                    text: 'Move to Complete',
                    value: BULK_ACTION_MOVE_TO_COMPLETE,
                },
                {
                    text: 'Move to Cancel',
                    value: BULK_ACTION_MOVE_TO_CANCELED,
                },

                {
                    text: 'Move to Refunded',
                    value: BULK_ACTION_REFUNDED,
                },
            );
        }
        if (selectedTab === 3) {
            bulkActionList.push(
                {
                    text: 'Move to Complete',
                    value: BULK_ACTION_MOVE_TO_COMPLETE,
                },

                {
                    text: 'Move to Cancel',
                    value: BULK_ACTION_MOVE_TO_CANCELED,
                },
            );
        }
        if (selectedTab === 4) {
            bulkActionList.push({
                text: 'Move to Refund',
                value: BULK_ACTION_REFUNDED,
            });
        }
        setBulkActionsMenuItems(bulkActionList);
    }, [selectedTab]);

    useEffect(() => {
        const orderCounts = {
            all:
                totalCompletedOrders +
                totalProcessingOrders +
                totalCancelledOrders +
                totalRefundedOrders +
                totalDeletedOrders,
            completed: totalCompletedOrders,
            processing: totalProcessingOrders,
            cancelled: totalCancelledOrders,
            refunded: totalRefundedOrders,
            trashed: totalDeletedOrders,
        };

        setOrderStatus(orderCounts);
    }, [
        totalItems,
        totalCompletedOrders,
        totalProcessingOrders,
        totalCancelledOrders,
        totalRefundedOrders,
        totalDeletedOrders,
    ]);

    useEffect(() => {
        setCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab, pageSize, createdDateFilter]);

    useEffect(() => {
        if (!isFilterClicked) {
            setCreatedDateFilter(null);
        }
    }, [isFilterClicked]);

    const columns = React.useMemo(
        () => [
            {
                id: 'transactionId',
                Header: 'Transaction Id',
                fieldName: 'transactionId',
                accessor: 'transactionId',
                Cell: function decorateCell({ value }) {
                    return (
                        <span className="cursor-pointer underline text-blue-600">
                            {loadingViewOne ? <NormalLoader /> : value}
                        </span>
                    );
                },
            },
            {
                id: 'userId',
                Header: 'User Id',
                fieldName: 'gamerId',
                accessor: 'gamerId',
            },
            {
                id: 'displayName',
                Header: 'Display name',
                fieldName: 'gamerName',
                accessor: 'gamerName',
            },
            {
                id: 'gamerEmail',
                Header: 'Gamer email',
                fieldName: 'gamerEmail',
                accessor: 'gamerEmail',
            },
            {
                id: 'itemName',
                Header: 'Item name (purchased)',
                fieldName: 'item.itemName',
                accessor: 'item.itemName',
            },
            {
                id: 'contentType',
                Header: 'Content Type',
                fieldName: 'contentType',
                accessor: 'contentType',
            },
            {
                id: 'supplier',
                Header: 'Supplier',
                fieldName: 'item.supplier.supplierName',
                accessor: 'item.supplier.supplierName',
            },
            {
                id: 'purchaseType',
                Header: 'Purchase Type',
                fieldName: 'purchaseMedium',
                accessor: 'purchaseMedium',
            },
            {
                id: 'purchaseCurrency',
                Header: 'Purchase Currency',
                fieldName: 'targetCurrency',
                accessor: 'targetCurrency',
                Cell: function decorateCell({ value, row }) {
                    if (row?.original?.purchaseMedium === PurchaseMedium.POINTS) {
                        return (
                            <div className="flex flex-row">
                                <Images
                                    width="w-6"
                                    height="h-full"
                                    src={'/images/icons/coin.svg'}
                                    alt="coin"
                                    customStyle="pl-4 pr-2.5"
                                />
                                <span className="pt-0.5">{`PTS`}</span>
                            </div>
                        );
                    } else {
                        return (
                            <div className="flex">
                                <i
                                    className={`${currencyAndCountryCodes
                                        .find((x) => x.currency === value)
                                        ?.country.toLowerCase()} flag px-5 pt-1`}
                                ></i>
                                <span>{value}</span>
                            </div>
                        );
                    }
                },
            },
            {
                id: 'voucherCode',
                Header: 'Voucher code',
                fieldName: 'vouchers.voucherCode',
                accessor: 'vouchers[0].voucherCode',
                Cell: function ({ row }) {
                    const voucherCode =
                        row?.original?.vouchers[0]?.voucherCode ||
                        row?.original?.vouchers[0]?.voucherSerial2;
                    return <span id={voucherCode}>{voucherCode}</span>;
                },
            },
            {
                id: 'partner',
                Header: 'Partner',
                fieldName: 'partner.partnerName',
                accessor: 'partner.partnerName',
            },
            {
                Header: 'Status',
                fieldName: 'orderStatus',
                accessor: 'orderStatus',
                Cell: function showBadge({ value }) {
                    return (
                        <span
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                value === COMPLETE_ORDERS
                                    ? 'bg-green-100 text-green-600'
                                    : value === CANCELLED_ORDERS
                                    ? 'bg-red-100 text-red-600'
                                    : value === REFUNDED_ORDERS
                                    ? 'bg-gray-100 text-gray-600'
                                    : value === PROCESSING_ORDERS
                                    ? 'bg-yellow-100 text-yellow-600'
                                    : ''
                            }`}
                        >
                            {value}
                        </span>
                    );
                },
            },
            {
                Header: 'Order date',
                fieldName: 'createdDate',
                accessor: 'createdDate',
                Cell: function date({ value }) {
                    return <span>{value?.slice(0, 16) + '(UTC)'}</span>;
                },
            },
            {
                id: 'voucherExpiry',
                Header: 'Voucher expiry',
                fieldName: 'vouchers.voucherExpiry',
                accessor: 'vouchers[0].voucherExpiry',
            },
            {
                id: 'voucherSerial',
                Header: 'Voucher name',
                fieldName: 'vouchers.voucherSerial',
                accessor: 'vouchers[0].voucherSerial',
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
        }
    };

    const orderDateOptions = [
        {
            text: 'Last 7 days',
            value: 'LAST_WEEK',
        },
        {
            text: 'All orders',
            value: 'ALL_ORDERS',
        },
    ];

    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm]);

    useEffect(() => {
        let startDateInstance;
        let endDateInstance;
        state?.map((date) => (startDateInstance = moment(date?.startDate).utcOffset(0, true)));
        state?.map((date) => (endDateInstance = moment(date?.endDate).utcOffset(0, true)));
        const startDate = startDateInstance.startOf('day').valueOf();
        const endDate = endDateInstance.endOf('day').valueOf();

        if (startDate || endDate) {
            setOrderFilter(true);
        }

        loadOrders({
            variables: {
                filter: {
                    orderStatus:
                        selectedTab === 2
                            ? 'COMPLETED'
                            : selectedTab === 3
                            ? 'PROCESSING'
                            : selectedTab === 4
                            ? 'CANCELLED'
                            : selectedTab === 5
                            ? 'REFUNDED'
                            : null,
                    dateStartTimeMillis:
                        startDate && startDate && startDate < endDate ? startDate : null,
                    dateEndTimeMillis:
                        endDate && startDate && endDate && startDate < endDate
                            ? endDate
                            : isFilterClicked && !endDate && startDate
                            ? startDateInstance.endOf('day').valueOf()
                            : null,
                    suppliers: selectedSupplierId,
                    partners: selectedPartnerIds,
                },
                offset: currentPage - 1 || 0,
                limit: Math.ceil(pageSize) || 1000000,
                sort: sortField,
                order: sortState,
                search: {
                    searchField: searchField,
                    searchText: searchTerm || null,
                },
            },
        });
    }, [
        currentPage,
        loadOrders,
        searchField,
        pageSize,
        isFilterClicked,
        selectedTab,
        searchTerm,
        state,
        sortField,
        sortState,
        selectedPartnerIds,
        selectedSupplierId,
    ]);

    return (
        <div className="border-2 border-gray-300 rounded-lg mr-7 mb-10">
            <div className="w-full ml-4 pt-4">
                {showToast && (
                    <Toast
                        setShowToast={setShowToast}
                        message={message}
                        width="w-10/12"
                        margin="ml-1"
                    />
                )}
                <div className={`${showToast ? 'mt-14' : null}`}>
                    <TableTabView
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        orderCounts={orderStatus}
                    />
                    <ConfirmationModalWithMessage
                        showConfirmationModal={showConfirmationModal}
                        setShowConfirmationModal={setShowConfirmationModal}
                        message={confirmationMessage}
                        messageTitle={confirmationMessageTitle}
                        setYesBtnClick={setConfirmationModalYesClicked}
                        setNoBtnClick={setConfirmationModalNoClicked}
                    />
                    <WarningMessageModal
                        showWarningModal={showWarningModal}
                        setShowWarningModal={setShowWarningModal}
                        warningModalBody={warningModalBody}
                        warningModalTitle={warningModalTitle}
                        setProcessFinishedClicked={setWarningModalOkayClicked}
                    />
                    <ViewOneOrder
                        showVerifyModal={viewOrderModalVisible}
                        setShowVerifyModal={setViewOrderModalVisible}
                        setLoadingViewOne={setLoadingViewOne}
                    />
                </div>
            </div>
            <div className={`flex flex-row w-full p-4`}>
                <div className="flex flex-row w-full">
                    <div className="flex realative lg:mr-4">
                        <button
                            onKeyDown={(e) => {
                                if (e.code === 'Enter') {
                                    setIsFilterClicked(!isFilterClicked);
                                }
                            }}
                            onClick={() => setIsFilterClicked(!isFilterClicked)}
                            className={`bg-transparent hover:bg-purple-500 hover:text-white py-2 px-4 border border-gray-300 hover:border-transparent rounded-md ${
                                isFilterClicked ? 'bg-purple-500 text-white' : 'text-gray-500'
                            }`}
                        >
                            <span className="flex">
                                <BsIcons.BsFillFunnelFill /> <span className="ml-2">Filter</span>
                            </span>
                        </button>
                    </div>
                    <div className="relative flex items-center">
                        <Dropdown
                            className="dropdownProfile connected-dropdown"
                            id="searchField"
                            value={searchField}
                            selectOnBlur
                            wrapSelection
                            selection
                            defaultValue={'transactionId'}
                            onChange={(e, { value }) => {
                                setSearchField(String(value));
                            }}
                            options={searchOptions}
                            pointing="top"
                            icon={<Icon name="chevron down" className="iconChevron"></Icon>}
                        />
                        <div>
                            <SearchBar
                                className="order-search"
                                id="order-list-search-field"
                                options={orderDataForSearch || []}
                                handleChange={(e, newVal, reason) => {
                                    handleChange(e, newVal, reason);
                                    setSearchClicked(true);
                                }}
                                placeHolder={''}
                                noOptionsText={EMPTY_SEARCH_RESULT_TEXT}
                            />
                        </div>
                    </div>
                    {showBulkActions && (
                        <div className="flex flex-row relative space-x-2 px-2 ml-10">
                            <select
                                className="pl-2 pr-8 z-0 rounded-md outline-none border font-poppins bg-white border-gray-400 hover:border-purple-500"
                                id="stockStatus"
                                placeholder="actions"
                                onChange={(e) => {
                                    setBulkAction(e.target.value);
                                }}
                                onBlur={(e) => setBulkAction(e.target.value)}
                            >
                                <option value="select" key="select" className="py-1 rounded-md">
                                    Select Action
                                </option>
                                {bulkActionsMenuItems.map((option: any, index) => {
                                    return (
                                        <option value={option.value} key={index} className="py-2">
                                            {option.text}
                                        </option>
                                    );
                                })}
                            </select>
                            <button
                                className="bg-purple-500 text-white rounded-md px-8 font-poppins text-center font-bold"
                                onClick={() => handleApplyBulkActionBtnClick()}
                            >
                                Apply
                            </button>
                        </div>
                    )}

                    <div className="flex flex-row relative space-x-2 px-2 ml-10">
                        <div className="flex flex-wrap flex-col relative">
                            <select
                                className="px-4 z-0 rounded-lg outline-none border h-full bg-white border-gray-400 hover:border-purple-500"
                                id="filter_product_type"
                                placeholder="actions"
                                onChange={(e) => {
                                    setSelectedOrderDateFilter(e.target.value);
                                    setCurrentPage(1);
                                    if (e.target.value === 'ALL_ORDERS') {
                                        setState([
                                            {
                                                startDate: new Date(),
                                                endDate: null,
                                                key: 'selection',
                                            },
                                        ]);
                                    } else if (e.target.value === 'LAST_WEEK') {
                                        setState([
                                            {
                                                startDate: new Date(
                                                    new Date().setDate(new Date().getDate() - 7),
                                                ),
                                                endDate: new Date(),
                                                key: 'selection',
                                            },
                                        ]);
                                    }
                                }}
                                value={selectedOrderDateFilter}
                            >
                                <option value="CUSTOM" disabled selected id="filter_product_type">
                                    Custom
                                </option>
                                {orderDateOptions.map((option: any, index) => {
                                    return (
                                        <option
                                            value={option.value}
                                            className={'font-medium'}
                                            key={index}
                                        >
                                            {option.text.slice(0, 13)}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {isFilterClicked && (
                <div className="flex flex-wrap ">
                    <div className="mt-3 px-4 mb-6">
                        <div className="flex flex-wrap flex-col relative">
                            <div className="text-gray-600 w-40 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3.5 ml-4">
                                Filter by created date
                            </div>
                            <DateRange
                                editableDateInputs={true}
                                onChange={(item) => {
                                    setState([item.selection]);
                                    setOrderFilter(true);
                                    setSelectedOrderDateFilter('CUSTOM');
                                    setCurrentPage(1);
                                }}
                                moveRangeOnFirstSelection={false}
                                ranges={state}
                            />
                        </div>
                    </div>
                    <div className="mt-3 mb-6 pt-3 ml-6 w-96">
                        <MultiSelectSearchBar
                            id="partner-multi-select"
                            dropDownOptions={partnerOptions}
                            labelName="Filter By Partner"
                            handleSelectedValues={handleSelectedPartnerValues}
                            removeSelectedValues={handleRemovedPartnerValues}
                            onClearSelection={handleClearSelectionCallback}
                        />
                    </div>
                    <div className="mt-3 px-4 pt-3 h-5 w-96">
                        <SearchBar
                            options={supplierOptions}
                            placeHolder="Filter By Supplier"
                            id="filter-supplier"
                            noOptionsText={'Select Supplier'}
                            handleChange={handleSelectedSupplier}
                            labelHeading="Filter By Supplier"
                            labelPlaceHolder="Select Supplier Filter"
                            value={selectedSupplier || ''}
                        />
                    </div>
                </div>
            )}

            {isFilterClicked &&
            createdDateFilterRemoved &&
            (selectedSupplier?.length > 0 || selectedPartners?.length > 0) ? (
                <div className="bg-purple-100 w-full h-auto flex py-2 px-4 my-5">
                    <div className="w-full -mt-2">
                        <div className="flex flex-col">
                            <div className="text-purple-500 font-poppins  flex justify-between p-5 -mt-1">
                                <div className="flex flex-wrap w-11/12 gap-5">
                                    {createdDateFilter == null && selectedSupplier?.length > 0 ? (
                                        <div className="border-2 border-purple-300 w-auto h-auto flex py-2 px-3 rounded-md mr-5">
                                            <div className="font-poppins mt-1 mr-3">
                                                {selectedSupplier}
                                            </div>
                                            <button
                                                className="text-purple-700 font-bold  text-lg"
                                                onClick={() => {
                                                    setCreatedDateFilter(null);
                                                    setSelectedSupplier(null);
                                                    setCreatedDateFilterRemoved(true);
                                                    setSelectedSupplier(null);
                                                }}
                                            >
                                                x
                                            </button>
                                        </div>
                                    ) : null}

                                    {selectedPartners &&
                                        selectedPartners.map((partner) => (
                                            <div
                                                className="border-2 border-purple-300 w-auto h-auto flex py-2 px-3 rounded-md mr-5"
                                                key={partner.cat}
                                            >
                                                <div className="font-poppins mt-1 mr-3">
                                                    {partner.value}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                                {createdDateFilterRemoved ? (
                                    <div className="w-1/12 p-3 border-l-2 border-gray-300">
                                        <div className="flex underline text-lg w-full h-full justify-center items-center">
                                            <button
                                                className="text-purple-400 font-bold  text-lg"
                                                onClick={() => {
                                                    if (clearSelectionRef.current) {
                                                        clearSelectionRef.current();
                                                    }
                                                    setCreatedDateFilter(null);
                                                    setCreatedDateFilterRemoved(true);
                                                    setSelectedPartner([]);
                                                    setSelectedSupplier(null);
                                                    handleRemovedPartnerValues([]);
                                                }}
                                            >
                                                Clear all
                                            </button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {orderLoading ? (
                <div className="w-full">
                    <div className="m-auto flex flex-col">
                        <div className="m-auto p-10">
                            <Loader />
                        </div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                </div>
            ) : (
                <div className="w-full">
                    <>
                        {selectedOneOrderData?.length ? (
                            <DataTable
                                columns={columns}
                                data={selectedOneOrderData}
                                pageCount={pageCount} //future usage
                                setPageIndex={setPageIndex} //future usage
                                serverSidePagination={true}
                                setSelectedItems={setSelectedOrders}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSizes={pageSize}
                                // customizedPageSize={Math.ceil(totalItems / totalPages)}
                                totalItems={totalItems}
                                setDefaultPageSize={setPageSize}
                                menuItems={instantEditMenuItems}
                                setSelectedMenuItem={setSelectedMenuItem}
                                setExposeMenuClickedItem={setClickedOrder}
                                checkbox={true}
                                setExposeCellClickedItem={setClickedOrder}
                                isOrderTable={true}
                                selectedTab={selectedTab}
                                setSortState={setSortState}
                                setSortField={setSortField}
                                isInitialLoad={isInitialLoad}
                                setIsInitialLoad={setIsInitialLoad}
                            />
                        ) : isFilterClicked && !selectedOneOrderData?.length ? (
                            <div className="m-auto flex flex-col ">
                                <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                    {columns?.map((column, i) => {
                                        return (
                                            <div
                                                className="text-black font-poppins font-semibold flex ml-12"
                                                key={i}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10">
                                    There aren’t any matches found for the applied filters.
                                </div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        ) : searchClicked && !selectedOneOrderData?.length ? (
                            <div className="m-auto flex flex-col ">
                                <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                    {columns?.map((column, i) => {
                                        return (
                                            <div
                                                className="text-black font-poppins font-semibold flex ml-12"
                                                key={i}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10 flex">
                                    Your search
                                    <div className="text-purple-500 mx-2">{`"${searchTerm}"`}</div>
                                    did not match any results
                                </div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        ) : (
                            <div className="m-auto flex flex-col">
                                <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                    {columns?.map((column, i) => {
                                        return (
                                            <div
                                                className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                key={i}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10">No data found.</div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        )}
                    </>
                </div>
            )}
        </div>
    );
}
