export enum JobType {
    SUPPLIER_JOB = 'SUPPLIER_JOB',
    COMMON_JOB = 'COMMON_JOB',
}

export enum SchedulerMethodType {
    API_UNIPIN_VOUCHER_UPDATE = 'API_UNIPIN_VOUCHER_UPDATE',
    API_UNIPIN_TOPUP_UPDATE = 'API_UNIPIN_TOPUP_UPDATE',
    API_BUKALAPAK_VOUCHER_UPDATE = 'API_BUKALAPAK_VOUCHER_UPDATE',
    API_BUKALAPAK_TOPUP_UPDATE = 'API_BUKALAPAK_TOPUP_UPDATE',
}

export enum JobTriggerStatus {
    FAILED = 'FAILED',
    COMPLETED = 'COMPLETED',
}
