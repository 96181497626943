import { useState, useEffect } from 'react';
import * as BsIcons from 'react-icons/bs';
import { Redirect, Route } from 'react-router-dom';
import NavBar from '../components/templates/NavBar';
import SideBar from '../components/templates/SideBar';
import InnerSideBarElementsView from '../components/ui/molecules/InnerSideBarElementAdd';
import ErrorHandling from '../service/ErrorHandling';
import { getCookie } from '../utils/cookiesService';

interface Props {
    children: any;
    navProps: any;
    crumbs: any;
    sidebarElements: any;
    edit?: boolean;
    view?: boolean;
}
const ScreensWithNavAndSideBarAndBreadCrumbAndViewSideBar = ({
    children,
    navProps,
    crumbs,
}: Props) => (
    <>
        <div className="container w-screen h-screen">
            <div className="flex flex-row w-screen">
                <div className="container relative bg-black w-1/3 lg:w-1/4 xl:w-1/6 h-full">
                    <div className="fixed w-1/3 lg:w-1/4 xl:w-1/6">
                        <SideBar />
                    </div>
                </div>

                <div className="flex w-2/3 lg:w-3/4 xl:w-5/6">
                    <div className="fixed w-2/3 lg:w-3/4 xl:w-5/6 z-50 bg-gray-50">
                        <NavBar props={navProps} />
                        <div className="pl-6 pb-4 mt-6 w-full border-b-2">
                            <div className="flex">
                                {crumbs.map((crumb, index) => (
                                    <div
                                        className="flex items-center font-poppins text-purple-500"
                                        key={crumb}
                                    >
                                        {crumb}{' '}
                                        {crumbs.length - 1 !== index ? (
                                            <div className="mx-3">
                                                <BsIcons.BsChevronRight />
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="pt-16 mt-24 flex w-full">
                        <div className="w-64 border-2 border-gray-100 -mt-5 fixed bg-gray-100  h-screen">
                            <InnerSideBarElementsView />
                        </div>
                        <div className="p-3 ml-56 w-full">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    </>
);

function ProtectedRoutesWithLayoutAndBreadcrumbAndViewSideBar({
    navProps,
    crumbs,
    sidebarElements,
    component: Component,
    ...restOfProps
}) {
    const token = getCookie('access_token');
    const [noToken, setNoToken] = useState(false);

    useEffect(() => {
        if (!token && !localStorage.getItem('Refreshed')) {
            setNoToken(true);
        }
    }, [token]);
    return (
        <Route
            exact
            {...restOfProps}
            render={(props) =>
                noToken ? (
                    <Redirect to="/" />
                ) : (
                    <ScreensWithNavAndSideBarAndBreadCrumbAndViewSideBar
                        crumbs={crumbs}
                        navProps={navProps}
                        sidebarElements={sidebarElements}
                    >
                        <ErrorHandling>
                            <Component {...props} />
                        </ErrorHandling>
                    </ScreensWithNavAndSideBarAndBreadCrumbAndViewSideBar>
                )
            }
        />
    );
}

export default ProtectedRoutesWithLayoutAndBreadcrumbAndViewSideBar;
