import { gql } from '@apollo/client';

export const FETCH_PRODUCT_GROUPS = gql`
    query (
        $filter: ProductGroupFilterInput
        $offset: Int!
        $limit: Int!
        $searchText: String
        $sort: String
        $order: SortOrder
    ) {
        productGroups(
            filter: $filter
            offset: $offset
            limit: $limit
            searchText: $searchText
            sort: $sort
            order: $order
        ) {
            productGroups {
                id
                productGroupId
                name
                displayName
                description
                bannerImage
                thumbnail
                productType
                featured
                urlMask
                productsCount
                isActive
                isDeleted
                itemOrder
                productsCount
            }
            currentPage
            totalProductGroups
            totalFilteredProductGroups
            totalPages
            totalActiveProductGroups
            totalInactiveProductGroups
            totalDeletedProductGroups
        }
    }
`;

export const FETCH_PRODUCT_GROUPS_BY_CATEGORY_ID = gql`
    query ($categoryId: ID!) {
        productGroupsByCategory(categoryId: $categoryId) {
            id
            productGroupId
            name
            displayName
            description
            bannerImage
            thumbnail
            productType
            featured
            urlMask
            productsCount
            isActive
            isDeleted
            itemOrder
            productsCount
        }
    }
`;

export const ADD_PRODUCT_GROUPS = gql`
    mutation ($productGroup: ProductGroupInput!) {
        saveProductGroup(productGroup: $productGroup) {
            id
            productGroupId
            name
        }
    }
`;

export const FETCH_PRODUCTS_GROUPS_BY_ID = gql`
    query ($productGroupId: ID!) {
        productGroup(productGroupId: $productGroupId) {
            id
            productGroupId
            name
            displayName
            description
            bannerImage
            thumbnail
            productType
            featured
            urlMask
            itemOrder
            items {
                id
                itemId
                itemName
                itemStatus
                contentType
                isStockAvailable
                supplier {
                    supplierName
                }
                product {
                    productType
                    orderStrategy
                    productId
                    productCode
                }
                deals {
                    id
                    endDateTime
                    dealName
                }
                mappedCatalogs {
                    catalogId
                    catalogName
                    dealExist
                }
            }
            categories {
                id
                categoryName
            }
            catalogs {
                catalogId
                catalogName
            }
        }
    }
`;
