import React, { Component } from 'react';

export default class ErrorHandling extends Component<any, any> {
    //used react error boundary
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getDerivedStateFromError(error) {
        return {
            error: error,
            hasError: true,
        };
    }
    render() {
        if (this.state.hasError) {
            return (
                <div className="ml-10 w-11/12 border-2 border-red-700 pr-10 p-2 bg-red-100  rounded-md mr-20">
                    <div className="flex ">
                        <div className=" pt-1 text-lg text-red-700 font-poppins">
                            Oops something went wrong!
                            <p> {this.state.error.message} </p>
                            <p> {this.state.error.stack} </p>
                        </div>
                        <div className="pl-2 pt-1 text-lg text-black">Please</div>
                        <div
                            className="pl-2 pt-1 text-lg text-purple-500 underline"
                            role="button"
                            tabIndex={0}
                            onKeyDown={() => {
                                window?.location?.reload();
                            }}
                            onClick={() => {
                                window?.location?.reload();
                            }}
                        >
                            refresh
                        </div>
                        <div className="pl-2 pt-1 text-lg text-black"> the page!</div>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}
