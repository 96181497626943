import { createTheme, MuiThemeProvider, Tooltip } from '@material-ui/core';
import React from 'react';
import { BsChevronDown, BsChevronUp, BsExclamationCircle } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { DUPLICATE_PRODUCT_REORDER_TOOLTIP_TEXT } from '../../../constants/product';
import { setOrderListSortReducer } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';

interface ButtonProps {
    column: any;
    showReorderTooltip: boolean;
    index: number;
    handleSortTable?: (value: string, isTableSorted: boolean) => void;
}

export const DataTableHeaderItems: React.FunctionComponent<ButtonProps> = ({
    column,
    showReorderTooltip,
    index,
    handleSortTable,
}) => {
    const theme = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    color: 'white',
                    backgroundColor: '#F4B32B',
                },
                arrow: {
                    color: '#F4B32B',
                },
            },
        },
    });

    const dispatch = useDispatch();
    const sortState = useSelector((state: RootState) => state.sortState.sortState);

    const columnSortHandler = (columnId: string) => {
        if (columnId && columnId !== '' && columnId !== undefined) {
            if (columnId !== sortState.sortField) {
                dispatch(
                    setOrderListSortReducer({
                        sortField: columnId,
                        isColumnSorted: false,
                    }),
                );
                handleSortTable(columnId, false);
            } else {
                dispatch(
                    setOrderListSortReducer({
                        sortField: columnId,
                        isColumnSorted: !sortState.isColumnSorted,
                    }),
                );
                handleSortTable(columnId, !sortState.isColumnSorted);
            }
        }
    };

    return (
        <th
            {...column.getHeaderProps()}
            className={`py-4 px-3 text-left text-sm text-gray-500 font-poppins min-w-max ${
                column.id === 'statusItem' ||
                column.id === 'stockCount' ||
                column.id === 'categories'
                    ? 'w-1/6 min-w-max'
                    : null
            } ${column.id === 'unitPrice' ? 'text-right' : 'text-left'} ${
                column.fieldName !== undefined && column.fieldName !== ''
                    ? 'cursor-pointer'
                    : 'cursor-default'
            }`}
            onClick={() => columnSortHandler(column.fieldName)}
        >
            <div className="flex items-center">
                <span
                    className={
                        sortState.sortField === column.fieldName
                            ? 'text-purple-400'
                            : 'text-gray-500'
                    }
                >
                    {column.render('Header')}
                </span>
                <button
                    hidden={
                        column.fieldName === undefined || column.fieldName === '' || index === 0
                    }
                >
                    {sortState.sortField === column.fieldName && !sortState.isColumnSorted ? (
                        <BsChevronDown
                            size={15}
                            className={`ml-2 ${
                                sortState.sortField === column.fieldName
                                    ? 'text-purple-400'
                                    : 'text-gray-500'
                            }`}
                            strokeWidth={2}
                        />
                    ) : (
                        <BsChevronUp
                            size={15}
                            className={`ml-2 ${
                                sortState.sortField === column.fieldName
                                    ? 'text-purple-400'
                                    : 'text-gray-500'
                            }`}
                            strokeWidth={2}
                        />
                    )}
                </button>
            </div>
            {showReorderTooltip && column.id === 'itemId' && (
                <MuiThemeProvider theme={theme}>
                    <Tooltip
                        key={column.id}
                        title={DUPLICATE_PRODUCT_REORDER_TOOLTIP_TEXT}
                        arrow
                        placement="top-start"
                    >
                        <span className="ml-1 inline-block items-center w-full min-w-max">
                            <BsExclamationCircle color="#ffad03" size="14" />
                        </span>
                    </Tooltip>
                </MuiThemeProvider>
            )}
        </th>
    );
};
