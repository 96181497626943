/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { pagination } from '../../../constants/config/constants';
import { PRODUCT_POINTS_TABLE_TYPE } from '../../../constants/partner';
import { VOUCHER_STATUS_AVAILABLE } from '../../../constants/supplier';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { FETCH_PRODUCTS_BY_ID } from '../../../queries/ItemQueries';
import { FETCH_PARTNER_DETAILS_FOR_PARTNER_CONVERSIONS } from '../../../queries/PartnerQueries';
import { FETCH_GLOBAL_POINTS_LIST } from '../../../queries/PointsQueries';
import { RootState } from '../../../redux/rootReducer';
import { roundToNearest } from '../../../helpers/valueRoundHelper.helpers';
import Loader from '../../../utils/loader';
import DataTable from '../../ui/organisms/DataTable';
import { InputField } from '../../ui/atoms/InputField';
import { currencyAndCountryCodes } from '../../../utils/currencies';
import DataTableAlter from '../../ui/organisms/DataTableAlter';
import { FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID } from '../../../queries/ProductQueries';
import {
    getFinalMarkedUpPrice,
    getFinalPointsValue,
} from '../../../helpers/CurrencyPriceHelper.helpers';

export default function ViewPricingDetails() {
    const parameters = useParams();
    const [partnersData, setPartnerData] = useState([]);
    const [pointsData, setPointsData] = useState([]);
    const [processedConversions, setProcessedConversions] = useState([]);
    const [conversionTableData, setConversionTableData] = useState([]);
    const [clickedProduct, setClickedProduct] = useState(null);
    const [selectedPartners, setSelectedPartners] = useState([]);
    const [productData, setProductData] = useState(null);
    const [conversionRateValue, setConversionRateValue] = useState(1);
    const [unitPriceValue, setUnitPriceValue] = useState(null);
    const [voucherInventories, setVoucherInventories] = useState([]);
    const [voucherLastInventories, setVoucherLastInventories] = useState([]);
    const [partnerConversionsData, setPartnerConversionsData] = useState([]);
    // const [contentType, setContenttype] = useState("");

    const itemID = parameters['id'];

    const { loading: selectedItemLoading } = useQuery(FETCH_PRODUCTS_BY_ID, {
        variables: { itemId: itemID },
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setProductData(data?.item);
            // setContenttype(data?.contentType);
        },
    });

    const { loading: partnerConversionsDataLoading } = useQuery(
        FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID,
        {
            variables: { itemId: itemID || productData?.id },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            context: { clientName: ClientsEnum.STORE },
            onCompleted: (data) => {
                setPartnerConversionsData(data?.partnerConversions);
            },
        },
    );

    const columns = React.useMemo(
        () => [
            {
                Header: 'Partner ID',
                accessor: 'partnerId',
            },
            {
                Header: 'Partner Name',
                accessor: 'partnerName',
            },
            {
                Header: 'Marked-up Percentage (DCB Currency)',
                accessor: 'markedUpPercentageForCurrency',
                Cell: function dispayMarkedUpPercentageForCurrency({ value }) {
                    return <span>{value ? value : 'N/A'}</span>;
                },
            },
            {
                Header: 'Marked-up Percentage (Points)',
                accessor: 'markedUpPercentage',
                Cell: function dispayMarkedUpPercentage({ value }) {
                    return <span>{value ? value : 'N/A'}</span>;
                },
            },
            {
                Header: 'Marked-up Percentage (PSP Currency)',
                accessor: 'markedUpPercentageForPsp',
                Cell: function displayMarkedUpPercentageForPsp({ value }) {
                    return <span>{value || 'N/A'}</span>;
                },
            },
            {
                id: 'markupPrice',
                Header: 'Final Marked-up Price (DCB Local)',
                accessor: '',
                Cell: function dispayMarkedUpPrice({ row }) {
                    return getFinalMarkedUpPrice(
                        row?.original?.overriddenMarkedUpPriceForCurrency,
                        row?.original?.markedUpPriceForCurrency,
                    );
                },
            },
            {
                Header: 'Final Marked-up Price (PSP Local)',
                accessor: '',
                Cell: function displayMarkedUpPriceForPsp({ row }) {
                    return getFinalMarkedUpPrice(
                        row?.original?.overriddenMarkedUpPriceForPsp,
                        row?.original?.markedUpPriceForPsp,
                    );
                },
            },
            {
                Header: 'Local Currency',
                accessor: 'localCurrency',
                Cell: function dispayLocalCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                id: 'pointsConversionRate',
                Header: 'Points Conversion Rate',
                accessor: 'localCurrencyConversionRate',
                Cell: function dispayPointsConversionPoints({ value }) {
                    return <span>{value ? value : 'N/A'}</span>;
                },
            },
            {
                id: 'itemPoints',
                Header: 'Final Retail Price',
                accessor: '',
                Cell: function dispayFinalRoundedPointsValue({ row }) {
                    return getFinalPointsValue(
                        row?.original?.finalRetailPrice,
                        row?.original?.finalRoundedPointsValue,
                    );
                },
            },
        ],
        [],
    );

    const { loading: partnerDataLoading, data } = useQuery(
        FETCH_PARTNER_DETAILS_FOR_PARTNER_CONVERSIONS,
        {
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-and-network',

            onCompleted: (data) => {
                const activePartners = data?.partners?.filter((partner) => partner?.isActive);
                setPartnerData(activePartners);
            },
        },
    );

    const { loading: pointsLoadinG } = useQuery(FETCH_GLOBAL_POINTS_LIST, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        context: { clientName: ClientsEnum.STORE },
        onCompleted: (data) => {
            setPointsData(data?.globalPointsList);
        },
    });

    const calculateMarkedUpPrice = (unitPrice, markedUpPercentage) => {
        if (unitPrice && markedUpPercentage) {
            return ((1 + markedUpPercentage / 100) * unitPrice).toFixed(2);
        } else {
            return '0.00';
        }
    };

    const getGlobalPointsConversionRate = (partnerId, supplierId) => {
        const pointsValue =
            pointsData?.find(
                (points) =>
                    points?.partner?.id === partnerId?.toString() &&
                    points?.supplier?.id === supplierId,
            )?.overriddenValue ||
            pointsData?.find(
                (points) =>
                    points?.partner?.id === partnerId?.toString() &&
                    points?.supplier?.id === supplierId,
            )?.defaultValue;

        return pointsValue;
    };

    useEffect(() => {
        const availableVoucher = productData?.product?.voucherInventories;
        const availableIds = availableVoucher?.map((voc) => voc?.id);

        if (availableIds?.length) {
            const firstVoucherID =
                availableIds?.length > 1 ? Math.max(...availableIds) : availableIds[0];
            const firstVoucher = productData?.product?.voucherInventories?.find(
                (voucher) => voucher?.id === firstVoucherID.toString(),
            );
            setVoucherLastInventories(firstVoucher);
        }
    }, [productData]);

    useEffect(() => {
        if (conversionRateValue && voucherInventories['unitPrice']) {
            const result = parseFloat(
                (voucherInventories['unitPrice'] * conversionRateValue).toString(),
            ).toFixed(2);
            setUnitPriceValue(result);
        } else if (
            conversionRateValue &&
            !voucherInventories['unitPrice'] &&
            voucherLastInventories['unitPrice']
        ) {
            const result = parseFloat(
                (voucherLastInventories['unitPrice'] * conversionRateValue).toString(),
            ).toFixed(2);
            setUnitPriceValue(result);
        } else if (!conversionRateValue) {
            setUnitPriceValue(
                voucherLastInventories['unitPrice']
                    ? voucherLastInventories['unitPrice'].toFixed(2)
                    : voucherLastInventories['unitPrice']
                    ? voucherLastInventories['unitPrice'].toFixed(2)
                    : 0,
            );
        }
    }, [conversionRateValue, setUnitPriceValue, voucherInventories, voucherLastInventories]);

    useEffect(() => {
        if (productData && partnersData) {
            if (productData?.itemPartnerPoints?.length > 0) {
                const overriddenPartnerIds = new Set(
                    productData?.itemPartnerPoints?.map((point) => point?.partner?.id),
                );

                const overriddenConversions = productData?.itemPartnerPoints?.map((point) => ({
                    id: point?.partner?.id,
                    partnerName: point?.partner?.partnerName,
                    pid: point?.partner?.partnerId,
                    pointsConversionRate: point?.pointsConversionRate
                        ? point?.pointsConversionRate
                        : getGlobalPointsConversionRate(
                              point?.partner?.id,
                              productData?.supplier?.id,
                          ),
                    itemId: productData?.id,
                    markupPrice: point?.markedUpPrice
                        ? point?.markedUpPrice
                        : calculateMarkedUpPrice(
                              unitPriceValue,
                              point?.partner?.markedUpPercentage,
                          ),
                    itemPoints: point?.pointsValue
                        ? point?.pointsValue
                        : (point?.markedUpPrice ||
                              calculateMarkedUpPrice(
                                  unitPriceValue,
                                  point?.partner?.markedUpPercentage,
                              )) *
                          (point?.pointsConversionRate ||
                              getGlobalPointsConversionRate(
                                  point?.partner?.id,
                                  productData?.supplier?.id,
                              )),
                    markedUpPercentage: point?.partner?.markedUpPercentage,
                    isOverriddenValue: true,
                    isOverriddenValueConversionPoint: point?.pointsConversionRate ? true : false,
                    isOverriddenValueMarkedUpPercentage: point?.markedUpPrice ? true : false,
                }));

                const partnerSupplierGlobalConversions = partnersData?.map((partner) => ({
                    id: partner?.id,
                    partnerName: partner?.partnerName,
                    pid: partner?.partnerId,
                    pointsConversionRate: getGlobalPointsConversionRate(
                        partner?.id,
                        productData?.supplier?.id,
                    ),
                    itemId: productData?.id,
                    markedUpPercentage: partner?.markedUpPercentage,
                    markupPrice: calculateMarkedUpPrice(
                        unitPriceValue,
                        partner?.markedUpPercentage,
                    ),
                    itemPoints:
                        parseFloat(
                            calculateMarkedUpPrice(unitPriceValue, partner?.markedUpPercentage),
                        ) * getGlobalPointsConversionRate(partner?.id, productData?.supplier?.id),
                    isOverriddenValue: false,
                    isOverriddenValueConversionPoint: false,
                    isOverriddenValueMarkedUpPercentage: false,
                }));

                const filteredGlobalConversions = partnerSupplierGlobalConversions?.filter(
                    (partnerConversion) => !overriddenPartnerIds?.has(partnerConversion.id),
                );

                setProcessedConversions([...overriddenConversions, ...filteredGlobalConversions]);
            } else {
                setProcessedConversions(
                    partnersData?.map((partner) => ({
                        id: partner?.id,
                        partnerName: partner?.partnerName,
                        pid: partner?.partnerId,
                        pointsConversionRate: getGlobalPointsConversionRate(
                            partner?.id,
                            productData?.supplier?.id,
                        ),
                        itemId: productData?.id,
                        markupPrice: calculateMarkedUpPrice(
                            unitPriceValue,
                            partner?.markedUpPercentage,
                        ),
                        itemPoints:
                            parseFloat(
                                calculateMarkedUpPrice(unitPriceValue, partner?.markedUpPercentage),
                            ) *
                            getGlobalPointsConversionRate(partner?.id, productData?.supplier?.id),
                        markedUpPercentage: partner?.markedUpPercentage,
                        isOverriddenValue: false,
                        isOverriddenValueConversionPoint: partner?.pointsConversionRate
                            ? true
                            : false,
                        isOverriddenValueMarkedUpPercentage: partner?.markedUpPrice ? true : false,
                    })),
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productData, pointsData, partnersData, unitPriceValue]);

    useEffect(() => {
        const availableVoucher = productData?.product?.voucherInventories?.filter((voucher) => {
            return voucher?.status === VOUCHER_STATUS_AVAILABLE;
        });
        const availableIds = availableVoucher?.map((voc) => voc?.id);

        if (availableIds?.length) {
            const firstVoucherID =
                availableIds?.length > 1 ? Math.min(...availableIds) : availableIds[0];
            const firstVoucher = productData?.product?.voucherInventories?.find(
                (voucher) => voucher?.id === firstVoucherID.toString(),
            );
            setVoucherInventories(firstVoucher);
        }
    }, [productData]);

    useEffect(() => {
        if (productData) {
            setConversionRateValue(productData?.currencyConversionRate);
        }
    }, [productData]);

    useEffect(() => {
        if (processedConversions && processedConversions?.length > 0) {
            const sortedResult = processedConversions?.sort((a, b) => a.id - b.id);
            setConversionTableData(sortedResult);
        }
    }, [processedConversions, setConversionTableData]);

    if (selectedItemLoading) return <Loader />;
    if (partnerDataLoading) return <Loader />;
    if (partnerConversionsDataLoading) return <Loader />;
    return (
        <div className="px-8 flex flex-col justify-between">
            <div className="text-lg font-poppins font-bold ">Pricing</div>
            {/* <div className="grid grid-cols-8 gap-4 mt-8">
                <div className="col-start-1 col-end-4 -mt-1">
                    <InputField
                        id="price"
                        placeHolder={`Cost of the product in ${
                            productData?.product?.currency ? productData?.product?.currency : ''
                        }`}
                        name={`Product cost ${
                            productData?.product?.currency
                                ? '(' + productData?.product?.currency + ')'
                                : ''
                        }`}
                        value={
                            voucherInventories['unitPrice']
                                ? voucherInventories['unitPrice'].toFixed(2)
                                : voucherLastInventories['unitPrice']
                                ? voucherLastInventories['unitPrice'].toFixed(2)
                                : 0
                        }
                        readonly
                    />
                </div>
                <div className="col-start-4 col-end-7 -mt-1">
                    <InputField
                        id="conversionRate"
                        placeHolder={`${
                            productData?.product?.currency
                                ? productData?.product?.currency + ' to USD conversion rate'
                                : 'Conversion rate'
                        }`}
                        name={`Conversion rate ${
                            productData?.product?.currency
                                ? '(' + productData?.product?.currency + ' to USD)'
                                : ''
                        }`}
                        readonly
                        value={conversionRateValue}
                        type="number"
                    />
                </div>
                <div className="col-start-7 col-end-9 -mt-1">
                    <InputField
                        id="unitPrice"
                        placeHolder="Unit price in USD"
                        name={`Converted product value(USD)`}
                        type="number"
                        value={unitPriceValue || '0.00'}
                        readonly
                    />
                </div>
            </div> */}
            <div className="grid grid-cols-8 gap-4 mt-8 mb-10">
                <div className="col-start-1 col-end-4 -mt-1">
                    <div className="font-poppins font-semibold text-lg">
                        Product cost & Currency
                    </div>
                    <div className="flex flex-row pt-2 font-poppins font-semibold text-gray-500 text-base mt-1">
                        <div className="mr-1">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === voucherInventories['currency'])
                                    ?.country.toLowerCase()} flag px-1 pb-0.5`}
                            ></i>
                        </div>
                        <div>
                            {voucherInventories['currency']
                                ? voucherInventories['currency']
                                : voucherLastInventories['currency']
                                ? voucherLastInventories['currency']
                                : ''}{' '}
                            {voucherInventories['unitPrice']
                                ? voucherInventories['unitPrice'].toFixed(2)
                                : voucherLastInventories['unitPrice']
                                ? voucherLastInventories['unitPrice'].toFixed(2)
                                : 0}
                        </div>
                    </div>
                </div>
                <div className="col-start-6 col-end-6">
                    <div className="flex flex-wrap flex-col relative w-full">
                        <div className="text-gray-500 w-24 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                            Content Type
                        </div>
                        <input
                            className="px-8 py-3 rounded-xl outline-none border-2  bg-white border-gray-400"
                            id="addProduct_type"
                            value={productData?.contentType}
                            readOnly={true}
                        />
                    </div>
                </div>
            </div>
            <div className="w-full py-4 h-full mb-auto">
                <div className=" border-2 rounded-md w-10/12">
                    <div className="text-lg font-poppins font-bold py-5 px-2 ">
                        Product - Partner conversions
                    </div>
                    {partnerConversionsData?.length > 0 ? (
                        <DataTableAlter
                            columns={columns}
                            data={partnerConversionsData}
                            pageCount={1}
                            setSelectedItems={setSelectedPartners}
                            customizedPageSize={partnerConversionsData?.length}
                            checkbox={false}
                            tableType={PRODUCT_POINTS_TABLE_TYPE}
                            clickedItemPointsData={clickedProduct}
                            hidePagination={true}
                        />
                    ) : (
                        <div className="m-auto flex flex-col ">
                            <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                {columns?.map((column, index) => {
                                    return (
                                        <div
                                            className="text-gray-600 font-poppins font-semibold flex ml-12"
                                            key={index}
                                        >
                                            {column?.Header}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="m-auto p-10">No data found.</div>
                            <div className="p-10 bg-purple-100"></div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
