/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { BsTrash } from 'react-icons/bs';
import { pagination } from '../../../constants/config/constants';
import {
    ALLSERV,
    API,
    CONFIGURATIONS_ERROR_MESSAGE,
    CSV,
    CSV_VOUCHERS,
    DELETE,
    DELETE_FILES_BODY,
    DELETE_FILE_BODY,
    DELETE_PRODUCT_BODY,
    DELETE_VOUCHERS_BODY,
    DELETE_VOUCHER_BODY,
    ERROR,
    ERROR_CSV_UPLOAD_VALIDATION,
    ERROR_MESSAGE,
    PRODUCT_UPDATING_ERROR_MESSAGE,
    SUCCESS,
    SUCCESS_CSV_CONFIRM,
    SUCCESS_MESSAGE_DELETE_FILE,
    SUCCESS_MESSAGE_DELETE_FILE_UNPUBLISHED,
    SUCCESS_MESSAGE_DELETE_PRODUCT,
    SUCCESS_MESSAGE_DELETE_PRODUCT_UNPUBLISHED,
    SUCCESS_MESSAGE_DELETE_VOUCHER,
    SUCCESS_MESSAGE_PRODUCT_UPDATE,
    UNIPIN,
    BUKALAPAK,
    VIEW_SUPPLIER_PAGE,
    VOUCHER_STATUS_AVAILABLE,
    PRODUCT_UPDATE_TIMEOUT_MESSAGE,
} from '../../../constants/supplier';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { FETCH_SUPPLIER_FILES, UPLOAD_CSV } from '../../../queries/CsvQueries';
import { FETCH_SUPPLIER_PRODUCTS, FETCH_SUPPLIER_VOUCHERS } from '../../../queries/ProductQueries';
import { saveSupplierName } from '../../../redux/rootActions';
import Loader from '../../../utils/loader';
import SuccessMessageModal from '../../templates/modals/SuccessMessageModal';
import UploadCsv from '../../templates/modals/UploadCsv';
import WarningMessageModal from '../../templates/modals/WarningMessageModal';
import { Buttons } from '../../ui/atoms/Button';
import DataTable from '../../ui/organisms/DataTable';
import DataTableAlter from '../../ui/organisms/DataTableAlter';
import { InputField } from '../../ui/atoms/InputField';
import {
    DELETE_BATCH,
    DELETE_BATCHES,
    DELETE_PRODUCT,
    DELETE_PRODUCTS,
    DELETE_VOUCHER,
    DELETE_VOUCHERS,
    UPDATE_PRODUCTS,
} from '../../../queries/SupplierQueries';
import Toast from '../../ui/atoms/Toast';
import SearchBar from '../../ui/molecules/SearchBar';
import { FETCH_SUPPLIER_PRODUCTS_BY_ID } from '../../../queries/ItemQueries';
import { SORT_ASC, SORT_DSC } from '../../../constants/common';
import NormalLoader from '../../../utils/normalLoader';
import { BsFillFunnelFill, BsGearFill } from 'react-icons/bs';
import { FileUploadType } from '../../../enums/csvFile';
import UploadTopupConfigurationCsv from '../../templates/modals/UploadTopupConfigurationCsv';
import { FETCH_ORDERFIELDS_CSV_MISMATCHES } from '../../../queries/CsvQueries';
import SupplierConfigurations from './SupplierConfiguration';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { getSupplierProductCode } from '../../../helpers/ItemHelpers.helpers';

interface SupplierProdProps {
    id: number;
    productName: string;
    item: Array<string>;
}

export default function ViewSupplier() {
    const history = useHistory();
    const [draftData, setDraftData] = useState([]);
    const [draftDataPagination, setDraftDataPagination] = useState([]);
    const [paginationError, setPaginationError] = useState([]);
    const [errorList, setErrorList] = useState([]);
    const [searchClicked, setSearchClicked] = useState(false);
    const [fileNames, setFileNames] = useState('');
    const [deleteVoucherCodes, setDeleteVoucherCodes] = useState(false);
    const [deleteFileUploads, setDeleteFileUploads] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [toastError, setToastError] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermFiles, setSearchTermFiles] = useState('');
    const [searchTermVoucher, setSearchTermVoucher] = useState('');
    const [message, setMessage] = useState('');
    const [processFinishedClicked, setProcessFinishedClicked] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [fileData, setFileData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showTopupfieldConfigModal, setShowTopupfieldConfigModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [pageSize, setPageSize] = useState(pagination?.pageSize);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [isFilterClicked, setIsFilterClicked] = useState(false);
    const [pageIndexVoucher, setPageIndexVoucher] = useState(pagination?.pageIndex);
    const [pageSizeVoucher, setPageSizeVoucher] = useState(pagination?.pageSize);
    const [pageCountVoucher, setPageCountVoucher] = useState(pagination?.pageCount);
    const [deleteFileByIdClicked, setDeleteFileByIdClicked] = useState(false);
    const [pageIndexFiles, setPageIndexFiles] = useState(pagination?.pageIndex);
    const [pageSizeFiles, setPageSizeFiles] = useState(pagination?.pageSize);
    const [pageCountFiles, setPageCountFiles] = useState(pagination?.pageCount);

    const [showToast, setShowToast] = useState(false);
    const [pageIndexError, setPageIndexError] = useState(pagination?.pageIndex);
    const [pageSizeError, setPageSizeError] = useState(pagination?.pageSize);
    const [pageCountError, setPageCountError] = useState(pagination?.pageCount);
    const [pageIndexDraftData, setPageIndexDraftData] = useState(pagination?.pageIndex);
    const [pageSizeDraftData, setPageSizeDraftData] = useState(pagination?.pageSize);
    const [pageCountDraftData, setPageCountDraftData] = useState(pagination?.pageCount);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [warningFinishedClicked, setWarningFinishedClicked] = useState(false);
    const location = useLocation<any>();
    const [supplierProducts, setSupplierProducts] = useState([]);
    const [supplierProductsById, setSupplierProductByID] = useState([]);
    const [productsOFFile, setProductsOfFile] = useState([]);
    const [voucherProducts, setVoucherProducts] = useState([]);
    const [files, setFiles] = useState([]);
    const data = location?.state?.supplier;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPageVoucher, setCurrentPageVoucher] = useState(1);
    const [totalVouchers, setTotalVouchers] = useState(0);
    const [currentPageFiles, setCurrentPageFiles] = useState(1);
    const [totalFiles, setTotalFiles] = useState(0);
    const [isDeleted, setIsDeleted] = useState(false);
    const [items, setItems] = useState([]);
    const [suppliersDataInitial, setSupplierDataInitial] = useState([]);
    const [clickedProduct, setClickedProduct] = useState(null);
    const [warningBody, setWarningBody] = useState(null);
    const [warningTitle, setWarningTitle] = useState(null);
    const dispatch = useDispatch();
    const [selectedSupplierProductType, setSelectedSupplierProductType] = useState('VOUCHER');
    const [selectedProductType, setSelectedProductType] = useState(null);
    const [isSelectedProductTypeRemoved, setIsSelectedProductTypeRemoved] = useState(true);
    const [orderFieldsCSVMismatches, setOrderFieldsCSVMismatches] = useState([]);
    const [showToolTip, setShowToolTip] = useState(false);
    const [isOrderFieldsDisabled, setIsOrderFieldsDisabled] = useState(false);
    const [isValidationError, setIsValidationError] = useState(false);
    const [isProductsUploadSuccess, setIsProductsUploadSuccess] = useState(false);
    const validationErrorRef = useRef(null);
    const draftProductsRef = useRef(null);
    const [toastStyle, setToastStyle] = useState('');

    const scrollToValidationErrors = () => {
        window.scrollTo({
            top: validationErrorRef.current.offsetTop - 150,
            behavior: 'smooth',
        });
    };

    const scrollToDraftProducts = () => {
        window.scrollTo({
            top: draftProductsRef.current.offsetTop - 150,
            behavior: 'smooth',
        });
    };

    const refreshPage = () => {
        window.location.reload();
    };

    const supplierProductsSearchOptions = [
        ...(supplierProducts?.map((product) => product?.productName) ?? []),
        ...(supplierProducts?.flatMap((product) => [
            product?.productCode ?? null,
            product?.productId ?? null,
        ]) ?? []),
    ].filter((item) => item !== null && item !== undefined);

    useEffect(() => {
        dispatch(saveSupplierName(data?.supplierName));
    }, [data?.supplierName]);

    const [uploadDraftProducts, { loading: prcoessQueryLoading, error: processQueryError }] =
        useMutation(UPLOAD_CSV, {
            context: { clientName: ClientsEnum.STORE },
            onCompleted: (data) => {
                setSuccess(true);
            },
        });

    const [loadProducts, { loading, error }] = useLazyQuery(FETCH_SUPPLIER_PRODUCTS, {
        context: { clientName: ClientsEnum.STORE },

        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted: (productsData) => {
            setSelectedSupplierProductType(
                productsData?.productsBySupplier?.products[0]?.productType || 'VOUCHER',
            );
            setSupplierProducts(productsData?.productsBySupplier?.products);
            setSupplierDataInitial(productsData?.productsBySupplier?.products);
            if (data?.configuration?.supplierType === BUKALAPAK) {
                setIsOrderFieldsDisabled(true);

                let topupProductsWithNoOrderFields = [];
                let remainingProducts = [];

                productsData?.productsBySupplier?.products?.forEach((product) => {
                    if (product?.productType === 'TOPUP' && !product?.orderFields) {
                        topupProductsWithNoOrderFields = [
                            ...topupProductsWithNoOrderFields,
                            product,
                        ];
                    } else {
                        remainingProducts = [...remainingProducts, product];
                    }
                });

                const sortedProducts = [...remainingProducts, ...topupProductsWithNoOrderFields];
                setSupplierProducts(sortedProducts);
                setSupplierDataInitial(sortedProducts);
            }
            setTotalItems(productsData?.productsBySupplier?.totalProducts);
        },
    });

    const [loadProductsByID, { loading: loadingByID, error: errorByID }] = useLazyQuery(
        FETCH_SUPPLIER_PRODUCTS_BY_ID,
        {
            context: { clientName: ClientsEnum.STORE },

            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
            onCompleted: (data) => {
                setSupplierProductByID(data?.productsBySupplier?.products);
                const supplierProduct = data?.productsBySupplier?.products?.find(
                    (prod) => prod?.id === clickedProduct?.id,
                );

                if (supplierProduct?.item) {
                    setItems(supplierProduct?.item);
                } else {
                    setItems([]);
                }
            },
        },
    );

    const [loadProductsByIDFile, { loading: loadingByFileID, error: errorByFileID }] = useLazyQuery(
        FETCH_SUPPLIER_PRODUCTS_BY_ID,
        {
            context: { clientName: ClientsEnum.STORE },

            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
            onCompleted: (data) => {
                setProductsOfFile(data?.productsBySupplier?.products);
            },
        },
    );

    const [loadVouchers, { loading: voucherLoading, error: voucherError }] = useLazyQuery(
        FETCH_SUPPLIER_VOUCHERS,
        {
            context: { clientName: ClientsEnum.STORE },

            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
            onCompleted: (data) => {
                setVoucherProducts(data?.voucherCodesBySupplier?.vouchers);
                setTotalVouchers(data?.voucherCodesBySupplier?.totalVouchers);
            },
        },
    );

    const [loadFiles, { loading: filesLoading, error: filesError }] = useLazyQuery(
        FETCH_SUPPLIER_FILES,
        {
            context: { clientName: ClientsEnum.STORE },

            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
            onCompleted: (data) => {
                setFiles(data?.csvFiles?.files);
                setTotalFiles(data?.csvFiles?.totalFiles);
            },
        },
    );

    const [deleteProduct, { loading: deleteProductLoading, error: deleteProductError }] =
        useMutation(DELETE_PRODUCT, {
            context: { clientName: ClientsEnum.STORE },
            onCompleted: (data) => {
                setIsDeleted(true);

                loadProducts({
                    variables: {
                        filter: {
                            productStatus: 'PUBLISHED',
                        },
                        supplierId: location?.state?.supplier?.id,
                        offset: currentPage - 1,
                        limit: Math.ceil(pageSize) || 1000000,
                        order: SORT_ASC,
                        searchText: searchTerm && searchClicked ? searchTerm : null,
                    },
                });

                setShowWarningModal(false);
                setShowWarningModal(false);
                setMessage(SUCCESS_MESSAGE_DELETE_PRODUCT);
                setToastError(false);
                setShowToast(true);
                setToastError(false);
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 3000);
            },
            onError: () => {
                setShowWarningModal(false);
                setMessage(ERROR_MESSAGE);
                setToastError(true);
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 3000);
            },
        });

    const handleProductsBulkDelete = () => {
        const idList = selectedProducts?.map((id) => parseInt(id));
        deleteProducts({
            variables: {
                productIds: idList,
            },
        });
    };

    const [deleteProducts, { loading: deleteProductsLoading, error: deleteProductsError }] =
        useMutation(DELETE_PRODUCTS, {
            context: { clientName: ClientsEnum.STORE },
            onCompleted: (data) => {
                setIsDeleted(true);
                loadProducts({
                    variables: {
                        filter: {
                            productStatus:
                                data?.configuration?.supplierType === BUKALAPAK
                                    ? null
                                    : 'PUBLISHED',
                        },
                        supplierId: location?.state?.supplier?.id,
                        offset: currentPage - 1,
                        limit: Math.ceil(pageSize) || 1000000,
                        order: SORT_ASC,
                        searchText: searchTerm && searchClicked ? searchTerm : null,
                    },
                });
                setShowWarningModal(false);
                setShowWarningModal(false);
                setSelectedProducts(null);
                setMessage(SUCCESS_MESSAGE_DELETE_PRODUCT);
                setToastError(false);
                setShowToast(true);
                setToastError(false);
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 3000);
            },
            onError: () => {
                setShowWarningModal(false);
                setMessage(ERROR_MESSAGE);
                setToastError(true);
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 3000);
            },
        });

    const [
        loadOrderFieldsMismatches,
        { loading: orderFieldsMismatchesLoading, error: orderFieldsMismatchesError },
    ] = useLazyQuery(FETCH_ORDERFIELDS_CSV_MISMATCHES, {
        context: { clientName: ClientsEnum.STORE },

        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted: (data) => {
            setOrderFieldsCSVMismatches(data?.getMismatchedOrderFieldsProductsList);
        },
    });

    useEffect(() => {
        setOrderFieldsCSVMismatches([]);
        if (
            data?.configuration &&
            data?.configuration?.supplierType === BUKALAPAK &&
            selectedSupplierProductType == 'TOPUP'
        ) {
            loadOrderFieldsMismatches({
                variables: {
                    supplierId: location?.state?.supplier?.id,
                },
            });
        }
    }, [selectedSupplierProductType, data]);

    useEffect(() => {
        loadProductsByID({
            variables: {
                filter: {
                    productStatus: 'PUBLISHED',
                    status: 'ALL',
                },
                offset: currentPage - 1,
                limit: Math.ceil(pageSize) || 1000000,
                order: SORT_ASC,
                supplierId: location?.state?.supplier?.id,
                searchText: null,
            },
        });
    }, [clickedProduct?.id]);

    useEffect(() => {
        loadProductsByIDFile({
            variables: {
                filter: {
                    productStatus: 'PUBLISHED',
                    status: 'ALL',
                    csvFileId: clickedProduct?.id,
                },
                offset: currentPage - 1,
                limit: Math.ceil(pageSize) || 1000000,
                order: SORT_ASC,
                supplierId: location?.state?.supplier?.id,
                searchText: null,
            },
        });
    }, [clickedProduct?.id]);

    useEffect(() => {
        const supplierProduct = supplierProductsById?.find(
            (prod) => prod?.id === clickedProduct?.id,
        );
        if (supplierProduct?.item && !deleteFileUploads && !deleteVoucherCodes) {
            setWarningBody(
                SUCCESS_MESSAGE_DELETE_PRODUCT_UNPUBLISHED +
                    `\n ➤ ${supplierProduct?.item?.itemName}`,
            );
            setWarningTitle(DELETE);
        } else if (!supplierProduct?.item && !deleteFileUploads && !deleteVoucherCodes) {
            setWarningBody(DELETE_PRODUCT_BODY);
            setWarningTitle(DELETE);
        }
    }, [clickedProduct?.id, supplierProductsById]);

    useEffect(() => {
        const supplierProductItem = productsOFFile?.map((product) => product?.item);
        const itemsList = supplierProductItem.filter((item) => (item ? item : null));

        if (itemsList?.length && deleteFileUploads && !deleteVoucherCodes) {
            setWarningBody(
                SUCCESS_MESSAGE_DELETE_FILE_UNPUBLISHED +
                    `  ${itemsList?.map((e) => {
                        return `\n ➤ ${e?.itemName}`;
                    })}`,
            );
            setWarningTitle(DELETE);
        } else if (!itemsList?.length && deleteFileUploads && !deleteVoucherCodes) {
            setWarningBody(DELETE_FILE_BODY);
            setWarningTitle(DELETE);
        }
    }, [clickedProduct?.id, productsOFFile, deleteFileUploads]);

    const [deleteFile, { loading: deleteFileLoading, error: deleteFileError }] = useMutation(
        DELETE_BATCH,
        {
            context: { clientName: ClientsEnum.STORE },
            onCompleted: (data) => {
                setIsDeleted(true);
                setShowWarningModal(false);
                setMessage(SUCCESS_MESSAGE_DELETE_FILE);
                setToastError(false);
                setShowToast(true);
                loadFiles({
                    variables: {
                        filter: {
                            supplierId: location?.state?.supplier?.id,
                        },
                        offset: currentPageFiles - 1,
                        limit: Math.ceil(pageSizeFiles) || 1000000,
                        searchText: searchTermFiles && searchClicked ? searchTermFiles : null,
                    },
                });
                setTimeout(() => {
                    setShowToast(false);
                }, 3000);
            },
            onError: () => {
                setShowWarningModal(false);
                setMessage(ERROR_MESSAGE);
                setToastError(true);
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 3000);
            },
        },
    );

    const handleFilesBulkDelete = () => {
        const idList = selectedProducts?.map((id) => parseInt(id));
        deleteFiles({
            variables: {
                fileIds: idList,
            },
        });
    };

    const [deleteFiles, { loading: deleteFilesLoading, error: deleteFilesError }] = useMutation(
        DELETE_BATCHES,
        {
            context: { clientName: ClientsEnum.STORE },
            onCompleted: (data) => {
                setIsDeleted(true);
                loadFiles({
                    variables: {
                        filter: {
                            supplierId: location?.state?.supplier?.id,
                        },
                        offset: currentPageFiles - 1,
                        limit: Math.ceil(pageSizeFiles) || 1000000,
                        searchText: searchTermFiles && searchClicked ? searchTermFiles : null,
                        sort: 'fileStatus',
                        order: SORT_ASC,
                    },
                });
                setShowWarningModal(false);
                setSelectedProducts(null);
                setMessage(SUCCESS_MESSAGE_DELETE_FILE);
                setToastError(false);
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 3000);
            },
            onError: () => {
                setShowWarningModal(false);
                setMessage(ERROR_MESSAGE);
                setToastError(true);
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 3000);
            },
        },
    );

    const [deleteVoucher, { loading: deleteVoucherLoading, error: deleteVoucherError }] =
        useMutation(DELETE_VOUCHER, {
            context: { clientName: ClientsEnum.STORE },
            onCompleted: (data) => {
                setIsDeleted(true);
                setShowWarningModal(false);
                setMessage(SUCCESS_MESSAGE_DELETE_VOUCHER);
                setToastError(false);
                setShowToast(true);
                loadVouchers({
                    variables: {
                        supplierId: location?.state?.supplier?.id,
                        offset: currentPageVoucher - 1,
                        limit: Math.ceil(pageSizeVoucher) || 1000000,
                        searchText: searchTermVoucher ? searchTermVoucher : null,
                        sort: 'status',
                        order: SORT_DSC,
                    },
                });
                setTimeout(() => {
                    setShowToast(false);
                }, 3000);
            },
            onError: () => {
                setShowWarningModal(false);
                setMessage(ERROR_MESSAGE);
                setToastError(true);
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 3000);
            },
        });

    const handleVoucherCodesBulkDelete = () => {
        const idList = selectedProducts?.map((id) => parseInt(id));
        deleteVouchers({
            variables: {
                voucherIds: idList,
            },
        });
    };

    const [deleteVouchers, { loading: deleteVouchersLoading, error: deleteVouchersError }] =
        useMutation(DELETE_VOUCHERS, {
            context: { clientName: ClientsEnum.STORE },
            onCompleted: (data) => {
                setIsDeleted(true);
                setShowWarningModal(false);
                setSelectedProducts(null);
                setMessage(SUCCESS_MESSAGE_DELETE_VOUCHER);
                loadVouchers({
                    variables: {
                        supplierId: location?.state?.supplier?.id,
                        offset: currentPageVoucher - 1,
                        limit: Math.ceil(pageSizeVoucher) || 1000000,
                        searchText: searchTermVoucher ? searchTermVoucher : null,
                        sort: 'status',
                        order: SORT_DSC,
                    },
                });
                setToastError(false);
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 3000);
            },
            onError: () => {
                setShowWarningModal(false);
                setMessage(ERROR_MESSAGE);
                setToastError(true);
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 3000);
            },
        });

    const [updateProducts, { loading: updateProductsLoading }] = useMutation(UPDATE_PRODUCTS, {
        context: { clientName: ClientsEnum.STORE },
        onCompleted: () => {
            loadProducts({
                variables: {
                    filter: {
                        productStatus:
                            data?.configuration?.supplierType === BUKALAPAK ? null : 'PUBLISHED',
                        excludeDeletedProducts:
                            data?.configuration?.supplierType === BUKALAPAK ? true : null,
                        status: 'ALL',
                    },
                    supplierId: location?.state?.supplier?.id,
                    offset: currentPage - 1,
                    limit: Math.ceil(pageSize) || 1000000,
                    order: SORT_ASC,
                    searchText: searchTerm ? searchTerm : null,
                    sort: data?.configuration?.supplierType === BUKALAPAK ? 'orderFields' : null,
                },
            });
            setShowWarningModal(false);
            setMessage(SUCCESS_MESSAGE_PRODUCT_UPDATE);
            setToastError(false);
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        },
        onError: (error: any) => {
            setShowWarningModal(false);
            const graphQLErrors = error.graphQLErrors;

            if (graphQLErrors && graphQLErrors.length > 0) {
                if (
                    graphQLErrors[0]?.message?.includes('[401 Unauthorized]') ||
                    graphQLErrors[0]?.message?.includes('malformed configurations')
                ) {
                    setMessage(CONFIGURATIONS_ERROR_MESSAGE);
                    setToastError(true);
                    setShowToast(true);
                    setTimeout(() => {
                        setShowToast(false);
                    }, 3000);
                } else if (graphQLErrors[0]?.message?.includes('timeout')) {
                    setMessage(PRODUCT_UPDATE_TIMEOUT_MESSAGE);
                    setToastError(false);
                    setShowToast(true);
                    setToastStyle('bg-blue-100 text-black-700');
                    // setTimeout(() => {
                    //     setShowToast(false);
                    // }, 3000);
                } else {
                    setMessage(PRODUCT_UPDATING_ERROR_MESSAGE);
                    setToastError(true);
                    setShowToast(true);
                    setTimeout(() => {
                        setShowToast(false);
                    }, 3000);
                }
            } else {
                setMessage(PRODUCT_UPDATING_ERROR_MESSAGE);
                setToastError(true);
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 3000);
            }
        },
    });

    useEffect(() => {
        if (success) {
            setShowSuccessModal(true);
        }
    }, [success]);

    useEffect(() => {
        if (!showModal && isValidationError) {
            scrollToValidationErrors();
            setIsValidationError(false);
            setIsProductsUploadSuccess(false);
        }
    }, [showModal, isValidationError]);

    useEffect(() => {
        if (!showModal && isProductsUploadSuccess) {
            scrollToDraftProducts();
            setIsProductsUploadSuccess(false);
            setIsValidationError(false);
        }
    }, [showModal, isProductsUploadSuccess]);

    useEffect(() => {
        if (warningFinishedClicked) {
            setTimeout(() => {
                setShowWarningModal(false);
            }, 1000);
        }
    }, [warningFinishedClicked]);

    useEffect(() => {
        loadProducts({
            variables: {
                filter: {
                    productStatus:
                        data?.configuration?.supplierType === BUKALAPAK ? null : 'PUBLISHED',
                    excludeDeletedProducts:
                        data?.configuration?.supplierType === BUKALAPAK ? true : null,
                    status: 'ALL',
                    productType: !isFilterClicked ? null : selectedProductType,
                },
                supplierId: location?.state?.supplier?.id,
                offset: currentPage - 1,
                limit: Math.ceil(pageSize) || 1000000,
                order: SORT_ASC,
                searchText: searchTerm ? searchTerm : null,
                sort: data?.configuration?.supplierType === BUKALAPAK ? 'orderFields' : null,
            },
        });
    }, [
        location?.state?.supplier?.id,
        currentPage,
        pageSize,
        isDeleted,
        searchTerm,
        selectedProductType,
        isFilterClicked,
    ]);

    useEffect(() => {
        if (searchTerm.length > 0) {
            setCurrentPage(1);
        }
    }, [searchClicked, searchTerm]);

    useEffect(() => {
        loadVouchers({
            variables: {
                supplierId: location?.state?.supplier?.id,
                offset: currentPageVoucher - 1,
                limit: Math.ceil(pageSizeVoucher) || 1000000,
                searchText: searchTermVoucher ? searchTermVoucher : null,
                sort: 'status',
                order: SORT_DSC,
            },
        });

        if (searchClicked && searchTermVoucher?.length > 0) {
            setCurrentPageVoucher(1);
        }
    }, [
        location?.state?.supplier?.id,
        currentPageVoucher,
        pageSizeVoucher,
        searchClicked,
        searchTermVoucher,
    ]);

    useEffect(() => {
        loadFiles({
            variables: {
                filter: {
                    supplierId: location?.state?.supplier?.id,
                },

                offset: currentPageFiles - 1,
                limit: Math.ceil(pageSizeFiles) || 1000000,
                searchText: searchTermFiles && searchClicked ? searchTermFiles : null,
                sort: 'fileStatus',
                order: SORT_ASC,
            },
        });
    }, [
        location?.state?.supplier?.id,
        currentPageFiles,
        pageSizeFiles,
        searchClicked,
        searchTermFiles,
    ]);

    useEffect(() => {
        if (draftData?.length) {
            setDraftDataPagination(draftData);
            setPaginationError([]);
        }
    }, [
        setDraftDataPagination,
        pageIndexDraftData,
        pageSizeDraftData,
        setPageCountDraftData,
        draftData,
    ]);

    useEffect(() => {
        if (suppliersDataInitial?.length) {
            setSupplierProducts(suppliersDataInitial);
        }
    }, [setSupplierProducts, pageIndex, pageSize, setPageCount, suppliersDataInitial]);

    useEffect(() => {
        if (processFinishedClicked) {
            setTimeout(() => {
                setShowSuccessModal(false);
                history.push('/suppliers');
            }, 1000);
        }
    }, [processFinishedClicked]);

    useEffect(() => {
        if (errorList?.length) {
            const errorLists = errorList?.map((error) => ({
                row: error?.toString().split(',')[0],
                error: error?.toString().split(',')[1],
            }));
            setPaginationError(errorLists);
        }
    }, [errorList?.length, errorList, setPaginationError]);

    useEffect(() => {
        if (paginationError?.length) {
            setPaginationError(paginationError);
        }
    }, [setPaginationError, pageIndexError, pageSizeError, setPageCountError]);

    const theme = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    color: 'white',
                    backgroundColor: '#5c5e5d',
                    fontSize: '12px',
                },
                arrow: {
                    color: '#5c5e5d',
                },
            },
        },
    });

    const coloumnsError = React.useMemo(
        () => [
            {
                Header: 'Row',
                accessor: 'row',
            },
            {
                Header: 'Validation errors',
                accessor: 'error',
            },
        ],
        [],
    );

    const supplierProductsColumn = React.useMemo(
        () => [
            {
                Header: 'Product Name',
                accessor: 'productName',
                id: 'primary-key',
                isViewProductScreen: true,
                isViewSupplierProductHyperLink: true,
                supplierName: data?.supplierName,
                isSupplierTrashed: data?.isDeleted,
            },
            {
                Header: 'Product Origin',
                id: 'orderStrategy',
                accessor: (product) => {
                    return product?.orderStrategy === CSV_VOUCHERS ? CSV : API;
                },
            },
            {
                Header: 'Product Type',
                accessor: 'productType',
            },
            {
                Header: 'Manufacturer',
                accessor: 'manufacturerName',
                isViewProductScreen: true,
            },
            {
                Header: 'SKU',
                accessor: 'productVvssku',
                isViewProductScreen: true,
            },
            {
                Header: 'Available Stocks',
                accessor: 'availableStocks',
                Cell: function availableVoucherInventories({ row }) {
                    return row?.original?.orderStrategy === CSV_VOUCHERS ? (
                        <span id={`${row?.original?.productName}-${row?.original?.availableStock}`}>
                            {row?.original?.availableStock}
                        </span>
                    ) : (
                        <span
                            id={`${row?.original?.productName}-${row?.original?.availableStock}`}
                        >{`-`}</span>
                    );
                },
            },
            {
                Header: 'Total Stocks',
                accessor: 'totalStocks',
                Cell: function voucherInventories({ row }) {
                    return row?.original?.orderStrategy === CSV_VOUCHERS ? (
                        <span id={`${row?.original?.productName}-${row?.original?.totalStock}`}>
                            {row?.original?.totalStock}
                        </span>
                    ) : (
                        <span
                            id={`${row?.original?.productName}-${row?.original?.totalStock}`}
                        >{`-`}</span>
                    );
                },
            },
            {
                Header: 'Price',
                accessor: '',
                Cell: function voucherInventories({ row }) {
                    const voucherInventories = row?.original?.voucherInventories?.filter(
                        (voucher) => {
                            return voucher?.status === VOUCHER_STATUS_AVAILABLE;
                        },
                    );
                    return (
                        <span
                            id={`${row?.original?.productName}-${
                                voucherInventories?.length ? voucherInventories[0]['unitPrice'] : 0
                            }`}
                        >
                            {voucherInventories?.length
                                ? voucherInventories[0]['unitPrice']
                                : row?.original?.voucherInventories[
                                      row?.original?.voucherInventories?.length - 1
                                  ]['unitPrice']}
                        </span>
                    );
                },
            },
            {
                Header: 'Currency',
                accessor: 'currency',
                idDisabled: true,
            },
        ],
        [],
    );

    const allServSupplierProductsColumn = React.useMemo(
        () => [
            {
                Header: 'Supplier Product Code',
                accessor: (product) => {
                    const productCode = getSupplierProductCode(product);
                    return productCode;
                },
            },
            {
                Header: 'Product Name',
                accessor: 'productName',
            },
            {
                Header: 'Product Origin',
                id: 'orderStrategy',
                accessor: (product) => {
                    if (product?.orderStrategy === CSV_VOUCHERS) {
                        return CSV;
                    } else if (product?.orderStrategy.startsWith('ALLSERV')) {
                        return ALLSERV;
                    } else {
                        return API;
                    }
                },
            },
            {
                Header: 'Product Type',
                accessor: 'productType',
            },
            {
                Header: 'Manufacturer',
                accessor: 'manufacturerName',
            },
            {
                Header: 'Price',
                accessor: 'unitPrice',
                Cell: function costPrice({ row }) {
                    return (
                        <div
                            className="text-left"
                            id={`${row?.original?.productName}-${row?.original?.unitPrice}`}
                        >
                            {row?.original?.unitPrice}
                        </div>
                    );
                },
            },
            {
                Header: 'Currency',
                accessor: 'currency',
            },
            {
                Header: 'Note',
                accessor: 'purchaseNote',
            },
        ],
        [],
    );

    const supplierVoucherColumn = React.useMemo(
        () => [
            {
                id: 'actionColumn',
                accessor: '',

                Cell: function delteProd({ row }) {
                    return (
                        <span
                            id={`${row?.original?.voucherCode}-delete-button`}
                            style={{
                                cursor: row?.original?.status === 'UNSOLD' ? 'pointer' : 'default',
                                color: row?.original?.status === 'UNSOLD' ? '#a855f7' : '#95989c',
                            }}
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                if (row?.original?.status === 'UNSOLD') {
                                    setWarningTitle(DELETE);
                                    setWarningBody(DELETE_VOUCHER_BODY);
                                    setShowWarningModal(true);
                                }
                            }}
                            onKeyDown={() => {
                                if (row?.original?.status === 'UNSOLD') {
                                    setWarningTitle(DELETE);
                                    setWarningBody(DELETE_VOUCHER_BODY);
                                    setShowWarningModal(true);
                                }
                            }}
                        >
                            {row?.original?.status === 'UNSOLD' ? (
                                <BsTrash />
                            ) : (
                                <div
                                    data-tooltip="Unavailable: Sold/Deleted"
                                    data-position="right center"
                                >
                                    <BsTrash />
                                </div>
                            )}
                        </span>
                    );
                },
            },
            {
                Header: 'Voucher code',
                accessor: 'voucherCode',
                id: 'primary-key',
                isViewProductScreen: true,
            },
            {
                Header: 'Voucher expiry',
                accessor: 'voucherExpiry',
                isViewProductScreen: true,
            },
            {
                Header: 'Voucher name',
                accessor: 'voucherSerial',
                isViewProductScreen: true,
            },
            {
                Header: 'Voucher pin',
                accessor: 'voucherPin',
                idDisabled: true,
            },
            {
                Header: 'Status',
                accessor: 'status',
                isViewProductScreen: true,
            },
            {
                Header: 'Created date',
                accessor: 'createdDate',
                isViewProductScreen: true,
                idDisabled: true,
            },
        ],
        [],
    );

    const filesColumns = React.useMemo(
        () => [
            {
                Header: 'File name',
                accessor: 'fileName',
                id: 'primary-key',
                isViewProductScreen: true,
                isFileNameHyperLink: true,
                supplierName: data?.supplierName,
                supplierId: data?.id,
            },
            {
                Header: 'File status',
                accessor: 'fileStatus',
                isViewProductScreen: true,
            },
            {
                Header: 'Created date',
                accessor: 'createdDate',
                idDisabled: true,
            },
            {
                Header: 'Available products',
                accessor: 'availableProducts',
                isViewProductScreen: true,
            },
            {
                Header: 'Total products',
                accessor: 'totalProducts',
                isViewProductScreen: true,
            },
            {
                Header: 'Available Vouchers',
                accessor: 'availableVouchers',
                isViewProductScreen: true,
            },
            {
                Header: 'Total Vouchers',
                accessor: 'totalVouchers',
                isViewProductScreen: true,
            },
        ],
        [],
    );

    const allServFilesColumns = React.useMemo(
        () => [
            {
                Header: 'File name',
                accessor: 'fileName',
                id: 'primary-key',
                isViewProductScreen: true,
                isFileNameHyperLink: true,
                supplierName: data?.supplierName,
                supplierId: data?.id,
            },
            {
                Header: 'File status',
                accessor: 'fileStatus',
                isViewProductScreen: true,
            },
            {
                Header: 'Created date',
                accessor: 'createdDate',
                idDisabled: true,
            },
            {
                Header: 'Total products',
                accessor: 'totalProducts',
                isViewProductScreen: true,
            },
        ],
        [],
    );

    useEffect(() => {
        setCurrentPage(1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize]);

    useEffect(() => {
        setCurrentPageFiles(1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSizeFiles]);

    useEffect(() => {
        setCurrentPageVoucher(1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSizeVoucher]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Voucher Code',
                accessor: 'voucherCode',
            },
            {
                Header: 'Publisher',
                accessor: 'publisherName',
            },
            {
                Header: 'Product Name',
                accessor: 'productName',
            },
            {
                Header: 'Voucher Expiry',
                accessor: 'voucherExpiry',
            },
            {
                Header: 'Voucher Pin',
                accessor: 'voucherPin',
            },
            {
                Header: 'Cost Price',
                accessor: 'costPrice',
            },
            {
                Header: 'Currency',
                accessor: 'currency',
            },
            {
                Header: 'Voucher Name',
                accessor: 'voucherSerial',
            },
            {
                Header: 'SKU',
                accessor: 'sku',
            },
            {
                Header: 'Additional Info',
                accessor: 'additionalData',
                Cell: function limit({ value }) {
                    return (
                        <span
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center`}
                        >
                            {value?.slice(0, 50)}
                        </span>
                    );
                },
            },
        ],
        [],
    );

    const allServDraftColumns = React.useMemo(
        () => [
            {
                Header: 'Product Name',
                accessor: 'productName',
            },
            {
                Header: 'Product Code',
                accessor: 'productCode',
            },
            {
                Header: 'Product Type',
                accessor: 'productType',
            },
            {
                Header: 'Manufacturer',
                accessor: 'publisherName',
            },
            {
                Header: 'Price',
                accessor: 'costPrice',
                Cell: function costPrice({ row }) {
                    return (
                        <div
                            className="text-left"
                            id={`${row?.original?.productName}-${row?.original?.costPrice}`}
                        >
                            {row?.original?.costPrice}
                        </div>
                    );
                },
            },
            {
                Header: 'Currency',
                accessor: 'currency',
            },
            {
                Header: 'Note',
                accessor: 'additionalData',
            },
        ],
        [],
    );

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
            setSearchTermFiles(newVal);
            setSearchTermVoucher(newVal);
        }
    };

    const supplierProductTypeOptions = [
        {
            label: 'Voucher',
            value: 'VOUCHER',
        },
        {
            label: 'Topup',
            value: 'TOPUP',
        },
    ];

    const handleUpdateProducts = () => {
        if (selectedSupplierProductType) {
            updateProducts({
                variables: {
                    supplierId: location?.state?.supplier?.supplierId,
                    productType: selectedSupplierProductType,
                },
            });
        }
    };

    const productTypeOpitions = [
        { text: 'Topup', value: 'TOPUP' },
        { text: 'Voucher', value: 'VOUCHER' },
    ];

    return (
        <>
            {showToast ? (
                <Toast
                    setShowToast={setShowToast}
                    error={toastError}
                    message={message}
                    width="w-10/12"
                    margin="ml-1"
                    style={toastStyle ? toastStyle : null}
                />
            ) : null}
            <div className="text-lg font-poppins font-bold px-4 pb-5 justify-start -ml-3">
                Supplier Details
            </div>
            <div className="flex w-full justify-between pr-10 pb-3 mb-4">
                <UploadCsv
                    showModal={showModal}
                    setShowModal={setShowModal}
                    supplierId={data?.id}
                    setDraftData={setDraftData}
                    setFileData={setFileData}
                    setErrorData={setErrorList}
                    setFilesName={setFileNames}
                    setIsValidationError={setIsValidationError}
                    setIsProductsUploadSuccess={setIsProductsUploadSuccess}
                />
                <UploadTopupConfigurationCsv
                    showModal={showTopupfieldConfigModal}
                    setShowModal={setShowTopupfieldConfigModal}
                    supplierId={data?.id}
                    setDraftData={setDraftData}
                    setFileData={setFileData}
                    setErrorData={setErrorList}
                    setFilesName={setFileNames}
                    csvtype={FileUploadType.ORDER_FIELDS_UPLOAD}
                    orderFieldsCSVMismatches={orderFieldsCSVMismatches}
                    supplier={data}
                    handleClose={refreshPage}
                />
                <WarningMessageModal
                    showWarningModal={showWarningModal}
                    setShowWarningModal={setShowWarningModal}
                    cancel={true}
                    onclick={() => {
                        if (deleteFileUploads) {
                            handleFilesBulkDelete();
                        } else if (deleteVoucherCodes) {
                            handleVoucherCodesBulkDelete();
                        } else if (draftData?.length) {
                            setDraftData([]);
                            setShowWarningModal(false);
                        } else {
                            handleProductsBulkDelete();
                        }
                    }}
                    loader={deleteProductsLoading}
                    warningModalBody={warningBody}
                    warningModalTitle={warningTitle}
                    setProcessFinishedClicked={setWarningFinishedClicked}
                />
                {data?.configuration &&
                (data?.configuration?.supplierType === 'CSV' ||
                    data?.configuration?.supplierType === 'ALLSERV') ? (
                    <>
                        <Buttons
                            name="View Supplier Products"
                            type="submit"
                            buttonType="primary"
                            id="view-supplier-products-button"
                            size="e-small"
                            disabled={data?.isDeleted || !data?.isActive}
                            padding="py-4"
                            onclick={() => {
                                setDeleteVoucherCodes(false);
                                setDeleteFileUploads(false);
                            }}
                        />
                        {data?.configuration?.supplierType === 'CSV' ? (
                            <Buttons
                                name="Delete Voucher Codes"
                                type="submit"
                                buttonType="delete"
                                id="delete-voucher-code-button"
                                size="e-small"
                                disabled={data?.isDeleted || !data?.isActive}
                                padding="py-4"
                                onclick={() => {
                                    if (draftData?.length) {
                                        setWarningBody(ERROR_CSV_UPLOAD_VALIDATION);
                                        setWarningTitle(ERROR);
                                        setShowWarningModal(true);
                                    } else {
                                        setDeleteVoucherCodes(true);
                                        setDeleteFileUploads(false);
                                        loadVouchers({
                                            variables: {
                                                supplierId: location?.state?.supplier?.id,
                                                offset: currentPageVoucher - 1,
                                                limit: Math.ceil(pageSizeVoucher) || 1000000,
                                                sort: 'status',
                                                order: SORT_DSC,
                                            },
                                        });
                                    }
                                }}
                            />
                        ) : null}
                        <Buttons
                            name="Delete File Uploads"
                            type="submit"
                            buttonType="delete"
                            id="delete-file-upload-button"
                            size="e-small"
                            disabled={data?.isDeleted || !data?.isActive}
                            padding="py-4"
                            onclick={() => {
                                if (draftData?.length) {
                                    setWarningBody(ERROR_CSV_UPLOAD_VALIDATION);
                                    setWarningTitle(ERROR);
                                    setShowWarningModal(true);
                                } else {
                                    setDeleteFileUploads(true);
                                    setDeleteVoucherCodes(false);
                                    loadFiles({
                                        variables: {
                                            filter: {
                                                supplierId: location?.state?.supplier?.id,
                                            },
                                            offset: currentPageFiles - 1,
                                            limit: Math.ceil(pageSizeFiles) || 1000000,
                                            sort: 'fileStatus',
                                            order: SORT_ASC,
                                        },
                                    });
                                }
                            }}
                        />
                    </>
                ) : null}

                {data?.configuration &&
                (data?.configuration?.supplierType === 'CSV' ||
                    data?.configuration?.supplierType === 'ALLSERV') ? (
                    <Buttons
                        name="Upload File"
                        type="submit"
                        buttonType="primary"
                        id="upload-file-button"
                        size="e-small"
                        disabled={data?.isDeleted || !data?.isActive}
                        padding="py-4"
                        onclick={() => {
                            setDeleteVoucherCodes(false);
                            setDeleteFileUploads(false);
                            if (draftData?.length) {
                                setWarningBody(ERROR_CSV_UPLOAD_VALIDATION);
                                setWarningTitle(ERROR);
                                setShowWarningModal(true);
                            } else {
                                setShowModal(true);
                            }
                        }}
                    />
                ) : null}

                <SuccessMessageModal
                    showSuccessModal={showSuccessModal}
                    setShowSuccessModal={setShowSuccessModal}
                    successModalTitle={SUCCESS}
                    successModalBody={SUCCESS_CSV_CONFIRM}
                    setProcessFinishedClicked={setProcessFinishedClicked}
                />
            </div>

            <div className="flex flex-col h-full justify-between w-full">
                <div className="grid grid-cols-8 gap-4 w-full mb-10">
                    <div className="col-start-1 col-end-4">
                        <InputField
                            readonly={true}
                            id="supplierName"
                            placeHolder={'supplier name'}
                            name="Supplier Name"
                            labelWidth="w-21"
                            value={data?.supplierName}
                        />
                    </div>

                    <div className="col-end-7 col-span-3">
                        <InputField
                            readonly={true}
                            id="supplierId"
                            placeHolder={'supplier id'}
                            name="Supplier ID"
                            labelWidth="w-21"
                            value={data?.supplierId}
                        />
                    </div>

                    <div className="col-start-1 col-end-4">
                        <InputField
                            readonly={true}
                            id="createdDate"
                            placeHolder="Created date"
                            name="Created Date"
                            labelWidth="w-23"
                            value={data?.createdDate?.slice(0, 10)}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-6 gap-4 my-5">
                    {data?.supplierMainType === API && (
                        <>
                            <div className="flex flex-wrap flex-col relative w-full">
                                <div className="text-gray-500 w-24 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                    Product Type
                                </div>
                                <select
                                    className="px-4 py-3 rounded-xl outline-none border-2 bg-white border-gray-400 hover:border-purple-500"
                                    id="select-product-type"
                                    onChange={(e) => {
                                        setSelectedSupplierProductType(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        setSelectedSupplierProductType(e.target.value);
                                    }}
                                    value={selectedSupplierProductType}
                                    disabled={data?.isDeleted || !data?.isActive}
                                >
                                    {supplierProductTypeOptions?.map((option: any, index) => {
                                        return (
                                            <option
                                                value={option.value}
                                                className={'font-medium'}
                                                key={index}
                                            >
                                                {option.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            <Buttons
                                name={
                                    updateProductsLoading || updateProductsLoading ? (
                                        <NormalLoader />
                                    ) : (
                                        'Update Products'
                                    )
                                }
                                type="submit"
                                buttonType="primary"
                                id="update-products"
                                size="large-full"
                                disabled={data?.isDeleted || !data?.isActive}
                                padding="py-4"
                                onclick={() => {
                                    handleUpdateProducts();
                                }}
                            />

                            {data?.configuration &&
                                data?.configuration?.supplierType === BUKALAPAK &&
                                selectedSupplierProductType == 'TOPUP' && (
                                    <div className="flex items-center col-span-2">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setShowTopupfieldConfigModal(true);
                                            }}
                                            id="topup-field-config"
                                            className={`w-fit px-4 py-4 justify-center font-poppins text-center font-bold border-2 rounded-md mr-5 bg-white ${
                                                supplierProducts?.find(
                                                    (supplierProduct) =>
                                                        supplierProduct?.productType === 'TOPUP',
                                                ) === undefined
                                                    ? 'text-gray-400 border-gray-400 cursor-default'
                                                    : 'text-purple-500 border-purple-500 hover:bg-purple-500 hover:text-white'
                                            }`}
                                            disabled={
                                                supplierProducts?.find(
                                                    (supplierProduct) =>
                                                        supplierProduct?.productType === 'TOPUP',
                                                ) === undefined
                                            }
                                        >
                                            <span className="flex">
                                                <BsGearFill />
                                                <span className="ml-2">
                                                    Top-up Field Configuration
                                                </span>
                                            </span>
                                        </button>
                                        <div
                                            onMouseOver={() => setShowToolTip(true)}
                                            onMouseLeave={() => setShowToolTip(false)}
                                            onFocus={() => setShowToolTip(true)}
                                        >
                                            {orderFieldsMismatchesLoading && <NormalLoader />}
                                            {orderFieldsCSVMismatches?.length > 0 && (
                                                <img
                                                    className={`w-10 h-10 ${
                                                        showToolTip ? 'hidden' : 'block'
                                                    }`}
                                                    src="/images/icons/error-svg.svg"
                                                    alt="success"
                                                />
                                            )}
                                            {showToolTip ? (
                                                <MuiThemeProvider theme={theme}>
                                                    <Tooltip
                                                        key={3}
                                                        title={
                                                            'Mismatches have been identified between Top-up products in the system and the uploaded CSV.'
                                                        }
                                                        arrow
                                                        placement="right-start"
                                                    >
                                                        <img
                                                            className="w-10 h-10"
                                                            src="/images/icons/error-svg.svg"
                                                            alt="success"
                                                        />
                                                    </Tooltip>
                                                </MuiThemeProvider>
                                            ) : null}
                                        </div>
                                    </div>
                                )}
                        </>
                    )}
                    <div className="flex gap-4 col-start-1 col-span-2">
                        {data?.configuration &&
                            (data?.configuration?.supplierType == BUKALAPAK ||
                                data?.configuration?.supplierType == UNIPIN) && (
                                <button
                                    onKeyDown={() => {
                                        setIsFilterClicked(!isFilterClicked);
                                    }}
                                    onClick={() => {
                                        setIsFilterClicked(!isFilterClicked);
                                        if (!isFilterClicked) {
                                            setSelectedProductType(null);
                                            setIsSelectedProductTypeRemoved(true);
                                        }
                                    }}
                                    id="product_type_filter"
                                    className={`bg-transparent hover:bg-purple-500 hover:text-white py-2 px-4 border border-gray-300 hover:border-transparent rounded-md ${
                                        isFilterClicked
                                            ? 'bg-purple-500 text-white'
                                            : 'text-gray-500'
                                    }`}
                                >
                                    <span className="flex">
                                        <BsFillFunnelFill /> <span className="ml-2">Filter</span>
                                    </span>
                                </button>
                            )}

                        <div className="w-full text-gray-500" id="product_search">
                            <SearchBar
                                id="view-supplier-search-field"
                                options={
                                    deleteVoucherCodes
                                        ? voucherProducts?.map((prod) => prod?.voucherCode)
                                        : deleteFileUploads
                                        ? files?.map((file) => file?.fileName)
                                        : supplierProductsSearchOptions
                                }
                                handleChange={(e, newVal, reason) => {
                                    setSearchClicked(true);
                                    handleChange(e, newVal, reason);
                                }}
                                placeHolder={'Search'}
                            />
                        </div>
                    </div>

                    {data?.configuration &&
                        (data?.configuration?.supplierType == BUKALAPAK ||
                            data?.configuration?.supplierType == UNIPIN) && (
                            <>
                                {isFilterClicked && (
                                    <div className="flex flex-wrap col-start-1 flex-col relative w-full">
                                        <div className="text-gray-500 font-poppins w-max text-center lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                            Filter by Product Type
                                        </div>
                                        <select
                                            className="px-4 py-3 z-0 rounded-lg outline-none border  bg-white border-gray-400 hover:border-purple-500"
                                            id="productTypes"
                                            placeholder="actions"
                                            onChange={(e) => {
                                                setSelectedProductType(e.target.value);
                                                setIsSelectedProductTypeRemoved(false);
                                            }}
                                        >
                                            {isSelectedProductTypeRemoved ? (
                                                <option value={null} disabled selected>
                                                    Select an option
                                                </option>
                                            ) : null}
                                            {productTypeOpitions.map((option: any, index) => {
                                                return (
                                                    <option
                                                        value={option.value}
                                                        className={'font-medium'}
                                                        key={index}
                                                    >
                                                        {option.text.slice(0, 13)}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                )}

                                {isFilterClicked && !isSelectedProductTypeRemoved && (
                                    <div className="col-start-1 col-span-6 w-full flex justify-between bg-purple-100 text-purple-500 px-5 py-3 mt-1">
                                        {selectedProductType && isFilterClicked && (
                                            <div className="border-2 border-purple-300 flex py-2 px-3 rounded-md mr-5">
                                                <div className="mt-1 mr-3">
                                                    {selectedProductType}
                                                </div>
                                                <button
                                                    className="text-purple-700 font-bold text-lg"
                                                    onClick={() => {
                                                        setSelectedProductType(null);
                                                        setIsSelectedProductTypeRemoved(true);
                                                    }}
                                                >
                                                    x
                                                </button>
                                            </div>
                                        )}

                                        {!isSelectedProductTypeRemoved && (
                                            <button
                                                className="text-purple-400 font-bold text-lg mx-10"
                                                onClick={() => {
                                                    setSelectedProductType(null);
                                                    setIsSelectedProductTypeRemoved(true);
                                                }}
                                            >
                                                Clear All
                                            </button>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                </div>
                {deleteVoucherCodes ? (
                    <div className="w-full border-2 border-gray-300 rounded-lg mr-10 mb-10 mt-9">
                        <div className="flex justify-between py-4 mr-9">
                            <div className="font-poppins text-lg font-bold py-2 px-2 flex">
                                Supplier vouchers
                            </div>
                            {selectedProducts?.length > 0 && (
                                <div className="relative flex flex-row flex-1 justify-end right-0">
                                    <button
                                        className="bg-purple-500 text-white rounded-md px-8 py-3 font-poppins  text-center font-bold"
                                        onClick={() => {
                                            setWarningBody(
                                                selectedProducts?.length == 1
                                                    ? DELETE_VOUCHER_BODY
                                                    : DELETE_VOUCHERS_BODY,
                                            );
                                            setWarningTitle(DELETE);
                                            setShowWarningModal(true);
                                        }}
                                    >
                                        {selectedProducts?.length == 1
                                            ? 'Delete'
                                            : 'Delete All Selected'}
                                    </button>
                                </div>
                            )}
                        </div>

                        <div className="w-full">
                            {voucherLoading ? (
                                <Loader />
                            ) : voucherProducts?.length ? (
                                <DataTable
                                    columns={supplierVoucherColumn}
                                    data={voucherProducts}
                                    setSelectedItems={setSelectedProducts}
                                    serverSidePagination={true}
                                    pageCount={pageCountVoucher}
                                    setPageIndex={setPageIndexVoucher}
                                    setExposeMenuClickedItem={setClickedProduct}
                                    setDefaultPageSize={setPageSizeVoucher}
                                    pageSizes={pageSizeVoucher}
                                    currentPage={currentPageVoucher}
                                    setCurrentPage={setCurrentPageVoucher}
                                    totalItems={totalVouchers}
                                    checkbox={true}
                                    checkboxHeader={true}
                                    isVoucherCodeDelete={true}
                                    isDeleteItemEnabled={true}
                                    isProductVoucherDelete={true}
                                />
                            ) : (
                                <div className="m-auto flex flex-col ">
                                    <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                        {supplierVoucherColumn?.map((column, i) => {
                                            return (
                                                <div
                                                    className="text-black font-poppins font-semibold flex ml-12"
                                                    key={i}
                                                >
                                                    {column?.Header}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="m-auto p-10">No data found.</div>
                                    <div className="p-10 bg-purple-100"></div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : deleteFileUploads ? (
                    <div className="w-full border-2 border-gray-300 rounded-lg mr-10 mb-10 mt-9">
                        <div className="flex justify-between py-3 mr-9">
                            <div className="font-poppins text-lg font-bold py-2 px-2 flex">
                                Supplier csv files
                            </div>
                            {selectedProducts?.length > 0 && (
                                <div className="relative flex flex-row flex-1 justify-end right-0">
                                    <button
                                        className="bg-purple-500 text-white rounded-md px-8 py-3 font-poppins  text-center font-bold"
                                        onClick={() => {
                                            setWarningBody(
                                                selectedProducts?.length == 1
                                                    ? DELETE_FILE_BODY
                                                    : DELETE_FILES_BODY,
                                            );
                                            setWarningTitle(DELETE);
                                            setShowWarningModal(true);
                                        }}
                                    >
                                        {selectedProducts?.length == 1
                                            ? 'Delete'
                                            : 'Delete All Selected'}
                                    </button>
                                </div>
                            )}
                        </div>

                        {filesLoading ? (
                            <Loader />
                        ) : files?.length ? (
                            <DataTable
                                columns={
                                    data.configuration?.supplierType === 'CSV'
                                        ? filesColumns
                                        : allServFilesColumns
                                }
                                data={files}
                                setSelectedItems={setSelectedProducts}
                                serverSidePagination={true}
                                pageCount={pageCountFiles}
                                setPageIndex={setPageIndexFiles}
                                setExposeMenuClickedItem={setClickedProduct}
                                setDefaultPageSize={setPageSizeFiles}
                                pageSizes={pageSizeFiles}
                                currentPage={currentPageFiles}
                                setCurrentPage={setCurrentPageFiles}
                                totalItems={totalFiles}
                                checkbox={true}
                                checkboxHeader={true}
                                isVoucherCodeFileDelete={true}
                                isDeleteItemEnabled={true}
                            />
                        ) : (
                            <div className="m-auto flex flex-col ">
                                <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                    {filesColumns?.map((column, i) => {
                                        return (
                                            <div
                                                className="text-black font-poppins font-semibold flex ml-12"
                                                key={i}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10">No data found.</div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        )}
                    </div>
                ) : !deleteFileUploads && !deleteVoucherCodes ? (
                    <div className="w-full border-2 border-gray-300 rounded-lg mr-10 mb-10 mt-9">
                        <div className="flex justify-between py-3 mr-9">
                            <div className="font-poppins text-lg font-bold py-2 px-2 flex">
                                Supplier Products
                            </div>
                            {selectedProducts?.length > 0 && (
                                <div className="relative flex flex-row flex-1 justify-end right-0">
                                    <button
                                        className="bg-purple-500 text-white rounded-md px-8 py-3 font-poppins  text-center font-bold"
                                        onClick={() => {
                                            setWarningBody(
                                                SUCCESS_MESSAGE_DELETE_PRODUCT_UNPUBLISHED,
                                            );
                                            setWarningTitle(DELETE);
                                            setShowWarningModal(true);
                                        }}
                                    >
                                        {selectedProducts?.length == 1
                                            ? 'Delete'
                                            : 'Delete All Selected'}
                                    </button>
                                </div>
                            )}
                        </div>

                        {loading || updateProductsLoading ? (
                            <Loader />
                        ) : supplierProducts?.length ? (
                            <DataTable
                                columns={
                                    data.configuration?.supplierType === 'CSV'
                                        ? supplierProductsColumn
                                        : allServSupplierProductsColumn
                                }
                                data={supplierProducts}
                                setSelectedItems={setSelectedProducts}
                                serverSidePagination={true}
                                pageCount={pageCount}
                                setPageIndex={setPageIndex}
                                setExposeMenuClickedItem={setClickedProduct}
                                setDefaultPageSize={setPageSize}
                                pageSizes={pageSize}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalItems={totalItems}
                                checkbox={true}
                                checkboxHeader={true}
                                isDeleteItemEnabled={true}
                                currentPageName={isOrderFieldsDisabled ? VIEW_SUPPLIER_PAGE : null}
                            />
                        ) : (
                            <div className="m-auto flex flex-col ">
                                <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                    {data.configuration?.supplierType === 'CSV'
                                        ? supplierProductsColumn?.map((column, i) => {
                                              return (
                                                  <div
                                                      className="text-gray-500 text-sm px-3 text-left font-poppins font-semibold flex"
                                                      key={i}
                                                  >
                                                      {column?.Header}
                                                  </div>
                                              );
                                          })
                                        : allServSupplierProductsColumn?.map((column, i) => {
                                              return (
                                                  <div
                                                      className="text-gray-500 text-sm px-3 text-left font-poppins font-semibold flex"
                                                      key={i}
                                                  >
                                                      {column?.Header}
                                                  </div>
                                              );
                                          })}
                                </div>
                                <div className="m-auto p-10">No data found.</div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        )}
                    </div>
                ) : searchClicked &&
                  !deleteVoucherCodes &&
                  !deleteFileUploads &&
                  !supplierProducts?.length ? (
                    <div className="m-auto p-10 flex">
                        Your search
                        <div className="text-purple-500 mx-2">{`"${searchTerm}"`}</div>
                        did not match any results
                    </div>
                ) : searchClicked &&
                  deleteVoucherCodes &&
                  !deleteFileUploads &&
                  !voucherProducts?.length ? (
                    <div className="m-auto p-10 flex">
                        Your search
                        <div className="text-purple-500 mx-2">{`"${searchTerm}"`}</div>
                        did not match any results
                    </div>
                ) : searchClicked && !deleteVoucherCodes && deleteFileUploads && !files?.length ? (
                    <div className="m-auto p-10 flex">
                        Your search
                        <div className="text-purple-500 mx-2">{`"${searchTerm}"`}</div>
                        did not match any results
                    </div>
                ) : null}

                {draftData?.length && !deleteFileUploads && !deleteVoucherCodes ? (
                    <div
                        ref={draftProductsRef}
                        className="w-full border-2 border-gray-300 rounded-lg mr-7 mb-10 mt-9 "
                    >
                        <div className="flex justify-between py-3 mr-9">
                            <div className="font-poppins text-lg font-bold py-2 px-2 flex">
                                CSV Draft Products -{' '}
                                {<div className="ml-1 font-poppins">{fileNames}</div>}
                            </div>
                        </div>

                        <DataTable
                            columns={
                                data.configuration?.supplierType === 'CSV'
                                    ? columns
                                    : allServDraftColumns
                            }
                            data={draftDataPagination}
                        />
                        <div className="flex justify-end p-3">
                            <Buttons
                                name="Cancel"
                                type="submit"
                                buttonType="secondary-main-grey"
                                id="cancel"
                                size="e-small"
                                padding="py-4 mr-4"
                                onclick={() => {
                                    setDraftData([]);
                                    setPaginationError([]);
                                    setErrorList([]);
                                    setSuccess(false);
                                    setShowModal(false);
                                    setShowSuccessModal(false);
                                    window.location.reload();
                                }}
                            />

                            <Buttons
                                name={prcoessQueryLoading ? <NormalLoader /> : 'Confirm file'}
                                type="submit"
                                buttonType="primary"
                                id="confirm-file"
                                size="e-small"
                                padding="py-4 mr-4"
                                onclick={() => {
                                    uploadDraftProducts({
                                        variables: {
                                            fileId: fileData,
                                        },
                                    });
                                }}
                            />
                        </div>
                    </div>
                ) : paginationError?.length && !deleteFileUploads && !deleteVoucherCodes ? (
                    <div
                        ref={validationErrorRef}
                        className="w-full border-2 border-gray-300 rounded-lg mr-7 mb-10 mt-9 "
                    >
                        <div className="flex justify-between py-3 mr-9">
                            <div className="font-poppins text-lg font-bold py-2 px-2 flex">
                                List of validation errors form csv -{' '}
                                {<div className="ml-1 font-poppins">{fileNames}</div>}
                            </div>
                        </div>
                        <DataTableAlter columns={coloumnsError} data={paginationError} />
                    </div>
                ) : null}
            </div>
        </>
    );
}
