/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    BASE_URL_AGREEMENT_UPLOAD_AWS_S3,
    PARTNER_ADD_SUCCESS_BODY,
    PARTNER_ADD_SUCCESS_TITLE,
    PARTNER_SAVED_SUCCESSFULLY,
} from '../../../../constants/partner';
import {
    FETCH_ADMIN_ONE_PROFILE_ID,
    GET_PARTNER_DATA,
    UPDATE_ADMIN_PROFILE,
} from '../../../../queries/AdminProfileQueries';
import { savePartnerName } from '../../../../redux/rootActions';
import { RootState } from '../../../../redux/rootReducer';
import NormalLoader from '../../../../utils/normalLoader';
import UploadDocumentComponent from '../../../templates/modals/UploadDocumentComponent';
import { Buttons } from '../../../ui/atoms/Button';
import DataTable from '../../../ui/organisms/DataTable';
import { FETCH_PARTNER_DETAILS_BY_ID } from '../../../../queries/PartnerQueries';
import jwtDecode from 'jwt-decode';
import { getCookie } from '../../../../utils/cookiesService';
import Toast from '../../../ui/atoms/Toast';

export default function AddNewPartnerAgreementView() {
    const history = useHistory();
    const dispatch = useDispatch();
    const partnerId = localStorage.getItem('partnerId');
    const createdPartner = useSelector((state: RootState) => state.auth.partner);

    const [file, setFile] = useState(null);
    const [maxSize, setMaxSize] = useState(5);
    const [selectedItem, setSelectedItems] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [s3DirName, setS3DirName] = useState('');
    const [fileLocation, setFileLocation] = useState(null);
    const [agreementsData, setAgreementsData] = useState(null);
    const [partnerData, setPartnerData] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');

    const token = getCookie('access_token');
    const tokenDetails: any = token ? jwtDecode(token) : null;

    const { loading: partnerDataLoading } = useQuery(FETCH_PARTNER_DETAILS_BY_ID, {
        variables: { partnerId: createdPartner?.partner?.id || partnerId },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setPartnerData(data?.partner);
        },
    });

    const { data: loggedInUserData } = useQuery(FETCH_ADMIN_ONE_PROFILE_ID, {
        fetchPolicy: 'network-only',
        variables: {
            userName: tokenDetails?.preferred_username,
        },
    });

    const [updatePartner, { loading: queryLoading, error: queryError }] = useMutation(
        UPDATE_ADMIN_PROFILE,
        {
            refetchQueries: [GET_PARTNER_DATA],
            onCompleted() {
                setMessage(PARTNER_SAVED_SUCCESSFULLY);
                setShowToast(true);
                setTimeout(() => {
                    history.push('/partners');
                    setShowToast(false);
                }, 2000);
            },
            onError(error) {
                setShowToast(true);
                setIsError(true);
                setMessage('Something went wrong while updating partner');
                console.log(error);
            },
        },
    );

    const handleUploadedFile = () => {
        const agreements = [];
        const agreement = {
            url: fileLocation,
            isActive: true,
            fileName: file?.name,
            fileSize: file?.size,
            fileType: file?.type,
        };

        if (fileLocation) {
            agreements.push(agreement);
        }

        updatePartner({
            variables: {
                userId: partnerData?.users[0]?.userId,
                userName: partnerData?.users[0]?.userName,
                user: {
                    firstName: loggedInUserData?.user?.firstName,
                    lastName: loggedInUserData?.user?.lastName,
                    contact: {
                        primaryEmail: partnerData?.users[0]?.contact?.primaryEmail,
                        recoveryEmail: partnerData?.users[0]?.contact?.primaryEmail,
                        countryCode: partnerData?.users[0]?.contact?.countryCode,
                        phoneNumber: partnerData?.users[0]?.contact?.phoneNumber,
                    },
                    partner: {
                        partnerId: partnerData?.partnerId || createdPartner?.partner?.partnerId,
                        partnerName: partnerData?.partnerName,
                        partnerUrl: partnerData?.partnerUrl,
                        creditLimit: partnerData?.creditLimit,
                        createdBy: loggedInUserData?.user?.userId,
                        modifiedBy: 0,
                        isActive: true,
                        partnerAgreements: agreements,
                        markedUpPercentageForCurrency: partnerData?.markedUpPercentageForCurrency,
                        markedUpPercentage: partnerData?.markedUpPercentage,
                    },
                },
            },
        });
    };

    const handleDisplayAgreement = useCallback(() => {
        if (file) {
            const agreementDataList = [];
            const agreementObj = {
                url: fileLocation,
                isActive: true,
                fileName: file?.name,
                fileSize: (file?.size * 0.001).toFixed(2),
                fileType: file?.type,
            };
            agreementDataList.push(agreementObj);
            setAgreementsData(agreementDataList);
        }
    }, [file, fileLocation]);

    useEffect(() => {
        if (createdPartner) {
            setS3DirName(BASE_URL_AGREEMENT_UPLOAD_AWS_S3 + createdPartner?.partner?.id + '/');
        }
    }, [createdPartner]);

    useEffect(() => {
        if (file && file !== null) {
            handleDisplayAgreement();
        }
    }, [file, handleDisplayAgreement]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'fileName',
            },
            {
                Header: 'File Type',
                accessor: 'fileType',
            },
            {
                Header: 'File Size (KB)',
                accessor: 'fileSize',
            },
        ],
        [],
    );

    return (
        <>
            {showToast && (
                <Toast
                    setShowToast={setShowToast}
                    message={message}
                    error={isError}
                    width="w-10/12"
                    margin="-mt-10 ml-10"
                    selfDestruct={true}
                    selfDestructTimer={5000}
                />
            )}
            <div className="h-full flex flex-col justify-evenly">
                <div className="text-lg font-poppins font-bold px-8">Agreements</div>
                <div className="font-poppins px-8 pt-6 pb-4">
                    Upload partner agreement documents
                </div>
                <div className="pl-8 pr-4 pt-2 h-64">
                    <UploadDocumentComponent
                        isEdit={false}
                        setFile={setFile}
                        maxSize={maxSize}
                        uploadDocTypeHint={
                            'You can upload your agreement here in pdf or docx format.'
                        }
                        s3DirName={s3DirName}
                        setFileLocation={setFileLocation}
                        isCsv={false}
                        setErrorTitle={setErrorTitle}
                        setErrorMessage={setErrorMessage}
                    />
                </div>
                <hr className="px-8 border mt-14 border-gray-200" />
                <div className="flex flex-col justify-evenly ml-8 mt-8 mb-24 border-2 border-gray-200 rounded-md">
                    <div className="font-poppins text-lg font-bold px-8 py-4 mr-16">
                        Uploaded Documents
                    </div>
                    <div className="rounded-md">
                        {agreementsData && (
                            <DataTable
                                columns={columns}
                                data={agreementsData}
                                pageCount={1}
                                setSelectedItems={setSelectedItems}
                                setPageIndex={setPageIndex}
                                setDefaultPageSize={setPageSize}
                                hidePagination={true}
                                hideSelection={true}
                            />
                        )}
                    </div>
                </div>
                <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0">
                    <Buttons
                        name="Cancel"
                        type="button"
                        buttonType="secondary-border-black"
                        id="Cancel"
                        size="e-small"
                        other="mr-3"
                        onclick={() => {
                            history.push('/partners');
                        }}
                    />
                    <Buttons
                        name={queryLoading ? <NormalLoader /> : 'Save'}
                        type="submit"
                        buttonType="primary"
                        id="update"
                        size="e-small"
                        onclick={() => {
                            handleUploadedFile();
                            dispatch(savePartnerName(createdPartner?.firstName));
                        }}
                    />
                </div>
            </div>
        </>
    );
}
