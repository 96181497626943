import { Buttons } from '../../../ui/atoms/Button';
import { InputField } from '../../../ui/atoms/InputField';
import cronstrue from 'cronstrue';
import { SchedulerMethodType } from '../../../../enums/job';

interface Props {
    jobInfo: any;
    handleRemoveJobClick: () => void;
}

export default function JobInfoViewer({ jobInfo, handleRemoveJobClick }: Props) {
    return (
        <>
            <div className="border-2 rounded-lg mr-12">
                <div className="grid grid-cols-8 gap-4 p-4">
                    <div className="col-start-1 col-end-3">
                        <div className="flex flex-wrap flex-col relative">
                            <InputField
                                id="jobId"
                                name="Job Id"
                                labelWidth="w-28"
                                readonly={true}
                                value={jobInfo?.jobId || ''}
                            />
                        </div>
                    </div>
                    <div className="col-start-3 col-end-5">
                        <div className="flex flex-wrap flex-col relative">
                            <InputField
                                id="jobName"
                                name="Job Name"
                                labelWidth="w-28"
                                readonly={true}
                                value={jobInfo?.jobName || ''}
                            />
                        </div>
                    </div>
                    <div className="col-start-5 col-end-7">
                        <div className="flex flex-wrap flex-col relative">
                            <InputField
                                id="cronExpression"
                                name="Cron Expression"
                                labelWidth="w-28"
                                readonly={true}
                                value={jobInfo?.cronDefinition || ''}
                            />
                        </div>
                    </div>
                    <div className="col-start-7 col-end-9">
                        <div className="flex flex-wrap flex-col relative">
                            <InputField
                                id="description"
                                name="Description"
                                labelWidth="w-28"
                                readonly={true}
                                value={jobInfo?.description || ''}
                            />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-8 gap-4 p-4">
                    <div className="col-start-1 col-end-3">
                        <div className="flex flex-wrap flex-col relative">
                            <InputField
                                id="lastTriggerTime"
                                name="Last Trigger Time"
                                labelWidth="w-28"
                                readonly={true}
                                value={jobInfo?.lastTriggerTime || ''}
                            />
                        </div>
                    </div>
                    <div className="col-start-3 col-end-5">
                        <div className="flex flex-wrap flex-col relative">
                            <InputField
                                id="lastTriggerStatus"
                                name="Last Trigger Status"
                                labelWidth="w-28"
                                readonly={true}
                                value={jobInfo?.lastTriggerStatus || ''}
                            />
                        </div>
                    </div>
                    <div className="col-start-5 col-end-7">
                        <div className="flex flex-wrap flex-col relative">
                            <InputField
                                id="executionTime"
                                name="Execution Time"
                                labelWidth="w-28"
                                readonly={true}
                                value={jobInfo?.executionTime || ''}
                            />
                        </div>
                    </div>
                    <div className="col-start-7 col-end-9">
                        <div className="flex flex-wrap flex-col relative">
                            <InputField
                                id="createdDate"
                                name="Created Date"
                                labelWidth="w-28"
                                readonly={true}
                                value={jobInfo?.createdDate || ''}
                            />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-8 gap-4 p-4">
                    <div className="col-start-1 col-end-4">
                        <div className="flex flex-wrap flex-col relative">
                            <InputField
                                id="cronDescribe"
                                name="Describe Job"
                                labelWidth="w-28"
                                readonly={true}
                                value={cronstrue.toString(jobInfo?.cronDefinition) || ''}
                            />
                        </div>
                    </div>
                    <div className="col-start-4 col-end-6">
                        <div className="flex flex-wrap flex-col relative">
                            <InputField
                                id="supplierProductType"
                                name="Supplier Product Type"
                                labelWidth="w-28"
                                readonly={true}
                                value={
                                    jobInfo?.schedulerMethodType ===
                                        SchedulerMethodType.API_UNIPIN_TOPUP_UPDATE ||
                                    jobInfo?.schedulerMethodType ===
                                        SchedulerMethodType.API_BUKALAPAK_TOPUP_UPDATE
                                        ? 'TOPUP'
                                        : 'VOUCHER' || ''
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="flex p-4">
                    <div className="flex w-full justify-center">
                        <Buttons
                            id="changeJob"
                            buttonType="primary"
                            size="small"
                            padding="p-3"
                            name={`Remove Job`}
                            onclick={() => handleRemoveJobClick()}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
