import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Buttons } from '../../ui/atoms/Button';

export default function ResetTwoStepAuthScreen() {
    const [codeError, setCodeError] = useState(false);

    const codeErrorShow = () => {
        setCodeError(!codeError);
        return codeError ? <Redirect to="/dashboard" /> : false;
    };

    const LinkComponent = () => {
        return <Redirect to="/resetPasswordEmailVerification" />;
    };

    return (
        <div className="container h-screen w-screen">
            <div
                className="flex bg-cover bg-center   h-full w-full  md:h-screen md:w-screen  bg-no-repeat"
                style={{ backgroundImage: `url(/images/background.svg)` }}
            >
                <div className="container  max-w-2xl  md:rounded-md  md:mt-24 lg:mt-20 xl:mt-7 2xl:mt-auto bg-white flex sm:m-auto  flex-wrap flex-col">
                    <div className="mb-7 md:mb-8 min-h-6 lg:mx-12 max-w-2xl">
                        <img className="w-28 mx-auto  mt-14" src="/images/favicon.png" alt=""></img>

                        <div className="flex flex-col flex-wrap justify-center -mt-4">
                            <div className="m-auto font-extrabold lg:text-2xl font-poppins sm:text-xl  justify-center mb-4">
                                Two-step verification
                            </div>

                            <div className="m-auto  text-gray-400 font-poppins flex flex-col text-xs md:text-sm mb-8 md:mb-3 ">
                                <p className=" m-auto justify-center">
                                    Please enter your verification code
                                </p>
                            </div>
                        </div>

                        <div
                            id="otp"
                            className="flex flex-row justify-center text-center px-2 mt-5 flex-wrap"
                        >
                            <input
                                className="m-2 border border-black h-16 w-16 text-center form-control rounded"
                                type="text"
                                id="first"
                                maxLength={1}
                            />
                            <input
                                className="m-2 border border-black h-16 w-16 text-center form-control rounded"
                                type="text"
                                id="first"
                                maxLength={1}
                            />
                            <input
                                className="m-2 border border-black h-16 w-16 text-center form-control rounded"
                                type="text"
                                id="first"
                                maxLength={1}
                            />
                            <input
                                className="m-2 border border-black h-16 w-16 text-center form-control rounded"
                                type="text"
                                id="first"
                                maxLength={1}
                            />
                            <input
                                className="m-2 border border-black h-16 w-16 text-center form-control rounded"
                                type="text"
                                id="first"
                                maxLength={1}
                            />
                            <input
                                className="m-2 border border-black h-16 w-16 text-center form-control rounded"
                                type="text"
                                id="first"
                                maxLength={1}
                            />
                        </div>

                        <div
                            className={` flex  flex-wrap mx-auto justify-center -mt-4 ${
                                codeError ? 'hidden' : 'block'
                            }`}
                        >
                            <div className="m-auto text-black font-poppins flex flex-col lg:flex-row  mt-4 ">
                                <a
                                    className="text-purple-600 font-semibold m-auto  lg:mx-1 text-sm"
                                    href="/reset-Password-Email-Verification"
                                >
                                    00:30
                                </a>
                                <p className=" m-auto justify-center text-xs">
                                    Please enter your verification code
                                    <a
                                        className="text-purple-600 ml-2 underline"
                                        href="/reset-Password"
                                    >
                                        Resend code
                                    </a>
                                </p>
                            </div>
                        </div>

                        <div
                            className={`  border-l-8 p-2  border-red-600 bg-red-100 mt-4 mx-5 md:mx-20 lg:mx-auto text-center ${
                                codeError ? 'block' : 'hidden'
                            }`}
                            role="alert"
                        >
                            <p className="font-semibold"> Invalid verification code</p>
                        </div>

                        <div className="pt-5 flex flex-col align-middle justify-center xl:mb-10">
                            <div className="flex   justify-center mb-2 py-2">
                                <Buttons
                                    name="Verify"
                                    buttonType="primary"
                                    onclick={codeErrorShow}
                                    id="login"
                                    size="large"
                                    padding="py-3"
                                />
                            </div>
                            <div className="flex   justify-center mb-2 py-2">
                                <Buttons
                                    name="Cancel"
                                    buttonType="secondary"
                                    onclick={LinkComponent}
                                    id="login"
                                    size="large"
                                    padding="py-3"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
