import React, { useEffect, useState } from 'react';
import { DEAL_PRICES } from '../../../constants/config/constants';

interface TabsProps {
    tabType?: string;
    children: Array<React.ReactElement<TabProps>>;
}

const Tabs: React.FunctionComponent<TabsProps> = ({ children, tabType }) => {
    const [activeTab, setActiveTab] = useState<string>(null);

    useEffect(() => {
        if (tabType != null && tabType == DEAL_PRICES) {
            try {
                children.forEach((element) => {
                    if (element.props.isDisabled != null && element.props.isDisabled == false) {
                        setActiveTab(element.props.label);
                        throw Error;
                    }
                });
            } catch (error) {
                console.log('Loop execution ended.');
            }
        } else {
            if (activeTab != null) {
                setActiveTab(activeTab);
            } else {
                setActiveTab(children[0].props.label);
            }
        }
    }, [children, tabType]);

    const handleClick = (e, newActiveTab) => {
        e.preventDefault();
        setActiveTab(newActiveTab);
    };

    return (
        <div className="max-w-fit mx-auto">
            <div className="flex border-b border-gray-300">
                {children.map((tab) => (
                    <button
                        disabled={tab.props.isDisabled}
                        id={tab.props.label}
                        key={tab.props.label}
                        className={`${
                            activeTab === tab.props.label
                                ? 'border-b-2 border-purple-500 text-purple-500 font-semibold'
                                : 'text-gray-500'
                        } flex-1 font-medium py-2 text-left pl-2`}
                        onClick={(e) => handleClick(e, tab.props.label)}
                    >
                        {tab.props.label}
                        {tab.props.isDirty ? <span style={{ color: '#FF0000' }}> * </span> : <></>}
                    </button>
                ))}
            </div>
            <div className="py-4">
                {children.map((tab) => {
                    if (tab.props.label === activeTab) {
                        return <div key={tab.props.label}>{tab.props.children}</div>;
                    }
                    return null;
                })}
            </div>
        </div>
    );
};

interface TabProps {
    label: string;
    children: React.ReactElement;
    isDirty?: boolean;
    isDisabled?: boolean;
}
const Tab: React.FunctionComponent<TabProps> = ({ children, ...rest }) => (
    <div className="hidden" {...rest}>
        {children}
    </div>
);

export { Tabs, Tab };
