export const BULK_ACTION_REFUNDED = 'REFUND';

export const BULK_ACTION_REFUNDED_SUCCESS = 'Successfully refunded selected orders ';

export const BULK_ACTION_MOVE_TO_COMPLETE = 'COMPLETE';

export const BULK_ACTION_MOVE_TO_COMPLETE_SUCCESS = 'Successfully completed selected orders ';

export const BULK_ACTION_MOVE_TO_CANCELED = 'CANCELED ';

export const BULK_ACTION_MOVE_TO_CANCELED_SUCCESS = 'Successfully cancelled selected orders ';

export const COMPLETE_ORDERS = 'COMPLETED';

export const REFUNDED_ORDERS = 'REFUNDED';

export const CANCELLED_ORDERS = 'CANCELLED';

export const PROCESSING_ORDERS = 'PROCESSING';

export const FAILED_ORDERS = 'FAILED';

export const BULK_ACTION_VALIDATION_FAILED_COMPLETE =
    "You can perform this action only to 'Processing' orders. \nPlease select multiple 'Processing' orders to proceed";

export const BULK_ACTION_VALIDATION_FAILED_REFUNDED =
    "You can perform this action only to 'Cancelled' orders. \nPlease select multiple 'Cancelled' orders to proceed.";

export const BULK_ACTION_VALIDATION_FAILED_CANCELED =
    "You can perform this action only to 'Processing' orders. \nPlease select multiple 'Processing' products to proceed.";

export const BULK_ACTION_VALIDATION_FAILED_TITLE_ORDERS = 'Sorry!';

export const EMPTY_SEARCH_RESULT_TEXT = 'No orders found';
