import {
    FETCH_CREATED_USER_DETAILS_FAILED,
    FETCH_CREATED_USER_DETAILS_SUCCESS,
    LOCAL_REMOVE_KEYCLOAK_PARTNER_DATA,
    LOCAL_REMOVE_PARTNER_DATA,
    LOCAL_REMOVE_SUPPLIER_DATA,
    LOCAL_SAVE_PARTNER_DATA,
    LOCAL_SAVE_SUPPLIER_DATA,
    PRODUCT_PRICE,
    RESET_CREATE_USER,
    RESET_ITEM_PARTNER_POINTS_SUCCESS,
    RESET_LOCAL_CONVERSION_RATE,
    RESET_PRODUCT_PRICE,
    RESET_SELECTED_ITEMS_DATA_TABLE,
    SAVE_CATEGORY_NAME,
    SAVE_CATEGORY_NAME_SUCCESS,
    SAVE_DEAL_ID,
    SAVE_DEAL_ID_SUCCESS,
    SAVE_ITEM_NAME,
    SAVE_ITEM_NAME_SUCCESS,
    SAVE_PARTNER_NAME,
    SAVE_PARTNER_NAME_SUCCESS,
    SAVE_PAYMENT_CHANNEL_ID,
    SAVE_PAYMENT_CHANNEL_ID_SUCCESS,
    SAVE_PAYMENT_CHANNEL_NAME,
    SAVE_PAYMENT_CHANNEL_NAME_SUCCESS,
    SAVE_PRODUCT_GROUP_NAME,
    SAVE_PRODUCT_GROUP_NAME_SUCCESS,
    SAVE_SUPPLIER_NAME,
    SAVE_SUPPLIER_NAME_SUCCESS,
    SET_ADMIN_PROFILE_LOGIN_CLICKED,
    SET_CONVERSION_RATE,
    SET_ITEM_PARTNER_POINTS,
    SET_SELECTED_ITEMS_DATA_TABLE,
    SET_UNIT_PRICE,
} from './../../enums/redux/redux-enum';
import {
    LOGIN,
    LOGIN_VERIFICATION,
    LOGIN_VERIFICATION_SUCCESS,
    LOGIN_VERIFICATION_FAILED,
    ADMIN_CLI_LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    GET_ALL_SESSION,
    GET_ALL_SESSION_SUCCESS,
    UPLOAD_PROFILE_PIC,
    UPLOAD_PROFILE_PIC_SUCCESS,
    SENT_NEW_USER_INVITE,
    SENT_NEW_USER_INVITE_SUCCESS,
    SENT_NEW_USER_INVITE_FAILURE,
    USERS_BY_ROLE_STORE_ADMIN_SUCCESS,
    USERS_BY_ROLE_PARTNER_SUCCESS,
    NEW_USER_PASSWORD,
    NEW_USER_PASSWORD_SUCCESS,
    NEW_USER_PASSWORD_FAIL,
    ADMIN_CLI_LOGIN_FAILED,
    REFRESH_LOGIN,
    REFRESH_LOGIN_SUCCESS,
    REFRESH_LOGIN_FAILED,
    CLEAR_ERRORS,
    FETCH_USER_DETAILS,
    CREATE_USER,
    CREATE_USER_SUCCESS,
    SET_AUTH_TOKEN,
    LOGOUT_FROM_ALL_SESSION,
    LOGOUT_FROM_ALL_SESSION_SUCCESS,
    LOGOUT,
    GET_ALL_USERS,
    USERS_BY_ROLE,
    FETCH_CREATED_USER_DETAILS,
    ASSIGN_CLIENT_ROLE,
    ASSIGN_CLIENT_ROLE_SUCCESS,
    CREATE_USER_FAILED,
    ROLE_TYPES_SUCCESS,
    USERS_BY_ROLE_FAILED,
} from '../../enums/redux/redux-enum';
import { SAVE_DEAL } from '../../queries/DealQueries';

export interface AuthTokensType {
    //will be add usertype tokens in fut
    adminAccessToken: string;
}

export interface LoginResponseType {
    access_token: string;
    expires_in: number;
    refresh_expires_in: number;
    refresh_token: string;
    token_type: string;
    not_before_policy: string;
    session_state: string;
    scope: string;
}
export interface RolesResponse {
    id: string;
    name: string;
    description: string;
    composite: boolean;
    clientRole: boolean;
    containerId: string;
}
export interface UsersByRolesResponse {
    username: string;
    email: string;
}

export interface AuthInitialState {
    isLoading: boolean;
    roleAssignLoading: boolean;
    loginResponse: LoginResponseType | null;
    loginErrorMessage: string | null;
    authToken: string | null;
    isLogout: boolean;
    roles: [RolesResponse];
    fetchedUser: any;
    createdUser: any;
    fetchUserError: any;
    createUserErrorMessage: any;
    createUserSuccess: boolean;
    isEmailSent: boolean;
    sendNewUSerEmailErrorMessage: string | null;
    isAdminProfileLogoutClicked: boolean;
    isNewUserPasswordSuccess: boolean;
    sendNewUSerFailErrorMessage: string | null;
    sessions: any;
    profilePicUrl: string;
    logOutAllSessions: boolean;
    isVerificationSuccess: boolean;
    usersList: any;
    partner: any;
    supplier: any;
    price: number;
    conversionRate: number;
    unitPrice: number;
    itemName: string;
    categoryName: string;
    productGroupName: string;
    paymentChannelName: string;
    paymentChannelId: number;
    dealId: number;
    partnerName: string;
    supplierName: string;
    swarmioAdminUsersByRoles: [UsersByRolesResponse];
    storeAdminUsersByRoles: [UsersByRolesResponse];
    partnerUsersByRoles: [UsersByRolesResponse];
    usersByRolesErrorMessage: string | null;
    partnerItemPoints: any;
    selectedItemsDataTable: any;
}

export const initialState: AuthInitialState = {
    isLoading: false,
    roleAssignLoading: false,
    loginResponse: null,
    loginErrorMessage: null,
    authToken: null,
    isLogout: false,
    roles: null,
    fetchedUser: null,
    createdUser: null,
    fetchUserError: null,
    createUserErrorMessage: null,
    createUserSuccess: false,
    isEmailSent: false,
    supplier: null,
    sendNewUSerEmailErrorMessage: null,
    itemName: '',
    categoryName: '',
    productGroupName: '',
    paymentChannelName: '',
    paymentChannelId: null,
    dealId: null,
    supplierName: '',
    partnerName: '',
    isNewUserPasswordSuccess: false,
    sendNewUSerFailErrorMessage: null,
    sessions: null,
    profilePicUrl: null,
    logOutAllSessions: null,
    isVerificationSuccess: null,
    usersList: null,
    partner: null,
    price: null,
    conversionRate: null,
    unitPrice: null,
    swarmioAdminUsersByRoles: null,
    storeAdminUsersByRoles: null,
    isAdminProfileLogoutClicked: false,
    partnerUsersByRoles: null,
    usersByRolesErrorMessage: null,
    partnerItemPoints: null,
    selectedItemsDataTable: null,
};

const authReducer = (state = initialState, action: any): AuthInitialState => {
    switch (action.type) {
        case LOGIN:
            return { ...state, isLoading: true, loginErrorMessage: null };
        case LOGIN_VERIFICATION:
            return { ...state, isLoading: true, loginErrorMessage: null };

        case LOGIN_VERIFICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isVerificationSuccess: true,
            };

        case LOGIN_VERIFICATION_FAILED:
            return {
                ...state,
                isLoading: false,
                loginErrorMessage: action.payload,
                isVerificationSuccess: false,
            };

        case ADMIN_CLI_LOGIN:
            return { ...state, isLoading: true, loginErrorMessage: null };

        case GET_ALL_USERS:
            return { ...state, usersList: action.payload };

        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                loginResponse: action.payload,
                isLogout: false,
            };

        case LOGIN_FAILED:
            return {
                ...state,
                isLoading: false,
                loginErrorMessage: action.payload,
            };

        case USERS_BY_ROLE:
            return {
                ...state,
            };

        case USERS_BY_ROLE_STORE_ADMIN_SUCCESS:
            return {
                ...state,
                storeAdminUsersByRoles: action.payload,
            };
        case SAVE_ITEM_NAME:
            return { ...state, itemName: null };
        case SAVE_ITEM_NAME_SUCCESS:
            return { ...state, itemName: action.payload };
        case SAVE_SUPPLIER_NAME:
            return { ...state, supplierName: null };
        case SAVE_SUPPLIER_NAME_SUCCESS:
            return { ...state, supplierName: action.payload };
        case SAVE_PARTNER_NAME:
            return { ...state, partnerName: null };
        case SAVE_PARTNER_NAME_SUCCESS:
            return { ...state, partnerName: action.payload };
        case SAVE_CATEGORY_NAME:
            return { ...state, categoryName: null };
        case SAVE_PRODUCT_GROUP_NAME:
            return { ...state, productGroupName: null };
        case SAVE_PAYMENT_CHANNEL_NAME:
            return { ...state, paymentChannelName: null };
        case SAVE_PAYMENT_CHANNEL_ID:
            return { ...state, paymentChannelId: null };
        case SET_ADMIN_PROFILE_LOGIN_CLICKED:
            return { ...state, isAdminProfileLogoutClicked: action.payload };
        case SAVE_CATEGORY_NAME_SUCCESS:
            return { ...state, categoryName: action.payload };
        case SAVE_PRODUCT_GROUP_NAME_SUCCESS:
            return { ...state, productGroupName: action.payload };
        case SAVE_PAYMENT_CHANNEL_NAME_SUCCESS:
            return { ...state, paymentChannelName: action.payload };
        case SAVE_PAYMENT_CHANNEL_ID_SUCCESS:
            return { ...state, paymentChannelId: action.payload };
        case USERS_BY_ROLE_PARTNER_SUCCESS:
            return {
                ...state,
                partnerUsersByRoles: action.payload,
            };

        case USERS_BY_ROLE_FAILED:
            return {
                ...state,
                isLoading: false,
                usersByRolesErrorMessage: action.payload,
            };

        case GET_ALL_SESSION:
            return { ...state, sessions: null };

        case GET_ALL_SESSION_SUCCESS:
            return {
                ...state,
                sessions: action.payload,
            };

        case UPLOAD_PROFILE_PIC:
            return { ...state, profilePicUrl: null };

        case UPLOAD_PROFILE_PIC_SUCCESS:
            return {
                ...state,
                profilePicUrl: action.payload,
            };

        case SENT_NEW_USER_INVITE:
            return { ...state, isLoading: true, isEmailSent: null };

        case SENT_NEW_USER_INVITE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isEmailSent: true,
            };
        case SENT_NEW_USER_INVITE_FAILURE:
            return {
                ...state,
                isLoading: false,
                sendNewUSerEmailErrorMessage: action.payload,
            };

        case NEW_USER_PASSWORD:
            return { ...state, isLoading: true, isNewUserPasswordSuccess: false };

        case NEW_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isNewUserPasswordSuccess: true,
            };
        case NEW_USER_PASSWORD_FAIL:
            return {
                ...state,
                isLoading: false,
                sendNewUSerFailErrorMessage: action.payload,
            };

        case ADMIN_CLI_LOGIN_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case REFRESH_LOGIN:
            return { ...state, isLoading: true, loginErrorMessage: null };

        case REFRESH_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                loginResponse: action.payload,
                isLogout: false,
            };

        case REFRESH_LOGIN_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                loginErrorMessage: null,
            };

        case FETCH_USER_DETAILS:
            return {
                ...state,
                fetchedUser: action.payload,
            };
        case FETCH_CREATED_USER_DETAILS:
            return {
                ...state,
                createdUser: null,
                isLoading: true,
            };
        case FETCH_CREATED_USER_DETAILS_SUCCESS:
            return {
                ...state,
                createdUser: action.payload,
                isLoading: false,
            };
        case FETCH_CREATED_USER_DETAILS_FAILED:
            return {
                ...state,
                fetchUserError: action.payload,
                isLoading: false,
            };

        case CREATE_USER:
            return {
                ...state,
                isLoading: true,
                createUserErrorMessage: null,
            };

        case CREATE_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                createUserErrorMessage: null,
                createUserSuccess: true,
            };

        case CREATE_USER_FAILED:
            return {
                ...state,
                isLoading: false,
                createUserErrorMessage: action.payload,
                createUserSuccess: false,
            };

        case RESET_CREATE_USER:
            return {
                ...state,
                isLoading: false,
                createUserErrorMessage: null,
                createUserSuccess: false,
            };

        case SET_AUTH_TOKEN:
            return {
                ...state,
                authToken: action.payload,
            };

        case LOGOUT_FROM_ALL_SESSION:
            return {
                ...state,
                logOutAllSessions: false,
            };

        case LOGOUT_FROM_ALL_SESSION_SUCCESS:
            return {
                ...state,
                logOutAllSessions: true,
            };

        case ROLE_TYPES_SUCCESS:
            return {
                ...state,
                roles: action.payload,
            };

        case ASSIGN_CLIENT_ROLE:
            return {
                ...state,
                roleAssignLoading: true,
            };

        case ASSIGN_CLIENT_ROLE_SUCCESS:
            return {
                ...state,
                roleAssignLoading: false,
            };

        case LOCAL_SAVE_PARTNER_DATA:
            return {
                ...state,
                partner: action.payload,
            };
        case LOCAL_REMOVE_PARTNER_DATA:
            return {
                ...state,
                partner: null,
            };
        case LOCAL_REMOVE_KEYCLOAK_PARTNER_DATA:
            return {
                ...state,
                createdUser: null,
            };
        case LOCAL_SAVE_SUPPLIER_DATA:
            return {
                ...state,
                supplier: action.payload,
            };
        case LOCAL_REMOVE_SUPPLIER_DATA:
            return {
                ...state,
                supplier: null,
            };
        case PRODUCT_PRICE:
            return {
                ...state,
                price: action.payload,
            };

        case RESET_PRODUCT_PRICE:
            return {
                ...state,
                price: null,
            };

        case LOGOUT:
            return {
                ...state,
                isLogout: true,
            };

        case SET_CONVERSION_RATE:
            return {
                ...state,
                conversionRate: action.payload,
            };

        case SET_UNIT_PRICE:
            return {
                ...state,
                unitPrice: action.payload,
            };

        case RESET_ITEM_PARTNER_POINTS_SUCCESS:
            return {
                ...state,
                partnerItemPoints: null,
            };

        case SET_ITEM_PARTNER_POINTS:
            return {
                ...state,
                partnerItemPoints: action.payload,
            };
        case RESET_LOCAL_CONVERSION_RATE:
            return {
                ...state,
                conversionRate: null,
            };
        case SET_SELECTED_ITEMS_DATA_TABLE:
            return {
                ...state,
                selectedItemsDataTable: action.payload,
            };
        case RESET_SELECTED_ITEMS_DATA_TABLE:
            return {
                ...state,
                selectedItemsDataTable: null,
            };
        case SAVE_DEAL_ID:
            return { ...state, dealId: null };
        case SAVE_DEAL_ID_SUCCESS:
            return { ...state, dealId: action.payload };
        default:
            return state;
    }
};

export default authReducer;
