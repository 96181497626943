import { useQuery, useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { PRODUCT_POINTS_TABLE_TYPE } from '../../../constants/partner';
import {
    PRODUCT_ADD_EXIT_CONFIRM_MESSAGE,
    PRODUCT_ADD_EXIT_CONFIRM_TITLE,
    UPDATE_ITEM_ERROR,
    SET_ITEM_POINTS_SUCCESS,
    UPDATE_ITEM_SUCCESS,
} from '../../../constants/product';
import { VOUCHER_STATUS_AVAILABLE } from '../../../constants/supplier';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import {
    RESET_ITEM_PARTNER_POINTS_SUCCESS,
    RESET_LOCAL_CONVERSION_RATE,
} from '../../../enums/redux/redux-enum';
import { FETCH_PRODUCTS_BY_ID, ADD_PRODUCTS } from '../../../queries/ItemQueries';
import { FETCH_PARTNER_DETAILS_FOR_PARTNER_CONVERSIONS } from '../../../queries/PartnerQueries';
import { FETCH_GLOBAL_POINTS_LIST } from '../../../queries/PointsQueries';
import { localProductPrice, saveProductName } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import Loader from '../../../utils/loader';
import UnsaveChangesWarning from '../../templates/modals/UnsaveChangesWarning';
import { Buttons } from '../../ui/atoms/Button';
import NormalLoader from '../../../utils/normalLoader';
import Toast from '../../ui/atoms/Toast';
import { currencyAndCountryCodes } from '../../../utils/currencies';
import ViewEditCurrencyPrices from './ViewEditCurrencyPrices';
import { FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID } from '../../../queries/ProductQueries';
import DataTableAlter from '../../ui/organisms/DataTableAlter';
import {
    getFinalMarkedUpPrice,
    getFinalPointsValue,
} from '../../../helpers/CurrencyPriceHelper.helpers';

export default function EditPricingDetails() {
    const dispatch = useDispatch();
    const history = useHistory();
    const parameters = useParams();

    const price = useSelector((state: RootState) => state.auth.price);
    const cPriceAdd = localStorage.getItem('cPrice');
    const currency = localStorage.getItem('currency');

    const [partnersData, setPartnerData] = useState([]);
    const [pointsData, setPointsData] = useState([]);
    const [processedConversionsRef, setProcessedConversionsRef] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const [conversionRateValue, setConversionRateValue] = useState(1);
    const [unitPriceValue, setUnitPriceValue] = useState(null);
    const [productData, setProductData] = useState(null);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [updatedConversions, setUpdatedConversions] = useState([]);
    const [voucherInventories, setVoucherInventories] = useState([]);
    const [voucherLastInventories, setVoucherLastInventories] = useState([]);
    const [isViewPricesModalVisible, setIsViewPricesModalVisible] = useState(false);
    const [selectedParter, setSelectedPartner] = useState(null);
    const [partnerConversionsData, setPartnerConversionsData] = useState([]);
    const [selectedProductType, setSelectedProductType] = useState('');

    const setPartnerItemPoints = useSelector((state: RootState) => state.auth.partnerItemPoints);
    const locallySavedConversionRate = useSelector((state: RootState) => state.auth.conversionRate);
    const updateCurrencyConversionSuccess = useSelector(
        (state: RootState) => state.store.updateCurrencyConversionSuccess,
    );
    const updateCurrencyConversionSuccessMessage = useSelector(
        (state: RootState) => state.store.updateCurrencyConversionSuccessMessage,
    );
    const resetCurrencyConversionSuccess = useSelector(
        (state: RootState) => state.store.resetCurrencyConversionSuccess,
    );
    const resetCurrencyConversionSuccessMessage = useSelector(
        (state: RootState) => state.store.resetCurrencyConversionSuccessMessage,
    );
    const itemID = parameters['id'];

    const [saveItem, { loading: queryLoading }] = useMutation(ADD_PRODUCTS, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        onCompleted: () => {
            setError(false);
            setMessage(UPDATE_ITEM_SUCCESS);
            setShowToast(true);
            setIsDirty(false);
            history.push('/products');
        },
        onError: (error) => {
            console.error(error);
            setError(true);
            setMessage(UPDATE_ITEM_ERROR);
            setShowToast(true);
        },
    });

    useQuery(FETCH_PARTNER_DETAILS_FOR_PARTNER_CONVERSIONS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',

        onCompleted: (data) => {
            const activePartners = data?.partners?.filter((partner) => partner?.isActive);
            setPartnerData(activePartners);
        },
    });

    const { loading: partnerConversionsDataLoading } = useQuery(
        FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID,
        {
            variables: { itemId: itemID || productData?.id },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            context: { clientName: ClientsEnum.STORE },
            onCompleted: (data) => {
                setPartnerConversionsData(data?.partnerConversions);
            },
        },
    );

    const { loading: selectedItemLoading } = useQuery(FETCH_PRODUCTS_BY_ID, {
        variables: { itemId: itemID },
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            dispatch(saveProductName(data?.item?.itemName));
            setProductData(data?.item);
            localStorage.setItem('iId', itemID);
        },
    });

    const { loading: pointsLoadinG } = useQuery(FETCH_GLOBAL_POINTS_LIST, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        context: { clientName: ClientsEnum.STORE },
        onCompleted: (data) => {
            setPointsData(data?.globalPointsList);
        },
    });

    useEffect(() => {
        const availableVoucher = productData?.product?.voucherInventories?.filter((voucher) => {
            return voucher?.status === VOUCHER_STATUS_AVAILABLE;
        });
        const availableIds = availableVoucher?.map((voc) => voc?.id);

        if (availableIds?.length) {
            const firstVoucherID =
                availableIds?.length > 1 ? Math.min(...availableIds) : availableIds[0];
            const firstVoucher = productData?.product?.voucherInventories?.find(
                (voucher) => voucher?.id === firstVoucherID.toString(),
            );
            setVoucherInventories(firstVoucher);
        }
    }, [productData]);

    useEffect(() => {
        const availableVoucher = productData?.product?.voucherInventories;
        const availableIds = availableVoucher?.map((voc) => voc?.id);

        if (availableIds?.length) {
            const firstVoucherID =
                availableIds?.length > 1 ? Math.max(...availableIds) : availableIds[0];
            const firstVoucher = productData?.product?.voucherInventories?.find(
                (voucher) => voucher?.id === firstVoucherID.toString(),
            );
            setVoucherLastInventories(firstVoucher);
        }
    }, [productData]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Partner ID',
                accessor: 'partnerId',
            },
            {
                Header: 'Partner Name',
                accessor: 'partnerName',
            },
            {
                Header: 'Marked-up Percentage (DCB Currency)',
                accessor: 'markedUpPercentageForCurrency',
                Cell: function dispayMarkedUpPercentageForCurrency({ value }) {
                    return <span>{value ? value : 'N/A'}</span>;
                },
            },
            {
                Header: 'Marked-up Percentage (Points)',
                accessor: 'markedUpPercentage',
                Cell: function dispayMarkedUpPercentage({ value }) {
                    return <span>{value ? value : 'N/A'}</span>;
                },
            },
            {
                Header: 'Marked-up Percentage (PSP Currency)',
                accessor: 'markedUpPercentageForPsp',
                Cell: function displayedMarkedUpPercentageForPsp({ value }) {
                    return <span>{value || 'N/A'}</span>;
                },
            },
            {
                id: 'markupPrice',
                Header: 'Final Marked-up Price (DCB Local)',
                accessor: '',
                Cell: function dispayMarkedUpPrice({ row }) {
                    return getFinalMarkedUpPrice(
                        row?.original?.overriddenMarkedUpPriceForCurrency,
                        row?.original?.markedUpPriceForCurrency,
                    );
                },
            },
            {
                Header: 'Final Marked-up Price (PSP Local)',
                accessor: '',
                Cell: function displayMarkedUpPriceForPsp({ row }) {
                    return getFinalMarkedUpPrice(
                        row?.original?.overriddenMarkedUpPriceForPsp,
                        row?.original?.markedUpPriceForPsp,
                    );
                },
            },
            {
                Header: 'Local Currency',
                accessor: 'localCurrency',
                Cell: function dispayLocalCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                id: 'pointsConversionRate',
                Header: 'Points Conversion Rate',
                accessor: 'localCurrencyConversionRate',
                Cell: function dispayPointsConversionPoints({ value }) {
                    return <span>{value ? value : 'N/A'}</span>;
                },
            },
            {
                id: 'itemPoints',
                Header: 'Final Retail Price',
                accessor: '',
                Cell: function dispayFinalRoundedPointsValue({ row }) {
                    return getFinalPointsValue(
                        row?.original?.finalRetailPrice,
                        row?.original?.finalRoundedPointsValue,
                    );
                },
            },
            {
                id: 'expander',
                accessor: '',
                disableSortBy: true,
                width: 5,
                Cell: (data) => (
                    <span
                        onClick={() => {
                            if (
                                data.row.original?.productsAvailable &&
                                data.row.original?.productsAvailable == true
                            ) {
                                setSelectedPartner({
                                    id: data.row.original?.id,
                                    pid: data.row.original?.partnerId,
                                    partnerName: data.row.original?.partnerName,
                                    localCurrency: data.row.original?.localCurrency,
                                    itemId: itemID,
                                    localCurrencyConversionRate:
                                        data.row.original?.localCurrencyConversionRate,
                                    localCurrencyConversionRateOverridden:
                                        data.row.original?.localCurrencyConversionRateOverridden,
                                    markedUpPercentage: data.row.original?.markedUpPercentage,
                                    markedUpPercentageForCurrency:
                                        data.row.original?.markedUpPercentageForCurrency,
                                    markedUpPercentageForPsp:
                                        data.row.original?.markedUpPercentageForPsp,
                                    markedUpPriceOverridden:
                                        data.row.original?.markedUpPriceOverridden,
                                    isPartnerCurrencyMarkedUpPercentageOverridden:
                                        data.row.original
                                            ?.isPartnerCurrencyMarkedUpPercentageOverridden,
                                    isPartnerPointsMarkedUpPercentageOverridden:
                                        data.row.original
                                            ?.isPartnerPointsMarkedUpPercentageOverridden,
                                    isPartnerPspMarkedUpPercentageOverridden:
                                        data.row.original?.isPartnerPspMarkedUpPercentageOverridden,
                                    markupPrice: data.row.original?.markedUpPrice,
                                    finalRetailPrice: data.row.original?.finalRetailPrice,
                                    finalRetailPriceOverridden:
                                        data.row.original?.finalRetailPriceOverridden,
                                });
                                setIsViewPricesModalVisible(true);
                            }
                        }}
                        role="button"
                        id={`${data.row.original?.partnerId}-edit-button`}
                        className={
                            data.row.original?.productsAvailable &&
                            data.row.original?.productsAvailable == true
                                ? 'text-purple-500 underline'
                                : 'text-gray-500 underline cursor-not-allowed'
                        }
                        aria-hidden="true"
                    >
                        Edit
                    </span>
                ),
            },
        ],
        [itemID],
    );

    const handleOnClickUpdate = () => {
        if (isDirty) {
            setError(false);
            saveItem({
                variables: {
                    item: {
                        id: productData?.id,
                        itemId: productData?.itemId,
                        itemName: productData?.itemName,
                        itemType: productData?.itemType,
                        itemDescBrief: productData?.itemDescBrief,
                        itemImageUrl: productData?.itemImageUrl,
                        itemGalleryUrls: productData?.itemGalleryUrls,
                        itemDescDetail: productData?.itemDescDetail,
                        deliveryMethod: productData?.deliveryMethod,
                        isDeleted: productData?.isDeleted,
                        currencyConversionRate: conversionRateValue,
                        unitPrice: unitPriceValue || voucherInventories['unitPrice'],
                        isActive: productData?.isActive,
                        createdBy: productData?.createdBy,
                        itemStatus: productData?.itemStatus,
                        stock: productData?.stock,
                        product: {
                            id: productData?.product?.id,
                        },
                        contentType: selectedProductType || productData?.contentType,
                    },
                },
            });
        } else {
            setError(true);
            setMessage(UPDATE_ITEM_ERROR);
            setShowToast(true);
            history.push('/products');
        }
    };

    const calculateMarkedUpPrice = (unitPrice, markedUpPercentage) => {
        if (unitPrice && markedUpPercentage) {
            return ((1 + markedUpPercentage / 100) * unitPrice).toFixed(2);
        } else {
            return '0.00';
        }
    };

    const getGlobalPointsConversionRate = useCallback(
        (partnerId, supplierId) => {
            const pointsValue =
                pointsData?.find(
                    (points) =>
                        points?.partner?.id === partnerId?.toString() &&
                        points?.supplier?.id === supplierId,
                )?.overriddenValue ||
                pointsData?.find(
                    (points) =>
                        points?.partner?.id === partnerId?.toString() &&
                        points?.supplier?.id === supplierId,
                )?.defaultValue;
            return pointsValue;
        },
        [pointsData],
    );

    useEffect(() => {
        if (!isDirty)
            dispatch(
                localProductPrice(
                    voucherInventories['unitPrice']
                        ? voucherInventories['unitPrice']
                        : voucherLastInventories['unitPrice']
                        ? voucherLastInventories['unitPrice']
                        : 0,
                ),
            );
        localStorage.setItem(
            'currency',
            voucherInventories['currency']
                ? voucherInventories['currency']
                : voucherLastInventories['currency']
                ? voucherLastInventories['currency']
                : '',
        );
    }, [dispatch, isDirty, voucherInventories, voucherLastInventories]);

    useEffect(() => {
        if (conversionRateValue && voucherInventories['unitPrice']) {
            const result = parseFloat(
                (voucherInventories['unitPrice'] * conversionRateValue).toString(),
            ).toFixed(2);
            setUnitPriceValue(result);
        } else if (
            conversionRateValue &&
            !voucherInventories['unitPrice'] &&
            voucherLastInventories['unitPrice']
        ) {
            const result = parseFloat(
                (voucherLastInventories['unitPrice'] * conversionRateValue).toString(),
            ).toFixed(2);
            setUnitPriceValue(result);
        } else if (!conversionRateValue) {
            setUnitPriceValue(
                voucherLastInventories['unitPrice']
                    ? voucherLastInventories['unitPrice'].toFixed(2)
                    : voucherLastInventories['unitPrice']
                    ? voucherLastInventories['unitPrice'].toFixed(2)
                    : 0,
            );
        }
    }, [conversionRateValue, setUnitPriceValue, voucherInventories, voucherLastInventories]);

    useEffect(() => {
        if (locallySavedConversionRate) {
            setConversionRateValue(locallySavedConversionRate);
        } else {
            setConversionRateValue(productData?.currencyConversionRate || 1);
        }
    }, [productData?.currencyConversionRate, locallySavedConversionRate]);

    /** useEffect used for update final conversion table data. If there are local overridden values,
     * they will get merged to global rates
     */
    useEffect(() => {
        if (productData && partnersData) {
            if (productData?.itemPartnerPoints?.length > 0) {
                const overriddenPartnerIds = new Set(
                    productData?.itemPartnerPoints?.map((point) => point?.partner?.id),
                );

                const overriddenConversions = productData?.itemPartnerPoints?.map((point) => ({
                    id: point?.partner?.id,
                    partnerName: point?.partner?.partnerName,
                    pid: point?.partner?.partnerId,
                    pointsConversionRate: point?.pointsConversionRate
                        ? point?.pointsConversionRate
                        : getGlobalPointsConversionRate(
                              point?.partner?.id,
                              productData?.supplier?.id,
                          ),
                    itemId: productData?.id,
                    markupPrice: point?.markedUpPrice
                        ? point?.markedUpPrice
                        : calculateMarkedUpPrice(
                              unitPriceValue,
                              point?.partner?.markedUpPercentage,
                          ),
                    itemPoints: point?.pointsValue
                        ? point?.pointsValue
                        : (point?.markedUpPrice ||
                              calculateMarkedUpPrice(
                                  unitPriceValue,
                                  point?.partner?.markedUpPercentage,
                              )) *
                          (point?.pointsConversionRate ||
                              getGlobalPointsConversionRate(
                                  point?.partner?.id,
                                  productData?.supplier?.id,
                              )),

                    markedUpPercentageForCurrency: point?.partner?.markedUpPercentageForCurrency,
                    markedUpPercentage: point?.partner?.markedUpPercentage,
                    isOverriddenValue: true,
                    isOverriddenValueConversionPoint: point?.pointsConversionRate ? true : false,
                    isOverriddenValueMarkedUpPercentage: point?.markedUpPrice ? true : false,
                    localCurrency: point?.partner?.localCurrency,
                    localCurrencyConversionRate: point?.localCurrencyConversionRate,
                }));
                const partnerSupplierGlobalConversions = partnersData?.map((partner) => ({
                    id: partner?.id,
                    partnerName: partner?.partnerName,
                    pid: partner?.partnerId,
                    pointsConversionRate: getGlobalPointsConversionRate(
                        partner?.id,
                        productData?.supplier?.id,
                    ),
                    itemId: productData?.id,
                    markedUpPercentageForCurrency: partner?.markedUpPercentageForCurrency,
                    markedUpPercentage: partner?.markedUpPercentage,
                    markupPrice: calculateMarkedUpPrice(
                        unitPriceValue,
                        partner?.markedUpPercentage,
                    ),
                    itemPoints:
                        parseFloat(
                            calculateMarkedUpPrice(unitPriceValue, partner?.markedUpPercentage),
                        ) * getGlobalPointsConversionRate(partner?.id, productData?.supplier?.id),
                    isOverriddenValue: false,
                    isOverriddenValueConversionPoint: false,
                    isOverriddenValueMarkedUpPercentage: false,
                    localCurrency: partner.localCurrency,
                }));

                const filteredGlobalConversions = partnerSupplierGlobalConversions?.filter(
                    (partnerConversion) => !overriddenPartnerIds?.has(partnerConversion.id),
                );

                setProcessedConversionsRef([
                    ...overriddenConversions,
                    ...filteredGlobalConversions,
                ]);
            } else {
                setProcessedConversionsRef(
                    partnersData?.map((partner) => ({
                        id: partner?.id,
                        partnerName: partner?.partnerName,
                        pid: partner?.partnerId,
                        pointsConversionRate: getGlobalPointsConversionRate(
                            partner?.id,
                            productData?.supplier?.id,
                        ),
                        itemId: productData?.id,
                        markupPrice: calculateMarkedUpPrice(
                            unitPriceValue,
                            partner?.markedUpPercentage,
                        ),
                        itemPoints:
                            parseFloat(
                                calculateMarkedUpPrice(unitPriceValue, partner?.markedUpPercentage),
                            ) *
                            getGlobalPointsConversionRate(partner?.id, productData?.supplier?.id),
                        markedUpPercentageForCurrency: partner?.markedUpPercentageForCurrency,
                        markedUpPercentage: partner?.markedUpPercentage,
                        isOverriddenValue: false,
                        isOverriddenValueConversionPoint: partner?.pointsConversionRate
                            ? true
                            : false,
                        isOverriddenValueMarkedUpPercentage: partner?.markedUpPrice ? true : false,
                    })),
                );
            }
        }
    }, [productData, pointsData, partnersData, unitPriceValue, getGlobalPointsConversionRate]);

    useEffect(() => {
        if (setPartnerItemPoints) {
            setError(false);
            setMessage(SET_ITEM_POINTS_SUCCESS);
            setShowToast(true);
            const conversions = updatedConversions;
            conversions.push(setPartnerItemPoints?.saveItemPoints?.partner?.id);

            setUpdatedConversions(conversions);
            dispatch({ type: RESET_ITEM_PARTNER_POINTS_SUCCESS });
        }
    }, [
        conversionRateValue,
        dispatch,
        processedConversionsRef,
        pointsData,
        setPartnerItemPoints,
        updatedConversions,
    ]);

    useEffect(() => {
        const result = processedConversionsRef?.map((item) => ({
            ...item,
            itemPoints: !item?.isOverriddenValueMarkedUpPercentage
                ? item?.pointsConversionRate *
                  parseFloat(calculateMarkedUpPrice(unitPriceValue, item?.markedUpPercentage))
                : item?.itemPoints,
            markupPrice: !item?.isOverriddenValueMarkedUpPercentage
                ? calculateMarkedUpPrice(unitPriceValue, item?.markedUpPercentage)
                : item?.markupPrice,
        }));
        setProcessedConversionsRef(result);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversionRateValue, unitPriceValue]);

    useEffect(() => {
        if (updateCurrencyConversionSuccessMessage && updateCurrencyConversionSuccess) {
            if (updateCurrencyConversionSuccess) {
                setMessage(updateCurrencyConversionSuccessMessage);
                setError(false);
                setShowToast(true);
            } else {
                setMessage(updateCurrencyConversionSuccessMessage);
                setError(true);
                setShowToast(true);
            }
        }
    }, [updateCurrencyConversionSuccess, updateCurrencyConversionSuccessMessage]);

    useEffect(() => {
        if (resetCurrencyConversionSuccess && resetCurrencyConversionSuccessMessage) {
            if (resetCurrencyConversionSuccess) {
                setMessage(resetCurrencyConversionSuccessMessage);
                setError(false);
                setShowToast(true);
            } else {
                setMessage(resetCurrencyConversionSuccessMessage);
                setError(true);
                setShowToast(true);
            }
        }
    }, [resetCurrencyConversionSuccessMessage, resetCurrencyConversionSuccess]);

    /**
     * reset updated local conversions redux state data
     */
    useEffect(() => {
        return () => {
            dispatch({ type: RESET_LOCAL_CONVERSION_RATE });
            localStorage.removeItem('iId');
            localStorage.removeItem('currency');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (pointsLoadinG) return <Loader />;
    if (partnerConversionsDataLoading) return <Loader />;
    if (selectedItemLoading) return <Loader />;

    const productTypeOptions = [
        {
            label: 'NORMAL',
            value: 'NORMAL',
        },
        {
            label: 'PREMIUM',
            value: 'PREMIUM',
        },
    ];

    return (
        <div className="px-8 flex flex-col justify-between">
            {showToast && (
                <Toast
                    setShowToast={setShowToast}
                    message={message}
                    width="w-8/12"
                    error={error}
                    selfDestruct={true}
                    selfDestructTimer={5000}
                />
            )}
            <UnsaveChangesWarning
                contentText={PRODUCT_ADD_EXIT_CONFIRM_MESSAGE}
                messageTitle={PRODUCT_ADD_EXIT_CONFIRM_TITLE}
                when={isDirty || window.onbeforeunload ? true : false}
                navigate={(path) => history.push(path)}
                shouldBlockNavigation={() => {
                    if (isDirty) {
                        return true;
                    }
                    return false;
                }}
                displayIcon={true}
                displayIconName={'clarity_error-standard-line-svg.svg'}
            />
            <div className="text-lg font-poppins font-bold ">Pricing</div>

            <div className="grid grid-cols-8 gap-4 mt-8 mb-10">
                <div className="col-start-1 col-end-4 -mt-1">
                    <div className="font-poppins font-semibold text-lg">
                        Product Cost & Currency
                    </div>
                    <div className="flex flex-row pt-2 font-poppins font-semibold text-gray-500 text-base mt-1">
                        <div className="mr-1">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find(
                                        (x) =>
                                            x.currency ===
                                            (voucherInventories['currency'] ||
                                                voucherLastInventories['currency']),
                                    )
                                    ?.country.toLowerCase()} flag px-1 pb-0.5`}
                            ></i>
                        </div>
                        <div>
                            {voucherInventories['currency']
                                ? voucherInventories['currency']
                                : voucherLastInventories['currency']
                                ? voucherLastInventories['currency']
                                : ''}{' '}
                            {voucherInventories['unitPrice']
                                ? voucherInventories['unitPrice'].toFixed(2)
                                : voucherLastInventories['unitPrice']
                                ? voucherLastInventories['unitPrice'].toFixed(2)
                                : 0}
                        </div>
                    </div>
                </div>
                <div className="col-start-6 col-end-6">
                    <div className="flex flex-wrap flex-col relative w-full">
                        <div className="text-gray-500 w-24 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                            Content Type
                        </div>
                        <select
                            className="px-8 py-3 rounded-xl outline-none border-2  bg-white border-gray-400 hover:border-purple-500"
                            id="addProduct_type"
                            onChange={(e) => {
                                setSelectedProductType(e.target.value);
                                setIsDirty(true);
                            }}
                            onBlur={(e) => {
                                setSelectedProductType(e.target.value);
                            }}
                            value={selectedProductType || productData?.contentType}
                            defaultValue={productData?.contentType}
                        >
                            {productTypeOptions?.map((option: any, index) => {
                                return (
                                    <option
                                        value={option.value}
                                        className={'font-medium'}
                                        key={index}
                                    >
                                        {option.label}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div className="w-full pb-24">
                <div className=" border-2 rounded-md w-10/12">
                    <div className="text-lg font-poppins font-bold py-5 px-2 ">
                        Product - Partner Conversions
                    </div>

                    {partnerConversionsData?.length > 0 ? (
                        <DataTableAlter
                            columns={columns}
                            data={partnerConversionsData}
                            pageCount={1}
                            customizedPageSize={partnerConversionsData?.length}
                            checkbox={false}
                            tableType={PRODUCT_POINTS_TABLE_TYPE}
                            hidePagination={true}
                        />
                    ) : (
                        <div className="m-auto flex flex-col ">
                            <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                {columns?.map((column, index) => {
                                    return (
                                        <div
                                            className="text-gray-600 font-poppins font-semibold flex ml-12"
                                            key={index}
                                        >
                                            {column?.Header}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="m-auto p-10">No data found.</div>
                            <div className="p-10 bg-purple-100"></div>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 mt-10 justify-end bg-gray-100 fixed bottom-0 right-0">
                <Buttons
                    name="Cancel"
                    type="submit"
                    buttonType="secondary-main-grey"
                    id="update"
                    size="e-small"
                    onclick={() => history.push('/products')}
                    other="ml-5"
                />

                <Buttons
                    name={queryLoading ? <NormalLoader /> : 'Save'}
                    type="submit"
                    buttonType="primary"
                    id="update"
                    size="e-small"
                    onclick={() => {
                        handleOnClickUpdate();
                    }}
                />
            </div>
            <div className="w-full">
                <ViewEditCurrencyPrices
                    openModal={isViewPricesModalVisible}
                    setOpenModal={setIsViewPricesModalVisible}
                    partner={selectedParter}
                    costPrice={price || Number(cPriceAdd)}
                    currency={currency}
                />
            </div>
        </div>
    );
}
