import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useOuterClick from '../../../hook/useOuterClick';
import {
    FETCH_ADMIN_ONE_PROFILE,
    GET_LOGIN_SESSIONS_DATA_BY_ID,
    UPDATE_USER_LOGIN_DATA,
} from '../../../queries/AdminProfileQueries';
import { logout, setAdminProfileLogout } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { getCookie } from '../../../utils/cookiesService';
import { Images } from '../../ui/atoms/Images';
import jwt from 'jwt-decode';
import { SESSION_STATUS_INACTIVE } from '../../../constants/userLoginSession';
import NormalLoader from '../../../utils/normalLoader';

export default function LogOutModel() {
    const [showModal, setShowModal] = React.useState(false);
    const [profImgLoaded, setProfImgLoaded] = React.useState(false);
    const history = useHistory();
    const isLogout = useSelector((state: RootState) => state.auth.isLogout);
    const dispatch = useDispatch();
    const token = getCookie('access_token');

    const tokenDetails: any = token ? jwt(token) : null;
    const { data, loading } = useQuery(FETCH_ADMIN_ONE_PROFILE, {
        variables: { userName: tokenDetails?.preferred_username },
        skip: !token,
    });

    const currentUser = data?.user;

    const { data: session } = useQuery(GET_LOGIN_SESSIONS_DATA_BY_ID, {
        variables: {
            userName: tokenDetails?.preferred_username,
            sessionId: tokenDetails?.sid,
        },
        skip: !token,
    });

    const [updateUserLoginData, { loading: updateLoading }] = useMutation(UPDATE_USER_LOGIN_DATA);

    useEffect(() => {
        if (isLogout) {
            localStorage.removeItem('is_first_login');
            history.push('/');
        }
    }, [isLogout, history]);

    const handleOnClick = () => {
        const sessionObj = session?.userLoginSessionById;
        if (session && tokenDetails) {
            updateUserLoginData({
                variables: {
                    userName: tokenDetails?.preferred_username,
                    userLogin: {
                        sessionId: sessionObj?.sessionId,
                        device: sessionObj?.device,
                        deviceType: sessionObj?.deviceType,
                        location: sessionObj?.location,
                        status: SESSION_STATUS_INACTIVE,
                    },
                },
            });
        }

        setTimeout(() => {
            if (location?.pathname === '/adminProfile') {
                dispatch(setAdminProfileLogout());
            } else {
                dispatch(logout());
            }
        }, 1000);
    };

    const handleOuterClick = () => {
        setShowModal(false);
    };

    const innerRef = useOuterClick(handleOuterClick);

    if (loading || updateLoading) return <NormalLoader />;

    return (
        <>
            <div
                className="mx-3"
                role="button"
                tabIndex={0}
                onClick={() => {
                    setShowModal(!showModal);
                }}
                onKeyDown={() => {
                    setShowModal(!showModal);
                }}
                ref={innerRef}
            >
                {currentUser?.profilePic ? (
                    <>
                        <img
                            className="w-9 h-9 object-contain rounded-full inset-0 bg-cover bg-center z-0"
                            onLoad={() => {
                                setProfImgLoaded(true);
                            }}
                            src={currentUser?.profilePic}
                            id="profile_icon"
                            alt={''}
                            style={profImgLoaded ? {} : { display: 'none' }}
                        />
                        {profImgLoaded ? null : (
                            <img
                                className="w-9 h-9 object-contain rounded-full inset-0 bg-cover bg-center z-0"
                                onLoad={() => {
                                    setProfImgLoaded(true);
                                }}
                                src={'/images/icons/profile.png'}
                                alt={'profile pic'}
                            />
                        )}
                    </>
                ) : (
                    <>
                        <img
                            className=" hover:bg-gray-200  "
                            src="/images/icons/profile.png"
                            alt="profile"
                        />
                    </>
                )}
            </div>

            {showModal ? (
                <>
                    <div className="w-52 fixed right-4 mt-58 z-50 flex flex-col justify-end border-2 border-gray-700 rounded-lg bg-white">
                        <div className="px-1 py-2">
                            <div className="w-full hover:bg-purple-100 flex m-auto  mb-2">
                                <div
                                    className="mb-3 mt-3 w-full"
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => {
                                        history.push('/adminProfile');
                                        setShowModal(false);
                                    }}
                                    onKeyDown={() => history.push('/adminProfile')}
                                >
                                    <div className="flex flex-row  h-10 mx-6 pt-2">
                                        <Images
                                            width="w-6"
                                            height="h-6"
                                            src="/images/icons/profile.png"
                                            alt="Settings"
                                        />

                                        <div className="mx-3 font-poppins text-sm">Profile</div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full  hover:bg-purple-100 flex m-auto ">
                                <div
                                    className="mb-3 mt-3 w-full"
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => handleOnClick()}
                                    onKeyDown={() => handleOnClick()}
                                >
                                    <div className="flex flex-row  h-10 mx-6 pt-2">
                                        <Images
                                            width="w-6"
                                            height="h-6"
                                            src="/images/icons/logout.png"
                                            alt="logout"
                                        />

                                        <div className="mx-3 font-poppins text-sm">Logout</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-75  inset-9 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}
