import { gql } from '@apollo/client';

export const FETCH_ORDERS_REPORT_DATA = gql`
    query ($filter: OrderReportFilter) {
        orderReport(filter: $filter) {
            reportUrl
        }
    }
`;

export const FETCH_INVENTORY_REPORT_DATA = gql`
    query ($filter: InventoryReportFilter) {
        inventoryReport(filter: $filter) {
            reportUrl
        }
    }
`;
