/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLazyQuery, useQuery } from '@apollo/client';
import React, { ReactElement, useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { ACTIVITY_LOG } from '../../../queries/AdminProfileQueries';
import { pagination } from '../../../constants/config/constants';
import DataTable from '../../ui/organisms/DataTable';
import { initialState } from '../../../redux/reducers/authReducer';
import DataTableAlter from '../../ui/organisms/DataTableAlter';
import { SORT_DSC } from '../../../constants/common';

interface props {
    username: any;
}

export default function ActivityTable({ username }: props) {
    const clickedUser = localStorage.getItem('current_user_username');
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [pageSize, setPageSize] = useState(50);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [activityData, setActivityData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [activityDataInitial, setActivityDataInitial] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const [callActivity, { data: activityLogData, loading: activityLogLoading, error }] =
        useLazyQuery(ACTIVITY_LOG, {
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',

            onCompleted: (data) => {
                setActivityData(activityLogData?.userActivityLogs?.logs?.slice(0, pageSize));
                setActivityDataInitial(activityLogData?.userActivityLogs?.logs);
                setTotalItems(data?.userActivityLogs?.totalActivityLogs);
            },
        });

    useEffect(() => {
        const filtered = activityDataInitial;
        // const noOfPages = Math.ceil(filtered?.length / pageSize);
        // const pageIndexFilteredData = filtered.slice(
        //     pageIndex * pageSize,
        //     pageIndex * pageSize + pageSize,
        // );
        // setPageCount(noOfPages);
        setActivityData(filtered);
    }, [pageIndex, pageSize, activityDataInitial]);

    useEffect(() => {
        callActivity({
            variables: {
                userName: username,
                offset: currentPage - 1 || 0,
                limit: Math.ceil(pageSize) || 100000,
                order: SORT_DSC,
            },
        });
    }, [callActivity, currentPage, pageSize, username]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'activityId',
            },
            {
                Header: 'Activity name',
                accessor: 'activityName',
            },
            {
                Header: 'Action',
                accessor: 'action',
            },

            {
                Header: 'Date',
                accessor: 'createdDate',
                Cell: function date({ value }) {
                    return <span>{value ? value.slice(0, 16) + '(UTC)' : null}</span>;
                },
            },
        ],
        [],
    );
    if (activityLogLoading) return <Loader />;

    return (
        <div className="w-full border-2 p-2 mr-9 rounded-xl">
            <div className="text-lg font-poppins font-bold px-4 mt-3 mb-3 ">Activity log</div>
            {activityLogLoading ? (
                <Loader />
            ) : activityData?.length ? (
                <DataTableAlter
                    columns={columns}
                    data={activityData}
                    setSelectedItems={setSelectedProducts}
                    pageCount={pageCount}
                    setPageIndex={setPageIndex}
                    serverSidePagination={true}
                    currentPage={currentPage}
                    pageSizes={pageSize}
                    setCurrentPage={setCurrentPage}
                    totalItems={totalItems}
                    setDefaultPageSize={setPageSize}
                />
            ) : (
                <div className="m-auto flex flex-col ">
                    <div className="py-3 px-5 bg-purple-100 justify-between flex">
                        {columns?.map((column, i) => {
                            return (
                                <div
                                    className="text-black font-poppins font-semibold flex ml-12"
                                    key={i}
                                >
                                    {column?.Header}
                                </div>
                            );
                        })}
                    </div>
                    <div className="m-auto p-10">No data found.</div>
                    <div className="p-10 bg-purple-100"></div>
                </div>
            )}
        </div>
    );
}
