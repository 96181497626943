import { useEffect, useState } from 'react';
import { API, CSV, ALLSERV, BUKALAPAK } from '../../../constants/supplier';
import ConfigurationInputView from './ConfigurationInputView';

interface Props {
    setSupplierConfiguration: (value) => void;
    previouslySelectedConfiguration?: any;
    configInputFields?: any;
    setconfigInputFields?: (value) => void;
    configInputValid?: () => boolean;
    selectedSupplierType: string;
    setSelectedSupplierType?: (value) => void;
    initialConfigInputFields?: any;
    setInitialConfigInputFields?: (value) => void;
    isSupplierCredentialsDisabled: boolean;
    selectedSupplierSubType?: string;
    setSelectedSupplierSubType?: (value) => void;
    supplierSuptypeError?: boolean;
    setSupplierSuptypeError?: (value) => void;
    isEditScreen?: boolean;
}

export default function SupplierConfigurations({
    setSupplierConfiguration,
    previouslySelectedConfiguration,
    configInputFields,
    setconfigInputFields,
    configInputValid,
    selectedSupplierType,
    setSelectedSupplierType,
    initialConfigInputFields,
    setInitialConfigInputFields,
    isSupplierCredentialsDisabled,
    selectedSupplierSubType,
    setSelectedSupplierSubType,
    supplierSuptypeError,
    setSupplierSuptypeError,
    isEditScreen,
}: Props) {
    const [selectedConfigurationProperties, setSelectedConfigurationProperties] = useState(null);
    const [resultantConfig, setResultantConfig] = useState(null);

    const supplierType = [
        {
            label: 'CSV',
            value: 'CSV',
        },
        {
            label: 'API',
            value: 'API',
        },
        {
            label: 'AllServ',
            value: 'ALLSERV',
        },
    ];

    const supplierSubtype = [
        {
            label: 'Unipin',
            value: 'UNIPIN',
        },
        {
            label: 'Bukalapak',
            value: 'BUKALAPAK',
        },
    ];

    useEffect(() => {
        if (previouslySelectedConfiguration) {
            if (previouslySelectedConfiguration?.supplierType !== CSV) {
                setSelectedConfigurationProperties(previouslySelectedConfiguration?.config);
                if (selectedSupplierType !== ALLSERV) {
                    setSelectedSupplierType(API);
                }
            }
        }
    }, [
        previouslySelectedConfiguration,
        selectedSupplierType,
        setSelectedConfigurationProperties,
        setSelectedSupplierType,
    ]);

    useEffect(() => {
        const previousConf = previouslySelectedConfiguration;
        if (resultantConfig) {
            if (previousConf && previousConf?.supplierType !== CSV) {
                const resultantSupplierConfiguration = { ...previousConf, config: resultantConfig };
                setSupplierConfiguration(resultantSupplierConfiguration);
            } else {
                const newConfiguration = {
                    config: resultantConfig,
                    supplierType:
                        selectedSupplierType === ALLSERV ? ALLSERV : selectedSupplierSubType,
                    name: selectedSupplierType === ALLSERV ? ALLSERV : selectedSupplierSubType,
                };
                setSupplierConfiguration(newConfiguration);
            }
        }
    }, [
        selectedSupplierType,
        previouslySelectedConfiguration,
        resultantConfig,
        setSupplierConfiguration,
        selectedSupplierSubType,
    ]);

    useEffect(() => {
        if (selectedSupplierType === CSV) {
            setSupplierConfiguration(null);
        }
    }, [selectedSupplierType, setSupplierConfiguration]);

    return (
        <>
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-8 xl:gap-4 p-4 w-full">
                <div className="xl:col-start-1 xl:col-end-7 w-full flex space-x-4">
                    <div className="flex flex-wrap flex-col relative w-1/2">
                        <div className="text-gray-500 w-28 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                            Supplier Type
                        </div>
                        <select
                            className={`selectdropdownIcon px-4 py-3 rounded-xl outline-none border-2 bg-white border-gray-400 ${
                                !isSupplierCredentialsDisabled && 'hover:border-purple-500'
                            } `}
                            id="supplier_type"
                            onChange={(e) => {
                                setSelectedSupplierType(e.target.value);
                            }}
                            onBlur={(e) => {
                                setSelectedSupplierType(e.target.value);
                            }}
                            value={selectedSupplierType}
                            disabled={isSupplierCredentialsDisabled}
                        >
                            {supplierType.map((option: any, index) => {
                                return (
                                    <option
                                        id={option.value}
                                        value={option.value}
                                        className={'font-medium'}
                                        key={index}
                                    >
                                        {option.label}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="relative w-1/2">
                        {selectedSupplierType === API && (
                            <div className="flex flex-wrap flex-col relative w-full">
                                <div className="text-gray-500 w-32 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                    Supplier Subtype
                                </div>
                                <select
                                    className={`selectdropdownIcon px-4 py-3 rounded-xl outline-none border-2 bg-white border-gray-400 ${
                                        !isSupplierCredentialsDisabled && 'hover:border-purple-500'
                                    }`}
                                    id="supplier_subtype"
                                    onChange={(e) => {
                                        if (supplierSuptypeError) {
                                            setSupplierSuptypeError(false);
                                        }

                                        setSelectedSupplierSubType(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        if (supplierSuptypeError) {
                                            setSupplierSuptypeError(false);
                                        }

                                        setSelectedSupplierSubType(e.target.value);
                                    }}
                                    value={selectedSupplierSubType}
                                    disabled={isSupplierCredentialsDisabled}
                                >
                                    <option value="" disabled selected>
                                        Select supplier subtype
                                    </option>
                                    {supplierSubtype.map((option: any, index) => {
                                        return (
                                            <option
                                                id={option.value}
                                                value={option.value}
                                                className={'font-medium'}
                                                key={index}
                                            >
                                                {option.label}
                                            </option>
                                        );
                                    })}
                                </select>
                                {supplierSuptypeError ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        Please select the supplier sub type
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {selectedSupplierType !== CSV ? (
                <>
                    <div className="font-poppins px-4 font-bold text-lg">Configurations</div>
                    <div style={{ marginBottom: '5%' }}>
                        <ConfigurationInputView
                            config={selectedConfigurationProperties}
                            setResultantConfig={setResultantConfig}
                            configInputFields={configInputFields}
                            setconfigInputFields={setconfigInputFields}
                            configInputValid={configInputValid}
                            initialConfigInputFields={initialConfigInputFields}
                            setInitialConfigInputFields={setInitialConfigInputFields}
                            disableInputs={selectedSupplierSubType !== BUKALAPAK && isEditScreen}
                            selectedSupplierSubType={selectedSupplierSubType}
                            isEditScreen={isEditScreen}
                        />
                    </div>
                </>
            ) : null}
        </>
    );
}
