import {
    SAVE_POINTS_DATA_FAILED,
    SAVE_POINTS_DATA_RESET,
    SAVE_POINTS_DATA_SUCCESS,
    UPDATE_ITEM_PARTNER_CURRENCY_CONVERSION_SUCCESS_MESSAGE,
    UPDATE_ITEM_PARTNER_CURRENCY_CONVERSION_SUCCESS,
    CURRENCY_CONVERSION_RESET_SUCCESS_MESSAGE,
    CURRENCY_CONVERSION_RESET_SUCCESS,
} from './../../enums/redux/redux-enum';

export interface storeState {
    updatedPoints: any | null;
    errorMessage: string | null;
    isSuccess: boolean;
    updateCurrencyConversionSuccess: boolean;
    updateCurrencyConversionSuccessMessage: string | null;
    resetCurrencyConversionSuccess: boolean;
    resetCurrencyConversionSuccessMessage: string | null;
}

export const storeInitialState: storeState = {
    updatedPoints: null,
    errorMessage: null,
    isSuccess: false,
    updateCurrencyConversionSuccess: false,
    updateCurrencyConversionSuccessMessage: null,
    resetCurrencyConversionSuccess: false,
    resetCurrencyConversionSuccessMessage: null,
};

const storeReducer = (state = storeInitialState, action: any): storeState => {
    switch (action.type) {
        case SAVE_POINTS_DATA_SUCCESS:
            return { ...state, updatedPoints: action.payload, isSuccess: true };
        case SAVE_POINTS_DATA_FAILED:
            return { ...state, errorMessage: action.payload, isSuccess: false };
        case SAVE_POINTS_DATA_RESET:
            return { ...state, updatedPoints: null, errorMessage: null, isSuccess: false };
        case UPDATE_ITEM_PARTNER_CURRENCY_CONVERSION_SUCCESS:
            return { ...state, updateCurrencyConversionSuccess: true };
        case UPDATE_ITEM_PARTNER_CURRENCY_CONVERSION_SUCCESS_MESSAGE:
            return { ...state, updateCurrencyConversionSuccessMessage: action.payload };
        case CURRENCY_CONVERSION_RESET_SUCCESS_MESSAGE:
            return { ...state, resetCurrencyConversionSuccessMessage: action.payload };
        case CURRENCY_CONVERSION_RESET_SUCCESS:
            return { ...state, resetCurrencyConversionSuccess: action.payload };
        default:
            return state;
    }
};

export default storeReducer;
