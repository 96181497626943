import { useState } from 'react';
import * as FaIcon from 'react-icons/fa';
import StoreMarginSubsection from './subsections/StoreMarginSubsection';
import StoreSalesSubsection from './subsections/StoreSalesSubsection';
import StoreSuppliersSubsection from './subsections/StoreSuppliersSubsection';
import TopItemsSubsection from './subsections/TopItemsSubsection';

export default function OverviewSubsections() {
    const [showSalesSection, setShowSalesSection] = useState(false);
    const [showTopItemsSection, setShowTopItemsSection] = useState(false);
    const [showStoreSuppliersSection, setShowStoreSuppliersSection] = useState(false);
    const [showStoreMarginSection, setShowStoreMarginSection] = useState(false);
    return (
        <>
            <div className="w-full">
                <div className="mt-4 pb-4 border-b border-t border-gray-300">
                    <div className="mb-4 flex justify-between">
                        <div className="font-poppins text-black font-bold pt-4 pb-2 text-lg">
                            Store Sales
                        </div>
                        <div
                            className="pr-10 pt-4 pb-2"
                            role="button"
                            tabIndex={0}
                            onClick={() => setShowSalesSection(!showSalesSection)}
                            onKeyDown={() => setShowSalesSection(!showSalesSection)}
                        >
                            {!showSalesSection ? (
                                <FaIcon.FaChevronDown className="mx-auto" />
                            ) : (
                                <FaIcon.FaChevronUp className="mx-auto" />
                            )}
                        </div>
                    </div>
                    {showSalesSection ? (
                        <div className="mr-8">
                            <StoreSalesSubsection />
                        </div>
                    ) : null}
                </div>
                <div className="mt-4 pb-4 border-b border-gray-300">
                    <div className="mb-4 flex justify-between">
                        <div className="font-poppins text-black font-bold py-2 text-lg">
                            Top Items
                        </div>
                        <div
                            className="pr-10 py-2"
                            role="button"
                            tabIndex={0}
                            onClick={() => setShowTopItemsSection(!showTopItemsSection)}
                            onKeyDown={() => setShowTopItemsSection(!showTopItemsSection)}
                        >
                            {!showTopItemsSection ? (
                                <FaIcon.FaChevronDown className="mx-auto" />
                            ) : (
                                <FaIcon.FaChevronUp className="mx-auto" />
                            )}
                        </div>
                    </div>
                    {showTopItemsSection ? (
                        <div className="mr-8">
                            <TopItemsSubsection />
                        </div>
                    ) : null}
                </div>
                <div className="mt-4 pb-4 border-b border-gray-300">
                    <div className="mb-4 flex justify-between">
                        <div className="font-poppins text-black font-bold py-2 text-lg">
                            Store Suppliers
                        </div>
                        <div
                            className="pr-10 py-2"
                            role="button"
                            tabIndex={0}
                            onClick={() => setShowStoreSuppliersSection(!showStoreSuppliersSection)}
                            onKeyDown={() =>
                                setShowStoreSuppliersSection(!showStoreSuppliersSection)
                            }
                        >
                            {!showStoreSuppliersSection ? (
                                <FaIcon.FaChevronDown className="mx-auto" />
                            ) : (
                                <FaIcon.FaChevronUp className="mx-auto" />
                            )}
                        </div>
                    </div>
                    {showStoreSuppliersSection ? (
                        <div className="mr-8">
                            <StoreSuppliersSubsection />
                        </div>
                    ) : null}
                </div>
                <div className="mt-4 pb-4 border-b border-gray-300">
                    <div className="mb-4 flex justify-between">
                        <div className="font-poppins text-black font-bold py-2 text-lg">
                            Store Margin
                        </div>
                        <div
                            className="pr-10 py-2"
                            role="button"
                            tabIndex={0}
                            onClick={() => setShowStoreMarginSection(!showStoreMarginSection)}
                            onKeyDown={() => setShowStoreMarginSection(!showStoreMarginSection)}
                        >
                            {!showStoreMarginSection ? (
                                <FaIcon.FaChevronDown className="mx-auto" />
                            ) : (
                                <FaIcon.FaChevronUp className="mx-auto" />
                            )}
                        </div>
                    </div>
                    {showStoreMarginSection ? (
                        <div className="mr-8">
                            <StoreMarginSubsection />
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
}
