export const INSTANT_EDIT_OPTION_VIEW_PRODUCT_GROUP = 'VIEW';

export const INSTANT_EDIT_OPTION_EDIT_PRODUCT_GROUP = 'EDIT';

export const INSTANT_EDIT_OPTION_ACTIVE_PRODUCT_GROUP = 'ACTIVE';

export const INSTANT_EDIT_OPTION_INACTIVE_PRODUCT_GROUP = 'INACTIVE';

export const INSTANT_EDIT_OPTION_TRASH_PRODUCT_GROUP = 'TRASH';

export const INSTANT_EDIT_OPTION_RESTORE_PRODUCT_GROUP = 'RESTORE';

export const ADD_PRODUCT_GROUP_PRODUCT_TYPE_CHANGE_WARNING_TITLE = 'Unable to change product type';

export const ADD_PRODUCT_GROUP_PRODUCT_TYPE_CHANGE_WARNING_DESCRIPTION =
    'To select a different product type, please deselect the currently chosen items for this group.';

export const ERROR_ADD_GROUP = 'Error occurred while creating the group';

export const ERROR_STATUS_UPDATE_GROUP = 'Error occurred while updating the status of the group';

export const SUCCESSFULLY_STATUS_UPDATE_GROUP = 'Successfully updated the status of the group';

export const SUCCESS_ADD_GROUP = 'Group Created Successfully.';

export const SUCCESS_EDIT_GROUP = 'Group Updated Successfully.';

export const EDIT_PRODUCT_GROUP_PAGE = 'EDIT_PRODUCT_GROUP_PAGE';

export const ADD_PRODUCT_GROUP_PAGE = 'ADD_PRODUCT_GROUP_PAGE';

export const AVAILABLE_PAYMENT_CHANNELS_PAGE = 'AVAILABLE_PAYMENT_CHANNELS_PAGE';

export const SUCCESS_ADD_SUPPLIER = 'Supplier created successfully.';
