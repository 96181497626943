import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle } from './DragHandle';
import styled from 'styled-components';
import { ItemStatus } from '../../../enums/item';

const DraggingRow = styled.td`
    background: rgb(233 213 255);
    font-family: 'Poppins', sans-serif;
    padding: 12px;
`;

export const DraggableTableRow = ({ row }) => {
    const TableData = styled.td`
        background: ${row?.original?.itemStatus === ItemStatus.UNPUBLISHED ? '#e5e7eb' : 'white'};
        &:first-of-type {
            min-width: 10ch;
        }
        color: rgb(107 114 128);
        border-bottom-width: 2px;
        border-color: rgb(243 244 246);
        padding: 12px;
        font-family: 'Poppins', sans-serif;
    `;
    const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
        id: row.original.id,
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition,
    };
    return (
        <tr ref={setNodeRef} style={style} {...row.getRowProps()} className="relative">
            {isDragging ? (
                <DraggingRow colSpan={row.cells.length}>&nbsp;</DraggingRow>
            ) : (
                row.cells.map((cell, i) => {
                    if (i === 0) {
                        return (
                            <TableData {...cell.getCellProps()}>
                                <DragHandle {...attributes} {...listeners} id={row.original.id} />
                                <span>{cell.render('Cell')}</span>
                            </TableData>
                        );
                    }
                    return (
                        <TableData key={i} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                        </TableData>
                    );
                })
            )}
        </tr>
    );
};
