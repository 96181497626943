/* eslint-disable @typescript-eslint/no-unused-vars */

import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { useHistory } from 'react-router';
import {
    assignClientRoleToUser,
    createUser,
    getRoles,
    getUserDetailsUsingAdminClient,
    newUserInvite,
} from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { Buttons } from '../../ui/atoms/Button';
import * as Yup from 'yup';
import { InputField } from '../../ui/atoms/InputField';
import { Formik } from 'formik';
import NormalLoader from '../../../utils/normalLoader';
import { ClientRoles, ClientRolesTextBlock } from '../../../enums/keycloak';

interface Props {
    showInviteNewUser: boolean;
    setShowInviteNewUser: (value: boolean) => void;
}

export default function InviteUserFormModal({
    showInviteNewUser,
    setShowInviteNewUser,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const [userEmail, setUserEmail] = useState('');

    const [rolesValid, setRolesValid] = useState([]);
    const [defaultRole, setDefaultRole] = useState<any>();
    const [userRole, setUserRole] = useState('');
    const [loading, setLoading] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [error, setError] = useState(false);
    const currentTimeInMille = new Date().valueOf();
    const history = useHistory();
    const [roleError, setRoleError] = useState(false);
    useEffect(() => {
        dispatch(getRoles());
    }, [dispatch]);

    const roles = useSelector((state: RootState) => state.auth.roles);
    const keyCloakUserDetails = useSelector((state: RootState) => state.auth.usersList);

    const isNewUserEmailSent = useSelector((state: RootState) => state.auth.isEmailSent);

    useEffect(() => {
        if (roles?.length) {
            const roleList = roles?.filter(
                (role) => role?.name !== ClientRoles.PARTNER && role?.name !== ClientRoles.USER,
            );
            const rolesNames = roleList?.map((role) => ({
                id: role?.id,
                name:
                    role?.name === ClientRoles?.STORE_ADMIN
                        ? ClientRolesTextBlock.STORE_ADMIN
                        : role?.name,
                value:
                    role?.name === ClientRoles?.STORE_ADMIN ? ClientRoles?.STORE_ADMIN : role?.name,
            }));
            setRolesValid(rolesNames);
            const defaultVal = rolesNames?.find(
                (role) => role?.name === ClientRolesTextBlock.STORE_ADMIN,
            );
            setDefaultRole(defaultVal);
        }
    }, [roles, roles?.length]);

    const keyCloakCreatedUser = useSelector((state: RootState) => state.auth.createdUser);
    useEffect(() => {
        if (isNewUserEmailSent) {
            setShowInviteNewUser(false);
            window.location.reload();
        }
    }, [isNewUserEmailSent, setShowInviteNewUser]);

    const sendNewUSerEmailErrorMessage = useSelector(
        (state: RootState) => state.auth.sendNewUSerEmailErrorMessage,
    );
    const keyCloakCreateUserSuccess = useSelector(
        (state: RootState) => state.auth.createUserSuccess,
    );

    const handleRoleAssign = (id: any, role: any) => {
        let roleObject = null;
        if (role && roles) {
            roleObject = roles.filter((roleItem) => roleItem.name === role);
        }
        if (roleObject && id && clicked) {
            setTimeout(() => {
                dispatch(assignClientRoleToUser(id, roleObject));
                dispatch(newUserInvite(userEmail));

                sendInvite();
            }, 3000);
        }
    };

    useEffect(() => {
        if (userEmail && keyCloakCreateUserSuccess) {
            setTimeout(() => {
                dispatch(getUserDetailsUsingAdminClient(userEmail));
            }, 2000);
        }
    }, [dispatch, userEmail, keyCloakCreateUserSuccess, clicked]);

    useEffect(() => {
        if (keyCloakCreatedUser?.length) {
            handleRoleAssign(keyCloakCreatedUser[0].id, userRole ? userRole : defaultRole?.value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, keyCloakCreatedUser, userRole, defaultRole?.value]);

    const sendInvite = () => {
        if (isNewUserEmailSent) {
            return (
                <div className="text-green-500 text-center text-base mt-1 ">
                    Email sent successfully!
                </div>
            );
        }
    };

    const validationSchema = Yup.object({
        email: Yup.string()
            .trim()
            .email('Incorrect email address')
            .required('Missing email address'),
    });

    return (
        <Modal
            open={showInviteNewUser}
            onClose={() => {
                setShowInviteNewUser(false);
            }}
            center={true}
            styles={{ modal: { borderRadius: 8, maxWidth: '706px', width: '80%' } }}
            focusTrapped={false}
        >
            <>
                <Formik
                    initialValues={{
                        email: '',
                        role: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async ({ email }) => {
                        const keylogUsers = keyCloakUserDetails?.map((user) => ({
                            userId: user?.userId,
                            userName: user?.username,
                            firstName: user?.firstName,
                            lastName: user?.lastName,
                            contact: {
                                primaryEmail: user?.email,
                            },
                        }));
                        setUserEmail(email);
                        const isAlreadyThere = keylogUsers?.some(
                            (user) => user?.contact?.primaryEmail === email,
                        );
                        setClicked(true);
                        if (isAlreadyThere) {
                            setError(true);
                        } else {
                            setError(false);
                            setLoading(true);
                            if (userRole || defaultRole?.value) {
                                dispatch(createUser(currentTimeInMille, email));
                            }
                        }
                    }}
                >
                    {({ values, handleChange, handleSubmit, handleBlur, errors, touched }) => (
                        <div className="border-0 rounded-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between border-b border-solid border-blueGray-200 rounded-t">
                                <h3 className="text-xl p-4 font-poppins  font-bold flex m-auto">
                                    Invite new user
                                </h3>
                            </div>

                            <div className="relative p-6  flex flex-col">
                                <div className="text-gray-400 text-base flex mb-3 m-auto">
                                    Enter the email address and select the user role
                                </div>
                            </div>
                            <div className="relative px-24 pt-6 -mt-10  flex flex-col">
                                <div className="flex flex-wrap flex-col mb-3 lg:mb-5 px-4">
                                    <div className="flex flex-wrap flex-col lg:mb-5">
                                        <InputField
                                            placeHolder="Lucyadmin@swarmio.com"
                                            name="Email address"
                                            id="email"
                                            labelWidth="w-28"
                                            onChange={handleChange('email')}
                                            value={values.email}
                                        />
                                        {errors.email && touched.email ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                {errors.email}
                                            </div>
                                        ) : null}
                                        {error ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                {'User already exists'}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="flex flex-wrap flex-col relative mt-3">
                                        <div className="text-gray-500 text-sm w-max font-poppins px-2 h-max bg-white z-30 ml-4 -mb-1">
                                            User role
                                        </div>
                                        <select
                                            className="p-4 lg:p-3 -mt-2  rounded-xl outline-none border-2  bg-white border-gray-400 hover:border-purple-500"
                                            id="role"
                                            onChange={(event) => {
                                                setUserRole(event.target.value);
                                            }}
                                            onBlur={() => handleBlur('role')}
                                            defaultValue={defaultRole?.name}
                                        >
                                            <option key="default" value="default" disabled selected>
                                                Select user role
                                            </option>
                                            {rolesValid?.map((role: any) => {
                                                return (
                                                    <option key={role.id} value={role.value}>
                                                        {role.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        {roleError ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                {'Role should not be empty'}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            {isNewUserEmailSent ? (
                                <div className="text-green-500 text-center text-xl mt-1 ">
                                    EMAIL SENT !!!
                                </div>
                            ) : null}
                            {isNewUserEmailSent ? setLoading(false) : null}

                            {sendNewUSerEmailErrorMessage ? (
                                <div className="text-red-500 text-center text-xl mt-1 ">
                                    {sendNewUSerEmailErrorMessage}
                                </div>
                            ) : null}
                            <div className="flex flex-row mt-6 justify-end m-4">
                                <Buttons
                                    name="Cancel"
                                    type="button"
                                    buttonType="secondary-main-grey"
                                    id="Change email address"
                                    size="small"
                                    other="mr-3"
                                    onclick={() => setShowInviteNewUser(false)}
                                />
                                <Buttons
                                    name={
                                        loading && !sendNewUSerEmailErrorMessage ? (
                                            <NormalLoader />
                                        ) : (
                                            'Invite user'
                                        )
                                    }
                                    type="submit"
                                    buttonType="primary"
                                    id="Change email address"
                                    size="small"
                                    padding="p-1"
                                    onclick={() => {
                                        handleSubmit();
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </Formik>
            </>
        </Modal>
    );
}
