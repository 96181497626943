import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import { useHistory, useLocation } from 'react-router-dom';
import {
    CONFIRM_EMAIL,
    FETCH_ADMIN_ONE_PROFILE,
    SEND_VERIFICATION_EMAIL,
} from '../../../queries/AdminProfileQueries';
import { Buttons } from '../../ui/atoms/Button';
import { getCookie } from '../../../utils/cookiesService';

export default function ConfirmVerificationEmail() {
    const location = useLocation();
    const history = useHistory();
    const [error, setError] = useState('');

    const verifyToken = location?.search.slice(7);
    const { data } = useQuery(CONFIRM_EMAIL, {
        variables: { token: verifyToken },
    });

    const [sendEmailVerification, { error: emailError }] = useMutation(SEND_VERIFICATION_EMAIL);
    const loggedInUser = localStorage.getItem('update-email');
    const token = getCookie('access_token');
    const tokenDetails: any = token ? jwt(token) : null;
    const { data: queryData } = useQuery(FETCH_ADMIN_ONE_PROFILE, {
        variables: { userName: tokenDetails?.preferred_username },
    });
    const currentUser = queryData?.user;

    useEffect(() => {
        if (data?.confirmEmailVerification === true) {
            setError(null);
        } else if (data?.confirmEmailVerification === false) {
            setError('Oops something went wrong');
        }
    }, [data, history]);

    return (
        <div className="flex w-screen h-screen ">
            {error ? (
                <div className="m-auto bg-purple-600 w-5/12 p-8">
                    <img className="flex m-auto" src="/images/icons/logo.png" alt="question" />
                    <div className="font-poppins text-2xl text-gray-200 font-extrabold mt-5 text-center">
                        SORRY, SOMETHING WENT WRONG
                    </div>
                    <div className="p-3 flex flex-col justify-center items-center">
                        <div className="font-poppins text-sm text-gray-200  mt-5 text-center">
                            The link you used to arrive at this page is not vaild. There is a
                        </div>
                        <div className="font-poppins text-sm text-gray-200  mt-5 text-center">
                            problem with this link that needs to be correct by Us. Please click on
                        </div>
                        <div className="font-poppins text-sm text-gray-200  mt-5 text-center">
                            the Resend email to send a new email.
                        </div>

                        <Buttons
                            name="Send new email"
                            type="button"
                            buttonType="secondary-main"
                            id="Change email address"
                            size="large"
                            other="ml-10 mt-4"
                            onclick={() => {
                                sendEmailVerification({
                                    variables: { email: currentUser?.contact?.primaryEmail },
                                });
                                if (!emailError) {
                                    history.push('/adminProfile');
                                }
                            }}
                        />
                        <div className="font-poppins text-sm text-gray-200  mt-5 text-center">
                            If you did not request this email change, please reach out to the
                            Swarmio Team at
                        </div>
                        <div className="font-poppins text-sm text-gray-200  mt-5 text-center">
                            [xxxxxxxx]
                        </div>
                        <div className="font-poppins text-sm text-gray-200  mt-5 text-center">
                            SWARMIO | TWITTER | FACE BOOK
                        </div>
                    </div>
                </div>
            ) : (
                <div className="m-auto bg-purple-600 w-5/12 p-8">
                    <img className="flex m-auto" src="/images/icons/logo.png" alt="question" />
                    <div className="font-poppins text-2xl text-gray-200 font-extrabold mt-5 text-center">
                        SUCCESS
                    </div>
                    <div className="p-3 flex flex-col justify-center items-center">
                        <Buttons
                            name="Back to profile"
                            type="button"
                            buttonType="secondary-main"
                            id="Change email address"
                            size="large"
                            other="ml-10 mt-4"
                            onclick={() => {
                                if (data?.confirmEmailVerification === true) {
                                    localStorage.setItem('current_user_email', loggedInUser);
                                    history.push('/adminProfile');
                                    window.location.reload();
                                }
                            }}
                        />

                        <div className="font-poppins text-sm text-gray-200  mt-5 text-center">
                            SWARMIO | TWITTER | FACE BOOK
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
