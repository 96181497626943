import React, { useEffect, useState } from 'react';
import '../../../../styles/dropdown.css';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import * as BsIcons from 'react-icons/bs';
import { pagination } from '../../../../constants/config/constants';
import DataTable from '../../../ui/organisms/DataTable';

import { useHistory } from 'react-router';
import {
    INSTANT_EDIT_OPTION_VIEW,
    INSTANT_EDIT_OPTION_RESTORE,
} from '../../../../constants/product';
import ConfirmationModalWithMessage from '../../../templates/modals/ConfirmationModalWithMessage';

import {
    FETCH_FAILED_ORDER_TRANSACTIONS,
    FETCH_SUCCESSFULL_RETRIED_ORDERS,
} from '../../../../queries/OrdersQueries';
import 'react-datepicker/dist/react-datepicker.css';
import {
    COMPLETE_ORDERS,
    EMPTY_SEARCH_RESULT_TEXT,
    PROCESSING_ORDERS,
    REFUNDED_ORDERS,
    FAILED_ORDERS,
    CANCELLED_ORDERS,
} from '../../../../constants/order';
import WarningMessageModal from '../../../templates/modals/WarningMessageModal';
import ViewOneFailedTransaction from '../../../templates/modals/ViewOneFailedTransaction';
import Toast from '../../../ui/atoms/Toast';
import {
    NOT_REGISTERED,
    NOT_REGISTERED_WARNING_BODY,
    NOT_REGISTERED_WARNING_TITLE,
    REGISTERED,
} from '../../../../constants/profile';
import SearchBar from '../../../ui/molecules/SearchBar';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { SORT_DSC } from '../../../../constants/common';
import NormalLoader from '../../../../utils/normalLoader';
import moment from 'moment';
import Loader from '../../../../utils/loader';
import { currencyAndCountryCodes } from '../../../../utils/currencies';
import { PurchaseMedium } from '../../../../enums/order';
import { Images } from '../../../ui/atoms/Images';
import TableTabView from '../../productScreens/TableTabView';

export default function FailedTransactionsScreen() {
    const [completeQuery, setCompleteQuery] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [instantEditMenuItems, setInstantEditMenuItems] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [selectedFailedTransaction, setSelectedFailedTransaction] = useState([]);
    const [searchClicked, setSearchClicked] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [confirmationMessageTitle, setConfirmationMessageTitle] = useState('');
    const [clickedOrder, setClickedOrder] = useState(null);
    const [failedTransactionData, setFailedTransactionData] = useState([]);
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const history = useHistory();
    const [selectedOneOrderData, setSelectedOneOrderData] = useState([]);
    const [loadingViewOne, setLoadingViewOne] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [orderDataInitial, setOrderDataInitial] = useState([]);
    const [failedTransactionsDataUnMutated, setFailedTransactionsDataMutated] = useState([]);
    const [isFilterClicked, setIsFilterClicked] = useState(false);
    const [failedTransactionFilter, setFailedTransactionFilter] = useState(false);
    const [message, setMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [confirmationModalYesClicked, setConfirmationModalYesClicked] = useState(false);
    const [confirmationModalNoClicked, setConfirmationModalNoClicked] = useState(false);
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [pageSize, setPageSize] = useState(pagination?.pageSize);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [selectedOrderDateFilter, setSelectedOrderDateFilter] = useState<string>('LAST_WEEK');
    const [showBulkActions, setShowBulkActions] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [queryError, setQueryError] = useState(false);
    const [warningModalBody, setWarningModalBody] = useState('');
    const [warningModalTitle, setWarningModalTitle] = useState('');
    const [warningModalOkayClicked, setWarningModalOkayClicked] = useState(false);
    const [viewFailedTransactionModalVisible, setViewFailedTransactionModalVisible] =
        useState(false);
    const [createdDateFilterRemoved, setCreatedDateFilterRemoved] = useState(true);
    const [createdDateFilter, setCreatedDateFilter] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [successRetries, setSuccessRetries] = useState(0);
    const [failedTransactionsTotalCount, setfailedTransactionsTotalCount] = useState(0);
    const [completedRetriedData, setCompletedRetriedData] = useState([]);
    const [completedRetriedDataInitial, setCompletedRetriedDataInitial] = useState([]);

    const [selectedFaileTransactionData, setSelectedFaileTransactionData] = useState(null);

    const [state, setState] = useState([
        {
            startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
            endDate: new Date(),
            key: 'selection',
        },
    ]);
    const [transactionStatus, setTransactionStatus] = useState({
        all: 0,
        success: 0,
    });

    const [sortState, setSortState] = useState<string>(SORT_DSC);
    const [sortField, setSortField] = useState<string>('createdDate');
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [
        loadFailedTransactions,
        { data: failedTransactionsData, loading: orderLoading, called },
    ] = useLazyQuery(FETCH_FAILED_ORDER_TRANSACTIONS, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted: () => {
            setFailedTransactionData(
                failedTransactionsData?.failedOrderTransactions?.failedTransactions,
            );
            setFailedTransactionsDataMutated(
                failedTransactionsData?.failedOrderTransactions?.failedTransactions,
            );
            setOrderDataInitial(
                failedTransactionsData?.failedOrderTransactions?.failedTransactions,
            );
            setCompleteQuery(true);
            setfailedTransactionsTotalCount(
                failedTransactionsData?.failedOrderTransactions?.totalFailedTransactions,
            );
            setTotalItems(failedTransactionsData?.failedOrderTransactions?.totalFailedTransactions);
            setSuccessRetries(
                failedTransactionsData?.failedOrderTransactions?.totalRetriedSuccessOrders,
            );
        },
    });

    const [
        loadCompletedRetriedTransactions,
        { data: completedTransactionsData, loading: completedTransactionsDataLoading },
    ] = useLazyQuery(FETCH_SUCCESSFULL_RETRIED_ORDERS, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted: () => {
            setCompletedRetriedData(completedTransactionsData?.retriedSuccessOrders?.orders);
            setCompletedRetriedDataInitial(completedTransactionsData?.retriedSuccessOrders?.orders);
            setTotalItems(completedTransactionsData?.retriedSuccessOrders?.totalOrders);
        },
    });

    useEffect(() => {
        if (clickedOrder?.id) {
            setViewFailedTransactionModalVisible(true);

            let selectedTransaction = clickedOrder;

            if (selectedTab === 1) {
                const filteredTransaction =
                    failedTransactionsData?.failedOrderTransactions?.failedTransactions.find(
                        (transaction) => transaction.transactionId === clickedOrder.transactionId,
                    );

                if (filteredTransaction) {
                    selectedTransaction = filteredTransaction;
                }
            }

            // set details to be sent to the modal
            if (selectedTransaction?.id) {
                setSelectedFaileTransactionData({
                    id: selectedTransaction.id,
                    transactionId: selectedTransaction.transactionId,
                    userId: selectedTransaction.gamerId,
                    displayName: selectedTransaction.gamerName,
                    gamerEmail: selectedTransaction.gamerEmail,
                    createdDate: selectedTransaction.createdDate,
                    purchaseMedium: selectedTransaction.purchaseMedium,
                    contentType: selectedTransaction.contentType,
                    purchaseCurrency:
                        selectedTransaction.purchaseCurrency || selectedTransaction.targetCurrency,
                    errorDetails:
                        selectedTransaction.errorCode + ' - ' + selectedTransaction.errorMessage,
                    item: selectedTransaction.item,
                    quantity: selectedTransaction.quantity,
                    status: selectedTransaction.status || selectedTransaction.orderStatus,
                    totalPoints: selectedTransaction.totalPoints,
                    targetCurrency:
                        selectedTransaction.purchaseCurrency || selectedTransaction.targetCurrency,
                    markedUpPrice: selectedTransaction.markedUpPrice,
                    retryButtonDisabled: selectedTransaction.retryButtonDisabled,
                    dealId: selectedTransaction?.dealId,
                    dealValue: selectedTransaction?.dealValue,
                    dealPercentage: selectedTransaction?.dealPercentage,
                });
            }
        }
    }, [clickedOrder?.id, orderLoading]);

    useEffect(() => {
        if (!viewFailedTransactionModalVisible) {
            localStorage.removeItem('orderId');
            setClickedOrder(null);
        }
    }, [showConfirmationModal, viewFailedTransactionModalVisible]);

    useEffect(() => {
        if (confirmationModalNoClicked) {
            setShowConfirmationModal(false);
            setSelectedMenuItem('');
            setConfirmationModalNoClicked(false);
        }
    }, [confirmationModalNoClicked]);

    useEffect(() => {
        const instantMenuList = [];

        selectedTab === 1
            ? instantMenuList.push({
                  text: 'View',
                  value: INSTANT_EDIT_OPTION_VIEW,
              })
            : instantMenuList.push(
                  {
                      text: 'View',
                      value: INSTANT_EDIT_OPTION_VIEW,
                  },
                  {
                      text: 'Restore',
                      value: INSTANT_EDIT_OPTION_RESTORE,
                  },
              );

        setInstantEditMenuItems(instantMenuList);
    }, [selectedTab]);

    const orderDataForSearch = [
        ...new Set(
            failedTransactionsDataUnMutated
                ?.filter((order) => order?.gamerId && order?.gamerId !== null)
                ?.map((order) => order?.gamerId)
                ?.concat(
                    failedTransactionsDataUnMutated
                        ?.filter((order) => order?.transactionId && order?.transactionId !== null)
                        ?.map((order) => order?.transactionId),
                )
                ?.concat(
                    failedTransactionsDataUnMutated
                        ?.filter((order) => order?.gamerEmail && order?.gamerEmail !== null)
                        ?.map((order) => order?.gamerEmail),
                )
                ?.concat(
                    failedTransactionsDataUnMutated
                        ?.filter((order) => order?.gamerName && order?.gamerName !== null)
                        ?.map((order) => order?.gamerName),
                )
                ?.concat(
                    failedTransactionsDataUnMutated
                        ?.filter((order) => order?.item?.itemName && order?.item?.itemName !== null)
                        ?.map((order) => order?.item?.itemName),
                ),
        ),
    ];

    const completedRetriedDataForSearch = [
        ...new Set(
            completedRetriedDataInitial
                ?.filter((order) => order?.gamerId && order?.gamerId !== null)
                ?.map((order) => order?.gamerId)
                ?.concat(
                    completedRetriedDataInitial
                        ?.filter((order) => order?.transactionId && order?.transactionId !== null)
                        ?.map((order) => order?.transactionId),
                )
                ?.concat(
                    completedRetriedDataInitial
                        ?.filter((order) => order?.gamerEmail && order?.gamerEmail !== null)
                        ?.map((order) => order?.gamerEmail),
                )
                ?.concat(
                    completedRetriedDataInitial
                        ?.filter((order) => order?.gamerName && order?.gamerName !== null)
                        ?.map((order) => order?.gamerName),
                )
                ?.concat(
                    completedRetriedDataInitial
                        ?.filter((order) => order?.item?.itemName && order?.item?.itemName !== null)
                        ?.map((order) => order?.item?.itemName),
                )
                ?.concat(
                    completedRetriedDataInitial
                        ?.filter(
                            (order) =>
                                order?.vouchers[0]?.voucherCode &&
                                order?.vouchers[0]?.voucherCode !== null,
                        )
                        ?.map((order) => order?.vouchers[0]?.voucherCode),
                )
                ?.concat(
                    completedRetriedDataInitial
                        ?.filter(
                            (order) =>
                                order?.partner?.partnerName && order?.partner?.partnerName !== null,
                        )
                        ?.map((order) => order?.partner?.partnerName),
                )
                ?.concat(
                    completedRetriedDataInitial
                        ?.filter(
                            (order) =>
                                order?.item?.supplier?.supplierName &&
                                order?.item?.supplier?.supplierName !== null,
                        )
                        ?.map((order) => order?.item?.supplier?.supplierName),
                ),
        ),
    ];

    const isRegistered = localStorage.getItem('isRegistered');

    useEffect(() => {
        if (warningModalOkayClicked && isRegistered === REGISTERED) {
            window.location.reload();
        }
    }, [history, warningModalOkayClicked, isRegistered]);

    useEffect(() => {
        if (isRegistered === NOT_REGISTERED && !clicked) {
            setWarningModalBody(NOT_REGISTERED_WARNING_BODY);
            setWarningModalTitle(NOT_REGISTERED_WARNING_TITLE);
            setShowWarningModal(true);
        }
    }, [clicked, isRegistered, showWarningModal]);

    useEffect(() => {
        if (warningModalOkayClicked && isRegistered === NOT_REGISTERED) {
            setClicked(true);
            history.push('/adminProfile');
        }
    }, [history, isRegistered, warningModalOkayClicked]);

    useEffect(() => {
        const filtered = orderDataInitial;

        setSelectedOneOrderData(filtered);
        setFilteredData(filtered); //future need
    }, [pageIndex, pageSize, orderDataInitial, searchTerm, isFilterClicked]);

    useEffect(() => {
        if (selectedFailedTransaction?.length > 1) {
            if (selectedTab !== 2 && selectedTab !== 5) {
                setShowBulkActions(true);
            }
        } else {
            setShowBulkActions(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFailedTransaction]);

    useEffect(() => {
        const transactionCounts = {
            all: failedTransactionsTotalCount,
            success: successRetries,
        };

        setTransactionStatus(transactionCounts);
    }, [failedTransactionsTotalCount, successRetries]);

    useEffect(() => {
        setCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab, pageSize, createdDateFilter]);

    useEffect(() => {
        if (!isFilterClicked) {
            setCreatedDateFilter(null);
        }
    }, [isFilterClicked]);

    const columns = React.useMemo(
        () => [
            {
                id: 'transactionId',
                Header: 'Transaction Id',
                fieldName: 'transactionId',
                accessor: 'transactionId',
                Cell: function decorateCell({ value }) {
                    return (
                        <span className="cursor-pointer underline text-blue-600">
                            {loadingViewOne ? <NormalLoader /> : value}
                        </span>
                    );
                },
            },
            {
                id: 'userId',
                Header: 'User Id',
                fieldName: 'gamerId',
                accessor: 'gamerId',
            },
            {
                id: 'displayName',
                Header: 'Display name',
                fieldName: 'gamerName',
                accessor: 'gamerName',
            },
            {
                id: 'gamerEmail',
                Header: 'Gamer email',
                fieldName: 'gamerEmail',
                accessor: 'gamerEmail',
            },
            {
                id: 'itemName',
                Header: 'Item name (purchased)',
                fieldName: 'item.itemName',
                accessor: 'item.itemName',
            },
            {
                id: 'contentType',
                Header: 'Content Type',
                fieldName: 'contentType',
                accessor: 'contentType',
            },
            {
                id: 'supplier',
                Header: 'Supplier',
                fieldName: 'item.supplier.supplierName',
                accessor: 'item.supplier.supplierName',
            },
            {
                id: 'purchaseType',
                Header: 'Purchase Type',
                fieldName: 'purchaseMedium',
                accessor: 'purchaseMedium',
            },
            {
                id: 'purchaseCurrency',
                Header: 'Purchase Currency',
                fieldName: 'purchaseCurrency',
                accessor: 'purchaseCurrency',
                Cell: function decorateCell({ value, row }) {
                    if (row?.original?.purchaseMedium === PurchaseMedium.POINTS) {
                        return (
                            <div className="flex flex-row">
                                <Images
                                    width="w-6"
                                    height="h-full"
                                    src={'/images/icons/coin.svg'}
                                    alt="coin"
                                    customStyle="pl-4 pr-2.5"
                                />
                                <span className="pt-0.5">{`PTS`}</span>
                            </div>
                        );
                    } else {
                        return (
                            <div className="flex">
                                <i
                                    className={`${currencyAndCountryCodes
                                        .find((x) => x.currency === value)
                                        ?.country.toLowerCase()} flag px-5 pt-1`}
                                ></i>
                                <span>{value}</span>
                            </div>
                        );
                    }
                },
            },
            {
                id: 'partner',
                Header: 'Partner',
                fieldName: 'partner.partnerName',
                accessor: 'partner.partnerName',
            },
            {
                Header: 'Status',
                fieldName: 'status',
                accessor: 'status',
                Cell: function showBadge({ value }) {
                    return (
                        <span
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                value === COMPLETE_ORDERS
                                    ? 'bg-green-100 text-green-600'
                                    : value === FAILED_ORDERS
                                    ? 'bg-red-100 text-red-600'
                                    : value === REFUNDED_ORDERS
                                    ? 'bg-gray-100 text-gray-600'
                                    : value === PROCESSING_ORDERS
                                    ? 'bg-yellow-100 text-yellow-600'
                                    : ''
                            }`}
                        >
                            {value}
                        </span>
                    );
                },
            },
            {
                Header: 'Order date',
                fieldName: 'createdDate',
                accessor: 'createdDate',
                Cell: function date({ value }) {
                    return <span>{value?.slice(0, 16) + '(UTC)'}</span>;
                },
            },
            {
                id: 'errorCode',
                Header: 'Error Code',
                fieldName: 'errorCode',
                accessor: 'errorCode',
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const completedRetriesColumn = React.useMemo(
        () => [
            {
                id: 'transactionId',
                Header: 'Transaction Id',
                fieldName: 'transactionId',
                accessor: 'transactionId',
                Cell: function decorateCell({ value }) {
                    return (
                        <span className="cursor-pointer underline text-blue-600">
                            {loadingViewOne ? <NormalLoader /> : value}
                        </span>
                    );
                },
            },
            {
                id: 'userId',
                Header: 'User Id',
                fieldName: 'gamerId',
                accessor: 'gamerId',
            },
            {
                id: 'displayName',
                Header: 'Display name',
                fieldName: 'gamerName',
                accessor: 'gamerName',
            },
            {
                id: 'gamerEmail',
                Header: 'Gamer email',
                fieldName: 'gamerEmail',
                accessor: 'gamerEmail',
            },
            {
                id: 'itemName',
                Header: 'Item name (purchased)',
                fieldName: 'item.itemName',
                accessor: 'item.itemName',
            },
            {
                id: 'contentType',
                Header: 'Content Type',
                fieldName: 'contentType',
                accessor: 'contentType',
            },
            {
                id: 'supplier',
                Header: 'Supplier',
                fieldName: 'item.supplier.supplierName',
                accessor: 'item.supplier.supplierName',
            },
            {
                id: 'purchaseType',
                Header: 'Purchase Type',
                fieldName: 'purchaseMedium',
                accessor: 'purchaseMedium',
            },
            {
                id: 'purchaseCurrency',
                Header: 'Purchase Currency',
                fieldName: 'targetCurrency',
                accessor: 'targetCurrency',
                Cell: function decorateCell({ value, row }) {
                    if (row?.original?.purchaseMedium === PurchaseMedium.POINTS) {
                        return (
                            <div className="flex flex-row">
                                <Images
                                    width="w-6"
                                    height="h-full"
                                    src={'/images/icons/coin.svg'}
                                    alt="coin"
                                    customStyle="pl-4 pr-2.5"
                                />
                                <span className="pt-0.5">{`PTS`}</span>
                            </div>
                        );
                    } else {
                        return (
                            <div className="flex">
                                <i
                                    className={`${currencyAndCountryCodes
                                        .find((x) => x.currency === value)
                                        ?.country.toLowerCase()} flag px-5 pt-1`}
                                ></i>
                                <span>{value}</span>
                            </div>
                        );
                    }
                },
            },
            {
                id: 'voucherCode',
                Header: 'Voucher code',
                fieldName: 'vouchers.voucherCode',
                accessor: 'vouchers[0].voucherCode',
                Cell: function ({ row }) {
                    const voucherCode =
                        row?.original?.vouchers[0]?.voucherCode ||
                        row?.original?.vouchers[0]?.voucherSerial2;
                    return <span id={voucherCode}>{voucherCode}</span>;
                },
            },
            {
                id: 'partner',
                Header: 'Partner',
                fieldName: 'partner.partnerName',
                accessor: 'partner.partnerName',
            },
            {
                Header: 'Status',
                fieldName: 'orderStatus',
                accessor: 'orderStatus',
                Cell: function showBadge({ value }) {
                    return (
                        <span
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                value === COMPLETE_ORDERS
                                    ? 'bg-green-100 text-green-600'
                                    : value === CANCELLED_ORDERS
                                    ? 'bg-red-100 text-red-600'
                                    : value === REFUNDED_ORDERS
                                    ? 'bg-gray-100 text-gray-600'
                                    : value === PROCESSING_ORDERS
                                    ? 'bg-yellow-100 text-yellow-600'
                                    : ''
                            }`}
                        >
                            {value}
                        </span>
                    );
                },
            },
            {
                Header: 'Order date',
                fieldName: 'createdDate',
                accessor: 'createdDate',
                Cell: function date({ value }) {
                    return <span>{value?.slice(0, 16) + '(UTC)'}</span>;
                },
            },
            {
                id: 'voucherExpiry',
                Header: 'Voucher expiry',
                fieldName: 'vouchers.voucherExpiry',
                accessor: 'vouchers[0].voucherExpiry',
            },
            {
                id: 'voucherSerial',
                Header: 'Voucher name',
                fieldName: 'vouchers.voucherSerial',
                accessor: 'vouchers[0].voucherSerial',
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
        }
    };

    const orderDateOptions = [
        {
            text: 'Last 7 days',
            value: 'LAST_WEEK',
        },
        {
            text: 'All',
            value: 'ALL_TRANSACTIONS',
        },
    ];

    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm]);

    const handleFailedOrderTransactions = () => {
        let startDateInstance;
        let endDateInstance;
        state?.map((date) => (startDateInstance = moment(date?.startDate).utcOffset(0, true)));
        state?.map((date) => (endDateInstance = moment(date?.endDate).utcOffset(0, true)));
        const startDate = startDateInstance.startOf('day').valueOf();
        const endDate = endDateInstance.endOf('day').valueOf();

        if (startDate || endDate) {
            setFailedTransactionFilter(true);
        }

        if (selectedTab === 1) {
            loadFailedTransactions({
                variables: {
                    filter: {
                        dateStartTimeMillis:
                            startDate && startDate && startDate < endDate ? startDate : null,
                        dateEndTimeMillis:
                            endDate && startDate && endDate && startDate < endDate
                                ? endDate
                                : isFilterClicked && !endDate && startDate
                                ? startDateInstance.endOf('day').valueOf()
                                : null,
                    },
                    offset: currentPage - 1 || 0,
                    limit: Math.ceil(pageSize) || 1000000,
                    sort: sortField,
                    order: sortState,
                    searchText: searchTerm || null,
                },
            });
        } else if (selectedTab === 2) {
            loadCompletedRetriedTransactions({
                variables: {
                    filter: {
                        dateStartTimeMillis:
                            startDate && startDate && startDate < endDate ? startDate : null,
                        dateEndTimeMillis:
                            endDate && startDate && endDate && startDate < endDate
                                ? endDate
                                : isFilterClicked && !endDate && startDate
                                ? startDateInstance.endOf('day').valueOf()
                                : null,
                    },
                    offset: currentPage - 1 || 0,
                    limit: Math.ceil(pageSize) || 1000000,
                    sort: sortField,
                    order: sortState,
                    searchText: searchTerm || null,
                },
            });
            //setSelectedOneOrderData(null);
        }
    };

    useEffect(() => {
        handleFailedOrderTransactions();
    }, [
        currentPage,
        loadFailedTransactions,
        pageSize,
        isFilterClicked,
        selectedTab,
        searchTerm,
        state,
        sortField,
        sortState,
        loadCompletedRetriedTransactions,
    ]);

    return (
        <div className="border-2 border-gray-300 rounded-lg mr-7 mb-10">
            <div className="w-full ml-4 pt-4">
                {showToast && (
                    <Toast
                        setShowToast={setShowToast}
                        message={message}
                        width="w-10/12"
                        margin="ml-1"
                    />
                )}
                <div className={`${showToast ? 'mt-14' : null}`}>
                    <TableTabView
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        failedTransactionsCount={transactionStatus}
                    />
                    <ConfirmationModalWithMessage
                        showConfirmationModal={showConfirmationModal}
                        setShowConfirmationModal={setShowConfirmationModal}
                        message={confirmationMessage}
                        messageTitle={confirmationMessageTitle}
                        setYesBtnClick={setConfirmationModalYesClicked}
                        setNoBtnClick={setConfirmationModalNoClicked}
                    />
                    <WarningMessageModal
                        showWarningModal={showWarningModal}
                        setShowWarningModal={setShowWarningModal}
                        warningModalBody={warningModalBody}
                        warningModalTitle={warningModalTitle}
                        setProcessFinishedClicked={setWarningModalOkayClicked}
                    />
                    <ViewOneFailedTransaction
                        showVerifyModal={viewFailedTransactionModalVisible}
                        setShowVerifyModal={setViewFailedTransactionModalVisible}
                        setLoadingViewOneFailedTransaction={setLoadingViewOne}
                        currentFailedTransaction={selectedFaileTransactionData}
                        refetchFailedTransactions={handleFailedOrderTransactions}
                    />
                </div>
            </div>
            <div className={`flex p-4`}>
                <div className="flex w-full">
                    <div className="flex realative lg:mr-4">
                        <button
                            onKeyDown={(e) => {
                                if (e.code === 'Enter') {
                                    setIsFilterClicked(!isFilterClicked);
                                }
                            }}
                            onClick={() => setIsFilterClicked(!isFilterClicked)}
                            className={`bg-transparent hover:bg-purple-500 hover:text-white py-2 px-4 border border-gray-300 hover:border-transparent rounded-md ${
                                isFilterClicked ? 'bg-purple-500 text-white' : 'text-gray-500'
                            }`}
                        >
                            <span className="flex">
                                <BsIcons.BsFillFunnelFill /> <span className="ml-2">Filter</span>
                            </span>
                        </button>
                    </div>
                    <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3">
                        <SearchBar
                            id="order-list-search-field"
                            options={
                                selectedTab === 1
                                    ? orderDataForSearch || []
                                    : completedRetriedDataForSearch || []
                            }
                            handleChange={(e, newVal, reason) => {
                                setSearchClicked(true);
                                handleChange(e, newVal, reason);
                            }}
                            placeHolder={''}
                            noOptionsText={EMPTY_SEARCH_RESULT_TEXT}
                        />
                    </div>

                    <div className="flex flex-row relative space-x-2 px-2 ml-10">
                        <div className="flex flex-wrap flex-col relative">
                            <select
                                className="px-4 z-0 rounded-lg outline-none border h-full bg-white border-gray-400 hover:border-purple-500"
                                id="filter_product_type"
                                placeholder="actions"
                                onChange={(e) => {
                                    setSelectedOrderDateFilter(e.target.value);
                                    setCurrentPage(1);
                                    if (e.target.value === 'ALL_TRANSACTIONS') {
                                        setState([
                                            {
                                                startDate: new Date(),
                                                endDate: null,
                                                key: 'selection',
                                            },
                                        ]);
                                    } else if (e.target.value === 'LAST_WEEK') {
                                        setState([
                                            {
                                                startDate: new Date(
                                                    new Date().setDate(new Date().getDate() - 7),
                                                ),
                                                endDate: new Date(),
                                                key: 'selection',
                                            },
                                        ]);
                                    }
                                }}
                                value={selectedOrderDateFilter}
                            >
                                <option value="CUSTOM" disabled selected id="filter_product_type">
                                    Custom
                                </option>
                                {orderDateOptions.map((option: any, index) => {
                                    return (
                                        <option
                                            value={option.value}
                                            className={'font-medium'}
                                            key={index}
                                        >
                                            {option.text.slice(0, 13)}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {isFilterClicked && (
                <div className="grid grid-cols-8 gap-4">
                    <div className="col-start-1 col-end-3 mt-3 px-4  mb-6">
                        <div className="flex flex-wrap flex-col relative">
                            <div className="text-gray-600 w-40 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3.5 ml-4">
                                Filter by created date
                            </div>
                            <DateRange
                                editableDateInputs={true}
                                onChange={(item) => {
                                    setState([item.selection]);
                                    setFailedTransactionFilter(true);
                                    setSelectedOrderDateFilter('CUSTOM');
                                    setCurrentPage(1);
                                }}
                                moveRangeOnFirstSelection={false}
                                ranges={state}
                            />
                        </div>
                    </div>
                </div>
            )}
            {isFilterClicked && !createdDateFilterRemoved ? (
                <div className="bg-purple-100 w-full h-20 flex py-2 px-4 my-5 ">
                    <div className="w-full -mt-2">
                        <div className="flex flex-col">
                            <div className="text-purple-500 font-poppins  flex justify-between p-5 -mt-1">
                                <div className="flex">
                                    {createdDateFilter ? (
                                        <div className="border-2 border-purple-300 w-auto h-auto flex py-2 px-3 rounded-md mr-5">
                                            <div className="font-poppins mt-1 mr-3">
                                                {createdDateFilter}
                                            </div>
                                            <button
                                                className="text-purple-700 font-bold  text-lg"
                                                onClick={() => {
                                                    setCreatedDateFilter(null);
                                                    setCreatedDateFilterRemoved(true);
                                                }}
                                            >
                                                x
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                                {!createdDateFilterRemoved ? (
                                    <div className="w-auto h-auto p-3 border-l-2 border-gray-300">
                                        <div className="right-0 my-auto  mx-10 border-l-2 flex underline text-lg">
                                            <button
                                                className="text-purple-400 font-bold  text-lg"
                                                onClick={() => {
                                                    setCreatedDateFilter(null);
                                                    setCreatedDateFilterRemoved(true);
                                                }}
                                            >
                                                Clear all
                                            </button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {orderLoading || completedTransactionsDataLoading ? (
                <div className="w-full">
                    <div className="m-auto flex flex-col ">
                        <div className="m-auto p-10">
                            <Loader />
                        </div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                </div>
            ) : (
                <div className="w-full">
                    <>
                        {(
                            selectedTab === 1
                                ? selectedOneOrderData?.length
                                : completedRetriedData?.length
                        ) ? (
                            <DataTable
                                columns={selectedTab === 1 ? columns : completedRetriesColumn}
                                data={
                                    selectedTab === 1 ? selectedOneOrderData : completedRetriedData
                                }
                                pageCount={pageCount} //future usage
                                setPageIndex={setPageIndex} //future usage
                                serverSidePagination={true}
                                setSelectedItems={setSelectedFailedTransaction}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSizes={pageSize}
                                // customizedPageSize={Math.ceil(totalItems / totalPages)}
                                totalItems={totalItems}
                                setDefaultPageSize={setPageSize}
                                menuItems={instantEditMenuItems}
                                setSelectedMenuItem={setSelectedMenuItem}
                                setExposeMenuClickedItem={setClickedOrder}
                                checkbox={false}
                                setExposeCellClickedItem={setClickedOrder}
                                isOrderTable={true}
                                selectedTab={selectedTab}
                                setSortState={setSortState}
                                setSortField={setSortField}
                                isInitialLoad={isInitialLoad}
                                setIsInitialLoad={setIsInitialLoad}
                            />
                        ) : isFilterClicked && !selectedOneOrderData?.length ? (
                            <div className="m-auto flex flex-col ">
                                <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                    {columns?.map((column, i) => {
                                        return (
                                            <div
                                                className="text-black font-poppins font-semibold flex ml-12"
                                                key={i}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10">
                                    There aren’t any matches found for the applied filters.
                                </div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        ) : searchClicked && !selectedOneOrderData?.length ? (
                            <div className="m-auto flex flex-col ">
                                <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                    {columns?.map((column, i) => {
                                        return (
                                            <div
                                                className="text-black font-poppins font-semibold flex ml-12"
                                                key={i}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10 flex">
                                    Your search
                                    <div className="text-purple-500 mx-2">{`"${searchTerm}"`}</div>
                                    did not match any results
                                </div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        ) : (
                            <div className="m-auto flex flex-col ">
                                <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                    {columns?.map((column, i) => {
                                        return (
                                            <div
                                                className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                key={i}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10">No data found.</div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        )}
                    </>
                </div>
            )}
        </div>
    );
}
