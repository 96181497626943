import { Modal } from 'react-responsive-modal';
import {
    MARKED_UP_PERCENTAGE_FOR_CURRENCY_CONFIRMATION_MESSAGE_TO_PARTNER,
    MARKED_UP_PERCENTAGE_FOR_CURRENCY_RESET_CONFIRMATION_TITLE,
} from '../../../constants/currency';
import { Buttons } from '../../ui/atoms/Button';

interface Props {
    showConfirmationModal: boolean;
    setShowConfirmationModal: (value: boolean) => void;
    handleResetSubmit: () => void;
    overrideItem: boolean;
}

export default function ResetMarkedUpPercentageForCurrencyConfirmationModal({
    showConfirmationModal,
    setShowConfirmationModal,
    handleResetSubmit,
    overrideItem,
}: Props) {
    return (
        <>
            <Modal
                open={showConfirmationModal}
                onClose={() => {
                    setShowConfirmationModal(false);
                }}
                center={true}
                styles={{
                    modal: { borderRadius: 15, maxWidth: '706px', width: '80%' },
                }}
                focusTrapped={false}
                closeOnOverlayClick={false}
            >
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-poppins font-bold flex m-auto">
                        {MARKED_UP_PERCENTAGE_FOR_CURRENCY_RESET_CONFIRMATION_TITLE}
                    </h3>
                </div>

                <div className="text-gray-500 font-poppins text-center text-lg flex mb-3 m-auto whitespace-pre-line">
                    <div
                        data-testid="message"
                        className="px-12 py-4 font-poppins whitespace-pre-wrap"
                    >
                        {overrideItem
                            ? MARKED_UP_PERCENTAGE_FOR_CURRENCY_CONFIRMATION_MESSAGE_TO_PARTNER
                            : ''}
                    </div>
                </div>
                <div className="flex flex-row mt-6 justify-end m-4">
                    <Buttons
                        name={'Reset'}
                        type="submit"
                        buttonType="secondary-main"
                        id="reset-submit"
                        size="e-small"
                        onclick={() => {
                            handleResetSubmit();
                        }}
                        padding="py-3"
                    />
                    <Buttons
                        name="Close"
                        type="submit"
                        buttonType="secondary"
                        id="okay"
                        size="e-small"
                        onclick={() => {
                            setShowConfirmationModal(false);
                        }}
                        padding="py-3"
                    />
                </div>
            </Modal>
        </>
    );
}
