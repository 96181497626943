import { Formik } from 'formik';
import { useMutation } from '@apollo/client';

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import {
    PARTNER_ADD_EXIT_CONFIRM_MESSAGE,
    PARTNER_ADD_EXIT_CONFIRM_TITLE,
} from '../../../constants/partner';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { ADD_POINTS, FETCH_POINTS_DATA } from '../../../queries/PointsQueries';
import NormalLoader from '../../../utils/normalLoader';
import UnsaveChangesWarning from '../../templates/modals/UnsaveChangesWarning';
import { Buttons } from '../../ui/atoms/Button';
import { InputField } from '../../ui/atoms/InputField';
import { RootState } from '../../../redux/rootReducer';

interface Props {
    selectedPartner: any;
    setEditedId?: any;
}
export default function SupplierPartnerPointsQuickEditView({
    selectedPartner,
    setEditedId,
}: Props) {
    const [isDirty, setIsDirty] = useState(false);
    const [isPointsLoading, setIsPointsLoading] = useState(false);
    const createdSupplier = useSelector((state: RootState) => state.auth.supplier);
    const history = useHistory();

    const [savePoints] = useMutation(ADD_POINTS, {
        context: { clientName: ClientsEnum.STORE },
        refetchQueries: [FETCH_POINTS_DATA],
    });

    const validationSchema = Yup.object({
        conversionPoints: Yup.number()
            .positive()
            .required('Conversion point value is required')
            .label('Conversion points')
            .moreThan(0),
    });

    return (
        <>
            <div className="w-full p-2 m-3 rounded-xl">
                <Formik
                    initialValues={{
                        supplierId:
                            createdSupplier?.supplierId ||
                            localStorage.getItem('supplier_supplierId'),
                        conversionPoints: selectedPartner?.pointsConversionRate || 0,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={({ conversionPoints }) => {
                        if (
                            selectedPartner?.id &&
                            (createdSupplier?.id || localStorage.getItem('supplier_id'))
                        ) {
                            setEditedId(selectedPartner?.partnerId);
                            setIsPointsLoading(true);
                            savePoints({
                                variables: {
                                    points: {
                                        partner: {
                                            id: selectedPartner?.id,
                                        },
                                        supplier: {
                                            id:
                                                createdSupplier?.id ||
                                                localStorage.getItem('supplier_id'),
                                        },
                                        defaultValue: conversionPoints,
                                        overriddenValue: conversionPoints || 0,
                                    },
                                },
                            });
                        }
                    }}
                >
                    {({ handleChange, handleSubmit, values, errors, touched }) => (
                        <>
                            <div className="pl-10 flex flex-row p-4 z-30">
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="flex col-start-1 col-end-2">
                                        <InputField
                                            id="partnerId"
                                            labelWidth="w-24"
                                            placeHolder={selectedPartner?.partnerId}
                                            name="Partner ID"
                                            readonly
                                        />
                                        <div className="ml-2 mt-5">
                                            <img
                                                src="/images/icons/lock.png"
                                                className={`text-gray-700 cursor-pointer w-4 h-5 block`}
                                                alt="show-password"
                                                role="presentation"
                                                draggable="false"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex col-start-2 col-span-2">
                                        <InputField
                                            id="partnerName"
                                            labelWidth="w-20"
                                            placeHolder={selectedPartner?.partnerName}
                                            readonly
                                            name="Partner name"
                                        />

                                        <div className="ml-2 mt-5">
                                            <img
                                                src="/images/icons/lock.png"
                                                className={`text-gray-700 cursor-pointer w-4 h-5 block`}
                                                alt="show-password"
                                                role="presentation"
                                                draggable="false"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <UnsaveChangesWarning
                                contentText={PARTNER_ADD_EXIT_CONFIRM_MESSAGE}
                                messageTitle={PARTNER_ADD_EXIT_CONFIRM_TITLE}
                                when={isDirty || window.onbeforeunload ? true : false}
                                navigate={(path) => history.push(path)}
                                shouldBlockNavigation={() => {
                                    if (isDirty) {
                                        return true;
                                    }
                                    return false;
                                }}
                                displayIcon={true}
                                displayIconName={'clarity_error-standard-line-svg.svg'}
                            />
                            <div className="flex pl-10">
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="col-start-1 col-end-2">
                                        <InputField
                                            id="conversionPoints"
                                            labelWidth="w-20"
                                            type="number"
                                            min="0"
                                            placeHolder={'0'}
                                            value={values?.conversionPoints}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setIsDirty(true);
                                            }}
                                            name="Conversion points"
                                        />
                                        {errors.conversionPoints && touched.conversionPoints ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                {errors.conversionPoints}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className={`flex flex-row px-14 py-3 w-full justify-end z-50`}>
                                <Buttons
                                    name={isPointsLoading ? <NormalLoader /> : 'Update'}
                                    type="submit"
                                    buttonType="primary"
                                    id="update"
                                    size="e-small"
                                    disabled={!isDirty}
                                    onclick={() => {
                                        handleSubmit();
                                    }}
                                />
                            </div>
                        </>
                    )}
                </Formik>
            </div>
        </>
    );
}
