import { COST, PROFIT, REVENUE, SALES } from '../../../constants/reports';
import SummaryTile from '../../ui/molecules/SummaryTile';

export default function ReportsTilesView() {
    const tilesArray = [PROFIT, SALES, COST, REVENUE];

    return (
        <div className="flex flex-col lg:flex-row  flex-shrink justify-between pr-10 ">
            {tilesArray?.map((tile) => {
                return (
                    <div key={1}>
                        <SummaryTile
                            bgColourClass={
                                tile === PROFIT
                                    ? 'bg-purple-100'
                                    : tile === SALES
                                    ? 'bg-green-100'
                                    : tile === COST
                                    ? 'bg-yellow-100'
                                    : 'bg-blue-100'
                            }
                            mainColourClass={
                                tile === PROFIT
                                    ? 'text-purple-500'
                                    : tile === SALES
                                    ? 'text-green-500'
                                    : tile === COST
                                    ? 'text-yellow-500'
                                    : 'text-blue-500'
                            }
                            title={
                                tile === PROFIT
                                    ? 'Total Profit'
                                    : tile === SALES
                                    ? 'Total Sales'
                                    : tile === COST
                                    ? 'Total Cost'
                                    : 'Revenue'
                            }
                            mainValue={
                                tile === PROFIT
                                    ? '$560K'
                                    : tile === SALES
                                    ? '$185K'
                                    : tile === COST
                                    ? '$375K' //add the real amounts instead of string
                                    : '$742K'
                            }
                            percentageValue={
                                tile === PROFIT
                                    ? ' 0.7%'
                                    : tile === SALES
                                    ? ' 0.7%'
                                    : tile === COST
                                    ? ' 0.7%' //add the real amounts instead of string
                                    : ' 0.7%'
                            }
                            isIncrement={true}
                            iconImgSrc={`/images/icons/${
                                tile === PROFIT
                                    ? 'profitIcon'
                                    : tile === SALES
                                    ? 'sales'
                                    : tile === COST
                                    ? 'cost'
                                    : 'revenue'
                            }.svg`}
                        />
                    </div>
                );
            })}
        </div>
    );
}
