/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQuery } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import { FETCH_ADMIN_ONE_PROFILE_ID } from '../../../../queries/AdminProfileQueries';
import {
    ADD_SUPPLIERS,
    FETCH_SUPPLIERS,
    FETCH_SUPPLIER_BY_ID,
    FETCH_SUPPLIER_CONFIGURATIONS,
} from '../../../../queries/SupplierQueries';
import { getCookie } from '../../../../utils/cookiesService';
import { countryOptions } from '../../../../utils/countries';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { Dropdown } from 'semantic-ui-react';
import NormalLoader from '../../../../utils/normalLoader';
import { Buttons } from '../../../ui/atoms/Button';
import { InputField } from '../../../ui/atoms/InputField';
import { useDispatch, useSelector } from 'react-redux';
import { localSaveCreatedSupplier, saveSupplierName } from '../../../../redux/rootActions';
import SupplierConfigurations from '../SupplierConfiguration';
import Loader from '../../../../utils/loader';
import { API, CSV } from '../../../../constants/supplier';
import Toast from '../../../ui/atoms/Toast';
import { RootState } from '../../../../redux/rootReducer';
import CronJobSchedulerView from '../../settings/scheduler/CronJobSchedulerView';
import { validateJobDetails } from '../../../../helpers/JobSchedulerHelpers.helpers';
import { JobType, SchedulerMethodType } from '../../../../enums/job';

export default function EditSupplierGeneral() {
    const history = useHistory();
    const location = useLocation<any>();
    const dispatch = useDispatch();

    const selectedSupplierData =
        useSelector((state: RootState) => state.auth.supplier) || location?.state?.supplier;
    const [selectedCode, setSelectedCode] = useState({});
    const [countryError, setCountryError] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [country, setCountry] = useState('');
    const [supplierConfiguration, setSupplierConfiguration] = useState(null);
    const [supplierData, setSupplierData] = useState(null);
    const token = getCookie('access_token');
    const tokenDetails: any = token ? jwtDecode(token) : null;
    const [configInputFields, setconfigInputFields] = useState([
        { key: '', value: '', error: false },
    ]);
    const [initialConfigInputFields, setInitialConfigInputFields] = useState(null);
    const [selectedSupplierType, setSelectedSupplierType] = useState<string>(CSV);
    const [countryCode, setCountryCode] = useState('');

    const [cronJob, setCronJob] = useState(null);
    const [newJobInfo, setNewJobInfo] = useState(null);
    const [schedulerEnabled, setSchedulerEnabled] = useState(false);
    const [schedulerInputInvalid, setSchedulerInputInvalid] = useState(false);
    const [removeJobClicked, setRemoveJobClicked] = useState(false);
    const [selectedSupplierSubType, setSelectedSupplierSubType] = useState<string>('');

    const [saveSupplier, { loading: queryLoading, error: queryError }] = useMutation(
        ADD_SUPPLIERS,
        {
            refetchQueries: [FETCH_SUPPLIERS],
            context: { clientName: ClientsEnum.STORE },
            onCompleted: (data) => {
                setMessage(`Successfully updated supplier ${data?.saveSupplier?.supplierName}`);
                setShowToast(true);
                setError(false);
                dispatch(localSaveCreatedSupplier(data?.saveSupplier));
                dispatch(saveSupplierName(data?.saveSupplier?.supplierName));
                localStorage.setItem(
                    'supplierName',
                    data?.saveSupplier?.supplierName || localStorage.getItem('supplierName'),
                );
                setTimeout(() => {
                    history.push('/suppliers');
                }, 1800);
            },
            onError: () => {
                setMessage(queryError?.message || 'Oops something went wrong');
                setShowToast(true);
                setError(true);
            },
        },
    );

    const { loading: supplierDataLoading } = useQuery(FETCH_SUPPLIER_BY_ID, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        context: { clientName: ClientsEnum.STORE },
        variables: {
            supplierId: selectedSupplierData?.id || localStorage.getItem('supplier_id'),
        },
        onCompleted: (data) => {
            setSupplierData(data?.supplier);
            setCountryCode(data?.supplier?.contact?.countryCode);

            if (cronJob == null) {
                setCronJob(data?.supplier?.job);
            }

            setSelectedSupplierType(data?.supplier?.supplierMainType);
            setSelectedSupplierSubType(data?.supplier?.configuration?.name);
        },
    });

    const { data: loggedInUserData } = useQuery(FETCH_ADMIN_ONE_PROFILE_ID, {
        fetchPolicy: 'network-only',
        variables: {
            userName: tokenDetails?.preferred_username,
        },
    });

    const { data: supplierConfigurations } = useQuery(FETCH_SUPPLIER_CONFIGURATIONS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        context: { clientName: ClientsEnum.STORE },
    });

    const configInputValid = () => {
        let isConfigInputsValid = true;

        if (selectedSupplierType !== CSV) {
            const updatedArray = configInputFields.map((input) => {
                const isInputError =
                    input?.key && input.key.length > 0 && input?.value && input.value.length > 0
                        ? false
                        : true;

                if (isInputError) {
                    isConfigInputsValid = false;
                }

                return {
                    key: input.key,
                    value: input.value,
                    error: isInputError,
                };
            });

            setconfigInputFields(updatedArray);
        }

        return isConfigInputsValid;
    };

    useEffect(() => {
        dispatch(saveSupplierName(selectedSupplierData?.supplierName));
        localStorage.setItem(
            'supplierName',
            selectedSupplierData?.supplierName || localStorage.getItem('supplierName'),
        );
    }, [selectedSupplierData?.supplierName, dispatch]);

    useEffect(() => {
        if (country) {
            const selectedCodes = countryOptions?.filter((countryOption: any) => {
                return countryOption?.text === country;
            });
            setCountryCode(selectedCodes[0].value);
            setSelectedCode(selectedCodes);
        }
    }, [country]);

    useEffect(() => {
        if (!selectedCode[0]?.value && !supplierData?.contact?.countryCode && clicked)
            setCountryError(true);
        else {
            setCountryError(false);
        }
    }, [clicked, selectedCode, supplierData?.contact?.countryCode]);

    const handleAddNewJobInfo = (value) => {
        setNewJobInfo(value);
        checkSchedulerInputValid();
    };

    const checkSchedulerInputValid = () => {
        let inputValid = true;

        if (schedulerEnabled) {
            inputValid = validateJobDetails(newJobInfo);

            if (!inputValid) {
                setSchedulerInputInvalid(true);
            } else {
                setSchedulerInputInvalid(false);
            }
        }

        return inputValid;
    };

    const handleSchedulerEnabled = (value) => {
        setSchedulerEnabled(value);
    };

    const handleRemoveJobClickParent = () => {
        setRemoveJobClicked(true);
        setCronJob(null);
    };

    const validationSchema = Yup.object({
        supplierName: Yup.string().trim().required('Supplier name is required'),
        phoneNumber: Yup.string()
            .required('Phone number cannot be blank')
            .min(9, 'Min digits should be 9')
            .matches(/^[0-9]+$/, 'Phone number must be number')
            .max(15, 'Must be exactly 10 digits'),
        email: Yup.string()
            .trim()
            .email('Email must be a valid email')
            .required('Email address is required'),
    });

    if (supplierDataLoading) return <Loader />;

    return (
        <>
            <Formik
                initialValues={{
                    supplierName: supplierData?.supplierName,
                    email: supplierData?.contact?.email,
                    countryCode: supplierData?.contact?.countryCode,
                    phoneNumber: supplierData?.contact?.contactNumber,
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={({ supplierName, email, phoneNumber }) => {
                    if (newJobInfo && schedulerEnabled) {
                        saveSupplier({
                            variables: {
                                supplier: {
                                    id: supplierData?.id,
                                    supplierId: supplierData?.supplierId,
                                    supplierName: supplierName,
                                    isActive: supplierData?.isActive,
                                    endpointUrl: supplierData?.endpointUrl,
                                    creditLimit: supplierData?.creditLimit,
                                    createdBy: loggedInUserData?.user?.userId,
                                    isDeleted: supplierData?.isDeleted,
                                    contact: {
                                        contactId: supplierData?.contact?.contactId
                                            ? supplierData?.contact?.contactId
                                            : null,
                                        email: email,
                                        contactNumber: phoneNumber,
                                        countryCode:
                                            selectedCode && selectedCode[0] && selectedCode[0].value
                                                ? selectedCode[0].value
                                                : supplierData?.contact?.countryCode,
                                    },
                                    configuration: {
                                        id: supplierConfiguration?.id,
                                        name: supplierConfiguration?.name,
                                        config: supplierConfiguration?.config,
                                        supplierType: supplierConfiguration?.supplierType,
                                    },
                                    job: {
                                        jobName: newJobInfo?.jobName,
                                        cronDefinition: newJobInfo?.cronDefinition,
                                        description: newJobInfo?.description,
                                        isActive: true,
                                        jobType: JobType.SUPPLIER_JOB,
                                        productType: newJobInfo?.productType || 'VOUCHER',
                                    },
                                },
                            },
                        });
                    } else if (cronJob && schedulerEnabled) {
                        saveSupplier({
                            variables: {
                                supplier: {
                                    id: supplierData?.id,
                                    supplierId: supplierData?.supplierId,
                                    supplierName: supplierName,
                                    isActive: supplierData?.isActive,
                                    endpointUrl: supplierData?.endpointUrl,
                                    creditLimit: supplierData?.creditLimit,
                                    createdBy: loggedInUserData?.user?.userId,
                                    isDeleted: supplierData?.isDeleted,
                                    contact: {
                                        contactId: supplierData?.contact?.contactId
                                            ? supplierData?.contact?.contactId
                                            : null,
                                        email: email,
                                        contactNumber: phoneNumber,
                                        countryCode:
                                            selectedCode && selectedCode[0] && selectedCode[0].value
                                                ? selectedCode[0].value
                                                : supplierData?.contact?.countryCode,
                                    },
                                    configuration: {
                                        id: supplierConfiguration?.id,
                                        name: supplierConfiguration?.name,
                                        config: supplierConfiguration?.config,
                                        supplierType: supplierConfiguration?.supplierType,
                                    },
                                    job: {
                                        id: cronJob?.id,
                                        jobName: cronJob?.jobName,
                                        cronDefinition: cronJob?.cronDefinition,
                                        description: cronJob?.description,
                                        jobType: JobType.SUPPLIER_JOB,
                                        productType:
                                            cronJob?.schedulerMethodType ===
                                                SchedulerMethodType.API_UNIPIN_TOPUP_UPDATE ||
                                            cronJob?.schedulerMethodType ===
                                                SchedulerMethodType.API_BUKALAPAK_TOPUP_UPDATE
                                                ? 'TOPUP'
                                                : 'VOUCHER',
                                    },
                                },
                            },
                        });
                    } else {
                        saveSupplier({
                            variables: {
                                supplier: {
                                    id: supplierData?.id,
                                    supplierId: supplierData?.supplierId,
                                    supplierName: supplierName,
                                    isActive: supplierData?.isActive,
                                    endpointUrl: supplierData?.endpointUrl,
                                    creditLimit: supplierData?.creditLimit,
                                    createdBy: loggedInUserData?.user?.userId,
                                    isDeleted: supplierData?.isDeleted,
                                    contact: {
                                        contactId: supplierData?.contact?.contactId
                                            ? supplierData?.contact?.contactId
                                            : null,
                                        email: email,
                                        contactNumber: phoneNumber,
                                        countryCode:
                                            selectedCode && selectedCode[0] && selectedCode[0].value
                                                ? selectedCode[0].value
                                                : supplierData?.contact?.countryCode,
                                    },
                                    configuration: {
                                        id: supplierConfiguration?.id,
                                        name: supplierConfiguration?.name,
                                        config: supplierConfiguration?.config,
                                        supplierType: supplierConfiguration?.supplierType,
                                    },
                                },
                            },
                        });
                    }
                }}
            >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                    <div className="h-full flex flex-col justify-between pl-9">
                        {showToast && (
                            <Toast
                                setShowToast={setShowToast}
                                message={message}
                                error={error}
                                width="w-10/12"
                            />
                        )}

                        <div
                            className={`flex items-start justify-between p-5 ${
                                showToast && 'mt-7'
                            }`}
                        >
                            <div className="text-lg font-poppins font-bold  z-30">
                                Edit Supplier
                            </div>
                        </div>

                        <div className="-mt-5 flex flex-col">
                            <div className=" flex-wrap flex-col px-4 grid grid-cols-8 gap-4 mt-5">
                                <div className="flex flex-wrap col-start-1 col-end-4 flex-col lg:mb-5">
                                    <InputField
                                        placeHolder="Supplier001"
                                        name="Supplier Name"
                                        id="supplierName"
                                        labelWidth="w-28"
                                        onChange={handleChange('supplierName')}
                                        value={values.supplierName || ''}
                                    />

                                    {errors.supplierName && touched.supplierName ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {errors.supplierName}
                                        </div>
                                    ) : null}
                                </div>

                                <div className=" col-end-7 col-span-3">
                                    <InputField
                                        placeHolder="777125043"
                                        id="phoneNumber"
                                        name="Phone Number"
                                        onChange={handleChange('phoneNumber')}
                                        type="tel"
                                        labelWidth="w-24"
                                        value={values.phoneNumber || ''}
                                    />
                                    {errors.phoneNumber && touched.phoneNumber ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {errors.phoneNumber}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="flex flex-wrap flex-col relative lg:mb-5 z-20 col-start-1 col-end-4 ">
                                    <div className="text-gray-500 w-16 pl-1 pr-2 text-center font-poppins text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Country
                                    </div>

                                    <Dropdown
                                        className="dropdownRed"
                                        placeholder="Select Country"
                                        id="countryCode"
                                        fluid
                                        selection
                                        defaultValue={countryCode}
                                        value={countryCode}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );
                                        }}
                                        onKeyDown={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );
                                        }}
                                        onSearchChange={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );
                                        }}
                                        onKeyUp={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );
                                        }}
                                        options={countryOptions}
                                    />
                                    {countryError ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {'Country is required'}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="flex flex-wrap flex-col lg:mb-5 col-end-7 col-span-3">
                                    <InputField
                                        placeHolder="Enter your email"
                                        name="Email Address"
                                        id="email"
                                        onChange={handleChange('email')}
                                        value={values.email || ''}
                                    />

                                    {errors.email && touched.email ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {errors.email}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <SupplierConfigurations
                            previouslySelectedConfiguration={supplierData?.configuration}
                            setSupplierConfiguration={setSupplierConfiguration}
                            configInputFields={configInputFields}
                            setconfigInputFields={setconfigInputFields}
                            configInputValid={configInputValid}
                            selectedSupplierType={selectedSupplierType}
                            setSelectedSupplierType={setSelectedSupplierType}
                            initialConfigInputFields={initialConfigInputFields}
                            setInitialConfigInputFields={setInitialConfigInputFields}
                            isSupplierCredentialsDisabled={true}
                            selectedSupplierSubType={selectedSupplierSubType}
                            setSelectedSupplierSubType={setSelectedSupplierSubType}
                            isEditScreen={true}
                        />

                        {selectedSupplierType === API && (
                            <div className="flex-col w-full mb-28 border-t-2 ml-4">
                                <CronJobSchedulerView
                                    initialJobInfo={cronJob}
                                    handleAddNewJobInfo={handleAddNewJobInfo}
                                    handleSchedulerEnabled={handleSchedulerEnabled}
                                    handleRemoveJobClickParent={handleRemoveJobClickParent}
                                    schedulerInputInvalid={schedulerInputInvalid}
                                />
                            </div>
                        )}

                        <div className="flex w-10/12 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0">
                            <Buttons
                                name="Cancel"
                                type="button"
                                buttonType="secondary-border-black"
                                id="cancel"
                                size="small"
                                other="mr-3"
                                onclick={() => history.push('/suppliers')}
                            />
                            <Buttons
                                name={queryLoading ? <NormalLoader /> : 'Update Supplier'}
                                type="submit"
                                buttonType="primary"
                                id="Update_Supplier"
                                size="small"
                                padding="p-1"
                                onclick={() => {
                                    setClicked(true);
                                    const isConfigInputsValid = configInputValid();
                                    let schedulerInputValid = true;
                                    if (schedulerEnabled && !cronJob) {
                                        schedulerInputValid = checkSchedulerInputValid();
                                    }

                                    if (
                                        !queryError &&
                                        !queryLoading &&
                                        !countryError &&
                                        isConfigInputsValid &&
                                        schedulerInputValid
                                    ) {
                                        handleSubmit();
                                    }
                                }}
                            />
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}
