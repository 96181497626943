import { gql } from '@apollo/client';

export const FETCH_PAYMENT_CHANNELS = gql`
    query (
        $filter: PaymentChannelFilterInput
        $offset: Int!
        $limit: Int!
        $searchText: String
        $sort: String
        $order: SortOrder
    ) {
        paymentChannels(
            filter: $filter
            offset: $offset
            limit: $limit
            searchText: $searchText
            sort: $sort
            order: $order
        ) {
            paymentChannels {
                id
                channelId
                channelName
                description
                paymentMethod
                isActive
            }
            currentPage
            totalPaymentChannels
            totalFilteredPaymentChannels
            totalPages
            totalActivePaymentChannels
            totalInactivePaymentChannels
        }
    }
`;

export const FETCH_PAYMENT_CHANNELS_FOR_DEALS = gql`
    query (
        $partnerIds: [ID!]
        $paymentMethods: [PaymentMethod]
        $offset: Int!
        $limit: Int!
        $searchText: String
        $sort: String
        $order: SortOrder
    ) {
        paymentChannelsByPartnersAndPaymentMethods(
            partnerIds: $partnerIds
            paymentMethods: $paymentMethods
            offset: $offset
            limit: $limit
            searchText: $searchText
            sort: $sort
            order: $order
        ) {
            paymentChannels {
                id
                channelId
                channelName
                description
                paymentMethod
                isActive
            }
            currentPage
            totalPaymentChannels
            totalFilteredPaymentChannels
            totalPages
            totalActivePaymentChannels
            totalInactivePaymentChannels
        }
    }
`;

export const ADD_PAYMENT_CHANNEL = gql`
    mutation ($paymentChannel: PaymentChannelInput!) {
        savePaymentChannel(paymentChannel: $paymentChannel) {
            id
            channelId
            channelName
        }
    }
`;

export const GET_PAYMENT_CHANNEL_BY_ID = gql`
    query ($paymentChannelId: ID!) {
        paymentChannel(paymentChannelId: $paymentChannelId) {
            id
            channelId
            channelName
            description
            paymentMethod
            isActive
        }
    }
`;

export const FETCH_PARTNER_ITEM_PAYMENT_CHANNELS = gql`
    query ($partnerId: ID!, $itemId: ID!, $paymentType: PaymentMethod) {
        partnerItemPaymentChannels(
            partnerId: $partnerId
            itemId: $itemId
            paymentType: $paymentType
        ) {
            id
            channelId
            paymentName
            isEnabled
            globallyEnabled
        }
    }
`;

export const UPDATE_PARTNER_ITEM_DISABLED_PAYMENT_CHANNELS = gql`
    mutation ($updateDisabledPaymentChannelInput: UpdateDisabledPaymentChannelInput) {
        updateDisabledPaymentChannels(
            updateDisabledPaymentChannelInput: $updateDisabledPaymentChannelInput
        ) {
            partnerId
            itemId
            paymentType
            disabledPaymentChannels {
                id
                channelId
                paymentName
                isEnabled
                globallyEnabled
            }
        }
    }
`;
