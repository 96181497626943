const PREFIX = '@AUTH_';
export const LOGIN = PREFIX + 'LOGIN';
export const LOGIN_VERIFICATION = PREFIX + 'LOGIN_VERIFICATION';
export const LOGIN_SUCCESS = PREFIX + 'LOGIN_SUCCESS';
export const LOGIN_VERIFICATION_SUCCESS = PREFIX + 'LOGIN_VERIFICATION_SUCCESS';
export const LOGIN_VERIFICATION_FAILED = PREFIX + 'LOGIN_VERIFICATION_FAILED ';
export const LOGIN_FAILED = PREFIX + 'LOGIN_FAILED';

export const USERS_BY_ROLE = PREFIX + 'USERS_BY_ROLE';
export const USERS_BY_ROLE_SWARMIO_ADMIN_SUCCESS = PREFIX + 'USERS_BY_ROLE_SWARMIO_ADMIN_SUCCESS';
export const USERS_BY_ROLE_STORE_ADMIN_SUCCESS = PREFIX + 'USERS_BY_ROLE_STORE_ADMIN_SUCCESS';
export const USERS_BY_ROLE_PARTNER_SUCCESS = PREFIX + 'USERS_BY_ROLE_PARTNER_SUCCESS';

export const USERS_BY_ROLE_FAILED = PREFIX + 'USERS_BY_ROLE_FAILED';

export const ADMIN_CLI_LOGIN = PREFIX + 'ADMIN_CLI_LOGIN';
export const ADMIN_CLI_LOGIN_SUCCESS = PREFIX + 'ADMIN_CLI_LOGIN_SUCCESS';
export const ADMIN_CLI_LOGIN_FAILED = PREFIX + 'ADMIN_CLI_LOGIN_FAILED';

export const REFRESH_LOGIN = PREFIX + 'REFRESH_LOGIN';
export const REFRESH_LOGIN_SUCCESS = PREFIX + 'REFRESH_LOGIN_SUCCESS';
export const REFRESH_LOGIN_FAILED = PREFIX + 'REFRESH_LOGIN_FAILED';

export const SET_AUTH_TOKEN = PREFIX + 'SET_AUTH_TOKEN';
export const CLEAR_ERRORS = PREFIX + 'CLEAR_ERRORS';

export const ROLE_TYPES = PREFIX + 'ROLE_TYPES';
export const ROLE_TYPES_SUCCESS = PREFIX + 'ROLE_TYPES_SUCCESS';

export const ASSIGN_ROLES = PREFIX + 'ASSIGN_ROLES';

export const NEW_USER_PASSWORD = PREFIX + 'NEW_USER_PASSWORD';
export const NEW_USER_PASSWORD_SUCCESS = PREFIX + 'NEW_USER_PASSWORD_SUCCESS';
export const NEW_USER_PASSWORD_FAIL = PREFIX + 'NEW_USER_PASSWORD_FAIL';

export const SENT_NEW_USER_INVITE = PREFIX + 'SENT_NEW_USER_INVITE';
export const SENT_NEW_USER_INVITE_SUCCESS = PREFIX + 'SENT_NEW_USER_INVITE_SUCCESS';
export const SENT_NEW_USER_INVITE_FAILURE = PREFIX + 'SENT_NEW_USER_INVITE_FAILURE';

export const GET_ALL_SESSION = PREFIX + 'GET_ALL_SESSION';
export const GET_ALL_SESSION_SUCCESS = PREFIX + 'GET_ALL_SESSION_SUCCESS';

export const LOGOUT_FROM_SESSION = PREFIX + 'LOGOUT_FROM_SESSION';
export const LOGOUT_FROM_SESSION_SUCCESS = PREFIX + 'LOGOUT_FROM_SESSION_SUCCESS';

export const LOGOUT_FROM_ALL_SESSION = PREFIX + 'LOGOUT_FROM_SESSION';
export const LOGOUT_FROM_ALL_SESSION_SUCCESS = PREFIX + 'LOGOUT_FROM_SESSION_SUCCESS';

export const UPLOAD_PROFILE_PIC = PREFIX + 'UPLOAD_PROFILE_PIC';
export const UPLOAD_PROFILE_PIC_SUCCESS = PREFIX + 'UPLOAD_PROFILE_PIC_SUCCESS';

export const CREATE_USER = PREFIX + 'CREATE_USER';

export const CREATE_USER_FAILED = PREFIX + 'CREATE_USER_FAILED';

export const CREATE_USER_SUCCESS = PREFIX + 'CREATE_USER_SUCCESS';

export const RESET_CREATE_USER = PREFIX + 'RESET_CREATE_USER';

export const FETCH_USER_DETAILS = PREFIX + 'FETCH_USER_DETAILS';

export const FETCH_CREATED_USER_DETAILS = PREFIX + 'FETCH_CREATED_USER_DETAILS';

export const FETCH_CREATED_USER_DETAILS_SUCCESS = PREFIX + 'FETCH_CREATED_USER_DETAILS_SUCCESS';

export const FETCH_CREATED_USER_DETAILS_FAILED = PREFIX + 'FETCH_CREATED_USER_DETAILS_FAILED';

export const LOGOUT = PREFIX + 'LOGOUT';

export const GET_ALL_USERS = PREFIX + 'ALL_USERS';

export const ASSIGN_CLIENT_ROLE = PREFIX + 'ASSIGN_CLIENT_ROLE';

export const ASSIGN_CLIENT_ROLE_SUCCESS = PREFIX + 'ASSIGN_CLIENT_ROLE_SUCCESS';

export const GET_GEO_LOCATION = PREFIX + 'GET_GEO_LOCATION';

export const UPLOAD_AGREEMENT = PREFIX + 'UPLOAD_AGREEMENT';
export const UPLOAD_AGREEMENT_SUCCESS = PREFIX + 'UPLOAD_AGREEMENT_SUCCESS';

export const LOCAL_SAVE_PARTNER_DATA = PREFIX + 'LOCAL_SAVE_PARTNER_DATA';

export const LOCAL_REMOVE_PARTNER_DATA = PREFIX + 'LOCAL_REMOVE_PARTNER_DATA';

export const LOCAL_SAVE_SUPPLIER_DATA = PREFIX + 'LOCAL_SAVE_SUPPLIER_DATA';

export const LOCAL_REMOVE_SUPPLIER_DATA = PREFIX + 'LOCAL_REMOVE_SUPPLIER_DATA';

export const PRODUCT_PRICE = PREFIX + 'PRODUCT_PRICE';

export const SAVE_POINTS_DATA_SUCCESS = PREFIX + 'SAVE_POINTS_DATA_SUCCESS';

export const SAVE_POINTS_DATA_FAILED = PREFIX + 'SAVE_POINTS_DATA_FAILED';

export const SAVE_POINTS_DATA_RESET = PREFIX + 'SAVE_POINTS_DATA_RESET';

export const SAVE_ITEM_NAME_SUCCESS = PREFIX + 'SAVE_ITEM_NAME_SUCCESS';

export const SAVE_ITEM_NAME = PREFIX + 'SAVE_ITEM_NAME';

export const SAVE_CATEGORY_NAME_SUCCESS = PREFIX + 'SAVE_CATEGORY_NAME_SUCCESS';

export const SAVE_CATEGORY_NAME = PREFIX + 'SAVE_CATEGORY_NAME';

export const SAVE_PRODUCT_GROUP_NAME = PREFIX + 'SAVE_PRODUCT_GROUP_NAME';

export const SAVE_PRODUCT_GROUP_NAME_SUCCESS = PREFIX + 'SAVE_PRODUCT_GROUP_NAME_SUCCESS';

export const SAVE_PAYMENT_CHANNEL_NAME = PREFIX + 'SAVE_PAYMENT_CHANNEL_NAME';

export const SAVE_PAYMENT_CHANNEL_NAME_SUCCESS = PREFIX + 'SAVE_PAYMENT_CHANNEL_NAME_SUCCESS';

export const SAVE_PAYMENT_CHANNEL_ID = PREFIX + 'SAVE_PAYMENT_CHANNEL_ID';

export const SAVE_PAYMENT_CHANNEL_ID_SUCCESS = PREFIX + 'SAVE_PAYMENT_CHANNEL_ID_SUCCESS';

export const SAVE_SUPPLIER_NAME_SUCCESS = PREFIX + 'SAVE_SUPPLIER_NAME_SUCCESS';

export const SAVE_SUPPLIER_NAME = PREFIX + 'SAVE_SUPPLIER_NAME';

export const SAVE_PARTNER_NAME_SUCCESS = PREFIX + 'SAVE_PARTNER_NAME_SUCCESS';

export const SAVE_PARTNER_NAME = PREFIX + 'SAVE_PARTNER_NAME';

export const SET_CONVERSION_RATE = PREFIX + 'SET_CONVERSION_RATE';

export const SET_UNIT_PRICE = PREFIX + 'SET_UNIT_PRICE';

export const SET_ITEM_PARTNER_POINTS_SUCCESS = PREFIX + 'SET_ITEM_PARTNER_POINTS_SUCCESS';

export const RESET_ITEM_PARTNER_POINTS_SUCCESS = PREFIX + 'RESET_ITEM_PARTNER_POINTS_SUCCESS';

export const SET_ITEM_PARTNER_POINTS = PREFIX + 'SET_ITEM_PARTNER_POINTS';

export const SET_ADMIN_PROFILE_LOGIN_CLICKED = PREFIX + 'SET_ADMIN_PROFILE_LOGIN_CLICKED';

export const LOCAL_REMOVE_KEYCLOAK_PARTNER_DATA = PREFIX + 'LOCAL_REMOVE_KEYCLOAK_PARTNER_DATA';

export const RESET_LOCAL_CONVERSION_RATE = PREFIX + 'RESET_LOCAL_CONVERSION_RATE';

export const SET_SELECTED_ITEMS_DATA_TABLE = PREFIX + 'SET_SELECTED_ITEMS_DATA_TABLE';

export const RESET_SELECTED_ITEMS_DATA_TABLE = PREFIX + 'RESET_SELECTED_ITEMS_DATA_TABLE';

export const RESET_SELECTED_GROUPS_DATA_TABLE = PREFIX + 'RESET_SELECTED_GROUPS_DATA_TABLE';

export const SET_ORDER_LIST_SORT_STATE = PREFIX + 'SET_ORDER_LIST_SORT_STATE';

export const SET_ITEM_ID = PREFIX + 'SET_ITEM_ID';

export const UPDATE_ITEM_PARTNER_CURRENCY_CONVERSION_SUCCESS_MESSAGE =
    PREFIX + 'UPDATE_ITEM_PARTNER_CURRENCY_CONVERSION_SUCCESS_MESSAGE';

export const UPDATE_ITEM_PARTNER_CURRENCY_CONVERSION_SUCCESS =
    PREFIX + 'UPDATE_ITEM_PARTNER_CURRENCY_CONVERSION_SUCCESS';

export const CURRENCY_CONVERSION_RESET_SUCCESS_MESSAGE =
    PREFIX + 'CURRENCY_CONVERSION_RESET_SUCCESS_MESSAGE';

export const CURRENCY_CONVERSION_RESET_SUCCESS = PREFIX + 'CURRENCY_CONVERSION_RESET_SUCCESS';

export const RESET_PRODUCT_PRICE = PREFIX + 'RESET_PRODUCT_PRICE';

export const CURRENCY_MARKED_UP_PERCENTAGE_RESET_SUCCESS_MESSAGE =
    PREFIX + 'CURRENCY_MARKED_UP_PERCENTAGE_RESET_SUCCESS_MESSAGE';

export const CURRENCY_MARKED_UP_PERCENTAGE_RESET_SUCCESS =
    PREFIX + 'CURRENCY_MARKED_UP_PERCENTAGE_RESET_SUCCESS';

export const POINTS_MARKED_UP_PERCENTAGE_RESET_SUCCESS_MESSAGE =
    PREFIX + 'POINTS_MARKED_UP_PERCENTAGE_RESET_SUCCESS_MESSAGE';

export const POINTS_MARKED_UP_PERCENTAGE_RESET_SUCCESS =
    PREFIX + 'POINTS_MARKED_UP_PERCENTAGE_RESET_SUCCESS';

export const PSP_MARKED_UP_PERCENTAGE_RESET_SUCCESS_MESSAGE =
    PREFIX + 'PSP_MARKED_UP_PERCENTAGE_RESET_SUCCESS_MESSAGE';

export const PSP_MARKED_UP_PERCENTAGE_RESET_SUCCESS =
    PREFIX + 'PSP_MARKED_UP_PERCENTAGE_RESET_SUCCESS';

export const UPDATE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE_SUCCESS_MESSAGE =
    PREFIX + 'UPDATE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE_SUCCESS_MESSAGE';

export const UPDATE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE_SUCCESS =
    PREFIX + 'UPDATE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE_SUCCESS';

export const SAVE_DEAL_ID = PREFIX + 'SAVE_DEAL_ID';

export const SAVE_DEAL_ID_SUCCESS = PREFIX + 'SAVE_DEAL_ID_SUCCESS';
