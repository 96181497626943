import { gql } from '@apollo/client';

export const FETCH_ORDERS = gql`
    query (
        $filter: OrderFilterInput
        $offset: Int!
        $limit: Int!
        $sort: String
        $order: SortOrder
        $search: OrderSearchInput
    ) {
        orders(
            filter: $filter
            offset: $offset
            limit: $limit
            sort: $sort
            order: $order
            search: $search
        ) {
            orders {
                id
                orderId
                orderStatus
                orderDate
                transactionId
                contentType
                gamerId
                gamerName
                gamerEmail
                billingAddress
                isActive
                createdBy
                createdDate
                modifiedBy
                modifiedDate
                purchaseMedium
                targetCurrency
                partner {
                    id
                    partnerId
                    partnerName
                }
                item {
                    id
                    itemId
                    itemName
                    contentType
                    supplier {
                        id
                        supplierId
                        supplierName
                    }
                }
                vouchers {
                    voucherCode
                    voucherExpiry
                    voucherSerial
                }
                quantity
                totalPoints
            }
            currentPage
            totalOrders
            totalPages
            totalCompletedOrders
            totalProcessingOrders
            totalCancelledOrders
            totalRefundedOrders
            totalDeletedOrders
        }
    }
`;

export const BULK_ACTION_ORDERS = gql`
    mutation ($orderIds: [ID]!, $orderListInput: OrderListInput!) {
        updateOrderList(orderIds: $orderIds, orderListInput: $orderListInput) {
            id
            orderId
            orderStatus
            orderDate
            billingAddress
            cancellationNote
        }
    }
`;

export const FETCH_ORDER = gql`
    query ($orderId: ID!) {
        order(orderId: $orderId) {
            id
            orderId
            orderStatus
            orderDate
            transactionId
            contentType
            gamerId
            gamerName
            gamerEmail
            createdBy
            createdDate
            modifiedBy
            modifiedDate
            item {
                id
                itemId
                itemName
                itemType
                contentType
            }
            quantity
            totalPoints
            purchaseMedium
            targetCurrency
            markedUpPrice
            dealId
            dealValue
            dealPercentage
        }
    }
`;

export const FETCH_FAILED_ORDER_TRANSACTIONS = gql`
    query (
        $filter: FailedOrderFilterInput
        $offset: Int!
        $limit: Int!
        $sort: String
        $order: SortOrder
        $searchText: String
    ) {
        failedOrderTransactions(
            filter: $filter
            offset: $offset
            limit: $limit
            sort: $sort
            order: $order
            searchText: $searchText
        ) {
            failedTransactions {
                id
                transactionId
                status
                createdDate
                gamerId
                gamerName
                gamerEmail
                createdDate
                productSellPrice
                purchaseCurrency
                status
                errorCode
                errorMessage
                currencyConversionRate
                unitPrice
                markedUpPrice
                pointsConversionRate
                pointsValue
                totalPoints
                purchaseMedium
                contentType
                quantity
                partner {
                    partnerName
                }
                item {
                    id
                    itemId
                    itemName
                    contentType
                    supplier {
                        id
                        supplierId
                        supplierName
                    }
                }
                retryButtonDisabled
                dealId
                dealValue
                dealPercentage
            }
            currentPage
            totalFailedTransactions
            totalRetriedSuccessOrders
            totalPages
        }
    }
`;

export const RETRY_TRANSACTION = gql`
    mutation ($failedOrderId: ID!) {
        retryFailedTransaction(failedOrderId: $failedOrderId) {
            id
            orderId
            quantity
            transactionId
            gamerId
            gamerName
            gamerEmail
            pointsValue
            orderDate
            orderStatus
            createdDate
            vouchers {
                voucherCode
                voucherExpiry
                voucherPin
                voucherSerial
            }
            transaction
            purchaseMedium
            purchaseCurrency
            purchaseCurrencyPrice
            totalPoints
        }
    }
`;

export const FETCH_SUCCESSFULL_RETRIED_ORDERS = gql`
    query (
        $filter: OrderFilterInput
        $offset: Int!
        $limit: Int!
        $sort: String
        $order: SortOrder
        $searchText: String
    ) {
        retriedSuccessOrders(
            filter: $filter
            offset: $offset
            limit: $limit
            sort: $sort
            order: $order
            searchText: $searchText
        ) {
            orders {
                id
                orderId
                orderStatus
                orderDate
                transactionId
                contentType
                gamerId
                gamerName
                gamerEmail
                billingAddress
                isActive
                createdBy
                createdDate
                modifiedBy
                modifiedDate
                purchaseMedium
                targetCurrency
                partner {
                    id
                    partnerId
                    partnerName
                }
                item {
                    id
                    itemId
                    itemName
                    contentType
                    supplier {
                        id
                        supplierId
                        supplierName
                    }
                }
                vouchers {
                    voucherCode
                    voucherExpiry
                    voucherSerial
                }
                quantity
                totalPoints
                markedUpPrice
                dealId
                dealValue
                dealPercentage
            }
            currentPage
            totalOrders
            totalPages
            totalCompletedOrders
            totalProcessingOrders
            totalCancelledOrders
            totalRefundedOrders
            totalDeletedOrders
        }
    }
`;

export const FETCH_RETRIED_FAILED_ORDER_TRANSACTIONS = gql`
    query ($transactionId: String!) {
        retriedFailedOrderTransactions(transactionId: $transactionId) {
            transactionId
            retriedTransactionId
            createdDate
            errorMessage
            status
        }
    }
`;
