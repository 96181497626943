export const ADMIN_PROFILE_UPDATE_SUCCESS = 'Successfully updated Admin profile';

export const ADMIN_PROFILE_UNSAVED_CHANGES_CONTENT =
    'There are unsaved changes to your profile. \nDo you wish to discard them?';

export const ADMIN_PROFILE_UNSAVED_CHANGES_TITLE = 'Unsaved changes';

export const REGISTERED = 'REGISTERED';

export const NOT_REGISTERED = 'NOT-REGISTERED';

export const NOT_REGISTERED_WARNING_TITLE = 'YOU ARE NOT REGISTERED';

export const NOT_REGISTERED_WARNING_BODY =
    'Please complete setting up your profile details to start using the system.';
