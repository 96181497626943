export const currencyAndCountryCodes = [
    { country: 'AF', currency: 'AFN' },
    { country: 'AL', currency: 'ALL' },
    { country: 'DZ', currency: 'DZD' },
    { country: 'AO', currency: 'AOA' },
    { country: 'AQ', currency: 'AAD' },
    { country: 'AR', currency: 'ARS' },
    { country: 'AM', currency: 'AMD' },
    { country: 'AW', currency: 'AWG' },
    { country: 'AU', currency: 'AUD' },
    { country: 'AZ', currency: 'AZN' },
    { country: 'BS', currency: 'BSD' },
    { country: 'BH', currency: 'BHD' },
    { country: 'BD', currency: 'BDT' },
    { country: 'BB', currency: 'BBD' },
    { country: 'BY', currency: 'BYN' },
    { country: 'BZ', currency: 'BZD' },
    { country: 'BM', currency: 'BMD' },
    { country: 'BT', currency: 'BTN' },
    { country: 'BO', currency: 'BOB' },
    { country: 'BA', currency: 'BAM' },
    { country: 'BW', currency: 'BWP' },
    { country: 'BR', currency: 'BRL' },
    { country: 'BN', currency: 'BND' },
    { country: 'BG', currency: 'BGN' },
    { country: 'BI', currency: 'BIF' },
    { country: 'KH', currency: 'KHR' },
    { country: 'CA', currency: 'CAD' },
    { country: 'CV', currency: 'CVE' },
    { country: 'KY', currency: 'KYD' },
    { country: 'CF', currency: 'XAF' },
    { country: 'CL', currency: 'CLP' },
    { country: 'CN', currency: 'CNY' },
    { country: 'CO', currency: 'COP' },
    { country: 'KM', currency: 'KMF' },
    { country: 'CD', currency: 'CDF' },
    { country: 'CR', currency: 'CRC' },
    { country: 'HR', currency: 'HRK' },
    { country: 'CU', currency: 'CUP' },
    { country: 'CZ', currency: 'CZK' },
    { country: 'DK', currency: 'DKK' },
    { country: 'DJ', currency: 'DJF' },
    { country: 'DO', currency: 'DOP' },
    { country: 'EG', currency: 'EGP' },
    { country: 'EU', currency: 'EUR' },
    { country: 'ER', currency: 'ERN' },
    { country: 'ET', currency: 'ETB' },
    { country: 'FK', currency: 'FKP' },
    { country: 'FJ', currency: 'FJD' },
    { country: 'GM', currency: 'GMD' },
    { country: 'GE', currency: 'GEL' },
    { country: 'GH', currency: 'GHS' },
    { country: 'GI', currency: 'GIP' },
    { country: 'GT', currency: 'GTQ' },
    { country: 'GN', currency: 'GNF' },
    { country: 'GY', currency: 'GYD' },
    { country: 'HT', currency: 'HTG' },
    { country: 'HN', currency: 'HNL' },
    { country: 'HK', currency: 'HKD' },
    { country: 'HU', currency: 'HUF' },
    { country: 'IS', currency: 'ISK' },
    { country: 'IN', currency: 'INR' },
    { country: 'ID', currency: 'IDR' },
    { country: 'IR', currency: 'IRR' },
    { country: 'IQ', currency: 'IQD' },
    { country: 'IL', currency: 'ILS' },
    { country: 'JM', currency: 'JMD' },
    { country: 'JP', currency: 'JPY' },
    { country: 'JO', currency: 'JOD' },
    { country: 'KZ', currency: 'KZT' },
    { country: 'KE', currency: 'KES' },
    { country: 'KP', currency: 'KPW' },
    { country: 'KR', currency: 'KRW' },
    { country: 'KW', currency: 'KWD' },
    { country: 'KG', currency: 'KGS' },
    { country: 'LA', currency: 'LAK' },
    { country: 'LB', currency: 'LBP' },
    { country: 'LS', currency: 'LSL' },
    { country: 'LR', currency: 'LRD' },
    { country: 'LY', currency: 'LYD' },
    { country: 'MO', currency: 'MOP' },
    { country: 'MK', currency: 'MKD' },
    { country: 'MG', currency: 'MGA' },
    { country: 'MW', currency: 'MWK' },
    { country: 'MY', currency: 'MYR' },
    { country: 'MV', currency: 'MVR' },
    { country: 'MR', currency: 'MRO' },
    { country: 'MU', currency: 'MUR' },
    { country: 'MX', currency: 'MXN' },
    { country: 'MD', currency: 'MDL' },
    { country: 'MN', currency: 'MNT' },
    { country: 'MA', currency: 'MAD' },
    { country: 'MZ', currency: 'MZN' },
    { country: 'MM', currency: 'MMK' },
    { country: 'NA', currency: 'NAD' },
    { country: 'NP', currency: 'NPR' },
    { country: 'NZ', currency: 'NZD' },
    { country: 'NI', currency: 'NIO' },
    { country: 'NG', currency: 'NGN' },
    { country: 'NO', currency: 'NOK' },
    { country: 'OM', currency: 'OMR' },
    { country: 'PK', currency: 'PKR' },
    { country: 'PA', currency: 'PAB' },
    { country: 'PG', currency: 'PGK' },
    { country: 'PY', currency: 'PYG' },
    { country: 'PE', currency: 'PEN' },
    { country: 'PH', currency: 'PHP' },
    { country: 'PL', currency: 'PLN' },
    { country: 'QA', currency: 'QAR' },
    { country: 'RO', currency: 'RON' },
    { country: 'RU', currency: 'RUB' },
    { country: 'RW', currency: 'RWF' },
    { country: 'SH', currency: 'SHP' },
    { country: 'WS', currency: 'WST' },
    { country: 'ST', currency: 'STD' },
    { country: 'SA', currency: 'SAR' },
    { country: 'RS', currency: 'RSD' },
    { country: 'SC', currency: 'SCR' },
    { country: 'SL', currency: 'SLL' },
    { country: 'SG', currency: 'SGD' },
    { country: 'SB', currency: 'SBD' },
    { country: 'SO', currency: 'SOS' },
    { country: 'ZA', currency: 'ZAR' },
    { country: 'SS', currency: 'SSP' },
    { country: 'LK', currency: 'LKR' },
    { country: 'SD', currency: 'SDG' },
    { country: 'SR', currency: 'SRD' },
    { country: 'SZ', currency: 'SZL' },
    { country: 'SE', currency: 'SEK' },
    { country: 'CH', currency: 'CHF' },
    { country: 'SY', currency: 'SYP' },
    { country: 'TW', currency: 'TWD' },
    { country: 'TJ', currency: 'TJS' },
    { country: 'TZ', currency: 'TZS' },
    { country: 'TH', currency: 'THB' },
    { country: 'TO', currency: 'TOP' },
    { country: 'TT', currency: 'TTD' },
    { country: 'TN', currency: 'TND' },
    { country: 'TR', currency: 'TRY' },
    { country: 'TM', currency: 'TMT' },
    { country: 'UG', currency: 'UGX' },
    { country: 'UA', currency: 'UAH' },
    { country: 'AE', currency: 'AED' },
    { country: 'GB', currency: 'GBP' },
    { country: 'US', currency: 'USD' },
    { country: 'UY', currency: 'UYU' },
    { country: 'UZ', currency: 'UZS' },
    { country: 'VU', currency: 'VUV' },
    { country: 'VE', currency: 'VEF' },
    { country: 'VN', currency: 'VND' },
    { country: 'YE', currency: 'YER' },
    { country: 'ZM', currency: 'ZMW' },
    { country: 'ZW', currency: 'ZWL' },
    { country: '', currency: 'XCD' },
    { country: '', currency: 'XOF' },
    { country: '', currency: 'XPF' },
    { country: '', currency: 'ANG' },
];
