/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLazyQuery, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { Buttons } from '../../ui/atoms/Button';
import { useHistory } from 'react-router';
import { FETCH_SUPPLIERS_DROPDOWN } from '../../../queries/SupplierQueries';
import Toast from '../../ui/atoms/Toast';
import React from 'react';
import DataTable from '../../ui/organisms/DataTable';
import { pagination } from '../../../constants/config/constants';
import SpinLoader from '../../../utils/spinLoader';
import { FETCH_SUPPLIER_PRODUCTS } from '../../../queries/ProductQueries';
import { MappedItemStatus, MappedProductStatus } from '../../../enums/product';
import {
    PARTNER_ADD_EXIT_CONFIRM_MESSAGE,
    PARTNER_ADD_EXIT_CONFIRM_TITLE,
} from '../../../constants/partner';
import UnsaveChangesWarning from '../../templates/modals/UnsaveChangesWarning';
import {
    PRODUCT_ADD_EXIT_CONFIRM_MESSAGE,
    PRODUCT_ADD_EXIT_CONFIRM_TITLE,
} from '../../../constants/product';
import { useDispatch } from 'react-redux';
import { saveSupplierName } from '../../../redux/rootActions';
import {
    ALLSERV,
    API,
    CSV,
    CSV_VOUCHERS,
    VOUCHER_STATUS_AVAILABLE,
} from '../../../constants/supplier';
import SearchBar from '../../ui/molecules/SearchBar';
import { getSupplierProductCode } from '../../../helpers/ItemHelpers.helpers';

export default function ProductInventory() {
    const history = useHistory();
    const [productInitialData, setProductsInitialData] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [message, setMessage] = useState('');
    const [clickedProduct, setClickedProduct] = useState(null);
    const [error, setError] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [showToast, setShowToast] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const [productNumberError, setProductNumberError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedSupplierError, setSelectedSupplierError] = useState(false);
    const [selectedSupplierMappingError, setSelectedSupplierMappingError] = useState(false);
    const [selectedOneSupplierData, setSelectedOneSupplierData] = useState([]);
    const [clicked, setClicked] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [pageSize, setPageSize] = useState(pagination?.pageSize);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchClicked, setSearchClicked] = useState(false);

    const dispatch = useDispatch();

    const { data: supplierData, loading } = useQuery(FETCH_SUPPLIERS_DROPDOWN, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
    });

    const selectedSupplierID = supplierData?.suppliers?.find((supplier) => {
        if (selectedSupplier) {
            return supplier?.supplierId === selectedSupplier;
        }
    });

    const handleFormatData = (products) => {
        return products?.map((product) => {
            return {
                ...product,
                sellPrice: `${product?.sellPrice.toFixed(2)} ${product?.currency}`,
            };
        });
    };

    const [getSupplierProducts, { loading: selectedSupplierDataLoading, called }] = useLazyQuery(
        FETCH_SUPPLIER_PRODUCTS,
        {
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                setSelectedOneSupplierData(data?.productsBySupplier?.products?.slice(0, pageSize));
                setTotalItems(data?.productsBySupplier?.totalProducts);
                setProductsInitialData(data?.productsBySupplier?.products);
            },
        },
    );

    const columns = React.useMemo(
        () => [
            {
                Header: 'Supplier Product Code',
                accessor: (product) => {
                    const productCode = getSupplierProductCode(product);
                    return productCode;
                },
            },
            {
                Header: 'Product Name',
                accessor: 'productName',
            },
            {
                Header: 'Product Origin',
                id: 'orderStrategy',
                accessor: (product) => {
                    if (product?.orderStrategy === CSV_VOUCHERS) {
                        return CSV;
                    } else if (product?.orderStrategy.startsWith('ALLSERV')) {
                        return ALLSERV;
                    } else {
                        return API;
                    }
                },
            },
            {
                Header: 'Product Type',
                accessor: 'productType',
            },
            {
                Header: 'Status',
                accessor: 'voucherInventories',
                Cell: function showBadge({ value }) {
                    const isStockAvailable = value?.some(
                        (val) => val?.status === VOUCHER_STATUS_AVAILABLE,
                    );
                    const status = isStockAvailable ? 'In stock' : 'Out of stock';
                    return (
                        <span
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                status === 'In stock'
                                    ? 'bg-green-100 text-green-600'
                                    : status === 'Out of stock'
                                    ? 'bg-red-100 text-red-600'
                                    : ''
                            }`}
                        >
                            {status}
                        </span>
                    );
                },
            },
        ],
        [],
    );
    useEffect(() => {
        if (dirty && selectedSupplierID?.id) {
            getSupplierProducts({
                variables: {
                    supplierId: selectedSupplierID?.id,
                    filter: {
                        status: MappedProductStatus?.UNMAPPED,
                        productStatus: MappedItemStatus?.PUBLISHED,
                    },
                    offset: currentPage - 1 || 0,
                    limit: Math.ceil(pageSize) || 100000,
                    searchText: searchTerm && searchClicked ? searchTerm : null,
                },
            });
        }
    }, [dirty, getSupplierProducts, selectedSupplierID?.id, pageSize, currentPage, searchTerm]);

    useEffect(() => {
        if (!selectedSupplier && clicked) setSelectedSupplierError(true);
        else {
            setSelectedSupplierError(false);
        }
    }, [selectedSupplier, clicked]);

    useEffect(() => {
        if (!selectedProducts?.length && clicked) setSelectedSupplierMappingError(true);
        else {
            setSelectedSupplierMappingError(false);
        }
    }, [selectedProducts?.length, clicked]);

    useEffect(() => {
        setSelectedOneSupplierData(productInitialData);
    }, [pageIndex, pageSize, productInitialData]);

    const supplier = supplierData?.suppliers?.find((supplier) => {
        if (selectedSupplier) {
            return supplier?.supplierId === selectedSupplier;
        }
    });

    useEffect(() => {
        dispatch(saveSupplierName(supplier?.supplierName));
    }, [supplier]);

    const productNames = productInitialData?.map((product) => product?.productName);

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
        }
    };

    return (
        <Formik
            initialValues={{
                itemCode: '',
                itemName: '',
                itemType: '',
                itemDescBrief: '',
                itemImageUrl: '',
                itemGalleryUrls: [],
                itemDescDetail: '',
                deliveryMethod: '',
            }}
            enableReinitialize
            onSubmit={() => {
                const selectedProductsDetails = productInitialData?.filter((initProd) =>
                    selectedProducts?.some((prod) => prod === initProd?.id),
                );
                if (!productNumberError && selectedProducts?.length === 1)
                    history.push({
                        pathname: '/products/general-add',
                        state: {
                            supplier: selectedSupplierID?.id,
                            product: selectedProductsDetails,
                        },
                    });
            }}
        >
            {({ handleSubmit }) => (
                <>
                    {showToast && (
                        <Toast setShowToast={setShowToast} message={message} error={error} />
                    )}

                    <div className="h-full flex flex-col justify-between">
                        <div className="text-xl font-poppins font-bold px-10">Select supplier</div>

                        <div className="grid grid-cols-1 gap-4  xl:grid-cols-8 xl:gap-4 p-8 w-full px-10">
                            <div className="xl:col-start-1 xl:col-end-4 w-full ">
                                <div className="flex flex-wrap flex-col relative w-full">
                                    <div className="text-gray-500 w-16 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Supplier
                                    </div>
                                    <select
                                        className="px-4 py-3 rounded-xl outline-none border-2  bg-white border-gray-400 hover:border-purple-500"
                                        id="addProduct_supplier"
                                        onChange={(e) => {
                                            setSelectedSupplier(e.target.value);
                                            setDirty(true);
                                        }}
                                        onBlur={(e) => {
                                            setSelectedSupplier(e.target.value);
                                            setDirty(true);
                                        }}
                                    >
                                        <option value="" disabled selected>
                                            Select supplier
                                        </option>
                                        {supplierData?.suppliers
                                            ?.filter((supplierItem) => supplierItem?.isActive)
                                            .map((option: any, index) => {
                                                return (
                                                    <option
                                                        value={option.supplierId}
                                                        className={'font-medium'}
                                                        key={index}
                                                    >
                                                        {option.supplierName}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                    {selectedSupplierError ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {'Supplier detail is missing'}
                                        </div>
                                    ) : null}
                                    {selectedSupplierMappingError ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {'Selected product detail is missing'}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row w-full px-10">
                            {' '}
                            {dirty && selectedSupplierID?.id && (
                                <div
                                    className="relative flex-grow-0 w-1/2 md:w-full lg:w-5/12 pr-14 text-gray-500"
                                    id="product_name_search"
                                >
                                    <SearchBar
                                        id="add-new-item-search-product-field"
                                        options={productNames}
                                        handleChange={(e, newVal, reason) => {
                                            setSearchClicked(true);
                                            handleChange(e, newVal, reason);
                                        }}
                                        placeHolder={'Search Product by Name'}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="w-full p-4 px-10 h-full mb-20">
                            {dirty && selectedSupplierID?.id && !selectedSupplierDataLoading ? (
                                <div className="p-1 border-2 rounded-md">
                                    {selectedOneSupplierData?.length ? (
                                        <>
                                            {' '}
                                            <div className="text-xl font-poppins font-bold py-5 p-2">
                                                Select product from supplier
                                            </div>
                                            <DataTable
                                                columns={columns}
                                                data={selectedOneSupplierData}
                                                pageCount={pageCount}
                                                serverSidePagination={true}
                                                currentPage={currentPage}
                                                pageSizes={pageSize}
                                                setCurrentPage={setCurrentPage}
                                                totalItems={totalItems}
                                                setSelectedItems={setSelectedProducts}
                                                setPageIndex={setPageIndex}
                                                setDefaultPageSize={setPageSize}
                                                setExposeMenuClickedItem={setClickedProduct}
                                                radioBtn={true}
                                            />
                                        </>
                                    ) : (
                                        <div className="m-auto flex flex-col ">
                                            <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                                {columns?.map((column, i) => {
                                                    return (
                                                        <div
                                                            className="text-black font-poppins font-semibold flex ml-12"
                                                            key={i}
                                                        >
                                                            {column?.Header}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="m-auto p-10">No data found.</div>
                                            <div className="p-10 bg-purple-100"></div>
                                        </div>
                                    )}
                                </div>
                            ) : selectedSupplierDataLoading ? (
                                <div className="flex flex-1 flex-col align-middle items-center justify-center px-8 lg:mt-32 md:mt-28 sm:mt-16">
                                    <div className="h-full align-middle my-2">
                                        <SpinLoader />
                                    </div>
                                    <div className="h-full align-middle my-4 text-center font-poppins text-base text-purple-500">
                                        Please wait this may take a moment
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <UnsaveChangesWarning
                            contentText={PRODUCT_ADD_EXIT_CONFIRM_MESSAGE}
                            messageTitle={PRODUCT_ADD_EXIT_CONFIRM_TITLE}
                            when={dirty || window.onbeforeunload ? true : false}
                            navigate={(path) => history.push(path)}
                            shouldBlockNavigation={(location) => {
                                if (dirty) {
                                    return true;
                                }
                                return false;
                            }}
                            displayIcon={true}
                            displayIconName={'clarity_error-standard-line-svg.svg'}
                        />

                        <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0">
                            <Buttons
                                name="Cancel"
                                type="button"
                                buttonType="secondary-border-black"
                                id="supplierTab_cancel"
                                size="e-small"
                                other="mr-3"
                                onclick={() => {
                                    history.push('/products');
                                }}
                            />
                            <Buttons
                                name={'Next'}
                                type="submit"
                                disabled={false}
                                buttonType="primary"
                                id="supplierTab_next"
                                size="e-small"
                                onclick={() => {
                                    setDirty(false);
                                    setClicked(true);
                                    handleSubmit();
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
