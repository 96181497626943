import { GET_GEO_LOCATION } from './../../enums/redux/redux-enum';

export interface geoLocationState {
    geoLocation: any | null;
    isSuccess: boolean;
}

export const geoLocationInitialState: geoLocationState = {
    geoLocation: null,
    isSuccess: false,
};

const geoLocationReducer = (state = geoLocationInitialState, action: any): geoLocationState => {
    switch (action.type) {
        case GET_GEO_LOCATION:
            return { ...state, geoLocation: action.payload, isSuccess: true };
        default:
            return state;
    }
};

export default geoLocationReducer;
