const AdminLoginValidation = {
    validateFirstLogin: function (activityLogList) {
        let isFirstLogin = false;
        if (activityLogList && activityLogList.length <= 0) {
            isFirstLogin = true;
        }
        return isFirstLogin;
    },
    validateLoginActionPerformed: function (activityLogList) {
        let loginActionPerformed = false;
        if (activityLogList) {
            activityLogList.forEach((log) => {
                if (log.action === 'FIRST_LOGIN') {
                    loginActionPerformed = true;
                }
            });
        }

        return loginActionPerformed;
    },
};

export default AdminLoginValidation;
