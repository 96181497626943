/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { useHistory } from 'react-router';
import { Buttons } from '../../ui/atoms/Button';

interface Props {
    showAddNewUser: boolean;
    setShowAddNewUser: (value: boolean) => void;
    setInviteUserFormModal: (value: boolean) => void;
}

export default function AddUserOptionModal({
    showAddNewUser,
    setShowAddNewUser,
    setInviteUserFormModal,
}: Props): ReactElement {
    const history = useHistory();

    return (
        <Modal
            open={showAddNewUser}
            onClose={() => {
                setShowAddNewUser(false);
            }}
            center={true}
            styles={{ modal: { borderRadius: 8, maxWidth: '706px', width: '80%' } }}
            focusTrapped={false}
        >
            <>
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-poppins  font-semibold flex m-auto">
                        Add new user
                    </h3>
                </div>

                <div className="relative px-4 py-8  flex flex-col">
                    <div className="text-gray-400 text-lg flex mb-3 m-auto">
                        Do you want to invite user or create a new user, Please select the
                    </div>
                    <div className="text-gray-400 text-lg flex mb-3 m-auto">below buttons</div>
                </div>

                <div className="flex flex-row justify-center">
                    <Buttons
                        name="Invite new user"
                        type="submit"
                        buttonType="secondary-main"
                        id="okay"
                        size="small"
                        onclick={() => {
                            setInviteUserFormModal(true);
                            setShowAddNewUser(false);
                        }}
                    />
                    <Buttons
                        name="Create new user"
                        type="submit"
                        buttonType="primary"
                        id="okay"
                        size="small"
                        onclick={() => {
                            setShowAddNewUser(false);
                            history.push('/users/account-details-add');
                        }}
                    />
                </div>

                <div className="flex flex-row mt-6 justify-end m-4">
                    <Buttons
                        name="Close"
                        type="submit"
                        buttonType="secondary-border-black"
                        id="okay"
                        size="small"
                        onclick={() => {
                            setShowAddNewUser(false);
                        }}
                    />
                </div>
            </>
        </Modal>
    );
}
