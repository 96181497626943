import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import { Buttons } from '../../../ui/atoms/Button';
import { InputField } from '../../../ui/atoms/InputField';
import { ADD_POINTS } from '../../../../queries/PointsQueries';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import {
    setPointsUpdatedSuccess,
    setPointsUpdateFailed,
} from '../../../../redux/actions/storeActions';
import NormalLoader from '../../../../utils/normalLoader';
import {
    PARTNER_ADD_EXIT_CONFIRM_MESSAGE,
    PARTNER_ADD_EXIT_CONFIRM_TITLE,
} from '../../../../constants/partner';
import UnsaveChangesWarning from '../../../templates/modals/UnsaveChangesWarning';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

interface Props {
    selectedSupplier: any;
    setEditedId?: any;
}
export default function PartnerQuickEditView({ selectedSupplier, setEditedId }: Props) {
    const dispatch = useDispatch();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const selectedPartner = useSelector((state: RootState) => state.auth.partner);
    const partnerId = localStorage.getItem('partnerId');
    const history = useHistory();
    const [savePoints, { loading: queryLoading }] = useMutation(ADD_POINTS, {
        context: { clientName: ClientsEnum.STORE },
        onCompleted: (data) => {
            dispatch(setPointsUpdatedSuccess(data?.saveGlobalPoints));
        },
        onError: (data) => {
            dispatch(setPointsUpdateFailed(data?.message));
        },
    });

    const validationSchema = Yup.object({
        rewardPoints: Yup.number()
            .positive()
            .label('Conversion Points')
            .required('Conversion point value is required')
            .moreThan(0),
    });

    return (
        <>
            <div className="w-full p-2 m-3 rounded-xl">
                <Formik
                    initialValues={{
                        supplierId: selectedSupplier?.supplierId,
                        supplierName: selectedSupplier?.supplierName,
                        rewardPoints: selectedSupplier?.overriddenValue
                            ? selectedSupplier?.overriddenValue
                            : selectedSupplier?.defaultValue,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async ({ rewardPoints }) => {
                        setEditedId(selectedSupplier?.supplierId);
                        savePoints({
                            variables: {
                                points: {
                                    partner: {
                                        id: selectedPartner?.partner?.id || partnerId,
                                    },
                                    supplier: {
                                        id: selectedSupplier?.id,
                                    },
                                    defaultValue: selectedSupplier?.defaultValue || rewardPoints,
                                    overriddenValue: rewardPoints ? rewardPoints : 0,
                                },
                            },
                        });
                    }}
                >
                    {({ values, errors, touched, handleChange, handleSubmit }) => (
                        <>
                            <div className="flex flex-row p-4 z-30">
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="flex col-start-1 col-end-2">
                                        <InputField
                                            id="supplierId"
                                            labelWidth="w-24"
                                            placeHolder={selectedSupplier?.supplierId}
                                            name="Supplier ID"
                                            readonly
                                        />
                                        <div className="ml-2 mt-5">
                                            <img
                                                src="/images/icons/lock.png"
                                                className={`text-gray-700 cursor-pointer w-4 h-5 block`}
                                                alt="show-password"
                                                role="presentation"
                                                draggable="false"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex col-start-2 col-span-2">
                                        <InputField
                                            id="supplierName"
                                            labelWidth="w-20"
                                            placeHolder={selectedSupplier?.supplierName}
                                            readonly
                                            name="Supplier name"
                                        />

                                        <div className="ml-2 mt-5">
                                            <img
                                                src="/images/icons/lock.png"
                                                className={`text-gray-700 cursor-pointer w-4 h-5 block`}
                                                alt="show-password"
                                                role="presentation"
                                                draggable="false"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <UnsaveChangesWarning
                                contentText={PARTNER_ADD_EXIT_CONFIRM_MESSAGE}
                                messageTitle={PARTNER_ADD_EXIT_CONFIRM_TITLE}
                                when={isDirty || window.onbeforeunload ? true : false}
                                navigate={(path) => history.push(path)}
                                shouldBlockNavigation={() => {
                                    if (isDirty) {
                                        return true;
                                    }
                                    return false;
                                }}
                                displayIcon={true}
                                displayIconName={'clarity_error-standard-line-svg.svg'}
                            />
                            <div className="flex pl-4">
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="col-start-1 col-end-2">
                                        <InputField
                                            id="rewardPoints"
                                            labelWidth="w-20"
                                            type="number"
                                            placeHolder={values?.rewardPoints}
                                            value={values?.rewardPoints}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setIsDirty(true);
                                            }}
                                            name="Conversion points"
                                            min="0"
                                        />
                                        {errors.rewardPoints && touched.rewardPoints ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                {errors.rewardPoints}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className={`flex flex-row px-14 py-3 w-full justify-end z-50`}>
                                <Buttons
                                    name={queryLoading ? <NormalLoader /> : 'Update'}
                                    type="submit"
                                    buttonType="primary"
                                    id="update"
                                    size="e-small"
                                    disabled={!isDirty}
                                    onclick={() => {
                                        handleSubmit();
                                    }}
                                />
                            </div>
                        </>
                    )}
                </Formik>
            </div>
        </>
    );
}
