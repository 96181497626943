/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
    FETCH_PARTNER_CURRENCY_PREFERENCES,
    FETCH_PARTNER_LOCAL_CURRENCY_CONVERSION_RATE,
    FETCH_PARTNER_TARGET_CURRENCY_PREFERENCES,
} from '../../../../queries/PartnerQueries';
import DataTable from '../../../ui/organisms/DataTable';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import Loader from '../../../../utils/loader';
import { currencyAndCountryCodes } from '../../../../utils/currencies';
import Multiselect from 'multiselect-react-dropdown';
import Toast from '../../../ui/atoms/Toast';
import SearchBar from '../../../ui/molecules/SearchBar';
import {
    LOCAL_CURRENCY_CONVERSION_RATE_FETCH_ERROR_MESSAGE,
    LOCAL_CURRENCY_CONVERSION_RATE_NOT_SET,
} from '../../../../constants/partner';

export default function ViewCurrencyPreferences() {
    const partnerId = localStorage.getItem('partnerId');

    const [partnerCurrencyPreferences, setPartnerCurrencyPreferences] = useState([]);
    const [searchedPartnerCurrencyPreferences, setSearchedPartnerCurrencyPreferences] = useState(
        [],
    );
    const [selectedTargetCurrencies, setSelectedTargetCurrencies] = useState([]);
    const [fetchedLocalCurrencyConversionRate, setFetchedLocalCurrencyConversionRate] = useState({
        localCurrency: null,
        conversionRate: null,
        error: null,
    });

    const [isError, setIsError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [searchClicked, setSearchClicked] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const { loading: partnerSelectedTargetCurrenciesLoading } = useQuery(
        FETCH_PARTNER_TARGET_CURRENCY_PREFERENCES,
        {
            variables: { partnerId: partnerId },
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
            context: { clientName: ClientsEnum.STORE },
            onCompleted(data) {
                const selectedTargetCurrencyKeyValuePairs =
                    data?.partnerTargetCurrencyPreferences?.map((pcp, index) => ({
                        index: index,
                        key: pcp,
                    }));
                setSelectedTargetCurrencies(selectedTargetCurrencyKeyValuePairs);
            },
            onError() {
                setShowToast(true);
                setIsError(true);
                setMessage(
                    'Something went wrong while fetching partner target currency preferences',
                );
            },
        },
    );

    const searchSuggestions = [
        ...new Set([
            ...partnerCurrencyPreferences?.map((currency) => currency?.sourceCurrency),
            ...partnerCurrencyPreferences?.map((currency) => currency?.targetCurrency),
        ]),
    ];

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
        }
    };

    useEffect(() => {
        if (!searchTerm || searchTerm?.length === 0) {
            setSearchedPartnerCurrencyPreferences(partnerCurrencyPreferences);
            setSearchClicked(false);
        } else {
            const arr = partnerCurrencyPreferences?.filter(
                (currency) =>
                    currency.targetCurrency.includes(searchTerm) ||
                    currency.sourceCurrency.toUpperCase().includes(searchTerm.toUpperCase()),
            );
            setSearchedPartnerCurrencyPreferences(arr);
            setSearchClicked(true);
        }
    }, [partnerCurrencyPreferences, searchTerm]);

    const { loading: partnerCurrencyPreferencesLoading } = useQuery(
        FETCH_PARTNER_CURRENCY_PREFERENCES,
        {
            variables: { partnerId: partnerId },
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
            context: { clientName: ClientsEnum.STORE },
            onCompleted(data) {
                const sortedPartnerCurrencyPreferences = data?.partnerCurrencyPreferences
                    ?.filter((pcp) => pcp.sourceCurrency)
                    .sort(function (a, b) {
                        const x1 = a.sourceCurrency.toLowerCase();
                        const y1 = b.sourceCurrency.toLowerCase();
                        const x2 = a.targetCurrency.toLowerCase();
                        const y2 = b.targetCurrency.toLowerCase();

                        if (x1 < y1) {
                            return -1;
                        } else if (x1 > y1) {
                            return 1;
                        } else {
                            return x2 < y2 ? -1 : 1;
                        }
                    });
                setPartnerCurrencyPreferences(sortedPartnerCurrencyPreferences);
            },
            onError() {
                setShowToast(true);
                setIsError(true);
                setMessage(
                    'Something went wrong while fetching overrided partner currency conversion rates',
                );
            },
        },
    );

    const { loading: fetchPartnerLocalCurrencyConversionRateLoading } = useQuery(
        FETCH_PARTNER_LOCAL_CURRENCY_CONVERSION_RATE,
        {
            variables: { partnerId },
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
            context: { clientName: ClientsEnum.STORE },
            onCompleted(data) {
                if (data.partnerLocalCurrencyConversionRate.length === 0) {
                    setFetchedLocalCurrencyConversionRate({
                        ...fetchedLocalCurrencyConversionRate,
                        error: LOCAL_CURRENCY_CONVERSION_RATE_NOT_SET,
                    });
                    return;
                }

                const result = data.partnerLocalCurrencyConversionRate[0];
                if (result) {
                    setFetchedLocalCurrencyConversionRate({
                        ...fetchedLocalCurrencyConversionRate,
                        localCurrency: result.localCurrency,
                        conversionRate: result.conversionRate,
                    });
                }
            },
            onError() {
                setShowToast(true);
                setIsError(true);
                setMessage(LOCAL_CURRENCY_CONVERSION_RATE_FETCH_ERROR_MESSAGE);
            },
        },
    );

    const columns = React.useMemo(
        () => [
            {
                Header: 'Source Currency',
                accessor: 'sourceCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Target Currency',
                accessor: 'targetCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Conversion Rate',
                accessor: 'conversionRate',
                Cell: function formttedFont({ row, value }) {
                    return (
                        <div
                            className={`flex ${
                                row?.original?.overridePartner ? 'text-red-500' : ''
                            }`}
                        >
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                id: 'summary',
                accessor: '',
                Cell: function displayConversionRateSummary({ row }) {
                    return row?.original?.conversionRate
                        ? `1 ${row?.original?.sourceCurrency} = ${row?.original?.conversionRate} ${row?.original?.targetCurrency}`
                        : `Currency conversion rate between ${row?.original?.sourceCurrency} and ${row?.original?.targetCurrency} is not set`;
                },
            },
        ],
        [],
    );

    if (partnerCurrencyPreferencesLoading || partnerSelectedTargetCurrenciesLoading)
        return <Loader />;

    return (
        <>
            {showToast && (
                <Toast
                    setShowToast={setShowToast}
                    message={message}
                    error={isError}
                    width="w-10/12"
                    margin="-mt-14 ml-10"
                    selfDestruct={true}
                    selfDestructTimer={5000}
                />
            )}
            <div className="h-full flex flex-col justify-evens px-8">
                <div className="text-lg font-poppins font-bold mb-5">Currency Preferences</div>
                <div className="flex order-0 flex-col relative mb-10 px-4">
                    <div className="text-gray-500 w-72 text-center font-poppins z-30 text-sm lg:text-sm h-max bg-white -mb-3 ml-4 border-opacity-20">
                        Selected Target Currency Preferences
                    </div>
                    <div className="z-20">
                        <Multiselect
                            displayValue="key"
                            style={{
                                chips: {
                                    background: '#7666F1',
                                },
                                multiselectContainer: {
                                    color: 'black',
                                },
                                searchBox: {
                                    border: '1px solid black',
                                    'border-radius': '10px',
                                    padding: '10px',
                                },
                            }}
                            selectedValues={selectedTargetCurrencies}
                            disable={true}
                            options={selectedTargetCurrencies}
                        />
                    </div>
                </div>

                {fetchPartnerLocalCurrencyConversionRateLoading ? (
                    <Loader />
                ) : (
                    <div className="mb-10">
                        <div className="flex py-3 px-4">
                            <div>Local Currency</div>
                        </div>
                        {fetchedLocalCurrencyConversionRate.error ? (
                            <div className="flex px-4 text-red-500">
                                {fetchedLocalCurrencyConversionRate.error}
                            </div>
                        ) : (
                            <div className="flex pt-4 px-4 items-baseline">
                                <i
                                    className={`${currencyAndCountryCodes
                                        .find(
                                            (x) =>
                                                x.currency ===
                                                fetchedLocalCurrencyConversionRate.localCurrency,
                                        )
                                        ?.country.toLowerCase()} flag pt-1`}
                                ></i>
                                <span>{fetchedLocalCurrencyConversionRate.localCurrency} 1 = </span>
                                <input
                                    name="conversionRate"
                                    type="text"
                                    className="p-2 border-2 outline-none rounded-lg mx-1 text-right"
                                    size={3}
                                    value={fetchedLocalCurrencyConversionRate.conversionRate}
                                    disabled
                                />
                                <span>PTS</span>
                            </div>
                        )}
                    </div>
                )}

                <div className="border-2 border-gray-300 rounded-lg ml-4 mr-4">
                    <div className="flex p-4">
                        <div className="flex w-full">
                            <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3">
                                <SearchBar
                                    id="target-currency-search"
                                    options={searchSuggestions}
                                    handleChange={(e, newVal, reason) => {
                                        handleChange(e, newVal, reason);
                                    }}
                                    placeHolder={'Search by currency'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <>
                            {searchedPartnerCurrencyPreferences?.length ? (
                                <DataTable
                                    columns={columns}
                                    data={searchedPartnerCurrencyPreferences}
                                    hidePagination={true}
                                    customizedPageSize={searchedPartnerCurrencyPreferences?.length}
                                    pageCount={1}
                                />
                            ) : searchClicked && !searchedPartnerCurrencyPreferences?.length ? (
                                <div className="m-auto flex flex-col ">
                                    <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                        {columns?.map((column, i) => {
                                            return (
                                                <div
                                                    className="text-black font-poppins font-semibold flex ml-12"
                                                    key={i}
                                                >
                                                    {column?.Header}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="m-auto p-10 flex">
                                        Your search
                                        <div className="text-purple-500 mx-2">{`"${searchTerm}"`}</div>
                                        did not match any results
                                    </div>
                                    <div className="p-10 bg-purple-100"></div>
                                </div>
                            ) : (
                                <div className="m-auto flex flex-col ">
                                    <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                        {columns?.map((column, i) => {
                                            return (
                                                <div
                                                    className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                    key={i}
                                                >
                                                    {column?.Header}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="m-auto p-10">
                                        {selectedTargetCurrencies?.length
                                            ? 'There are no applicable currency conversion rates, as the partner has no products that would cause the source currencies to be missing'
                                            : 'No data found.'}
                                    </div>
                                    <div className="p-10 bg-purple-100"></div>
                                </div>
                            )}
                        </>
                    </div>
                </div>
            </div>
        </>
    );
}
