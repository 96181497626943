import { gql } from '@apollo/client';

export const FETCH_SUPPLIER_PRODUCTS = gql`
    query (
        $filter: ProductsFilterInput
        $offset: Int!
        $limit: Int!
        $supplierId: ID!
        $searchText: String
        $sort: String
    ) {
        productsBySupplier(
            filter: $filter
            offset: $offset
            limit: $limit
            searchText: $searchText
            supplierId: $supplierId
            sort: $sort
        ) {
            products {
                id
                manufacturerId
                manufacturerName
                manufacturerBrandId
                manufacturerBrandName
                manufacturerBrandProductFormatId
                manufacturerBrandProductFormatDesc
                manufacturerBrandProductFields
                productId
                productName
                productVvssku
                currency
                unitPrice
                sellPrice
                productRedemptionInstructions
                totalStock
                availableStock
                voucherInventories {
                    id
                    batchId
                    status
                    currency
                    unitPrice
                    stockCount
                    createdDate
                    modifiedDate
                }
                productVouchers {
                    id
                    voucherCode
                    voucherExpiry
                    voucherSerial
                    voucherPin
                }
                productImageUrl
                createdDate
                modifiedDate
                orderStrategy
                productType
                productCode
                purchaseNote
                orderFields
            }
            currentPage
            totalProducts
            totalPages
        }
    }
`;

export const FETCH_SUPPLIER_VOUCHERS = gql`
    query (
        $filter: VoucherFilterInput
        $offset: Int!
        $limit: Int!
        $supplierId: ID!
        $searchText: String
        $sort: String
        $order: SortOrder
    ) {
        voucherCodesBySupplier(
            filter: $filter
            offset: $offset
            limit: $limit
            supplierId: $supplierId
            searchText: $searchText
            sort: $sort
            order: $order
        ) {
            vouchers {
                id
                voucherCode
                voucherExpiry
                voucherSerial
                voucherPin
                status
                createdDate
            }
            currentPage
            totalVouchers
            totalPages
        }
    }
`;

export const FETCH__PRODUCTS = gql`
    query {
        products {
            id
            manufacturerId
            manufacturerName
            manufacturerBrandId
            manufacturerBrandName
            manufacturerBrandProductFormatId
            manufacturerBrandProductFormatDesc
            manufacturerBrandProductFields
            productId
            productName
            productVvssku
            currency
            currencySymbol
            unitPrice
            sellPrice
            productRedemptionInstructions
            productImageUrl
            createdDate
            modifiedDate
        }
    }
`;

export const FETCH_PRODUCT_BY_ID = gql`
    query ($productId: ID!) {
        product(productId: $productId) {
            id
            productName
            productVouchers {
                id
                voucherCode
                voucherExpiry
                voucherSerial
                voucherPin
                status
            }
        }
    }
`;

export const FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID = gql`
    query ($itemId: ID!) {
        partnerConversions(itemId: $itemId) {
            id
            partnerId
            partnerName
            markedUpPercentageForCurrency
            markedUpPercentage
            markedUpPercentageForPsp
            isPartnerCurrencyMarkedUpPercentageOverridden
            isPartnerPointsMarkedUpPercentageOverridden
            isPartnerPspMarkedUpPercentageOverridden
            markedUpPrice
            markedUpPriceForCurrency
            markedUpPriceForPsp
            markedUpPriceOverridden
            localCurrency
            localCurrencyConversionRate
            localCurrencyConversionRateOverridden
            finalRoundedPointsValue
            productsAvailable
            currencyPrices {
                currency
                markUpPrice
            }
            finalRetailPriceOverridden
            finalRetailPrice
            overriddenMarkedUpPriceForCurrency
            overriddenMarkedUpPriceForPsp
        }
    }
`;

export const RESET_LOCAL_CURRENCY_RATE = gql`
    mutation ($partnerId: ID!, $itemId: ID!) {
        resetLocalConversionRate(partnerId: $partnerId, itemId: $itemId)
    }
`;

export const RESET_MARKED_UP_PRICE = gql`
    mutation ($partnerId: ID!, $itemId: ID!) {
        resetMarkedUpPrice(partnerId: $partnerId, itemId: $itemId)
    }
`;

export const FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_AND_PARTNER_ID = gql`
    query ($itemId: ID!, $partnerId: ID!) {
        partnerConversionsByItemAndPartnerId(itemId: $itemId, partnerId: $partnerId) {
            id
            partnerId
            partnerName
            markedUpPercentageForCurrency
            markedUpPercentage
            markedUpPercentageForPsp
            isPartnerCurrencyMarkedUpPercentageOverridden
            isPartnerPointsMarkedUpPercentageOverridden
            isPartnerPspMarkedUpPercentageOverridden
            markedUpPrice
            markedUpPriceForCurrency
            markedUpPriceForPsp
            markedUpPriceOverridden
            localCurrency
            localCurrencyConversionRate
            localCurrencyConversionRateOverridden
            finalRoundedPointsValue
            productsAvailable
            currencyPrices {
                currency
                markUpPrice
            }
            finalRetailPriceOverridden
            finalRetailPrice
            overriddenMarkedUpPriceForCurrency
            overriddenMarkedUpPriceForPsp
        }
    }
`;
