import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

export default function InnerSideBarElementsView() {
    const history = useHistory();
    const parameters = useParams();

    const itemID = parameters['id'];

    let redirectProp;
    const handleClick = (props) => {
        redirectProp = `/products/${props}`;

        return history?.push(redirectProp);
    };
    const pathSelected = window?.location?.pathname;

    return (
        <div className="flex flex-col">
            <div
                id="general-button"
                className={`flex font-poppins hover:bg-gray-200 text-gray-500 border-b-2 ${
                    pathSelected.includes(`/products/general-view`)
                        ? 'text-purple-500'
                        : 'text-gray-500'
                }`}
                role="button"
                tabIndex={0}
                onClick={() => handleClick(`general-view/${itemID}`)}
                onKeyDown={() => handleClick(`general-view/${itemID}`)}
            >
                <div className="m-4">General</div>
            </div>

            <div
                id="pricing-and-conversion-button"
                className={`flex  font-poppins hover:bg-gray-200 text-gray-500 border-b-2 ${
                    pathSelected.includes(`/products/pricing-view`)
                        ? 'text-purple-500'
                        : 'text-gray-500'
                }`}
                role="button"
                tabIndex={0}
                onClick={() => handleClick(`pricing-view/${itemID}`)}
                onKeyDown={() => handleClick(`pricing-view/${itemID}`)}
            >
                <div className="m-4">Pricing and Conversions</div>
            </div>
        </div>
    );
}
