export const isProd = false;

export const AUTH_API_URL = process.env.REACT_APP_AUTH_API;

export const GQL_USER_API_URL = process.env.REACT_APP_BACKEND_USER_API;

export const GQL_STORE_API_URL = process.env.REACT_APP_BACKEND_STORE_API;

export const GQL_UTIL_API_URL = process.env.REACT_APP_BACKEND_UTIL_API;

export const s3Config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: '',
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_s3_ACCESS_KEY,
};

export const awsSDKConfig = {
    accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_s3_ACCESS_KEY,
    region: process.env.REACT_APP_REGION,
};

export const awsSDKS3Params = {
    params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
    region: process.env.REACT_APP_REGION,
};

export const pagination = {
    pageIndex: 0,
    pageSize: 50,
    pageCount: 1,
};

export const SWAR_MVP_HIDE_FEATURE = process.env.REACT_APP_SWAR_MVP_HIDE_FEATURE;

export const ERROR_SERVER = 'ERROR';

export const ERROR_NETWORK = 'ServerParseError: Unexpected end of JSON input';

export const REVIEW_MISMATCHES = 'Review Mismatches';

export const DEAL_PRICES = 'Deal Prices';

export const PRODUCTS_EXLCUDE_URLS = ['/deal/products-edit'];
