import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getCookie } from '../../utils/cookiesService';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { logout, refreshAuthToken } from '../../redux/rootActions';

export default function LoginValidity() {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const token = getCookie('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    const [tokenExpiryTimeout, setTokenExpiryTimeout] = useState<NodeJS.Timeout | null>(null);

    const checkTokenExpiry = () => {
        if (token) {
            const decoded_token: any = jwtDecode(token);
            const currentTime = new Date().getTime();
            const tokenExpiryTime = decoded_token?.exp * 1000;
            // Refresh token 5 seconds before it expires
            const timeUntilExpiry = tokenExpiryTime - currentTime - 5000;
            if (timeUntilExpiry > 0) {
                // Set a timeout to refresh the token just before it expires
                setTokenExpiryTimeout(
                    setTimeout(() => {
                        dispatch(refreshAuthToken(refreshToken));
                    }, timeUntilExpiry),
                );
            } else {
                // Token already expired, log out user
                if (refreshToken) {
                    dispatch(refreshAuthToken(refreshToken));
                } else {
                    dispatch(logout());
                    history.push('/');
                }
            }
        }
    };

    useEffect(() => {
        if (token) {
            checkTokenExpiry();
        }

        // Clean up on unmount
        return () => {
            if (tokenExpiryTimeout) {
                clearTimeout(tokenExpiryTimeout);
            }
        };
    }, [dispatch, history, location, token, refreshToken]);

    return <></>;
}
