import { useHistory } from 'react-router';

interface Props {
    edit?: boolean;
    view?: boolean;
    sideBarElements?: any;
    add?: boolean;
}
export default function InnerSideBarElements({ edit, view, sideBarElements, add }: Props) {
    const history = useHistory();
    let redirectProp;
    const handleClick = (props) => {
        if (!sideBarElements) {
            redirectProp = `/products/${props}`;
        } else {
            redirectProp = props;
        }

        return history?.push(redirectProp);
    };
    const pathSelected = window?.location?.pathname;

    if (!sideBarElements) {
        return (
            <div className="flex flex-col h-screen">
                <div
                    className={`flex  font-poppins hover:bg-gray-200 text-gray-500  border-b-2 ${
                        pathSelected === `/products/inventory-add`
                            ? 'text-purple-500'
                            : 'text-gray-500'
                    }`}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        if (edit) {
                            handleClick('inventory-edit');
                        } else if (view) {
                            handleClick('inventory-view');
                        } else {
                            handleClick('inventory-add');
                        }
                    }}
                    onKeyDown={() => handleClick('inventory')}
                >
                    <div className="m-4">Supplier</div>
                </div>
                <div
                    id="general-button"
                    className={`flex  font-poppins hover:bg-gray-200 text-gray-500  border-b-2 ${
                        pathSelected === `/products/general-add`
                            ? 'text-purple-500'
                            : 'text-gray-500'
                    }`}
                    role="button"
                >
                    <div className="m-4">General</div>
                </div>
                <div
                    id="pricing-and-conversion-button"
                    className={`flex  font-poppins hover:bg-gray-200 text-gray-500 border-b-2 ${
                        pathSelected === `/products/pricing-add`
                            ? 'text-purple-500'
                            : 'text-gray-500'
                    }`}
                    role="button"
                    tabIndex={0}
                >
                    <div className="m-4">Pricing and Conversions</div>
                </div>
            </div>
        );
    } else {
        return sideBarElements?.map((element: any) => (
            <div className="flex flex-col" key={element?.path}>
                <div
                    id={`${element?.name}-button`}
                    className={`flex font-poppins hover:bg-gray-200 text-gray-500 border-b-2 ${
                        pathSelected === element.path ? 'text-purple-500' : 'text-gray-500'
                    }`}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        if (!add) {
                            handleClick(element?.path);
                        }
                    }}
                    onKeyDown={() => {
                        if (!add) {
                            handleClick(element?.path);
                        }
                    }}
                >
                    <div className="m-4">{element?.name}</div>
                </div>
            </div>
        ));
    }
}
