import React, { useEffect } from 'react';
import * as BsIcons from 'react-icons/bs';

interface Props {
    error?: boolean;
    message: string;
    setShowToast: any;
    width?: any;
    margin?: any;
    selfDestruct?: boolean;
    selfDestructTimer?: any;
    style?: any;
}

export default function Toast({
    error,
    message,
    setShowToast,
    width,
    margin,
    selfDestruct,
    selfDestructTimer,
    style,
}: Props) {
    useEffect(() => {
        if (selfDestruct) {
            setTimeout(() => {
                setShowToast(false);
            }, selfDestructTimer);
        }
    }, [selfDestructTimer, selfDestruct, setShowToast]);

    return (
        <div
            className={`z-50 -mt-8 h-16 flex flex-row fixed ${margin ? margin : 'm-auto'} pl-10 ${
                error
                    ? `bg-red-100 ${width ? width : 'w-9/12'} right-0`
                    : `bg-green-100 ${width ? width : 'w-9/12'} right-0`
            } mb-2 py-2 rounded-md items-center ${style}`}
        >
            <div
                className={`flex-1 pr-5 -ml-5 font-poppins ${
                    error ? 'text-red-700' : 'text-green-700'
                }`}
            >
                {message}
            </div>
            <div className="flex w-2/10 px-5">
                <button
                    onClick={() => {
                        setShowToast(false);
                    }}
                >
                    <BsIcons.BsX className="mx-auto text-lg" />
                </button>
            </div>
        </div>
    );
}
