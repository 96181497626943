export const PARTNER_ADD_EXIT_CONFIRM_MESSAGE =
    'Are you sure you want to cancel adding a new partner?';
export const PARTNER_PREFERENCE_ADD_EXIT_CONFIRM_TITLE = 'Sorry!';
export const PARTNER_PREFERENCE_ADD_EXIT_CONFIRM_MESSAGE =
    'You cannot proceed without updating the currency preferences with atleast one currency';
export const PARTNER_ADD_EXIT_CONFIRM_TITLE = 'Are you sure';
export const PARTNER_CURRENCY_PREFERENCE_EDIT_CANCEL =
    'Are you sure you want to cancel editing partner currency preferences?';
export const BASE_URL_AGREEMENT_UPLOAD_AWS_S3 = 'partner-agreement/';
export const INSTANT_EDIT_OPTION_VIEW_PARTNER = 'VIEW';
export const INSTANT_EDIT_OPTION_EDIT_PARTNER = 'EDIT';
export const INSTANT_EDIT_OPTION_TRASH_PARTNER = 'TRASH';
export const PARTNER_ADD_SUCCESS_TITLE = 'Success!';
export const PARTNER_ADD_SUCCESS_BODY = 'Successfully added a partner.';
export const PARTNER_EDIT_SUCCESS = 'Successfully edited a partner.';
export const PARTNER_ADD_POINTS_TABLE_TYPE = 'PARTNER_ADD_POINTS';
export const PRODUCT_POINTS_TABLE_TYPE = 'PRODUCT_ADD_POINTS';
export const SUPPLIER_PARTNER_POINTS_TABLE_TYPE = 'SUPPLIER_PARTNER_POINTS_TABLE_TYPE';
export const POINTS_OVERRIDE_SUCCESS_MESSAGE_PREFIX = 'Successfully SID';
export const POINTS_OVERRIDE_SUCCESS_MESSAGE_POSTFIX = ' supplier platform reward updated';
export const POINTS_OVERRIDE_NOT_COMPLETED_WARNING_TITLE = 'Error';
export const POINTS_OVERRIDE_NOT_COMPLETED_WARNING_MESSAGE =
    'Please update default conversion points for all suppliers!';
export const POINTS_OVERRIDE_FAILED_MESSAGE = 'Failed to updated points conversion rate';
export const PARTNER_EDIT_WARNING_TITLE = 'Warning!';
export const PARTNER_EDIT_WARNING_BODY =
    "You haven't made any changes if you want to go back to the partners page click cancel.";
export const DELETE_PARTNER_TITLE = 'Delete Partner';
export const DELETE_PARTNER_BODY = 'Do you want to delete this partner?';
export const getPartnerTrashedSuccessMessage = (partnerName) => {
    return `Successfully trashed partner ${partnerName}`;
};
export const getCategoryOrderUpdateSuccessMessage = (partnerName) => {
    return `Successfully updated the catalog category order setup for ${partnerName}`;
};
export const getCategoryOrderUpdateFailedMessage = (partnerName) => {
    return `Failed to update the catalog category order setup for ${partnerName}`;
};
export const PARTNER_EMPTY_TARGET_CURRENCY_ERROR =
    'Partner target currency preferences should not be empty';
export const PARTNER_CURRENCY_ADD_PATH = '/partner/currency-preferences-add';
export const GLOBAL_CURRENCY_NOT_SET_PARTNER_LEVEL_ERROR =
    "Could not update partner currency preference's conversion rate. Please set the global conversion value for this currency combination";
export const LOCAL_CURRENCY_CONVERSION_RATE_UPDATE_SUCCESS_MESSAGE =
    'Local currency conversion rate saved successfully';
export const LOCAL_CURRENCY_CONVERSION_RATE_UPDATE_ERROR_MESSAGE =
    'Failed to update local currency conversion rate';
export const LOCAL_CURRENCY_CONVERSION_RATE_FETCH_ERROR_MESSAGE =
    'Failed to fetch local currency conversion rate';
export const LOCAL_CURRENCY_CONVERSION_RATE_NOT_SET = 'Local currency not set';
export const PARTNER_CREATED_SUCCESSFULLY = 'Partner created successfully';
export const PARTNER_ACTIVATED_SUCCESSFULLY = 'Partner activated successfully';
export const PARTNER_SAVED_SUCCESSFULLY = 'Partner saved successfully';
