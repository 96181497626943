export const CONFIRMATION_UPDATE_PARTNER_PREFERENCE_TITLE = 'Update partner preference';
export const CONFIRMATION_UPDATE_GLOBAL_CONVERSION_RATE_TITLE = 'Update conversion rate?';
export const CONFIRMATION_DELETE_CONVERSION_RATE_TITLE = 'Delete conversion rate?';
export const CONFIRMATION_UPDATE_PARTNER_PREFERENCE_MESSAGE =
    'Are you sure want to update the partner preference?';
export const CONFIRMATION_UPDATE_GLOBAL_CONVERSION_RATE_MESSAGE =
    'Are you sure want to update the conversion rate of selected currencies?';
export const SUCCESS_REPONSE_SAVE_ITEM_PARTNER_CURRENCY_RATE =
    'Successfully updated conversion rate for partner: ';
export const FAILED_RESPONSE_SAVE_ITEM_PARTNER_CURRENCY_RATE =
    'Failed to update conversion rate, error: ';
export const CONFIRMATION_DELETE_CONVERSION_RATE_MESSAGE =
    'Are you sure want to delete the conversion rate of selected currencies?';
export const CURRENCY_RATE_RESET_CONFIRMATION_TITLE = 'Reset currency conversion rate?';
export const CURRENCY_RATE_RESET_CONFIRMATION_MESSAGE_TO_GLOBAL =
    'Are you sure want to reset overrided currency conversion rate to global conversion rate?';
export const CURRENCY_RATE_RESET_CONFIRMATION_MESSAGE_TO_PARTNER =
    "Are you sure want to reset overrided currency conversion rate to partner's conversion rate?";
export const CURRENCY_CONVERSION_RESET_SUCCESS = 'Successfully reset currency conversion rate of ';
export const CURRENCY_CONVERSION_RESET_FAILED =
    'Failed to reset currency conversion rate with error: ';
export const GLOBAL_CURRENCY_NOT_SET_ITEM_PARTNER_LEVEL_ERROR =
    'Could not update item-partner currency conversion rate. Please set the global conversion value for this currency combination';
export const MARKEDUP_PERCENTAGE_FOR_CURRENCY_NOT_SET_ERROR =
    'Could not update marked up percentage for currency. Please set the marked up percentage for currency for this partner first';
export const MARKEDUP_PERCENTAGE_FOR_POINTS_NOT_SET_ERROR =
    'Could not update marked up percentage for points. Please set the marked up percentage for points for this partner first';
export const MARKED_UP_PERCENTAGE_FOR_CURRENCY_RESET_CONFIRMATION_TITLE =
    'Reset Marked-up Percentage for Currency?';
export const MARKED_UP_PERCENTAGE_FOR_CURRENCY_CONFIRMATION_MESSAGE_TO_PARTNER =
    "Are you sure want to reset overrided marked-up percentage for currency rate to partner's marked-up percentage for currency rate?";
export const CURRENCY_MARKED_UP_PERCENTAGE_RESET_SUCCESS =
    'Successfully reset currency marked-up percentage of ';
export const CURRENCY_MARKED_UP_PERCENTAGE_RESET_FAILED =
    'Failed to reset currency marked-up percentage with error: ';
export const MARKED_UP_PERCENTAGE_FOR_POINTS_RESET_CONFIRMATION_TITLE =
    'Reset Marked-up Percentage for Points?';
export const MARKED_UP_PERCENTAGE_FOR_POINTS_CONFIRMATION_MESSAGE_TO_PARTNER =
    "Are you sure want to reset overrided marked-up percentage for points rate to partner's marked-up percentage for points rate?";
export const POINTS_MARKED_UP_PERCENTAGE_RESET_SUCCESS =
    'Successfully reset points marked-up percentage of ';
export const POINTS_MARKED_UP_PERCENTAGE_RESET_FAILED =
    'Failed to reset points marked-up percentage with error: ';
export const PSP_MARKED_UP_PERCENTAGE_RESET_SUCCESS =
    'Successfully reset psp marked-up percentage of ';
export const PSP_MARKED_UP_PERCENTAGE_RESET_FAILED =
    'Failed to reset psp marked-up percentage with error: ';
export const MARKEDUP_PERCENTAGE_FOR_PSP_NOT_SET_ERROR =
    'Could not update marked up percentage for PSP. Please set the marked up percentage for currency for this partner first';
export const MARKED_UP_PERCENTAGE_FOR_PSP_RESET_CONFIRMATION_TITLE =
    'Reset Marked-up Percentage for PSP?';
export const MARKED_UP_PERCENTAGE_FOR_PSP_CONFIRMATION_MESSAGE_TO_PARTNER =
    "Are you sure want to reset overrided marked-up percentage for PSP rate to partner's marked-up percentage for PSP rate?";
export const SUCCESS_REPONSE_SAVE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE =
    'Successfully updated PSP marked-up percentage for partner: ';
export const FAILED_REPONSE_SAVE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE =
    'Failed to update PSP marked-up percentage, error: ';
export const FINAL_MARKED_UP_PRICE_TITLE = 'Reset final marked up price';
export const FINAL_MARKED_UP_PRICE_MESSAGE =
    'Are you sure you want to reset the final marked up price?';
