import React, { useEffect, useState } from 'react';
import * as BsIcons from 'react-icons/bs';
import { ALLSERV, API, CSV, CSV_VOUCHERS } from '../../../constants/supplier';
import SearchBar from '../../ui/molecules/SearchBar';
import { DraggableDataTable } from '../../ui/organisms/DraggableDataTable';
import { getSupplierProductCode } from '../../../helpers/ItemHelpers.helpers';
import Select from 'react-select';
import {
    CATALOG_NO_OPTION_MESSAGE,
    NOT_MAPPED_CATALOG_NO_OPTION_MESSAGE,
} from '../../../constants/product';
import makeAnimated from 'react-select/animated';
import WarningMessageModal from '../../templates/modals/WarningMessageModal';

interface Props {
    groupItems: any;
    selectedCatalogs: any;
    handleCatalogsChange?: (value) => void;
    removeDeletedItems: (value) => void;
    orderProducts: (value) => void;
    setItemCatalogs?: any;
    itemCatalogs?: any;
    setIsDirty?: (value: boolean) => void;
    isDirty?: boolean;
}

export default function GroupSelectedItemsView({
    groupItems,
    selectedCatalogs,
    removeDeletedItems,
    orderProducts,
    setItemCatalogs,
    itemCatalogs,
    setIsDirty,
    isDirty,
}: Props) {
    const animatedComponents = makeAnimated();
    const [categoryItemsData, setCategoryItemsData] = useState([]);
    const [searchClicked, setSearchClicked] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [warningModalBody, setWarningModalBody] = useState('');
    const [warningModalTitle, setWarningModalTitle] = useState('');

    const colourStyles = {
        menuList: (styles) => ({
            ...styles,
            background: '#f3e8ff',
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused ? '#f3e8ff' : isSelected ? '#f3e8ff' : undefined,
            zIndex: 1,
        }),
        multiValue: (styles) => {
            return {
                ...styles,
                backgroundColor: '#c084fc',
            };
        },
        multiValueRemove: (styles) => ({
            ...styles,

            ':hover': {
                backgroundColor: '#c084fc',
                color: 'white',
            },
        }),
        menu: (base) => ({
            ...base,
            zIndex: 100,
            background: '#c084fc',
        }),
    };

    useEffect(() => {
        if (groupItems) {
            const items = [];
            groupItems?.forEach((item) => {
                const exists = items?.some((catItem) => catItem?.itemId === item?.itemId);
                if (!exists) items.push(item);
            });
            setCategoryItemsData(items);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupItems, setCategoryItemsData]);

    useEffect(() => {
        const items = [...groupItems];
        if (searchClicked && searchTerm && searchTerm?.length > 2) {
            const filteredList = items?.filter((item) => {
                return (
                    item?.itemName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    item?.itemId?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    item?.product?.productCode?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    item?.product?.productId?.toLowerCase().includes(searchTerm?.toLowerCase())
                );
            });
            setCategoryItemsData(filteredList);
        } else {
            setCategoryItemsData(items);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchClicked, searchTerm, setCategoryItemsData]);

    const productNames = groupItems
        ?.map((product) => product?.itemName)
        .concat(
            groupItems?.map((product) => product?.itemId),
            groupItems
                ?.map((product) => product?.product?.productCode || product?.product?.productId)
                .filter((code) => code != null),
        );

    const groupItemsColumns = React.useMemo(
        () => [
            {
                id: 'delete',
                accessor: '',

                Cell: function deleteProd({ row }) {
                    return (
                        <span
                            id={`${row?.original?.id}-delete`}
                            style={{
                                cursor: 'pointer',
                                color: '#a855f7',
                                position: 'absolute',
                                padding: '5px',
                            }}
                            role="button"
                            tabIndex={0}
                            onKeyDown={() => {
                                removeDeletedItems(row?.original?.id);
                            }}
                            onClick={() => {
                                removeDeletedItems(row?.original?.id);
                            }}
                        >
                            <BsIcons.BsTrash />
                        </span>
                    );
                },
            },
            {
                Header: 'Product ID',
                accessor: 'itemId',
            },
            {
                Header: 'Supplier Product Code',
                accessor: 'product',
                Cell: function showSupplierProductCode({ value }) {
                    const productCode = getSupplierProductCode(value);
                    return productCode;
                },
            },
            {
                Header: 'Product Name',
                accessor: 'itemName',
            },
            {
                Header: 'Product Origin',
                id: 'product.orderStrategy',
                accessor: (item) => {
                    if (item?.product?.orderStrategy === CSV_VOUCHERS) {
                        return CSV;
                    } else if (item?.product?.orderStrategy.startsWith('ALLSERV')) {
                        return ALLSERV;
                    } else {
                        return API;
                    }
                },
            },
            {
                Header: 'Product Type',
                accessor: 'product.productType',
            },
            {
                Header: 'Content Type',
                accessor: 'contentType',
            },
            {
                Header: 'Supplier',
                accessor: 'supplier.supplierName',
            },
            {
                Header: 'Stocks Status',
                accessor: 'isStockAvailable',
                Cell: function showBadge({ value }) {
                    const status = value ? 'In Stock' : 'Out of Stock';
                    return (
                        <span
                            style={{
                                minWidth:
                                    status === 'In Stock'
                                        ? '72px'
                                        : status === 'Out of Stock'
                                        ? '96px'
                                        : '',
                            }}
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                status === 'In Stock'
                                    ? 'bg-yellow-100 text-yellow-600'
                                    : status === 'Out of Stock'
                                    ? 'bg-red-100 text-red-600'
                                    : ''
                            }`}
                        >
                            {status}
                        </span>
                    );
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedCatalogs, itemCatalogs, setItemCatalogs],
    );

    const editGroupItemsColumns = React.useMemo(
        () => [
            {
                id: 'delete',
                accessor: '',

                Cell: function deleteProd({ row }) {
                    return (
                        <span
                            id={`${row?.original?.id}-delete`}
                            style={{
                                cursor: 'pointer',
                                color: '#a855f7',
                                position: 'absolute',
                                padding: '5px',
                            }}
                            role="button"
                            tabIndex={0}
                            onKeyDown={() => {
                                removeDeletedItems(row?.original?.id);
                            }}
                            onClick={() => {
                                removeDeletedItems(row?.original?.id);
                            }}
                        >
                            <BsIcons.BsTrash />
                        </span>
                    );
                },
            },
            {
                Header: 'Product ID',
                accessor: 'itemId',
            },
            {
                Header: 'Supplier Product Code',
                accessor: 'product',
                Cell: function showSupplierProductCode({ value }) {
                    const productCode = getSupplierProductCode(value);
                    return productCode;
                },
            },
            {
                Header: 'Product Name',
                accessor: 'itemName',
            },
            {
                Header: 'Product Origin',
                id: 'product.orderStrategy',
                accessor: (item) => {
                    if (item?.product?.orderStrategy === CSV_VOUCHERS) {
                        return CSV;
                    } else if (item?.product?.orderStrategy.startsWith('ALLSERV')) {
                        return ALLSERV;
                    } else {
                        return API;
                    }
                },
            },
            {
                Header: 'Product Type',
                accessor: 'product.productType',
            },
            {
                Header: 'Content Type',
                accessor: 'contentType',
            },
            {
                Header: 'Supplier',
                accessor: 'supplier.supplierName',
            },
            {
                Header: 'Stocks Status',
                accessor: 'isStockAvailable',
                Cell: function showBadge({ value }) {
                    const status = value ? 'In Stock' : 'Out of Stock';
                    return (
                        <span
                            style={{
                                minWidth:
                                    status === 'In Stock'
                                        ? '72px'
                                        : status === 'Out of Stock'
                                        ? '96px'
                                        : '',
                            }}
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                status === 'In Stock'
                                    ? 'bg-yellow-100 text-yellow-600'
                                    : status === 'Out of Stock'
                                    ? 'bg-red-100 text-red-600'
                                    : ''
                            }`}
                        >
                            {status}
                        </span>
                    );
                },
            },
            {
                Header: 'Catalogs',
                accessor: '',
                Cell: function showCatalog({ row }) {
                    const foundCatalogs = itemCatalogs?.find(
                        (itemCatalog) => itemCatalog?.id === row?.original?.id,
                    );

                    let catalogOptions = [];

                    if (foundCatalogs) {
                        catalogOptions = foundCatalogs?.catalogs?.map((catalog) => ({
                            label: catalog?.label || catalog?.catalogName,
                            value: catalog?.value || catalog?.catalogId,
                        }));
                    }

                    return (
                        <span className="d-inline-block z-999 py-3">
                            <Select
                                id={`${row?.original?.id}-select-catalog`}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                styles={colourStyles}
                                options={selectedCatalogs}
                                noOptionsMessage={() =>
                                    selectedCatalogs?.length > 0
                                        ? CATALOG_NO_OPTION_MESSAGE
                                        : NOT_MAPPED_CATALOG_NO_OPTION_MESSAGE
                                }
                                value={catalogOptions}
                                onChange={(list) => {
                                    const newCatalogIds =
                                        list?.map((catalog) => catalog?.value) || [];

                                    const removedCatalogs =
                                        row?.original?.mappedCatalogs?.filter(
                                            (catalog) =>
                                                !newCatalogIds.includes(catalog?.catalogId),
                                        ) || [];

                                    const isAvailableInDeal = removedCatalogs.some(
                                        (removedCatalog) => removedCatalog?.dealExist === true,
                                    );

                                    if (!isAvailableInDeal) {
                                        const updatedItemCatalogs = [
                                            ...(itemCatalogs?.filter(
                                                (itemCatalog) =>
                                                    itemCatalog?.id !== row?.original?.id,
                                            ) || []),
                                            { id: row?.original?.id, catalogs: list },
                                        ];

                                        setItemCatalogs(updatedItemCatalogs);

                                        if (setIsDirty && isDirty === false) {
                                            setIsDirty(true);
                                        }
                                    } else {
                                        setWarningModalTitle('Alert');
                                        if (removedCatalogs?.length > 1) {
                                            setWarningModalBody(
                                                'This item is already assigned to a deal(s) through any of these partners. You are not allowed to remove this catalog for this product.',
                                            );
                                        } else {
                                            setWarningModalBody(
                                                'This item is already assigned to a deal(s) through this partner. You are not allowed to remove this catalog for this product.',
                                            );
                                        }

                                        setShowWarningModal(true);
                                    }
                                }}
                                className="w-65"
                            />
                        </span>
                    );
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedCatalogs, itemCatalogs, setItemCatalogs],
    );

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
        }
    };

    return (
        <>
            <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3 text-gray-500 px-4 pb-4">
                <WarningMessageModal
                    showWarningModal={showWarningModal}
                    setShowWarningModal={setShowWarningModal}
                    warningModalBody={warningModalBody}
                    warningModalTitle={warningModalTitle}
                />
                <SearchBar
                    id="add-new-category-search-field"
                    options={productNames}
                    handleChange={(e, newVal, reason) => {
                        setSearchClicked(true);
                        handleChange(e, newVal, reason);
                    }}
                    placeHolder={'Search Product by Name or ID'}
                />
            </div>
            <div className="w-full">
                {categoryItemsData?.length ? (
                    <DraggableDataTable
                        columns={itemCatalogs ? editGroupItemsColumns : groupItemsColumns}
                        data={categoryItemsData}
                        setData={orderProducts}
                    />
                ) : (
                    <div className="m-auto flex flex-col ">
                        <div className="py-4 px-5 bg-purple-100 justify-between flex">
                            {groupItemsColumns?.map((column, i) => {
                                return (
                                    <div
                                        className="text-gray-600 font-poppins font-semibold flex ml-12"
                                        key={i}
                                    >
                                        {column?.Header}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="m-auto p-10">No data found.</div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                )}
            </div>
        </>
    );
}
