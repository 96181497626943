export enum FileUploadType {
    PRODUCTS_UPLOAD = 'PRODUCTS_UPLOAD',
    ORDER_FIELDS_UPLOAD = 'ORDER_FIELDS_UPLOAD',
}

export enum FileStatus {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    DELETED = 'DELETED',
    REJECTED = 'REJECTED',
}
