import { useEffect, useState } from 'react';
import Cron from 'react-cron-generator';
import { InputField } from '../../../ui/atoms/InputField';
import JobInfoViewer from './JobInfoViewer';
import '../../../../styles/cronscheduler.css';
import ReactSwitch from 'react-switch';

interface Props {
    initialJobInfo: any;
    handleAddNewJobInfo: (value) => void;
    handleSchedulerEnabled: (value) => void;
    handleRemoveJobClickParent?: () => void;
    schedulerInputInvalid: boolean;
}

export default function CronJobSchedulerView({
    initialJobInfo,
    handleAddNewJobInfo,
    handleSchedulerEnabled,
    handleRemoveJobClickParent,
    schedulerInputInvalid,
}: Props) {
    // Cron generator
    const [cronExp, setCronExp] = useState<string>('0 0 00 1/1 * ? *');
    const [jobInfo, setJobInfo] = useState(null);
    const [newJobInfo, setNewJobInfo] = useState(null);
    const [schedulerEnabled, setSchedulerEnabled] = useState(false);

    const supplierProductType = [
        {
            label: 'VOUCHER',
            value: 'VOUCHER',
        },
        {
            label: 'TOPUP',
            value: 'TOPUP',
        },
    ];

    const handleChange = (value) => {
        if (value) {
            setCronExp(value);
            const info = { ...newJobInfo };
            info.cronDefinition = value;
            setNewJobInfo(info);
        }
    };

    const handleJobNameChange = (value) => {
        const info = { ...newJobInfo };
        info.jobName = value;
        setNewJobInfo(info);
    };

    const handleDescriptionChange = (value) => {
        const info = { ...newJobInfo };
        info.description = value;
        setNewJobInfo(info);
    };

    const handleSupplierProductTypeChange = (value) => {
        const info = { ...newJobInfo };
        info.productType = value;
        setNewJobInfo(info);
    };

    useEffect(() => {
        if (initialJobInfo) {
            setJobInfo(initialJobInfo);
            setSchedulerEnabled(true);
        }
    }, [initialJobInfo]);

    useEffect(() => {
        if (newJobInfo?.jobName && newJobInfo?.cronDefinition) {
            handleAddNewJobInfo(newJobInfo);
        }
    }, [
        newJobInfo?.jobName,
        newJobInfo?.cronDefinition,
        newJobInfo?.description,
        handleAddNewJobInfo,
        newJobInfo,
    ]);

    useEffect(() => {
        if (!schedulerEnabled) {
            setNewJobInfo(null);
            handleAddNewJobInfo(null);
        }
        handleSchedulerEnabled(schedulerEnabled);
    }, [handleAddNewJobInfo, handleSchedulerEnabled, schedulerEnabled]);

    const handleToggle = (value) => {
        setSchedulerEnabled(value);
    };

    const handleRemoveJobClick = () => {
        if (handleRemoveJobClickParent) {
            handleRemoveJobClickParent();
        }
        setJobInfo(null);
    };

    return (
        <>
            <div className="font-poppins py-4 font-bold text-lg">Job Scheduler</div>
            <div className="flex pt-2 pb-2">
                <div className="grid grid-cols-8 gap-4">
                    <div className="col-start-1 col-end-3 font-poppins text-lg">
                        Enable Scheduling
                    </div>
                    <div className="col-start-3 col-span-1">
                        <ReactSwitch
                            checked={schedulerEnabled}
                            onChange={(e) => handleToggle(e)}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#8b5cf6"
                            height={24}
                            width={46}
                        />
                    </div>
                </div>
            </div>
            {schedulerEnabled ? (
                <>
                    {jobInfo ? (
                        <JobInfoViewer
                            jobInfo={jobInfo}
                            handleRemoveJobClick={handleRemoveJobClick}
                        />
                    ) : (
                        <div>
                            <div className="flex pb-4">
                                <div className="flex flex-col">
                                    <div className="grid grid-cols-8 gap-4">
                                        <div className="col-start-1 col-end-2">
                                            <InputField
                                                id="jobName"
                                                value={newJobInfo?.jobName || ''}
                                                name="Job Name *"
                                                onChange={(e) =>
                                                    handleJobNameChange(e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className="col-start-2 col-end-4">
                                            <div className="flex flex-wrap flex-col relative w-full">
                                                <div className="text-gray-500 w-40 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                                    Supplier Product Type
                                                </div>
                                                <select
                                                    className="px-2 py-3.5 rounded-xl outline-none border-2  bg-white border-gray-400 hover:border-purple-500"
                                                    id="supplier_type"
                                                    onChange={(e) => {
                                                        handleSupplierProductTypeChange(
                                                            e.target.value,
                                                        );
                                                    }}
                                                    onBlur={(e) => {
                                                        handleSupplierProductTypeChange(
                                                            e.target.value,
                                                        );
                                                    }}
                                                    value={newJobInfo?.productType || 'VOUCHER'}
                                                >
                                                    {supplierProductType.map(
                                                        (option: any, index) => {
                                                            return (
                                                                <option
                                                                    id={option.value}
                                                                    value={option.value}
                                                                    className={'font-medium'}
                                                                    key={index}
                                                                >
                                                                    {option.label}
                                                                </option>
                                                            );
                                                        },
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-start-4 col-end-6">
                                            <InputField
                                                id="cronExpression"
                                                value={cronExp || ''}
                                                readonly={true}
                                                name="Cron Expression"
                                            />
                                        </div>
                                        <div className="col-start-6 col-end-8">
                                            <InputField
                                                id="description"
                                                value={newJobInfo?.description || ''}
                                                name="Description"
                                                onChange={(e) =>
                                                    handleDescriptionChange(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                    {schedulerInputInvalid ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {`Job name is required`}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="flex pr-4 pb-4">
                                <Cron
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    showResultText={true}
                                    showResultCron={true}
                                />
                            </div>
                            <div className="flex w-full pb-4 font-poppins text-gray-500">
                                <p>
                                    Note: Cron expression syntaxs and cron expression online
                                    generator can be found{' '}
                                    <a
                                        href="https://www.freeformatter.com/cron-expression-generator-quartz.html"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        here
                                    </a>
                                    . <br />
                                    To add externally created cron expression move to custom tab.
                                </p>
                            </div>
                        </div>
                    )}
                </>
            ) : null}
        </>
    );
}
