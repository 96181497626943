export const INSTANT_VIEW = 'INSTANT_VIEW';
export const INSTANT_TRASH = 'INSTANT_TRASH';
export const INSTANT_RESTORE = 'INSTANT_RESTORE';
export const SUCCESS_CSV_UPLOAD = 'Successfully uploaded csv';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const VALIDATION_ERROR = 'There are validation errors, errors are listed below';
export const ERROR_MESSAGE = 'SORRY, SOMETHING WENT WRONG';
export const SUCCESS_CSV_CONFIRM = 'Successfully uploaded the products';
export const ERROR_CSV_UPLOAD_VALIDATION = 'There are draft products, please cancel or confirm it';
export const DELETE = 'Are you sure?';

export const RESTORE_SUPPLIER_TITLE = 'Restore Supplier';

export const RESTORE_SUPPLIER_BODY = 'Do you want to restore this supplier?';

export const DELETE_SUPPLIER_TITLE = 'Trash Supplier';

export const DELETE_SUPPLIER_BODY = 'Are you sure you want to trash this supplier?';

export const ALREADY_DELETED_WARNING_TITLE = 'SUPPLIER IS ALREADY DELETED';

export const UPDATE_SUPPLIER_TITLE = 'Cannot update supplier type';

export const ALREADY_DELETED_WARNING_BODY =
    'The supplier you are trying to delete has already been deleted.';

export const DELETE_PRODUCT_BODY = 'Do you want to delete this product?';

export const DELETE_VOUCHER_BODY = 'Do you want to delete this voucher?';

export const DELETE_VOUCHERS_BODY = 'Do you want to delete these vouchers?';

export const SUCCESS_MESSAGE_DELETE_PRODUCT = 'Successfully deleted the product';

export const SUCCESS_MESSAGE_DELETE_FILE = 'Successfully deleted the file';

export const SUCCESS_MESSAGE_DELETE_VOUCHER = 'Successfully deleted the voucher';

export const DELETE_FILE_BODY = 'Do you want to delete this file?';

export const DELETE_FILES_BODY = 'Do you want to delete these files?';

export const ERROR_CODE_HEADERS = 1026;

export const SUCCESS_MESSAGE_DELETE_PRODUCT_UNPUBLISHED = `The item(s) that attached to this product will be trashed.  \n Are you sure you want to continue? `;

export const SUCCESS_MESSAGE_DELETE_FILE_UNPUBLISHED = `The item(s) that attached to this file will be un-published.  \n Are you sure you want to continue? `;

export const VOUCHER_STATUS_AVAILABLE = 'AVAILABLE';

export const INSTANT_EDIT = 'INSTANT_EDIT';

export const CSV_VOUCHERS = 'CSV_VOUCHERS';

export const ALLSERV_EPIN_API = 'ALLSERV_EPIN_API';

export const CSV = 'CSV';

export const SEAGM = 'SEAGM';

export const UNIPIN = 'UNIPIN';

export const ALLSERV = 'ALLSERV';

export const BUKALAPAK = 'BUKALAPAK';

export const CONFIGURATIONS_ERROR_MESSAGE =
    'Invalid configurations . Please check the API configuration values to establish communications with supplier.';

export const PRODUCT_UPDATING_ERROR_MESSAGE = 'There was an error while updating the products';

export const API = 'API';
export const SUCCESS_MESSAGE_PRODUCT_UPDATE = 'Successfully updated products';

export const VIEW_SUPPLIER_PAGE = 'VIEW_SUPPLIER_PAGE';

export const getSupplierProductsWarningMessage = (count) => {
    return `There is/are ${count} product/s associated with this supplier and trashing this supplier would trash all those products. Are you sure you want to continue?`;
};

export const getEditSupplierProductsWarningMessage = (count) => {
    return `There is/are ${count} product/s associated with this supplier. You cannot make changes to the supplier type`;
};

export const API_SUPPLIER_TOPUP_CONFIG_CSV_MISSING_FIELDS_ERROR_CODE = 1040;

export const API_SUPPLIER_TOPUP_CONFIG_CSV_FILE_FORMAT_ERROR_CODE = 1025;

export const API_SUPPLIER_TOPUP_CONFIG_CSV_FILE_MISMATCHES_MESSAGE =
    'We have found a mismatch between the product codes in the system and the CSV file. Do you still want to proceed with the file upload?';

export const TOPUP_CONFIG_CSV_VALIDATION_ERROR =
    'The uploaded CSV file is invalid. Kindly review and reupload';

export const MISSING_FIELDS_TITLE = 'Missing fields';

export const INVALID_FILE_FORMAT_TITLE = 'Invalid File Format';

export const FILE_SIZE_EXCEED_TITLE = 'File Size Exceed';

export const FILE_SIZE_EXCEED_ERROR = 'The uploaded file exceeds the maximum size limit';

export const INVALID_FILE_FORMAT_ERROR = 'The uploaded file format is invalid';

export const PRODUCT_UPDATE_TIMEOUT_MESSAGE =
    'Updating the product list may take few moments. Please check back in few minutes.';
