import SideBarElements from './SideBarElements';

export default function SideBar() {
    return (
        <div className="w-full bg-gray-900 h-screen text-white">
            <div className=" pb-2 border-b-2 border-gray-50">
                <img className="mx-auto  h-16" src="/images/icons/swarmioIcon.svg" alt="question" />
            </div>
            <SideBarElements />
        </div>
    );
}
