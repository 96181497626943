import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { updateNewUserPassword } from '../../../redux/rootActions';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import jwt from 'jwt-decode';
import passwordGenerator from '../../../service/encryption/passwordGenerator';
import { Buttons } from '../../ui/atoms/Button';

export default function ResetNewUserPasswordScreen() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const location = useLocation();
    const [password, setPassword] = useState('');

    const passwordShow = () => {
        setShowPassword(!showPassword);
    };

    const token = location?.search.slice(5);
    const tokenDetails: any = jwt(token);

    const validationSchema = Yup.object({
        password: Yup.string()
            .required('Please Enter your password')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{12,})/,
                'Your password should contain at least twelve characters with upper and lower case letters, numbers and symbols like !@#$%^',
            ),
        repeatPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });

    useEffect(() => {
        const generatedPassword = passwordGenerator();
        setPassword(generatedPassword);
    }, []);

    return (
        <div className="container h-screen w-screen">
            <div
                className="flex bg-cover bg-center   h-full w-full  md:h-screen md:w-screen  bg-no-repeat"
                style={{ backgroundImage: `url(/images/background.svg)` }}
            >
                <div className="container flex  bg-white flex-wrap flex-col max-w-2xl sm:m-auto  md:rounded-md md:mt-36 lg:mt-20 xl:mt-28 2xl:mt-auto ">
                    <div className="mb-7 md:mb-8 min-h-6 lg:mx-12 max-w-2xl">
                        <img className="w-28 mx-auto  mt-14" src="/images/favicon.png" alt=""></img>

                        <div className=" flex flex-col flex-wrap justify-center -mt-4">
                            <div className="m-auto font-extrabold lg:text-2xl font-poppins sm:text-xl  justify-center mb-4">
                                ENTER YOUR PASSWORD
                            </div>

                            <div className="m-auto  text-gray-400 font-poppins flex flex-col text-xs md:text-sm mb-8 md:mb-3">
                                <p className=" m-auto justify-center hidden md:block">
                                    The password should be atleast twelve characters long.
                                </p>
                                <p className=" m-auto justify-center md:hidden ">
                                    The password should be atleast twelve
                                </p>
                                <p className=" m-auto justify-center md:hidden">characters long.</p>

                                <p className=" m-auto  justify-center">
                                    To make it stronger,use upper and lower case letters,
                                </p>
                                <p className=" m-auto  justify-center">
                                    numbers and symbols like !@#$%^*
                                </p>
                            </div>
                        </div>

                        <Formik
                            initialValues={{
                                password: password,
                                repeatPassword: '',
                            }}
                            enableReinitialize
                            validationSchema={validationSchema}
                            onSubmit={async ({ password }) => {
                                dispatch(updateNewUserPassword(password, tokenDetails?.sub));
                                history.push('/');
                            }}
                        >
                            {({ values, errors, touched, handleChange, handleSubmit }) => (
                                <form
                                    onSubmit={(event) => {
                                        event.preventDefault();
                                        handleSubmit();
                                    }}
                                >
                                    <div className="flex flex-col flex-wrap mt-5 md:mt-1 mx-10">
                                        <div className="flex flex-col mt-4 mb-1">
                                            <div className="text-gray-500 text-sm  w-max font-poppins  h-max bg-white z-30 ml-4">
                                                Enter your password
                                            </div>

                                            <div className="relative">
                                                <input
                                                    className="p-3 pt-3 md:p:3  w-full rounded-xl outline-none -mt-3 font-poppins bg-gray-1f00  border-2 border-gray-400  hover:border-purple-500"
                                                    id="password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    placeholder="Admin123"
                                                    onChange={handleChange('password')}
                                                    value={values.password}
                                                />
                                                {errors.password && touched.password ? (
                                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                        {errors.password}
                                                    </div>
                                                ) : null}

                                                <div className="absolute inset-y-0 right-0 pr-3 flex text-base item-center">
                                                    <img
                                                        src="/images/icons/eye 1.png"
                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                            showPassword ? 'block' : 'hidden'
                                                        }`}
                                                        onClick={passwordShow}
                                                        alt="show-password"
                                                        role="presentation"
                                                        draggable="false"
                                                    />

                                                    <img
                                                        src="/images/icons/eye-hide.png"
                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                            showPassword ? 'hidden' : 'block'
                                                        }`}
                                                        alt="hide-password"
                                                        role="presentation"
                                                        onClick={passwordShow}
                                                        draggable="false"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col mt-5 mb-6">
                                            <div className="text-gray-500 text-sm  w-max font-poppins  h-max bg-white z-30 ml-4">
                                                Repeat your password
                                            </div>

                                            <div className="relative">
                                                <input
                                                    className="p-3 pt-3 md:p:3  w-full rounded-xl outline-none -mt-3 font-poppins bg-gray-1f00  border-2 border-gray-400  hover:border-purple-500"
                                                    id="repeatPassword"
                                                    type={showPassword ? 'text' : 'password'}
                                                    placeholder="Admin123"
                                                    onChange={handleChange('repeatPassword')}
                                                    value={values.repeatPassword}
                                                />
                                                {errors.repeatPassword && touched.repeatPassword ? (
                                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                        {errors.repeatPassword}
                                                    </div>
                                                ) : null}

                                                <div className="absolute inset-y-0 right-0 pr-3 flex text-base item-center">
                                                    <img
                                                        src="/images/icons/eye 1.png"
                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                            showPassword ? 'block' : 'hidden'
                                                        }`}
                                                        onClick={passwordShow}
                                                        alt="show-password"
                                                        role="presentation"
                                                        draggable="false"
                                                    />

                                                    <img
                                                        src="/images/icons/eye-hide.png"
                                                        className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                            showPassword ? 'hidden' : 'block'
                                                        }`}
                                                        alt="hide-password"
                                                        role="presentation"
                                                        onClick={passwordShow}
                                                        draggable="false"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="pt-5 flex align-middle justify-center xl:mb-10">
                                            <Buttons
                                                name="Reset Password"
                                                type="submit"
                                                buttonType="primary"
                                                id="resetPassword"
                                                size="large"
                                                padding="py-1"
                                            />
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}
