/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import UploadDocumentComponent from './UploadDocumentComponent';
import { Modal } from 'semantic-ui-react';
import DataTable from '../../ui/organisms/DataTable';
import { BASE_URL_CSV_AWS_S3 } from '../../../constants/common';
import { Buttons } from '../../ui/atoms/Button';
import { useMutation } from '@apollo/client';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import SuccessMessageModal from './SuccessMessageModal';
import {
    ERROR,
    ERROR_CODE_HEADERS,
    ERROR_MESSAGE,
    SUCCESS,
    SUCCESS_CSV_UPLOAD,
    VALIDATION_ERROR,
} from '../../../constants/supplier';
import { useHistory } from 'react-router-dom';
import WarningMessageModal from './WarningMessageModal';
import Loader from '../../../utils/loader';
import { PROCESS_CSV, SAVE_CSV } from '../../../queries/CsvQueries';
import { pagination } from '../../../constants/config/constants';
import { FileUploadType } from '../../../enums/csvFile';

interface Props {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    supplierId?: any;
    setDraftData?: any;
    setErrorData?: any;
    setFilesName?: any;
    setFileData?: any;
    setIsValidationError?: (value: boolean) => void;
    setIsProductsUploadSuccess?: (value: boolean) => void;
}

export default function UploadCsv({
    showModal,
    setShowModal,
    supplierId,
    setDraftData,
    setFilesName,
    setErrorData,
    setFileData,
    setIsValidationError,
    setIsProductsUploadSuccess,
}: Props) {
    const [fileLocation, setFileLocation] = useState(null);
    const [file, setFile] = useState(null);
    const [maxSize, setMaxSize] = useState(20);
    const [isComplete, setIsComplete] = useState(false);
    const [processFinishedClicked, setProcessFinishedClicked] = useState(false);
    const [warningFinishedClicked, setWarningFinishedClicked] = useState(false);
    const [uploadCSVSuccess, setUploadCSVSucess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [pageSize, setPageSize] = useState(pagination?.pageSize);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [completed, setCompleted] = useState(0);
    const [fileName, setFileName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const timestamp = new Date().valueOf();
    const [errorTitle, setErrorTitle] = useState(ERROR);

    const [processCSVFile, { loading: prcoessQueryLoading, error: processQueryError }] =
        useMutation(PROCESS_CSV, {
            context: { clientName: ClientsEnum.STORE },
            onCompleted: (data) => {
                setDraftData(data?.processCSVFile);
                setLoading(false);
                setTimeout(() => {
                    if (setIsProductsUploadSuccess) {
                        setIsProductsUploadSuccess(true);
                    }
                    setUploadCSVSucess(true);
                    setShowModal(true);
                }, 1000);
            },
            onError: (error) => {
                setLoading(false);
                if (setIsValidationError) {
                    setIsValidationError(true);
                }

                setErrorMessage(
                    error?.graphQLErrors[0]?.extensions?.errorCode !== ERROR_CODE_HEADERS
                        ? VALIDATION_ERROR
                        : error?.message,
                );
                setShowWarningModal(true);
                setErrorData(
                    error?.graphQLErrors[0]?.extensions?.errorCode !== ERROR_CODE_HEADERS
                        ? JSON.parse(error.message)
                        : null,
                );
            },
        });
    const [saveCSVFiles, { loading: csvQueryLoading, error: csvQueryError }] = useMutation(
        SAVE_CSV,
        {
            context: { clientName: ClientsEnum.STORE },
            onCompleted: (data) => {
                if (data?.saveCSVFile?.id) {
                    setFileData(data?.saveCSVFile?.id);
                    processCSVFile({
                        variables: {
                            fileId: data?.saveCSVFile?.id,
                        },
                    });
                }
            },
            onError: (error) => {
                setLoading(false);
                setShowWarningModal(true);
            },
        },
    );

    const csvFiles = {
        url: fileLocation,
        fileName: file?.name,
        fileSize: file?.size,
        fileType: file?.type,
    };

    useEffect(() => {
        if (csvFiles?.fileName) {
            setFilesName(csvFiles?.fileName);
        }
    }, [csvFiles?.fileName]);

    useEffect(() => {
        if (isComplete && supplierId && csvFiles?.url && file?.name) {
            setLoading(true);
            saveCSVFiles({
                variables: {
                    supplierId: supplierId,
                    fileUrl: csvFiles?.url,
                    fileName: file?.name,
                    fileUploadType: FileUploadType.PRODUCTS_UPLOAD,
                },
            });
        }
    }, [isComplete, supplierId, csvFiles?.url, file?.name]);

    useEffect(() => {
        if (processFinishedClicked) {
            setTimeout(() => {
                if (!prcoessQueryLoading && !csvQueryLoading) {
                    setUploadCSVSucess(false);
                    setShowModal(false);
                }
            }, 1000);
            setTimeout(() => {
                if (!prcoessQueryLoading && !csvQueryLoading) {
                    setShowModal(false);
                }
            }, 100);
            setProcessFinishedClicked(false);
        }
    }, [processFinishedClicked, prcoessQueryLoading, csvQueryLoading]);

    useEffect(() => {
        if (warningFinishedClicked) {
            setTimeout(() => {
                setShowWarningModal(false);
                setShowModal(false);
                setWarningFinishedClicked(false);
            }, 1000);
        }
    }, [warningFinishedClicked]);

    useEffect(() => {
        setFileName(BASE_URL_CSV_AWS_S3 + timestamp + 1);
    }, []);

    return (
        <Modal
            open={showModal}
            onClose={() => {
                setShowModal(false);
            }}
            center={true}
            focusTrapped={false}
            size={'large'}
            className="p-10"
        >
            <div className="pl-8 pr-2 pt-2 p-10">
                {csvQueryLoading ? (
                    <Loader />
                ) : prcoessQueryLoading ? (
                    <Loader />
                ) : loading ? (
                    <Loader />
                ) : (
                    <UploadDocumentComponent
                        isEdit={false}
                        setFile={setFile}
                        maxSize={maxSize}
                        uploadDocTypeHint={'You can upload your files in csv format.'}
                        s3DirName={fileName ? fileName : BASE_URL_CSV_AWS_S3 + timestamp + 1}
                        setFileLocation={setFileLocation}
                        setCompletedCsv={setCompleted}
                        isCsv={true}
                        setIsComplete={setIsComplete}
                        setErrorMessage={setErrorMessage}
                        setErrorTitle={setErrorTitle}
                    />
                )}
            </div>
            <SuccessMessageModal
                showSuccessModal={uploadCSVSuccess}
                setShowSuccessModal={setUploadCSVSucess}
                successModalTitle={SUCCESS}
                successModalBody={SUCCESS_CSV_UPLOAD}
                setProcessFinishedClicked={setProcessFinishedClicked}
            />
            <WarningMessageModal
                showWarningModal={showWarningModal}
                setShowWarningModal={setShowWarningModal}
                warningModalBody={errorMessage ? errorMessage : ERROR_MESSAGE}
                warningModalTitle={ERROR}
                setProcessFinishedClicked={setWarningFinishedClicked}
            />
            <div className="mt-12 justify-end flex">
                <Buttons
                    name="Close"
                    buttonType="secondary-main-grey"
                    id="close-button"
                    size="e-small"
                    padding="py-2 mr-4"
                    onclick={() => {
                        setShowModal(false);
                    }}
                />
            </div>
        </Modal>
    );
}
