import Modal from 'react-responsive-modal';
import NormalLoader from '../../../utils/normalLoader';
import DataTable from '../../ui/organisms/DataTable';

interface Props {
    showConfirmationModal: boolean;
    setShowConfirmationModal: (value: boolean) => void;
    message?: any;
    messageTitle?: any;
    setYesBtnClick?: (value: boolean) => void;
    setNoBtnClick?: (value: boolean) => void;
    yesButtonName?: string;
    noButtonName?: string;
    isProcessing?: boolean;
    columns: any;
    data: any;
}

export default function ConfirmationModalWithTable({
    showConfirmationModal,
    setShowConfirmationModal,
    message,
    messageTitle,
    setYesBtnClick,
    setNoBtnClick,
    yesButtonName,
    noButtonName,
    isProcessing,
    columns,
    data,
}: Props) {
    return (
        <div>
            <Modal
                open={showConfirmationModal}
                onClose={() => {
                    setShowConfirmationModal(false);
                }}
                center={true}
                styles={{
                    modal: { borderRadius: 15, maxWidth: '786px', width: '70%' },
                }}
                focusTrapped={false}
                closeOnOverlayClick={false}
            >
                <div className="flex justify-center ">
                    <h3 className="text-center font-bold font-poppins p-4">{messageTitle || ''}</h3>
                </div>

                <div className="flex flex-col text-center font-poppins justify-center border-t-2">
                    <div
                        data-testid="message"
                        className="px-12 py-4 font-poppins whitespace-pre-wrap"
                    >
                        {message || ''}
                    </div>
                </div>
                <div className="w-full">
                    <div className="m-auto flex flex-col">
                        <div className="rounded-md">
                            <DataTable
                                columns={columns}
                                data={data}
                                setPageIndex={0}
                                pageCount={1}
                                pageSizes={5}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex space-x-2 px-4 py-4 justify-end">
                    <button
                        className="bg-transparent  text-purple-500 rounded-md px-12 py-3 font-poppins text-center border-2 border-purple-500"
                        onClick={() => {
                            setNoBtnClick(true);
                        }}
                        onBlur={() => {
                            setNoBtnClick(true);
                        }}
                    >
                        {noButtonName || 'No'}
                    </button>
                    <button
                        className="bg-transparent bg-purple-500 text-white  rounded-md px-12 py-3 font-poppins text-center border-2 border-purple-500 hover:bg-purple-500 hover:text-white"
                        onClick={() => {
                            setYesBtnClick(true);
                        }}
                        onBlur={() => {
                            setYesBtnClick(true);
                        }}
                    >
                        {isProcessing ? <NormalLoader /> : yesButtonName || 'Yes'}
                    </button>
                </div>
            </Modal>
        </div>
    );
}
