import { useMutation, useQuery } from '@apollo/client';
import * as Yup from 'yup';
import { useState } from 'react';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import { FETCH_SUPPLIER_CONFIGURATIONS } from '../../../../queries/SupplierQueries';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import NormalLoader from '../../../../utils/normalLoader';
import { Buttons } from '../../../ui/atoms/Button';
import { InputField } from '../../../ui/atoms/InputField';
import {
    ADD_PAYMENT_CHANNEL,
    FETCH_PAYMENT_CHANNELS,
    GET_PAYMENT_CHANNEL_BY_ID,
} from '../../../../queries/PaymentChannelQueries';
import {
    ERROR_ADD_PAYMENT_CHANNEL,
    SUCCESS_EDIT_PAYMENT_CHANNEL,
} from '../../../../constants/paymentChannels';
import Toast from '../../../ui/atoms/Toast';
import { useDispatch } from 'react-redux';
import { savePaymentChannelId, savePaymentChannelName } from '../../../../redux/rootActions';
import Loader from '../../../../utils/loader';

export default function EditPaymentChannelScreen() {
    const history = useHistory();
    const parameters = useParams();
    const dispatch = useDispatch();
    const [paymentMethod, setPaymentMethod] = useState<string>(null);
    const [showToast, setShowToast] = useState(false);
    const [descriptionLong, setDescriptionLong] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [uniqueNameError, setUniqueNameError] = useState(false);
    const [uniqueIdError, setUniqueIdError] = useState(false);
    const paymentChannelId = parameters['id'];

    useQuery(FETCH_SUPPLIER_CONFIGURATIONS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        context: { clientName: ClientsEnum.STORE },
    });

    const { data: paymentChannelQueryData, loading: paymentChannelLoading } = useQuery(
        GET_PAYMENT_CHANNEL_BY_ID,
        {
            variables: { paymentChannelId: paymentChannelId },
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: () => {
                dispatch(
                    savePaymentChannelName(paymentChannelQueryData?.paymentChannel?.channelName),
                );
                dispatch(savePaymentChannelId(paymentChannelQueryData?.paymentChannel?.id));
                setPaymentMethod(paymentChannelQueryData?.paymentChannel?.paymentMethod);
            },
        },
    );

    const paymentChannel = paymentChannelQueryData?.paymentChannel;

    const [savePaymentChannel, { loading: queryLoading }] = useMutation(ADD_PAYMENT_CHANNEL, {
        refetchQueries: [FETCH_PAYMENT_CHANNELS],
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        onCompleted: () => {
            setMessage(SUCCESS_EDIT_PAYMENT_CHANNEL);
            setShowToast(true);
            setTimeout(() => {
                history.push('/payment-channels');
            }, 2000);
        },
        onError: (error: any) => {
            const graphQLErrors = error.graphQLErrors;

            if (graphQLErrors && graphQLErrors.length > 0) {
                if (graphQLErrors[0].extensions.errorCode === 1036) {
                    if (graphQLErrors[0].extensions.errorMessage.includes('channel ID')) {
                        setUniqueIdError(true);
                    } else if (graphQLErrors[0].extensions.errorMessage.includes('channel name')) {
                        setUniqueNameError(true);
                    }
                }
            } else {
                setMessage(ERROR_ADD_PAYMENT_CHANNEL);
                setShowToast(true);
                setError(true);
            }
        },
    });

    const validationSchema = Yup.object({
        paymentId: Yup.string().trim().required('Payment ID is required'),
        name: Yup.string().trim().required('Payment name is required'),
    });

    if (paymentChannelLoading) return <Loader />;

    return (
        <Formik
            initialValues={{
                paymentId: paymentChannel?.channelId,
                name: paymentChannel?.channelName,
                description: paymentChannel?.description,
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={({ paymentId, name }) => {
                setError(false);
                setUniqueIdError(false);
                setUniqueNameError(false);
                savePaymentChannel({
                    variables: {
                        paymentChannel: {
                            id: paymentChannelId,
                            channelId: paymentId,
                            channelName: name,
                            description: descriptionLong,
                            paymentMethod: paymentMethod,
                        },
                    },
                });
            }}
        >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
                <>
                    {showToast && (
                        <Toast
                            setShowToast={setShowToast}
                            message={message}
                            width="w-10/12"
                            margin="ml-1"
                            error={error}
                        />
                    )}
                    <div className="h-full flex flex-col justify-between pl-9">
                        <div className="flex items-start justify-between p-5 ">
                            <div className="text-lg font-poppins font-bold  z-30">
                                Add New Payment Channel
                            </div>
                        </div>

                        <div className="-mt-5 flex flex-col">
                            <div className=" flex-wrap flex-col px-4 grid grid-cols-8 gap-4 mt-5">
                                <div className="flex flex-wrap col-start-1 col-end-3 flex-col lg:mb-5">
                                    <InputField
                                        placeHolder="ID"
                                        name="ID"
                                        id="paymentId"
                                        labelWidth="w-28"
                                        onChange={(event) => {
                                            if (uniqueIdError === true) {
                                                setUniqueIdError(false);
                                            }
                                            handleChange(event);
                                        }}
                                        value={values.paymentId}
                                    />
                                    {errors.paymentId && touched.paymentId ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {errors.paymentId}
                                        </div>
                                    ) : null}
                                    {uniqueIdError ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            Channel ID already exists
                                        </div>
                                    ) : null}
                                    {(errors.paymentId && touched.paymentId) || uniqueIdError
                                        ? window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                        : null}
                                </div>
                                <div className="flex flex-wrap flex-col lg:mb-5 col-start-1 col-end-3">
                                    <InputField
                                        placeHolder="Name"
                                        name="Name"
                                        id="name"
                                        labelWidth="w-28"
                                        onChange={(event) => {
                                            if (uniqueNameError === true) {
                                                setUniqueNameError(false);
                                            }
                                            handleChange(event);
                                        }}
                                        value={values.name}
                                    />

                                    {errors.name && touched.name ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {errors.name}
                                        </div>
                                    ) : null}
                                    {uniqueNameError ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            Channel Name already exists
                                        </div>
                                    ) : null}
                                    {(errors.name && touched.name) || uniqueNameError
                                        ? window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                        : null}
                                </div>
                                <div className="flex flex-wrap flex-col relative lg:mb-5 z-20 col-start-1 col-end-3">
                                    <div className="text-gray-500 w-16 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Method
                                    </div>
                                    <select
                                        value={paymentMethod}
                                        disabled={true}
                                        className="selectdropdownIcon p-4 lg:p-3 lg:py-4  rounded-xl outline-none border-2  bg-white border-gray-400"
                                        id="product-type"
                                        onChange={(e) => {
                                            setPaymentMethod(e.target.value);
                                        }}
                                        onBlur={(e) => {
                                            setPaymentMethod(e.target.value);
                                        }}
                                    >
                                        <option value={null} disabled selected>
                                            Select payment method
                                        </option>
                                        <option value="DCB">DCB</option>
                                        <option value="PSP">PSP</option>
                                    </select>
                                </div>
                                <div className="col-start-1 col-end-4">
                                    <div className="col-start-1 col-end-9 flex flex-wrap relative">
                                        <div
                                            className={`text-gray-500  w-24 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20`}
                                        >
                                            Description
                                        </div>
                                        <textarea
                                            className="p-4 z-0 min-w-full rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                            id="groupDescription"
                                            name="groupDescription"
                                            rows={3}
                                            cols={138}
                                            draggable={false}
                                            maxLength={300}
                                            onInput={handleChange('description')}
                                            onChange={(e) => {
                                                setDescriptionLong(e.target.value);
                                            }}
                                            value={values?.description}
                                            placeholder="Enter payment description"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex w-10/12 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0">
                            <Buttons
                                name="Cancel"
                                type="button"
                                buttonType="secondary-border-black"
                                id="cancel"
                                size="small"
                                other="mr-3"
                                onclick={() => history.push('/payment-channels')}
                            />
                            <Buttons
                                name={queryLoading ? <NormalLoader /> : 'Save'}
                                type="submit"
                                buttonType="primary"
                                id="edit-payment-channel"
                                size="small"
                                padding="p-1"
                                onclick={() => {
                                    if (!queryLoading) {
                                        handleSubmit();
                                    }
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
