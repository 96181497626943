import { gql } from '@apollo/client';

export const FETCH_CATALOG = gql`
    query {
        catalogs {
            catalogId
            catalogName
            createdBy
            createdDate
            partner {
                partnerName
                isActive
            }
        }
    }
`;
