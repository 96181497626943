import authReducer, { AuthInitialState } from './reducers/authReducer';
import { combineReducers } from 'redux';
import { LOGOUT } from '../enums/redux/redux-enum';
import geoLocationReducer, { geoLocationState } from './reducers/geoLocationReducer';
import storeReducer, { storeState } from './reducers/storeReducer';
import orderListSortReducer, { orderListSortState } from './reducers/orderListSortReducer';
import itemIDReducer, { itemIDState } from './reducers/itemIDReducer';

export interface RootState {
    auth: AuthInitialState;
    geoLocation: geoLocationState;
    store: storeState;
    sortState: orderListSortState;
    itemIDState: itemIDState;
}

const appReducer = combineReducers({
    auth: authReducer,
    geoLocation: geoLocationReducer,
    store: storeReducer,
    sortState: orderListSortReducer,
    itemIDState: itemIDReducer,
});

const rootReducer = (state: any, action: any) => {
    if (action.type === LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
