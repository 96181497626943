import generator from 'generate-password';

export default function passwordGenerator() {
    const generatedPassword = generator.generate({
        length: 14,
        lowercase: true,
        exclude: '<\\>',
        strict: true,
        uppercase: true,
        numbers: true,
        symbols: true,
        excludeSimilarCharacters: true,
    });
    return generatedPassword;
}
