export function roundToNearest(valueToRound, valueToRoundTo) {
    return Math.ceil(valueToRound / valueToRoundTo) * valueToRoundTo;
}

export function roundToUpperBoundary(valueToRound, valueToRoundTo) {
    return Math.ceil(valueToRound * valueToRoundTo) / valueToRoundTo;
}

export function roundToLowerBoundary(valueToRound, valueToRoundTo) {
    return Math.floor(valueToRound * valueToRoundTo) / valueToRoundTo;
}
