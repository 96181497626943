/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Modal } from 'react-responsive-modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import jwt from 'jwt-decode';
import {
    FETCH_ADMIN_ONE_PROFILE,
    FETCH_ADMIN_PROFILE,
    SEND_VERIFICATION_EMAIL,
    UPDATE_ADMIN_PROFILE,
} from '../../../queries/AdminProfileQueries';
import { Buttons } from '../../ui/atoms/Button';
import { Images } from '../../ui/atoms/Images';
import { InputField } from '../../ui/atoms/InputField';
import { getCookie } from '../../../utils/cookiesService';
import Loader from '../../../utils/loader';
import NormalLoader from '../../../utils/normalLoader';
import { ERROR } from '../../../constants/users';

interface Props {
    showEmailModal: boolean;
    setShowEmailModal: (value: boolean) => void;
    setShowVerifyModal: (value: boolean) => void;
}

export default function ChangeEmailModal({
    showEmailModal,
    setShowEmailModal,
    setShowVerifyModal,
}: Props): ReactElement {
    const [verificationError, setVerificationError] = useState('');
    const token = getCookie('access_token');
    const tokenDetails: any = token ? jwt(token) : null;
    const [sentEmailSuccess, setSentEmailSuccess] = useState(false);
    const [usersData, setUsersData] = useState([]);
    const [error, setError] = useState(false);
    const [loadingPage, setLoadingPage] = useState(false);
    const { data } = useQuery(FETCH_ADMIN_ONE_PROFILE, {
        variables: { userName: tokenDetails?.preferred_username },
        skip: !tokenDetails,
    });
    const currentUser = data?.user;
    const [sendEmailVerification, { loading, error: emailError }] = useMutation(
        SEND_VERIFICATION_EMAIL,
        {
            refetchQueries: [FETCH_ADMIN_ONE_PROFILE],
            onError(error) {
                console.log(error);
            },
            onCompleted() {
                setLoadingPage(false);
                setShowEmailModal(false);
                setShowVerifyModal(true);
            },
        },
    );

    const {
        data: userData,
        loading: userLoading,
        called,
    } = useQuery(FETCH_ADMIN_PROFILE, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            const userDataUpdated = userData?.users;
            setUsersData(userDataUpdated);
        },
    });

    const [updateUser, { error: queryError }] = useMutation(UPDATE_ADMIN_PROFILE, {
        refetchQueries: [FETCH_ADMIN_ONE_PROFILE],
        awaitRefetchQueries: true,
        onCompleted(data) {
            if (data?.updateUser?.contact?.primaryEmail) {
                sendEmailVerification({
                    variables: { email: data?.updateUser?.contact?.primaryEmail },
                });
                if (!queryError) {
                    setSentEmailSuccess(true);
                    setShowEmailModal(false);
                }
            }
        },
    });

    useEffect(() => {
        if (!currentUser?.contact?.primaryEmailVerified) {
            setVerificationError('Current Primary Email not verified');
        }
    }, [setVerificationError, currentUser]);
    if (loading) return <Loader />;
    if (queryError) return <p>Error UPDATING USER ERROR :(</p>;

    const validationSchema = Yup.object({
        primaryEmail: Yup.string()
            .trim()
            .email('Email must be a valid email')
            .required('Email address is required'),
        primaryEmailConfirm: Yup.string()
            .trim()
            .email('Email must be a valid email')
            .required('Email address is required')
            .oneOf([Yup.ref('primaryEmail'), null], 'Emails must match'),
    });

    return (
        <Modal
            open={showEmailModal}
            onClose={() => {
                setError(false);
                setShowEmailModal(false);
            }}
            center={true}
            styles={{ modal: { borderRadius: 8, maxWidth: '706px', width: '80%' } }}
            focusTrapped={false}
        >
            <Formik
                initialValues={{
                    primaryEmail: '',
                    primaryEmailConfirm: '',
                    firstName: currentUser?.firstName || '',
                    lastName: currentUser?.lastName || '',
                    profilePic: currentUser?.profilePic || '',
                    countryCode: currentUser?.contact?.countryCode || '',
                    recoveryEmail: currentUser?.recoveryEmail || '',
                    phoneNumber: currentUser?.contact?.phoneNumber || '',
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={({ primaryEmail }) => {
                    if (!verificationError && currentUser?.contact?.primaryEmail !== primaryEmail) {
                        setLoadingPage(true);
                        const userExist = usersData?.find(
                            (user) => user?.contact?.primaryEmail === primaryEmail,
                        );

                        if (userExist) {
                            setError(true);
                            setLoadingPage(false);
                        } else {
                            updateUser({
                                variables: {
                                    userName: tokenDetails?.preferred_username,
                                    user: {
                                        firstName: currentUser?.firstName,
                                        lastName: currentUser?.lastName,
                                        profilePic: currentUser?.profilePic,
                                        contact: {
                                            primaryEmail: primaryEmail,
                                            recoveryEmail: currentUser?.contact?.recoveryEmail,
                                            countryCode: currentUser?.contact?.countryCode,
                                            phoneNumber: currentUser?.contact.phoneNumber,
                                        },
                                    },
                                },
                            });
                            localStorage.setItem('update-email', primaryEmail);
                        }
                    }
                }}
            >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                    <>
                        <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            <h3
                                className="text-xl font-poppins  font-semibold flex m-auto"
                                id="change_email_button"
                            >
                                Change admin email address
                            </h3>
                        </div>

                        <div className="relative p-6  flex flex-col">
                            <div className="flex m-auto mb-4">
                                <Images
                                    width="w-16"
                                    height="h-16"
                                    src="/images/icons/email.png"
                                    alt="Email"
                                />
                            </div>

                            <div className="text-gray-400 text-lg flex mb-3 m-auto">
                                Your current email address is
                                <div className="mx-2 underline text-purple-500 ">
                                    {currentUser?.contact?.primaryEmail}
                                </div>
                            </div>

                            <div className="flex flex-wrap flex-col mb-3 lg:mb-5 px-4">
                                <div className="flex flex-wrap flex-col lg:mb-5">
                                    <InputField
                                        placeHolder="Lucyadmin@swarmio.com"
                                        readonly={verificationError ? true : false}
                                        name="New email address"
                                        id="primaryEmail"
                                        onChange={handleChange('primaryEmail')}
                                        value={values.primaryEmail}
                                    />
                                    {error ? (
                                        <div
                                            className="text-red-500 text-xs mt-1 md:text-sm"
                                            id="newEmailRequired_error"
                                        >
                                            {ERROR}
                                        </div>
                                    ) : null}

                                    {errors.primaryEmail && touched ? (
                                        <div
                                            className="text-red-500 text-xs mt-1 md:text-sm"
                                            id="newEmailRequired_error"
                                        >
                                            {errors.primaryEmail}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="flex flex-wrap flex-col lg:mb-5">
                                    <InputField
                                        placeHolder="Lucyadmin@swarmio.com"
                                        readonly={verificationError ? true : false}
                                        name="Repeat new email address"
                                        id="primaryEmailConfirm"
                                        onChange={handleChange('primaryEmailConfirm')}
                                        value={values.primaryEmailConfirm}
                                    />
                                    {errors.primaryEmailConfirm && touched ? (
                                        <div
                                            className="text-red-500 text-xs mt-1 md:text-sm"
                                            id="repeatEmailRequired_error"
                                        >
                                            {errors.primaryEmailConfirm}
                                        </div>
                                    ) : null}
                                    {queryError ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {queryError}
                                        </div>
                                    ) : null}

                                    {verificationError ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {verificationError}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row mt-6 justify-end m-4">
                            <Buttons
                                name="Cancel"
                                type="button"
                                buttonType="secondary-border-black"
                                id="cancel"
                                size="small"
                                other="mr-3"
                                onclick={() => {
                                    setError(false);
                                    setShowEmailModal(false);
                                }}
                            />
                            <Buttons
                                name={loadingPage ? <NormalLoader /> : 'Update'}
                                type="submit"
                                buttonType="primary"
                                id="update"
                                size="small"
                                padding="p-1"
                                onclick={() => {
                                    if (!loadingPage) {
                                        handleSubmit();
                                    }
                                }}
                            />
                        </div>
                    </>
                )}
            </Formik>
        </Modal>
    );
}
