import { gql } from '@apollo/client';

export const SAVE_CSV = gql`
    mutation (
        $supplierId: ID!
        $fileUrl: String
        $fileName: String
        $fileUploadType: FileUploadType
    ) {
        saveCSVFile(
            supplierId: $supplierId
            fileUrl: $fileUrl
            fileName: $fileName
            fileUploadType: $fileUploadType
        ) {
            id
            fileUrl
            fileStatus
        }
    }
`;

export const PROCESS_CSV = gql`
    mutation ($fileId: ID!) {
        processCSVFile(fileId: $fileId) {
            id
            publisherName
            voucherCode
            voucherExpiry
            voucherPin
            productName
            voucherSerial
            currency
            costPrice
            additionalData
            totalStock
            availableStock
            sku
            productCode
            productType
        }
    }
`;

export const UPLOAD_CSV = gql`
    mutation ($fileId: ID!) {
        uploadDraftProducts(fileId: $fileId) {
            id
            manufacturerId
        }
    }
`;

export const UPLOAD_TOPUP_CONFIG_ORDER_FIELDS = gql`
    mutation ($fileId: ID!) {
        uploadOrderFields(fileId: $fileId) {
            id
            manufacturerId
        }
    }
`;

export const FETCH_SUPPLIER_FILES = gql`
    query (
        $filter: FilterInput
        $offset: Int!
        $limit: Int!
        $searchText: String
        $sort: String
        $order: SortOrder
    ) {
        csvFiles(
            filter: $filter
            offset: $offset
            limit: $limit
            searchText: $searchText
            sort: $sort
            order: $order
        ) {
            files {
                id
                fileName
                fileUrl
                fileStatus
                createdBy
                createdDate
                totalProducts
                totalVouchers
                availableProducts
                availableVouchers
                modifiedDate
            }
            currentPage
            totalFiles
            totalPages
        }
    }
`;

export const PROCESS_ORDER_FIELDS_CSV = gql`
    mutation ($fileId: ID!) {
        processOrderFieldsCSVFile(fileId: $fileId) {
            productCode
            productName
            isProductAvailable
        }
    }
`;

export const FETCH_ORDERFIELDS_CSV_MISMATCHES = gql`
    query ($supplierId: ID!) {
        getMismatchedOrderFieldsProductsList(supplierId: $supplierId) {
            productCode
            productName
            isProductAvailable
        }
    }
`;
