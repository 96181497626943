import { gql } from '@apollo/client';

export const FETCH_CATEGORIES = gql`
    query {
        categories {
            id
            categoryId
            parentCategory
            categoryName
            categoryDescription
            categoryImage
            categoryCoverImage
            categoryIcon
            isActive
            isDeleted
            groupOrder
            groupCount
            featured
            productGroupCatalogCategory {
                productGroup {
                    id
                    productGroupId
                    name
                    displayName
                    productType
                    isActive
                }
                catalog {
                    catalogId
                    catalogName
                }

                category {
                    id
                    categoryId
                    categoryName
                    parentCategory
                }
            }
        }
    }
`;

export const FETCH_CATEGORIES_FOR_PARENT_CATEGORY_DROPDOWN = gql`
    query {
        categories {
            id
            categoryId
            categoryName
            isActive
            groupOrder
        }
    }
`;

export const FETCH_CATEGORIES_FOR_FILTER = gql`
    query {
        categories {
            id
            categoryName
            isActive
            isDeleted
        }
    }
`;

export const ADD_CATEGORIES = gql`
    mutation ($category: categoryInput!) {
        saveCategory(category: $category) {
            id
            categoryId
            categoryName
        }
    }
`;

export const FETCH_CATEGORIES_BY_ID = gql`
    query ($categoryId: ID!) {
        category(categoryId: $categoryId) {
            id
            categoryId
            parentCategory
            categoryName
            categoryDescription
            categoryImage
            categoryCoverImage
            categoryIcon
            isActive
            isDeleted
            groupOrder
            featured
            urlMask
            productGroupCatalogCategory {
                productGroup {
                    id
                    productGroupId
                    name
                    displayName
                    description
                    productType
                    featured
                    isActive
                    isDeleted
                    itemOrder
                    deals {
                        id
                        endDateTime
                        dealName
                    }
                }
                catalog {
                    catalogId
                    catalogName
                }

                category {
                    id
                    categoryId
                    categoryName
                    parentCategory
                }
            }
        }
    }
`;

export const BULK_ACTION = gql`
    mutation ($categoryIds: [ID]!, $categoryListInput: CategoryListInput!) {
        updateCategoryList(categoryIds: $categoryIds, categoryListInput: $categoryListInput) {
            id
            categoryId
            parentCategory
            categoryName
            categoryDescription
            categoryImage
            categoryCoverImage
            categoryIcon
            isActive
            isDeleted
        }
    }
`;
