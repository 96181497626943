import React, { ReactElement } from 'react';
import { Modal } from 'react-responsive-modal';
import NormalLoader from '../../../utils/normalLoader';
import { Buttons } from '../../ui/atoms/Button';
import { Images } from '../../ui/atoms/Images';

interface Props {
    showWarningModal: boolean;
    setShowWarningModal: (value: boolean) => void;
    warningModalTitle?: string;
    warningModalBody?: string;
    cancel?: boolean;
    loader?: boolean;
    onclick?: () => void;
    setProcessFinishedClicked?: (value: boolean) => void;
    isGlobalCurrencyUpdateEnabled?: boolean;
    setGlobalCurrencyUpdateModal?: (value: boolean) => void;
}

export default function WarningMessageModal({
    showWarningModal,
    setShowWarningModal,
    warningModalTitle,
    warningModalBody,
    cancel,
    onclick,
    loader,
    setProcessFinishedClicked,
    isGlobalCurrencyUpdateEnabled,
    setGlobalCurrencyUpdateModal,
}: Props): ReactElement {
    const handleClose = () => {
        setShowWarningModal(false);
        if (setProcessFinishedClicked) {
            setProcessFinishedClicked(true);
        }
    };
    return (
        <Modal
            open={showWarningModal}
            onClose={() => {
                handleClose();
            }}
            center={true}
            styles={{ modal: { borderRadius: 15, maxWidth: '706px', width: '80%' } }}
            focusTrapped={false}
        >
            <>
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-poppins  font-bold flex m-auto">
                        {warningModalTitle}
                    </h3>
                </div>

                <div className="relative p-6  flex flex-col">
                    <div className="flex m-auto mb-4">
                        <Images
                            width="w-16"
                            height="h-16"
                            src="/images/icons/clarity_error-standard-line.png"
                            alt="success"
                        />
                    </div>
                    <p
                        data-testid="message"
                        className="text-gray-500 font-poppins text-center text-lg flex mb-3 m-auto whitespace-pre-line p-8 relative"
                    >
                        {warningModalBody}
                    </p>
                </div>

                <div className="flex flex-row mt-6 justify-end m-4">
                    {cancel ? (
                        <>
                            {' '}
                            <Buttons
                                name="Cancel"
                                type="submit"
                                buttonType="secondary-main"
                                id="cancel"
                                other={'mx-3'}
                                size="small"
                                onclick={() => {
                                    handleClose();
                                }}
                            />
                            <Buttons
                                name={loader ? <NormalLoader /> : 'Delete'}
                                type="submit"
                                buttonType="primary"
                                id="okay"
                                size="small"
                                onclick={onclick}
                            />
                        </>
                    ) : (
                        <>
                            {isGlobalCurrencyUpdateEnabled ? (
                                <div className="w-full flex justify-between">
                                    <Buttons
                                        name="Okay"
                                        type="submit"
                                        buttonType="primary"
                                        id="okay"
                                        size="small"
                                        onclick={() => {
                                            handleClose();
                                        }}
                                    />
                                    <Buttons
                                        name="Set it now"
                                        type="submit"
                                        buttonType="secondary-main"
                                        id="okay"
                                        size="small"
                                        onclick={() => {
                                            if (setGlobalCurrencyUpdateModal) {
                                                setGlobalCurrencyUpdateModal(true);
                                            }
                                        }}
                                    />
                                </div>
                            ) : (
                                <Buttons
                                    name="Okay"
                                    type="submit"
                                    buttonType="primary"
                                    id="okay"
                                    size="small"
                                    onclick={() => {
                                        handleClose();
                                    }}
                                />
                            )}
                        </>
                    )}
                </div>
            </>
        </Modal>
    );
}
