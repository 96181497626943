import { gql } from '@apollo/client';

export const FETCH_PARTNER_ITEM_CONVERSION_RATES = gql`
    query ($partnerId: ID!, $itemId: ID) {
        currencyConversions(partnerId: $partnerId, itemId: $itemId) {
            sourceCurrency
            targetCurrency
            conversionRate
            finalPriceDCBOverridden
            finalPricePSPOverridden
            overridePartner
            overrideItem
            overrideFinalPriceDCB
            overrideFinalPricePSP
        }
    }
`;

export const SAVE_ITEM_PARTNER_CURRENCY_CONVERSIONS = gql`
    mutation (
        $partnerId: ID!
        $itemId: ID!
        $conversions: [ItemPartnerCurrencyConversionsInput]!
        $markedUpPercentageForCurrency: Float
        $markedUpPercentageForPSP: Float
    ) {
        saveItemPartnerCurrencyConversions(
            partnerId: $partnerId
            itemId: $itemId
            conversions: $conversions
            markedUpPercentageForCurrency: $markedUpPercentageForCurrency
            markedUpPercentageForPSP: $markedUpPercentageForPSP
        ) {
            sourceCurrency
            targetCurrency
            conversionRate
        }
    }
`;

export const SAVE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE = gql`
    mutation ($partnerId: ID!, $itemId: ID!, $markedUpPercentageForPsp: Float) {
        overridePspMarkedUpPercentage(
            partnerId: $partnerId
            itemId: $itemId
            markedUpPercentageForPsp: $markedUpPercentageForPsp
        )
    }
`;

export const RESET_OVERRIDE_CONVERSION_RATE = gql`
    mutation ($partnerId: ID!, $itemId: ID, $conversion: ItemPartnerCurrencyConversionsInput!) {
        resetOverrideRate(partnerId: $partnerId, itemId: $itemId, conversion: $conversion)
    }
`;

export const RESET_OVERRIDE_CURRENCY_MARKED_UP_PERCENTAGE = gql`
    mutation ($partnerId: ID!, $itemId: ID) {
        resetOverrideCurrencyMarkedUpPercentage(partnerId: $partnerId, itemId: $itemId)
    }
`;

export const RESET_OVERRIDE_POINTS_MARKED_UP_PERCENTAGE = gql`
    mutation ($partnerId: ID!, $itemId: ID) {
        resetOverridePointsMarkedUpPercentage(partnerId: $partnerId, itemId: $itemId)
    }
`;

export const RESET_OVERRIDE_PSP_MARKED_UP_PERCENTAGE = gql`
    mutation ($partnerId: ID!, $itemId: ID) {
        resetOverridePspMarkedUpPercentage(partnerId: $partnerId, itemId: $itemId)
    }
`;

export const RESET_OVERRIDE_FINAL_PRICE = gql`
    mutation (
        $partnerId: ID!
        $itemId: ID!
        $sourceCurrency: String!
        $targetCurrency: String!
        $paymentMethod: String!
    ) {
        resetOverrideFinalPrice(
            partnerId: $partnerId
            itemId: $itemId
            sourceCurrency: $sourceCurrency
            targetCurrency: $targetCurrency
            paymentMethod: $paymentMethod
        )
    }
`;
