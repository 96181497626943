import DataTable from '../../../ui/organisms/DataTable';
import React, { useEffect, useState } from 'react';
// import '../../../../styles/dropdown.css';
import { pagination } from '../../../../constants/config/constants';
import moment from 'moment';
import DataTableAlter from '../../../ui/organisms/DataTableAlter';
import { useHistory, useLocation } from 'react-router-dom';
import { Buttons } from '../../../ui/atoms/Button';

export default function ViewCsvMismatches() {
    const history = useHistory();

    const [pageSize, setPageSize] = useState(pagination?.pageSize);
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [productsNotInCSV, setProductsNotInCSV] = useState([]);
    const [productsNotInSystem, setProductsNotInSystem] = useState([]);

    const location = useLocation<any>();
    const data = location?.state?.orderFieldsCSVMismatches;
    const supplierData = location?.state?.supplier;

    useEffect(() => {
        const availableProductsInDB = data?.filter((p) => p.isProductAvailable === true);
        setProductsNotInCSV(availableProductsInDB);

        const unavailableProductsInDB = data?.filter((p) => p.isProductAvailable === false);
        setProductsNotInSystem(unavailableProductsInDB);
    }, [data]);

    const navigateBackToViewSupplierScreen = () => {
        history.push({
            pathname: '/suppliers-view',
            state: { supplier: supplierData },
        });
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Product Code',
                accessor: 'productCode',
            },
            {
                Header: 'Product Name ',
                accessor: 'productName',
            },
        ],
        [],
    );

    return (
        <div>
            <div className="border-2 border-gray-300 rounded-lg mr-7 mb-10">
                <div className={productsNotInCSV?.length ? 'block' : 'hidden'}>
                    <div className="flex justify-between py-3 mr-9">
                        <div className="font-poppins text-lg font-bold py-2 px-2 flex">
                            Products do not Exist in CSV
                        </div>
                    </div>
                    <div>
                        <div>
                            <DataTableAlter
                                columns={columns}
                                data={productsNotInCSV}
                                totalItems={productsNotInCSV.length}
                                pageCount={pageCount}
                                setPageIndex={setPageIndex}
                            />
                        </div>
                    </div>
                </div>

                <div className={productsNotInSystem?.length ? 'block' : 'hidden'}>
                    <div className="flex justify-between py-3 mr-9">
                        <div className="font-poppins text-lg font-bold py-2 px-2 flex">
                            Products do not Exist in the System
                        </div>
                    </div>
                    <div>
                        <div>
                            <DataTableAlter
                                columns={columns}
                                data={productsNotInSystem}
                                totalItems={productsNotInSystem.length}
                                pageCount={pageCount}
                                setPageIndex={setPageIndex}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex p-4">
                <div className="flex w-full">
                    <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3"></div>
                </div>
                <Buttons
                    name="Back to View Supplier"
                    type="submit"
                    buttonType="primary"
                    id="back-to-view-supplier-button"
                    size="e-small"
                    padding="py-2"
                    onclick={() => {
                        navigateBackToViewSupplierScreen();
                    }}
                />
            </div>
        </div>
    );
}
