import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface BarProps {
    width?: number;
    height?: number;
    labelsData?: any;
    dataArray?: any;
}

export function BarCharts({ width, height, labelsData, dataArray }: BarProps) {
    const data = {
        labels: labelsData,
        datasets: [
            {
                label: '# of Points',
                data: dataArray,
                backgroundColor: ['rgba(93, 63, 211, 0.6)'],
                borderColor: ['rgba(93, 63, 211, 1)'],
                borderWidth: 2,
            },
        ],
    };
    return (
        <div className={`py-4 min-w-max ${width > 900 ? 'overflow-x-scroll' : null}`}>
            <Bar
                className="min-w-max"
                options={{
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    maintainAspectRatio: true,

                    scales: {
                        y: {
                            grid: {
                                drawBorder: false,
                                display: false,
                            },
                            stacked: true,
                            ticks: {
                                font: {
                                    size: 14,
                                },
                            },
                        },
                        x: {
                            grid: {
                                display: false,
                                drawBorder: false,
                            },
                            stacked: true,
                            ticks: {
                                font: {
                                    size: 14,
                                },
                            },
                        },
                    },
                }}
                width={width}
                height={height}
                data={data}
            />
        </div>
    );
}
