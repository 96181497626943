import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import ErrorHandling from '../service/ErrorHandling';
import { getCookie } from '../utils/cookiesService';

function ProtectedRoute({ component: Component, ...restOfProps }) {
    const token = getCookie('access_token');
    const [noToken, setNoToken] = useState(false);

    useEffect(() => {
        if (!token && !localStorage.getItem('Refreshed')) {
            setNoToken(true);
        }
    }, [token]);

    return (
        <Route
            exact
            {...restOfProps}
            render={(props) =>
                noToken ? (
                    <Redirect to="/" />
                ) : (
                    <ErrorHandling>
                        <Component {...props} />
                    </ErrorHandling>
                )
            }
        />
    );
}

export default ProtectedRoute;
