import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { verifyPassword } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { Buttons } from '../../ui/atoms/Button';
import { Images } from '../../ui/atoms/Images';
import NormalLoader from '../../../utils/normalLoader';
interface Props {
    showValidateModal: boolean;
    setShowValidateModal: (value: boolean) => void;
    setShowChangePasswordModal: (value: boolean) => void;
}

export default function ValidateOldPassword({
    showValidateModal,
    setShowValidateModal,
    setShowChangePasswordModal,
}: Props): ReactElement {
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [verifyLogin, setVerifyLogin] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');

    const validationSchema = Yup.object({
        password: Yup.string().required('Password cannot be blank'),
    });

    const loggedInUser = localStorage.getItem('current_user_email');

    const passwordShow = () => {
        setShowPassword(!showPassword);
    };

    const isVerificationSuccess = useSelector(
        (state: RootState) => state.auth.isVerificationSuccess,
    );

    const isLoading = useSelector((state: RootState) => state.auth.isLoading);

    useEffect(() => {
        if (isVerificationSuccess && verifyLogin && !isLoading) {
            setShowValidateModal(false);
            setVerifyLogin(false);
            setPasswordError(false);
            setNextButtonDisabled(false);
            setShowChangePasswordModal(true);
        } else if (!isVerificationSuccess && verifyLogin) {
            setPasswordError(true);
            setNextButtonDisabled(true);
        }
    }, [
        isVerificationSuccess,
        setPasswordError,
        setShowValidateModal,
        setShowChangePasswordModal,
        verifyLogin,
        isLoading,
    ]);

    useEffect(() => {
        if (currentPassword === '') {
            setPasswordError(false);
            setNextButtonDisabled(false);
            formRef.current?.resetForm();
        }
    }, [currentPassword]);

    useEffect(() => {
        setVerifyLogin(false);
        setPasswordError(false);
        setNextButtonDisabled(false);
    }, []);

    return (
        <Modal
            open={showValidateModal}
            onClose={() => {
                setShowValidateModal(false);
                setVerifyLogin(false);
                setPasswordError(false);
                setNextButtonDisabled(false);
            }}
            center={true}
            styles={{ modal: { borderRadius: 8, maxWidth: '706px', width: '80%' } }}
            focusTrapped={false}
        >
            <>
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-poppins  font-semibold flex m-auto">
                        Change password
                    </h3>
                </div>
                <Formik
                    innerRef={formRef}
                    enableReinitialize
                    initialValues={{
                        password: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async ({ password }) => {
                        setVerifyLogin(false);
                        dispatch(verifyPassword(loggedInUser, password));
                        setVerifyLogin(true);
                    }}
                >
                    {({ values, handleChange, handleSubmit, errors, touched }) => (
                        <div className="flex flex-col flex-wrap mt-5 md:mt-1 mx-10">
                            <div className="flex flex-col mt-4 mb-1">
                                <div className="flex justify-center p-4">
                                    <Images
                                        height="h-15"
                                        width="w-15"
                                        src="/images/icons/change-password.svg"
                                        alt="Change Password"
                                    />
                                </div>
                                <div className="flex justify-center text-gray-500 text-base font-poppins font-normal mt-4">
                                    Please enter your current password
                                </div>
                                <div className="flex justify-center px-24 py-6">
                                    <div
                                        className={`flex flex-col flex-grow ${
                                            !passwordError ? 'pr-4' : ''
                                        }`}
                                    >
                                        <div className="text-gray-500 text-sm w-max font-poppins h-max bg-white z-30 ml-4 px-1">
                                            Enter current password
                                        </div>

                                        <div className="relative">
                                            <input
                                                className={`p-4 md:p:3  w-full rounded-xl outline-none -mt-3 font-poppins bg-gray-1f00  border-2 border-gray-400  hover:border-purple-500 ${
                                                    passwordError && verifyLogin
                                                        ? 'border-red-500'
                                                        : ''
                                                }`}
                                                id="password"
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder="Enter your old password"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setCurrentPassword(e.target.value);
                                                    setPasswordError(false);
                                                    setNextButtonDisabled(false);
                                                }}
                                                value={values.password}
                                            />
                                            {errors.password && touched ? (
                                                <div
                                                    className="text-red-500 text-xs mt-1 md:text-sm"
                                                    id="password_error"
                                                >
                                                    {errors.password}
                                                </div>
                                            ) : null}
                                            {!isVerificationSuccess &&
                                            !isLoading &&
                                            verifyLogin &&
                                            passwordError ? (
                                                <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                    Invalid password
                                                </div>
                                            ) : null}

                                            <div className="absolute inset-y-0 right-0 pr-3 pt-1 flex text-base item-center">
                                                <img
                                                    src="/images/icons/eye 1.png"
                                                    className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                        showPassword ? 'block' : 'hidden'
                                                    }`}
                                                    onClick={passwordShow}
                                                    alt="show-password"
                                                    role="presentation"
                                                    draggable="false"
                                                />

                                                <img
                                                    src="/images/icons/eye-hide.png"
                                                    className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                        showPassword ? 'hidden' : 'block'
                                                    }`}
                                                    alt="hide-password"
                                                    role="presentation"
                                                    onClick={passwordShow}
                                                    draggable="false"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex relative pt-6 ml-1">
                                        {passwordError && (
                                            <Images
                                                height="h-15"
                                                width="w-15"
                                                src="/images/icons/Danger.svg"
                                                alt="Change Password"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="pt-5 flex align-middle justify-end xl:mb-10 gap-1">
                                <Buttons
                                    name="Cancel"
                                    type="submit"
                                    buttonType="secondary-main-grey"
                                    id="cancel"
                                    size="small"
                                    onclick={() => {
                                        setShowValidateModal(false);
                                        setVerifyLogin(false);
                                        setPasswordError(false);
                                        setNextButtonDisabled(false);
                                    }}
                                />
                                <Buttons
                                    name={isLoading && verifyLogin ? <NormalLoader /> : 'Next'}
                                    disabled={nextButtonDisabled}
                                    type="submit"
                                    buttonType="primary"
                                    id="update"
                                    size="small"
                                    onclick={() => {
                                        handleSubmit();
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </Formik>
            </>
        </Modal>
    );
}
