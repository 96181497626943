import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { InputField } from '../../../ui/atoms/InputField';
import '../../../../styles/timePicker.css';
import { useHistory, useParams } from 'react-router-dom';
import { Buttons } from '../../../ui/atoms/Button';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
    FETCH_DEAL_BY_ID,
    FETCH_DEAL_LOG_BY_ID,
    IS_DEAL_NAME_EXISTS,
    SAVE_DEAL,
} from '../../../../queries/DealQueries';
import 'date-fns';
import { useDealForm } from '../../../../contexts/DealFormContext';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import Loader from '../../../../utils/loader';
import {
    combineDateAndTime,
    getDateFromUTCDateTime,
    getTimeFromUtcDatetime,
} from '../../../../helpers/DateTimeHelpers.helpers';
import { DEAL_EDIT_SUCCESS, DEAL_TIME_OVELAPPING_ERROR_MSG } from '../../../../constants/deal';
import Toast from '../../../ui/atoms/Toast';
import ReactSwitch from 'react-switch';
import NormalLoader from '../../../../utils/normalLoader';
import DataTable from '../../../ui/organisms/DataTable';
import DealLogTable from './DealLogTable';
import { DealStatus, RoundOffBoundary, RoundOffPlace } from '../../../../types/deals';
import { useDispatch } from 'react-redux';
import { saveDealId } from '../../../../redux/rootActions';

const EditDealGeneral = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const parameters = useParams();
    const dealID = parameters['id'];
    const [isDirty, setIsDirty] = useState(false);
    const [message, setMessage] = useState('');
    const [complete, setComplete] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [error, setError] = useState(false);
    const [uniqueNameError, setUniqueNameError] = useState(false);

    const [paused, setPaused] = useState(true);
    const [pageCount, setPageCount] = useState(null);
    const [pageIndex, setPageIndex] = useState(null);
    const [pageSize, setPageSize] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isOngoingDeal, setIsOngoingDeal] = useState(false);
    const [isEndedDeal, setIsEndedDeal] = useState(false);

    const [isDisabled, setIsDisabled] = useState(false);
    const [overlappingDealItemError, setOverlappingDealItemError] = useState(false);

    const { data: dealData, loading: dealLoading } = useQuery(FETCH_DEAL_BY_ID, {
        variables: { dealId: dealID },
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setError(false);

            /* Paused on indicates that the deal is temporarily deactived. In other words isActive = false */
            setPaused(!dealData?.deal?.isActive);
            setIsOngoingDeal(dealData?.deal?.dealStatus == DealStatus[DealStatus.ONGOING]);
            setIsEndedDeal(dealData?.deal?.dealStatus == DealStatus[DealStatus.ENDED]);
        },
        onError(error: any) {
            const graphQLErrors = error.graphQLErrors;
            if (graphQLErrors && graphQLErrors.length > 0) {
                if (graphQLErrors[0].extensions.errorCode === 1003) {
                    setMessage(graphQLErrors[0].message);
                    setShowToast(true);
                    setError(true);
                }
            } else {
                setMessage('Error');
                setShowToast(true);
                setError(true);
            }
        },
    });

    const {
        data: dealLogData,
        loading: dealLogDataLoading,
        error: dealLogQueryError,
    } = useQuery(FETCH_DEAL_LOG_BY_ID, {
        variables: {
            dealId: dealID,
            offset: currentPage - 1 || 0,
            limit: Math.ceil(pageSize) || 10000,
        },
        context: { clientName: ClientsEnum.STORE },
        nextFetchPolicy: 'cache-and-network',
        fetchPolicy: 'network-only',
    });

    const [saveDeal, { loading: queryLoading, error: queryError }] = useMutation(SAVE_DEAL, {
        context: { clientName: ClientsEnum.STORE },
        refetchQueries: [FETCH_DEAL_BY_ID],
        onCompleted() {
            setIsDirty(false);
            setError(false);
            setMessage(DEAL_EDIT_SUCCESS);
            setShowToast(true);
        },
        onError(error: any) {
            const graphQLErrors = error.graphQLErrors;
            if (graphQLErrors && graphQLErrors.length > 0) {
                if (graphQLErrors[0].extensions.errorCode === 1642) {
                    setUniqueNameError(true);
                } else {
                    if (graphQLErrors[0].extensions.errorCode === 1646) {
                        setMessage(DEAL_TIME_OVELAPPING_ERROR_MSG);
                        setShowToast(true);
                        setError(true);
                    } else {
                        setShowToast(true);
                        setMessage(graphQLErrors[0].extensions.errorMessage);
                        setError(true);
                    }
                }
            } else {
                setMessage('Error');
                setShowToast(true);
                setError(true);
            }
        },
    });

    const deal = dealData?.deal;

    useEffect(() => {
        dispatch(saveDealId(dealID));
    }, []);

    useEffect(() => {
        setCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize]);

    useEffect(() => {
        if (isEndedDeal || (!isDirty && paused)) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [isDirty, paused, isEndedDeal]);

    const [checkDealNameExists, { loading, data }] = useLazyQuery(IS_DEAL_NAME_EXISTS, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
    });

    const checkDealNameUnique = async (dealName: string) => {
        try {
            const { data } = await checkDealNameExists({ variables: { dealName } });
            return !data.isDealNameExists;
        } catch (error) {
            console.error('Error checking deal name uniqueness:', error);
            return false;
        }
    };

    const currentUtcDatetime = new Date().toISOString();
    const today = new Date().toISOString().split('T')[0];

    const nowTime = getTimeFromUtcDatetime(currentUtcDatetime);
    const nowDate = getDateFromUTCDateTime(currentUtcDatetime);

    const validationSchema = Yup.object().shape({
        dealName: Yup.string()
            .max(50, 'Deal name cannot exceed 50 characters')
            .required('Deal name is required'),
        percentage: Yup.number()
            .typeError('Deal percentage must be a number')
            .min(0, 'Deal percentage cannot be less than 0')
            .max(100, 'Deal percentage cannot be more than 100')
            .required('Deal percentage is required'),
        startDate: Yup.date()
            // .min(new Date().toISOString().split('T')[0], 'Start date cannot be in the past')
            // .test('future-time', 'Start date cannot be in the past', function (value) {
            //     const { startDate } = this.parent;
            //     const selectedStartDate = getOnlyDate(startDate);
            //     if (selectedStartDate) {
            //         if (!isOngoingDeal) {
            //             const nowDate = getOnlyDate(new Date());
            //             if (selectedStartDate === nowDate) {
            //                 return startDate <= nowDate;
            //             }
            //             return true;
            //         }
            //     }
            //     return true;
            // })
            .required('Start date is required'),
        startTime: Yup.string()
            .required('Start time is required')
            .test('future-time', 'Start time cannot be in the past', function (value) {
                const { startDate, startTime } = this.parent;
                const selectedStartDate = getOnlyDate(startDate);
                if (selectedStartDate) {
                    if (!isOngoingDeal) {
                        //const nowDate = getOnlyDate(new Date());
                        if (selectedStartDate === nowDate) {
                            //const nowTime = getOnlyTime(new Date());
                            return startTime >= nowTime;
                        }
                        return true;
                    }
                }
                return true;
            }),
        endDate: Yup.date()
            .min(Yup.ref('startDate'), 'End date cannot be before the start date')
            .required('End date is required'),
        endTime: Yup.string()
            .required('End time is required')
            .test('future-time', 'End time cannot be before the start time.', function (value) {
                const { startDate, startTime, endDate, endTime } = this.parent;
                const selectedStartDate = getOnlyDate(startDate);
                const selectedEndtDate = getOnlyDate(endDate);
                if (selectedStartDate === selectedEndtDate) {
                    if (endTime < startTime) {
                        return false;
                    }
                    return true;
                } else {
                    return true;
                }
            })
            .test('future-time', 'End time cannot be in the past.', function (value) {
                const { startDate, startTime, endDate, endTime } = this.parent;
                const selectedEndtDate = getOnlyDate(endDate);
                if (selectedEndtDate) {
                    {
                        //const nowDate = getOnlyDate(new Date());
                        if (selectedEndtDate === nowDate) {
                            //const nowTime = getOnlyTime(new Date());
                            return endTime >= nowTime;
                        }
                        return true;
                    }
                }
                return true;
            }),
        roundBoundary: Yup.string(),
        decimalPlaces: Yup.string().when('roundBoundary', {
            is: (roundBoundary) => roundBoundary !== undefined && roundBoundary !== '',
            then: Yup.string()
                .required('Rounding off to a decimal place is required')
                .oneOf(
                    ['WHOLE_NUMBER', 'FIRST_DECIMAL', 'SECOND_DECIMAL'],
                    'Invalid decimal place',
                ),
            otherwise: Yup.string(),
        }),
    });

    const getOnlyDate = (input: Date | string): string => {
        if (!input) return ''; // Return empty string for undefined input

        const date = typeof input === 'string' ? new Date(input) : input;

        // Check if date is valid
        if (isNaN(date.getTime())) return ''; // Return empty string for invalid dates

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const getOnlyTime = (input: Date | string): string => {
        const date = typeof input === 'string' ? new Date(input) : input;
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const boundaryOptions = [
        { key: 'upper', value: 'UPPER_BOUNDARY', text: 'UPPER BOUNDARY' },
        { key: 'lower', value: 'LOWER_BOUNDARY', text: 'LOWER BOUNDARY' },
    ];

    const decimalOptions = [
        { key: 'whole', value: 'WHOLE_NUMBER', text: 'WHOLE NUMBER (0)' },
        { key: 'first', value: 'FIRST_DECIMAL', text: '1ST DECIMAL PLACE (0.0)' },
        { key: 'second', value: 'SECOND_DECIMAL', text: '2ND DECIMAL PLACE (0.00)' },
    ];

    const columns = useMemo(
        () => [
            { id: 'date', Header: 'Date', accessor: 'createdDate' },
            { id: 'itemId', Header: 'Product ID', accessor: 'itemId' },
            { id: 'productName', Header: 'Product Name', accessor: 'itemName' },
            { id: 'description', Header: 'Description', accessor: 'description' },
            { id: 'initiatedBy', Header: 'Initiated By', accessor: 'initiatedBy' },
        ],
        [],
    );

    if (dealLoading) return <Loader />;

    return (
        <Formik
            initialValues={{
                dealName: deal?.dealName,
                percentage: deal?.dealPercentage,
                startDate: deal != null ? getDateFromUTCDateTime(deal?.startDateTime) : null,
                startTime: deal != null ? getTimeFromUtcDatetime(deal?.startDateTime) : null,
                endDate: deal != null ? getDateFromUTCDateTime(deal?.endDateTime) : null,
                endTime: deal != null ? getTimeFromUtcDatetime(deal?.endDateTime) : null,
                roundBoundary:
                    deal?.finalPriceRoundOffBoundary != null
                        ? deal?.finalPriceRoundOffBoundary
                        : '',
                decimalPlaces:
                    deal?.finalPriceRoundOffDecimalPlace != null
                        ? deal?.finalPriceRoundOffDecimalPlace
                        : '',
            }}
            validationSchema={validationSchema}
            onSubmit={({
                dealName,
                percentage,
                startDate,
                startTime,
                endDate,
                endTime,
                roundBoundary,
                decimalPlaces,
            }) => {
                saveDeal({
                    variables: {
                        dealInput: {
                            id: dealID,
                            dealName: dealName,
                            dealPercentage: percentage,
                            finalPriceRoundOffBoundary:
                                roundBoundary != ''
                                    ? roundBoundary
                                    : RoundOffBoundary[RoundOffBoundary.NOT_SELECTED],
                            finalPriceRoundOffDecimalPlace:
                                decimalPlaces != ''
                                    ? decimalPlaces
                                    : RoundOffPlace[RoundOffPlace.NOT_SELECTED],
                            startDateTime: combineDateAndTime(startDate, startTime),
                            endDateTime: combineDateAndTime(endDate, endTime),
                            isActive: !paused,
                        },
                    },
                });
            }}
        >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
                <>
                    <>
                        {' '}
                        {showToast && (
                            <Toast
                                setShowToast={setShowToast}
                                message={message}
                                width="w-10/12"
                                margin="ml-1"
                                error={error}
                                selfDestruct={true}
                                selfDestructTimer={3000}
                            />
                        )}
                    </>
                    <form onSubmit={handleSubmit} className="flex flex-col justify-evens">
                        <div className="grid grid-cols-2">
                            <div className="text-lg font-poppins font-bold px-14 mx-4">
                                Deal Details
                            </div>
                            <div className="flex space-x-5 items-center justify-end px-14 mx-4">
                                <span>Pause Deal</span>
                                <ReactSwitch
                                    id="pauseDeal"
                                    checked={paused}
                                    onChange={(e: boolean) => {
                                        setIsDirty(true);
                                        setPaused(e);
                                    }}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onColor="#8b5cf6"
                                    height={24}
                                    width={46}
                                    disabled={isEndedDeal}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-8 gap-4 mt-8 px-14 mx-4">
                            <div className="col-start-1 col-end-4">
                                <InputField
                                    id="dealName"
                                    placeHolder={'Deal Name'}
                                    name="Deal Name"
                                    labelWidth="w-21"
                                    onChange={(event) => {
                                        if (uniqueNameError === true) {
                                            setUniqueNameError(false);
                                        }
                                        handleChange(event);
                                    }}
                                    value={values.dealName}
                                    disabled={isDisabled}
                                    inputTextSize="text-base"
                                />
                                {errors.dealName && touched.dealName ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.dealName}
                                    </div>
                                ) : null}
                                {uniqueNameError ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        Deal name must be unique
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-start-1 col-end-4">
                                <InputField
                                    id="percentage"
                                    placeHolder={'Percentage (%)'}
                                    name="Percentage (%)"
                                    labelWidth="w-21"
                                    onChange={(event) => {
                                        handleChange(event);
                                    }}
                                    value={values.percentage}
                                    disabled={isDisabled}
                                    type="number"
                                    inputTextSize="text-base"
                                />
                                {errors.percentage && touched.percentage ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.percentage}
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-start-1 col-end-4">
                                <InputField
                                    id="startDate"
                                    placeHolder={'Start Date'}
                                    name="Start Date"
                                    labelWidth="w-21"
                                    type="date"
                                    onChange={(event) => {
                                        handleChange(event);
                                    }}
                                    min={today}
                                    disabled={isOngoingDeal || isDisabled}
                                    value={values.startDate}
                                    readonly={isOngoingDeal}
                                    inputTextSize="text-base"
                                />
                                {errors.startDate && touched.startDate ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.startDate}
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-start-4 col-end-7">
                                <InputField
                                    id="startTime"
                                    placeHolder={'Start Time UTC'}
                                    name="Start Time UTC"
                                    labelWidth="w-21"
                                    type="time"
                                    onChange={(event) => {
                                        handleChange(event);
                                    }}
                                    disabled={isOngoingDeal || isDisabled}
                                    value={values.startTime}
                                    readonly={isOngoingDeal}
                                    inputTextSize="text-base"
                                />
                                {errors.startTime && touched.startTime ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.startTime}
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-start-1 col-end-4">
                                <InputField
                                    id="endDate"
                                    placeHolder={'End Date'}
                                    name="End Date"
                                    labelWidth="w-21"
                                    type="date"
                                    onChange={(event) => {
                                        handleChange(event);
                                    }}
                                    value={values.endDate}
                                    min={today}
                                    disabled={isDisabled}
                                    inputTextSize="text-base"
                                />
                                {errors.endDate && touched.endDate ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.endDate}
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-start-4 col-end-7 ">
                                <InputField
                                    id="endTime"
                                    placeHolder={'End Time UTC'}
                                    name="End Time UTC"
                                    labelWidth="w-21"
                                    type="time"
                                    onChange={(event) => {
                                        handleChange(event);
                                    }}
                                    value={values.endTime}
                                    disabled={isDisabled}
                                    inputTextSize="text-base"
                                />
                                {errors.endTime && touched.endTime ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.endTime}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <hr className="px-14 border mt-5" />
                        <div className="h-full flex flex-col  justify-evens">
                            <div className="text-lg font-poppins font-bold py-8 px-14 mx-4 pb-8">
                                Final Price Adjustment
                            </div>
                            <div className="grid grid-cols-8 gap-4 px-14 mx-4">
                                <div className="col-start-1 col-end-4">
                                    <div className="flex flex-wrap flex-col relative w-full">
                                        <div className="text-gray-500 w-40 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                            Round off Boundary
                                        </div>
                                        <select
                                            id="roundBoundary"
                                            name="roundBoundary"
                                            defaultValue={values.roundBoundary}
                                            onChange={(event) => {
                                                handleChange(event);
                                            }}
                                            className="px-4 py-3 rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500 w-full"
                                            disabled={isDisabled}
                                        >
                                            <option value="" label="Select Boundary"></option>
                                            {boundaryOptions.map((option) => (
                                                <option
                                                    key={option.key}
                                                    value={option.value}
                                                    label={option.text}
                                                />
                                            ))}
                                        </select>
                                    </div>
                                    {errors.roundBoundary && touched.roundBoundary ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {errors.roundBoundary}
                                        </div>
                                    ) : null}
                                </div>

                                <div className="col-start-4 col-end-7">
                                    <div className="flex flex-wrap flex-col relative w-full">
                                        <div className="text-gray-500 w-40 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                            Decimal Places
                                        </div>
                                        <select
                                            id="decimalPlaces"
                                            name="decimalPlaces"
                                            value={values.decimalPlaces}
                                            onChange={(event) => {
                                                handleChange(event);
                                            }}
                                            className="px-4 py-3 rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500 w-full"
                                            disabled={!values.roundBoundary || isDisabled}
                                        >
                                            <option value="" label="Select Decimal Places" />
                                            {decimalOptions.map((option) => (
                                                <option
                                                    key={option.key}
                                                    value={option.value}
                                                    label={option.text}
                                                />
                                            ))}
                                        </select>
                                    </div>
                                    {errors.decimalPlaces && touched.decimalPlaces ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {errors.decimalPlaces}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 pt-1 pb-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0 z-20">
                                <Buttons
                                    name="Cancel"
                                    type="button"
                                    buttonType="secondary-border-black"
                                    id="cancel-button"
                                    size="e-small"
                                    other="mr-3"
                                    onclick={() => history.push('/deals')}
                                />
                                <Buttons
                                    name={queryLoading ? <NormalLoader /> : 'Save'}
                                    type="submit"
                                    buttonType="primary"
                                    id="save-button"
                                    size="e-small"
                                    disabled={isDisabled}
                                />
                            </div>
                        </div>
                    </form>
                    <div className="flex flex-col">
                        <DealLogTable dealId={dealID} />
                    </div>
                </>
            )}
        </Formik>
    );
};

export default EditDealGeneral;
