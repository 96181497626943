/* eslint-disable @typescript-eslint/no-unused-vars */

import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as BsIcons from 'react-icons/bs';
import { Buttons } from '../../ui/atoms/Button';
import { InputField } from '../../ui/atoms/InputField';
import { useHistory } from 'react-router';
import UploadCategoryImage from './UploadCategoryImage';
import { useLazyQuery, useQuery } from '@apollo/client';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import {
    FETCH_CATEGORIES,
    FETCH_CATEGORIES_BY_ID,
    FETCH_CATEGORIES_FOR_PARENT_CATEGORY_DROPDOWN,
} from '../../../queries/CategoryQueries';
import Loader from '../../../utils/loader';
import { FETCH_CATALOG } from '../../../queries/catalogQueries';
import { FETCH_PRODUCT_GROUPS_BY_CATEGORY_ID } from '../../../queries/ProductGroupQueries';
import React from 'react';
import Multiselect from 'multiselect-react-dropdown';
import makeAnimated from 'react-select/animated';
import { saveCategoryName } from '../../../redux/rootActions';
import { useDispatch } from 'react-redux';
import DataTableAlter from '../../ui/organisms/DataTableAlter';
import Select from 'react-select';
import ReactSwitch from 'react-switch';

export default function ViewCategory() {
    const [thumbNail, setThumbNail] = useState('');
    const [selectedTabProducts, setSelectedTabProducts] = useState([]);
    const [coverImage, setCoverImage] = useState('');
    const [catalogData, setCatalogData] = useState([]);
    const [productGroupsData, setProductGroupsData] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [categoriesCatalog, setCategoriesCatalog] = useState([]);
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const animatedComponents = makeAnimated();
    const selectedCategoryToEdit = localStorage.getItem('view-category');

    const { data: categoriesQueryData, loading: categoryLoading } = useQuery(
        FETCH_CATEGORIES_FOR_PARENT_CATEGORY_DROPDOWN,
        {
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: () => {
                setCategoriesData(categoriesQueryData?.categories);
            },
        },
    );
    const dispatch = useDispatch();

    const { data: categoryQueryData, loading } = useQuery(FETCH_CATEGORIES_BY_ID, {
        variables: { categoryId: selectedCategoryToEdit },
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            dispatch(saveCategoryName(categoryQueryData?.category?.categoryName));
            setCoverImage(categoryQueryData?.category?.categoryCoverImage);
            setThumbNail(categoryQueryData?.category?.categoryImage);
        },
    });

    const { data: prouctGroupsQueryData, loading: productGroupsloading } = useQuery(
        FETCH_PRODUCT_GROUPS_BY_CATEGORY_ID,
        {
            variables: { categoryId: selectedCategoryToEdit },
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: () => {
                setProductGroupsData(prouctGroupsQueryData?.productGroupsByCategory);
            },
        },
    );

    const { loading: catalogLoading, data: catalogs } = useQuery(FETCH_CATALOG, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setCatalogData(data?.catalogs);
        },
    });

    const category = categoryQueryData?.category;

    const defaultParentCategory = categoriesData?.filter((option: any) => {
        return option.id === category?.parentCategory?.toString();
    });

    const defaultParentCategoryOutput = defaultParentCategory?.map((category) => ({
        text: category.categoryName,
        value: category.categoryId,
    }));

    const colourStyles = {
        menuList: (styles) => ({
            ...styles,
            background: '#f3e8ff',
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused ? '#f3e8ff' : isSelected ? '#f3e8ff' : undefined,
            zIndex: 1,
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#c084fc',
            };
        },
        multiValueRemove: (base) => ({
            ...base,
            display: 'none',
        }),
        menu: (base) => ({
            ...base,
            zIndex: 100,
            background: '#c084fc',
        }),
    };

    const categoryProductsColumns = React.useMemo(
        () => [
            {
                Header: 'Group ID',
                accessor: 'productGroupId',
            },
            {
                Header: 'Group Title',
                accessor: 'name',
            },
            {
                Header: 'Display Name',
                accessor: 'displayName',
            },
            {
                Header: 'Product Type',
                accessor: 'productType',
            },
            {
                Header: 'Product Count',
                accessor: 'productsCount',
            },
            {
                Header: 'Status',
                accessor: '',
                Cell: function showBadges({ row }) {
                    const isActive = row?.original?.isActive;
                    const isFeatured = row?.original?.featured;
                    return (
                        <>
                            <span
                                className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                    row?.original?.isActive === true
                                        ? 'bg-green-100 text-green-600'
                                        : row?.original?.isActive === false
                                        ? 'bg-red-100 text-red-600'
                                        : ''
                                }`}
                            >
                                {row?.original?.isActive === true ? 'Active' : 'Inactive'}
                            </span>
                            {row?.original?.featured ? (
                                <span className="inline-flex rounded-full h-6 px-3 justify-center items-center text-sm bg-yellow-200 text-yellow-600 ml-1">
                                    Featured
                                </span>
                            ) : null}
                        </>
                    );
                },
            },
            {
                Header: 'Catalogs',
                accessor: '',
                Cell: function showCatalog({ row }) {
                    const prodGroupCatalogs = catalogData
                        ?.filter((cat) => cat?.partner?.isActive === true)
                        ?.map((catalog) => ({
                            value: catalog.catalogId,
                            label: catalog.catalogName,
                        }));

                    const groupCatalogs = category?.productGroupCatalogCategory
                        ?.filter((groups) => groups?.productGroup?.id === row?.original?.id)
                        ?.map((pgcc) => {
                            return pgcc?.catalog?.catalogId;
                        });

                    const set = new Set(groupCatalogs);
                    const uniqueSetValues = [...set];

                    const uniqueCatalogs = prodGroupCatalogs?.filter((catalog) =>
                        uniqueSetValues?.some((cat) => {
                            return cat === catalog?.value;
                        }),
                    );

                    return (
                        <span className="d-inline-block z-50 h-auto py-3">
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                styles={colourStyles}
                                isDisabled={true}
                                isClearable={false}
                                defaultValue={uniqueCatalogs}
                            />
                        </span>
                    );
                },
            },
        ],

        [catalogData, category],
    );

    useEffect(() => {
        const dataArray = [];

        category?.productGroupCatalogCategory?.forEach((data) => {
            if (data?.category?.id === category?.id) {
                const object = {
                    catalogId: data?.catalog?.catalogId,
                };
                dataArray?.push(object);
            }
        });
        const set = new Set(dataArray);
        const uniqueSetValues = [...set];
        const catalogsList = catalogData
            ?.filter((cat) => cat?.partner?.isActive === true)
            ?.map((catalog) => ({
                cat: catalog.catalogId,
                key: catalog.catalogName,
            }));
        const filteredCatalog = catalogsList?.filter((cat) =>
            uniqueSetValues?.some((cate) => cat?.cat === cate?.catalogId),
        );
        setCategoriesCatalog(filteredCatalog);
    }, [catalogData, category?.id, category?.productGroupCatalogCategory]);

    useEffect(() => {
        if (!category || !category.productGroupCatalogCategory) {
            // Handle the case where category or productGroupCatalogCategory is null or undefined
            return;
        }

        const dataArray = [];
        category.productGroupCatalogCategory.forEach((pgcc) => {
            const object = {
                id: pgcc?.productGroup?.id,
            };
            dataArray.push(object);
        });

        const set = new Set(dataArray);
        const uniqueSetValues = [...set];

        if (!category.productGroupCatalogCategory) {
            // Handle the case where groupData is null or undefined
            return;
        }

        const groupsList = productGroupsData?.map((pg: any) => ({
            id: pg?.id,
            productGroupId: pg?.productGroupId,
            name: pg?.name,
            displayName: pg?.displayName,
            description: pg?.description,
            productType: pg?.productType,
            featured: pg?.featured,
            isActive: pg?.isActive,
            isDeleted: pg?.isDeleted,
            productsCount: pg?.productsCount,
        }));

        if (!groupsList) {
            // Handle the case where groupsList is null or undefined
            return;
        }

        const filteredGroups = groupsList.filter((grp) =>
            uniqueSetValues.some((group) => grp?.id === group?.id),
        );

        const orderedGroups = filteredGroups.sort((a, b) => {
            const indexOfA = category.groupOrder.indexOf(a.id);
            const indexOfB = category.groupOrder.indexOf(b.id);
            return indexOfA - indexOfB;
        });
        setSelectedTabProducts(orderedGroups);
    }, [category, categoryQueryData?.category?.productGroupCatalogCategory, productGroupsData]);

    if (categoryLoading) return <Loader />;
    if (loading) return <Loader />;
    if (catalogLoading) return <Loader />;
    if (productGroupsloading) return <Loader />;

    return (
        <Formik
            initialValues={{
                categoryName: category?.categoryName,
                categoryDescription: category?.categoryDescription,
                featured: category?.featured,
                categoryUrlMask: category?.urlMask,
            }}
            enableReinitialize
            onSubmit={() => {
                history.push('/products/categories');
            }}
        >
            {({ handleSubmit, handleChange, values }) => (
                <>
                    {showToast && (
                        <div
                            className={`" z-50  -mt-5 h-16 flex flex-row  pl-10 ${
                                error ? 'bg-red-200 fixed w-8/12' : 'bg-green-200 fixed w-9/12'
                            } mb-2 px-1 py-2 rounded-md items-center`}
                        >
                            <div className="w-1/10 font-medium text-xl">
                                <BsIcons.BsInfoCircleFill className="mx-auto" />
                            </div>
                            <div className="flex-1 px-5 font-poppins">{message}</div>
                            <div className="flex w-2/10 px-5">
                                <button
                                    onClick={() => {
                                        setShowToast(false);
                                        if (!error) history.push('/adminProfile/categories');
                                    }}
                                >
                                    <BsIcons.BsX className="mx-auto text-lg" />
                                </button>
                            </div>
                        </div>
                    )}
                    <div className="pr-44 -ml-4">
                        <div className="grid grid-cols-10 gap-4">
                            <div className="col-start-1 col-end-5 flex space-x-5 mt-3 px-4">
                                <div className="w-full">
                                    <InputField
                                        id="categoryName"
                                        placeHolder="Enter category title"
                                        name="Category Title"
                                        labelWidth="w-25"
                                        onChange={handleChange('categoryName')}
                                        value={values.categoryName}
                                        readonly={true}
                                    />
                                </div>
                            </div>

                            <div className="col-start-6 col-end-10 w-full flex items-center">
                                <div className="flex items-center gap-4">
                                    <span>Featured Category</span>
                                    <ReactSwitch
                                        id="featured"
                                        checked={values.featured}
                                        onChange={null}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        onColor="#8b5cf6"
                                        height={24}
                                        width={46}
                                        readOnly={true}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className="col-start-1 col-end-5 w-full">
                                <div className="flex order-1 flex-wrap flex-col relative mb-2 px-4">
                                    <div className="mt-3">
                                        <InputField
                                            id="categoryUrlMask"
                                            name="Category ID - URL Mask"
                                            labelWidth="w-25"
                                            maxLength={255}
                                            value={values.categoryUrlMask}
                                            readonly
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-start-1 col-end-9 mt-5 flex flex-wrap relative px-4">
                                <div
                                    className={`text-gray-500  w-58 text-center font-poppins text-sm lg:text-sm h-max bg-white z-40 -mb-3 ml-4 border-opacity-20`}
                                >
                                    Category Description (Detailed)
                                </div>
                                <textarea
                                    className={`p-4 z-0 min-w-full rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500`}
                                    id="categoryDescription"
                                    name="categoryDescription"
                                    rows={3}
                                    cols={138}
                                    draggable={false}
                                    maxLength={50}
                                    placeholder={values.categoryDescription}
                                    readOnly={true}
                                    disabled={true}
                                />
                            </div>

                            <div className="col-start-1 col-end-5 ">
                                <div className="flex order-1 flex-wrap flex-col relative mb-2 px-4">
                                    <div className="text-gray-500 w-44 text-center font-poppins z-30 text-sm lg:text-sm h-max bg-white -mb-3 ml-4 border-opacity-20">
                                        Select Partner Catalog
                                    </div>
                                    <div className="z-20">
                                        <Multiselect
                                            displayValue="key"
                                            style={{
                                                chips: {
                                                    background: '#7666F1',
                                                },
                                                multiselectContainer: {
                                                    color: 'black',
                                                },
                                                searchBox: {
                                                    border: '1px solid black',
                                                    'border-radius': '10px',
                                                    padding: '10px',
                                                },
                                            }}
                                            selectedValues={categoriesCatalog}
                                            disable={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="lg:col-start-1 lg:col-end-5 ">
                                <div className="col-start-1 col-end-5 mt-3 px-4">
                                    <InputField
                                        id="parentCategory"
                                        name="Parent Category"
                                        labelWidth="w-32"
                                        value={defaultParentCategoryOutput[0]?.text || 'none'}
                                        readonly={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-5 flex-col xl:flex-row pl-4 pr-64">
                            <div className="w-full">
                                <div className="flex my-3">
                                    <div className="mr-1 font-poppins">Category Thumbnail</div>
                                    <div className="text-gray-400">(Max 100X 100)</div>
                                </div>

                                <UploadCategoryImage
                                    width={'w-5/6'}
                                    height={'h-80'}
                                    isEdit={true}
                                    placeholder={`Drag and drop category thumbnail images here or`}
                                    inputId="thumbnail"
                                    isThumbNail={true}
                                    imageProp={thumbNail}
                                    disabled={true}
                                    setImage={setThumbNail}
                                />
                            </div>
                            <div className="w-full">
                                <div className="flex my-3">
                                    <div className="mr-1 font-poppins">Category Cover Image</div>
                                    <div className="text-gray-400"> (Max 2048 X 2048)</div>
                                </div>

                                <UploadCategoryImage
                                    width={'w-5/6'}
                                    height={'h-80'}
                                    isEdit={true}
                                    placeholder={'Drag and drop category cover images here or'}
                                    inputId="coverImage"
                                    imageProp={coverImage}
                                    disabled={true}
                                    setImage={setCoverImage}
                                />
                            </div>
                        </div>
                        <div className="text-gray-400 flex mb-5 pl-4">What can I upload?</div>
                    </div>
                    <div className="my-3 lg:my-10 border-2 rounder-sm py-3  pr-6">
                        <div className="text-xl font-poppins font-bold py-5 p-2">
                            {`  Selected Groups (${
                                selectedTabProducts?.length >= 10
                                    ? selectedTabProducts?.length
                                    : `0${selectedTabProducts?.length}`
                            })`}
                        </div>
                        {selectedTabProducts?.length ? (
                            <DataTableAlter
                                columns={categoryProductsColumns}
                                data={selectedTabProducts}
                                pageCount={1} //future need
                                customizedPageSize={selectedTabProducts?.length}
                                pageSizes={selectedTabProducts?.length}
                                radioBtn={false}
                                hidePagination={true}
                            />
                        ) : (
                            <div className="m-auto flex flex-col ">
                                <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                    {categoryProductsColumns?.map((column, index) => {
                                        return (
                                            <div
                                                className="text-black font-poppins font-semibold flex ml-12"
                                                key={index}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10">No selected products.</div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        )}
                    </div>

                    <div className="w-full py-4 justify-end bg-gray-100 flex mt-3 pr-8">
                        {category?.isDeleted ? null : (
                            <Buttons
                                name="Edit"
                                type="button"
                                buttonType="secondary-border-black"
                                id="Cancel"
                                size="e-small"
                                other="mr-3"
                                onclick={() => {
                                    localStorage.setItem('edit-category', category?.id);
                                    history.push('/products/categories/edit');
                                }}
                            />
                        )}

                        <Buttons
                            name={'Close'}
                            type="submit"
                            buttonType="primary"
                            id="update"
                            size="e-small"
                            onclick={() => {
                                handleSubmit();
                            }}
                        />
                    </div>
                </>
            )}
        </Formik>
    );
}
