import { useState, useMemo, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { PaymentMethod } from '../../../../enums/payment';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import { FETCH_PAYMENT_CHANNELS_FOR_DEALS } from '../../../../queries/PaymentChannelQueries';
import DataTable from '../../../ui/organisms/DataTable';
import TableTabView from '../../productScreens/TableTabView';
import { Buttons } from '../../../ui/atoms/Button';
import { useDealForm } from '../../../../contexts/DealFormContext';
import WarningMessageModal from '../../../templates/modals/WarningMessageModal';
import Loader from '../../../../utils/loader';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

export default function SelectPaymentChannels() {
    const history = useHistory();
    const animatedComponents = makeAnimated();
    const [tableData, setTableData] = useState([]);
    const [initialsTableData, setInitialTableData] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTab, setSelectedTab] = useState(1);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [initialCheckedChannels, setInitialCheckedChannels] = useState({});
    const [checkedRowIds, setCheckedRowIds] = useState({});
    const { formData, updateFormData } = useDealForm();
    const [selectedPaymentChannels, setSelectedPaymentChannels] = useState(
        formData.selectedPaymentChannels,
    );
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState(
        formData.selectedPaymentMethods,
    );
    const [paymentMethodError, setPaymentMethodError] = useState(false);
    const [isPaymentMethodFilterVisible, setIsPaymentMethodFilterVisible] = useState(false);
    const [warningMessage, setWarningMessage] = useState<string>();
    const [paymentMethodOptions, setPaymentMethodOptions] = useState([
        {
            value: 'all',
            label: 'All',
        },
        {
            value: PaymentMethod.DCB,
            label: 'DCB',
        },
        {
            value: PaymentMethod.PSP,
            label: 'PSP',
        },
    ]);

    const PAYMENT_METHODS_WITH_CHANNELS = [
        PaymentMethod.DCB,
        PaymentMethod.PSP,
        PaymentMethod.POINTS,
    ];

    const [paymentMethodFilter, setPaymentMethodFilter] = useState('all');

    const [fetchPaymentChannelsData, { loading: paymentChannelsLoading }] = useLazyQuery(
        FETCH_PAYMENT_CHANNELS_FOR_DEALS,
        {
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                setSelectedPaymentChannels(formData.selectedPaymentChannels);
                setTableData(data.paymentChannelsByPartnersAndPaymentMethods.paymentChannels);
                setInitialTableData(
                    data.paymentChannelsByPartnersAndPaymentMethods.paymentChannels,
                );
            },
        },
    );

    const colourStyles = {
        menuList: (styles) => ({
            ...styles,
            background: 'white',
            color: 'black',
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused ? '#c084fc' : isSelected ? '#f3e8ff' : undefined,
            zIndex: 1,
            color: 'black',
        }),
        multiValue: (styles) => {
            return {
                ...styles,
                backgroundColor: '#c084fc',
            };
        },
        multiValueRemove: (styles) => ({
            ...styles,

            ':hover': {
                backgroundColor: '#c084fc',
                color: 'black',
            },
        }),
        menu: (base) => ({
            ...base,
            zIndex: 100,
            background: '#c084fc',
        }),
    };

    useEffect(() => {
        const selectedPartners = [...formData.selectedPartners];
        if (selectedPaymentMethods.length > 0 && selectedPartners.length > 0) {
            fetchPaymentChannelsData({
                variables: {
                    partnerIds: selectedPartners,
                    paymentMethods: selectedPaymentMethods,
                    offset: 0,
                    limit: 10,
                    sort: null,
                    order: null,
                    searchText: null,
                },
            });
        }
    }, [selectedPaymentMethods]);

    useEffect(() => {
        if (selectedPaymentMethods.length > 0) {
            if (
                selectedPaymentMethods.includes(PaymentMethod.DCB) &&
                selectedPaymentMethods.includes(PaymentMethod.PSP)
            ) {
                setIsPaymentMethodFilterVisible(true);
            } else {
                setIsPaymentMethodFilterVisible(false);
            }
        }
    }, [selectedPaymentMethods]);

    useEffect(() => {
        const checkedList = {};
        if (selectedPaymentChannels) {
            selectedPaymentChannels.forEach((rowItem) => {
                checkedList[rowItem?.id] = true;
            });
        }
        setInitialCheckedChannels(checkedList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPaymentChannels]);

    useEffect(() => {
        if (initialsTableData.length > 0) {
            const filteredChannels = initialsTableData.filter(
                (channel) => checkedRowIds[channel.id],
            );
            setSelectedPaymentChannels(filteredChannels);
            updateFormData({ selectedPaymentChannels: filteredChannels });
        }
    }, [checkedRowIds, initialsTableData]);

    const handleCheckboxChange = (e) => {
        setPaymentMethodError(false);
        const { value, checked } = e.target;
        setSelectedPaymentMethods((prev) =>
            checked ? [...prev, value] : prev.filter((method) => method !== value),
        );
        updateFormData({
            selectedPaymentMethods: checked
                ? [...selectedPaymentMethods, value]
                : selectedPaymentMethods.filter((method) => method !== value),
        });
    };

    const addCheckedChannels = (checkedData) => {
        // Collect the IDs of checked items
        const checkedIds = new Set(checkedData.map((data) => data.id));

        // Filter the tableData to get the checked channels
        const checkedChannels = tableData.filter((channel) => checkedIds.has(channel.id));

        // Update the selected payment channels and the form data
        setSelectedPaymentChannels(checkedChannels);
        updateFormData({ selectedPaymentChannels: checkedChannels });

        // Create a mapping of row IDs for checked items
        const checkedRowIds = checkedChannels.reduce((acc, channel) => {
            acc[channel.id] = true;
            return acc;
        }, {});
        setCheckedRowIds(checkedRowIds);
    };

    const columns = useMemo(
        () => [
            { userId: 'id', Header: 'Channel ID', accessor: 'channelId' },

            {
                id: 'name',
                Header: 'Name',
                accessor: 'channelName',
                Cell: function productGroupCell({ row }) {
                    return (
                        <Link
                            className="hover:underline"
                            rel="noreferrer"
                            target="_blank"
                            to={`/payment-channels/view/${row?.original?.id}`}
                        >
                            {row?.original?.channelName}
                        </Link>
                    );
                },
            },
            {
                id: 'description',
                Header: 'Description',
                accessor: 'description',
            },
            {
                id: 'method',
                Header: 'Method',
                accessor: 'paymentMethod',
            },
            {
                id: 'status',
                Header: 'Status',
                accessor: 'isActive',
                Cell: function paymentStatus({ value }) {
                    return (
                        <span
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                value ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'
                            }`}
                        >
                            {value ? 'Active' : 'Inactive'}
                        </span>
                    );
                },
            },
        ],
        [],
    );

    const isValid = () => {
        let isValid = true;

        const selectedDCBChannelsLength = selectedPaymentChannels?.filter(
            (paymentChannel) => paymentChannel.paymentMethod === PaymentMethod[PaymentMethod.DCB],
        ).length;
        const selectedPSPChannelsLength = selectedPaymentChannels?.filter(
            (paymentChannel) => paymentChannel.paymentMethod === PaymentMethod[PaymentMethod.PSP],
        ).length;

        if (selectedPaymentMethods?.length === 0) {
            setPaymentMethodError(true);
            isValid = false;
        } else if (
            selectedPaymentMethods.includes(PaymentMethod.DCB) &&
            selectedPaymentMethods.includes(PaymentMethod.PSP) &&
            selectedPaymentChannels?.length === 0
        ) {
            setWarningMessage('Please select at least one DCB and PSP channel.');
            setShowWarningModal(true);
            isValid = false;
        } else if (
            selectedPaymentMethods.includes(PaymentMethod.DCB) &&
            selectedDCBChannelsLength === 0
        ) {
            setWarningMessage('Please select at least one DCB channel.');
            setShowWarningModal(true);
            isValid = false;
        } else if (
            selectedPaymentMethods.includes(PaymentMethod.PSP) &&
            selectedPSPChannelsLength === 0
        ) {
            setWarningMessage('Please select at least one PSP channel.');
            setShowWarningModal(true);
            isValid = false;
        }

        return isValid;
    };

    const handleSubmit = () => {
        if (isValid()) {
            history.push('/deals/select-products');
        }
    };

    return (
        <div>
            <WarningMessageModal
                showWarningModal={showWarningModal}
                setShowWarningModal={setShowWarningModal}
                warningModalBody={warningMessage}
                warningModalTitle={'Alert'}
            />
            <div className="text-lg font-poppins font-bold px-8 ml-3">Payment Methods</div>
            <div className="flex mt-5 mb-10 ml-7">
                {PAYMENT_METHODS_WITH_CHANNELS.map((paymentMethod) => (
                    <div key={paymentMethod} className="flex mx-5 items-baseline">
                        <input
                            type="checkbox"
                            value={PaymentMethod[paymentMethod]}
                            checked={selectedPaymentMethods.includes(paymentMethod)}
                            onChange={handleCheckboxChange}
                        />
                        <label className="ml-2">{paymentMethod}</label>
                    </div>
                ))}
            </div>
            {paymentMethodError ? (
                <div className="text-red-500 text-xs -mt-3 md:text-sm ml-12">
                    Please select at least one payment method.
                </div>
            ) : null}
            {selectedPaymentMethods.length > 0 && (
                <>
                    <div className="text-lg font-poppins font-bold px-8 ml-3">Payment Channels</div>
                    <div className="border-2 border-gray-300 rounded-lg ml-10 mt-7 mr-7 mb-24">
                        <div className="w-full">
                            <div className="pl-5">
                                <TableTabView
                                    selectedTab={selectedTab}
                                    setSelectedTab={setSelectedTab}
                                    dealPaymentChannelCounts={{
                                        all: tableData.length,
                                        selected: selectedPaymentChannels.length,
                                    }}
                                />
                            </div>

                            {selectedTab === 1 && (
                                <>
                                    {paymentChannelsLoading ? (
                                        <div>
                                            <Loader />
                                        </div>
                                    ) : tableData?.length > 0 ? (
                                        <div className="mt-8">
                                            <div
                                                className="px-5 w-1/2 mb-7"
                                                hidden={!isPaymentMethodFilterVisible}
                                            >
                                                <div className="flex flex-wrap flex-col relative">
                                                    <div className="text-gray-600 w-32 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                                        Filter by method
                                                    </div>

                                                    <select
                                                        className="px-4 py-3 z-0 rounded-lg outline-none border  bg-white border-gray-400 hover:border-purple-500"
                                                        id="filter-by-method"
                                                        placeholder="actions"
                                                        onChange={(e) => {
                                                            setPaymentMethodFilter(e.target.value);
                                                            if (e.target.value !== 'all') {
                                                                setTableData(
                                                                    initialsTableData.filter(
                                                                        (paymentChannel) =>
                                                                            paymentChannel.paymentMethod ===
                                                                            PaymentMethod[
                                                                                e.target.value
                                                                            ],
                                                                    ),
                                                                );
                                                            } else {
                                                                setTableData(initialsTableData);
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            setPaymentMethodFilter(e.target.value);
                                                            if (e.target.value !== 'all') {
                                                                setTableData(
                                                                    initialsTableData.filter(
                                                                        (paymentChannel) =>
                                                                            paymentChannel.paymentMethod ===
                                                                            PaymentMethod[
                                                                                e.target.value
                                                                            ],
                                                                    ),
                                                                );
                                                            } else {
                                                                setTableData(initialsTableData);
                                                            }
                                                        }}
                                                    >
                                                        {paymentMethodOptions.map(
                                                            (option: any, index) => {
                                                                return (
                                                                    <option
                                                                        value={option.value}
                                                                        className={'font-medium'}
                                                                        key={index}
                                                                    >
                                                                        {option.label}
                                                                    </option>
                                                                );
                                                            },
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <DataTable
                                                columns={columns}
                                                data={tableData}
                                                loading={paymentChannelsLoading}
                                                selectedTab={setSelectedTab}
                                                isInitialLoad={isInitialLoad}
                                                setIsInitialLoad={setIsInitialLoad}
                                                checkbox={true}
                                                checkboxHeader={true}
                                                initialSelectedChannels={initialCheckedChannels}
                                                setCheckedChannels={addCheckedChannels}
                                                setCheckedRowIds={setCheckedRowIds}
                                            />
                                        </div>
                                    ) : (
                                        <div className="w-full py-10 text-center">
                                            No active payment channels are available for the
                                            selected payment methods
                                        </div>
                                    )}
                                </>
                            )}
                            {selectedTab === 2 && (
                                <>
                                    {selectedPaymentChannels?.length ? (
                                        <div className="mt-8">
                                            <DataTable
                                                columns={columns}
                                                data={selectedPaymentChannels}
                                            />
                                        </div>
                                    ) : (
                                        <div className="w-full py-10 text-center">
                                            No payment channels have been selected
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
            <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0 z-20">
                <Buttons
                    name="Go Back"
                    type="button"
                    buttonType="secondary-border-black"
                    id="cancel-button"
                    size="e-small"
                    other="mr-3"
                    onclick={() => {
                        history.push('/deals/select-partners');
                    }}
                />
                <Buttons
                    name={'Continue'}
                    type="submit"
                    buttonType="primary"
                    id="save-button"
                    size="e-small"
                    onclick={handleSubmit}
                />
            </div>
        </div>
    );
}
