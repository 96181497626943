import { useLazyQuery, useQuery } from '@apollo/client';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import {
    REPORT_GENERATE_ERROR_RESPONSE,
    REPORT_GENERATE_SUCCESS_RESPONSE,
} from '../../../../constants/reports';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import { FETCH_CATEGORIES_FOR_FILTER } from '../../../../queries/CategoryQueries';
import { FETCH_PARTNER_DETAILS_FOR_FILTER } from '../../../../queries/PartnerQueries';
import { FETCH_ORDERS_REPORT_DATA } from '../../../../queries/ReportQueries';
import { FETCH_SUPPLIERS_DROPDOWN } from '../../../../queries/SupplierQueries';
import NormalLoader from '../../../../utils/normalLoader';
import { Buttons } from '../../../ui/atoms/Button';
import Toast from '../../../ui/atoms/Toast';
import FilterDropdownReports from '../FilterDropdownReports';
import { currencyAndCountryCodes } from '../../../../utils/currencies';
import Multiselect from 'multiselect-react-dropdown';
import {
    convertDataToFilterDropdownOptions,
    sortDataByStatus,
} from '../../../../helpers/FilterDropdownReportsHelper.helpers';

export default function OrdersReport() {
    const [ordersReportQueryCompleted, setOrdersReportQueryCompleted] = useState(false);
    const [message, setMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [error, setError] = useState(false);
    const [supplierData, setSupplierData] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [partnersData, setPartnersData] = useState([]);
    const [selectedPartnerId, setSelectedPartnerId] = useState(null);
    const [selectedSupplierId, setSelectedSupplierId] = useState(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedTransactionType, setSelectedTransactionType] = useState(null);
    const [selectedContentType, setSelectedContentType] = useState(null);
    const [selectedlocalCurrency, setSelectedLocalCurrency] = useState(null);
    const [selectedSourceCurrency, setSelectedSourceCurrency] = useState(null);
    const [selectedCurrencyOfSale, setSelectedCurrencyOfSale] = useState(null);
    const [targetCurrencies, setTargetCurrencies] = useState([]);
    const [selectedTargetCurrencies, setSelectedTargetCurrencies] = useState([]);
    const [startDateMoment, setStartDateMoment] = useState<Moment>(null);
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection',
        },
    ]);

    const downloadReport = (url: string) => {
        const linkEl = document.createElement('a');
        linkEl.href = url;
        linkEl.click();
    };

    const [loadOrderReport, { loading: ordersReportDataLoading, error: ordersReportDataError }] =
        useLazyQuery(FETCH_ORDERS_REPORT_DATA, {
            context: { clientName: ClientsEnum.UTIL },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
            onCompleted: (data) => {
                setOrdersReportQueryCompleted(true);
                downloadReport(data.orderReport.reportUrl);
            },
        });

    const { data: supplierQueryData } = useQuery(FETCH_SUPPLIERS_DROPDOWN, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setSupplierData(getSortedData(supplierQueryData?.suppliers));
        },
    });

    const { data: categoryData } = useQuery(FETCH_CATEGORIES_FOR_FILTER, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setCategoriesData(getSortedData(categoryData?.categories));
        },
    });

    const { data: partnerData } = useQuery(FETCH_PARTNER_DETAILS_FOR_FILTER, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setPartnersData(getSortedData(partnerData?.partners));
        },
    });

    useEffect(() => {
        if (ordersReportDataError) {
            setMessage(REPORT_GENERATE_ERROR_RESPONSE);
            setError(true);
            setShowToast(true);
        }
    }, [ordersReportDataError]);

    useEffect(() => {
        let startDateInstance;
        let endDateInstance;
        state?.map((date) => (startDateInstance = moment(date?.startDate).utcOffset(0, true)));
        state?.map((date) => (endDateInstance = moment(date?.endDate).utcOffset(0, true)));
        setStartDate(startDateInstance.startOf('day').valueOf());
        setEndDate(endDateInstance.endOf('day').valueOf());
        if (startDateInstance) {
            setStartDateMoment(startDateInstance);
        }
    }, [state]);

    useEffect(() => {
        setTargetCurrencies(
            currencyAndCountryCodes
                .map((ccc, index) => ({
                    index: index,
                    key: ccc.currency,
                    icon: <i className={`${ccc.country?.toLowerCase()}`} />,
                }))
                .sort(function (a, b) {
                    if (a.key < b.key) return -1;
                    else return 1;
                }),
        );
    }, []);

    useEffect(() => {
        if (ordersReportQueryCompleted) {
            setMessage(REPORT_GENERATE_SUCCESS_RESPONSE);
            setError(false);
            setShowToast(true);
        }
    }, [ordersReportQueryCompleted]);

    const partnerList = partnersData?.map((partner) =>
        convertDataToFilterDropdownOptions('partner', partner),
    );

    const suppliersList = supplierData?.map((supplier) =>
        convertDataToFilterDropdownOptions('supplier', supplier),
    );

    const categoriesList = categoriesData?.map((category) =>
        convertDataToFilterDropdownOptions('category', category),
    );

    const transactionTypesList = [
        {
            value: 'CURRENCY',
            text: 'Currency',
        },
        {
            value: 'POINTS',
            text: 'Points',
        },
        {
            value: 'PSP',
            text: 'PSP',
        },
    ];

    const contentTypesList = [
        {
            value: 'NORMAL',
            text: 'Normal',
        },
        {
            value: 'PREMIUM',
            text: 'Premium',
        },
    ];

    const getSortedData = (data: any[]) => {
        let allData = [];
        const [activeData, inactiveData, trashedData] = sortDataByStatus(data);

        if (activeData.length > 0) {
            allData = allData.concat([{ text: 'Active', isSeparator: true }, ...activeData]);
        }
        if (inactiveData.length > 0) {
            allData = allData.concat([{ text: 'Inactive', isSeparator: true }, ...inactiveData]);
        }
        if (trashedData.length > 0) {
            allData = allData.concat([{ text: 'Trashed', isSeparator: true }, ...trashedData]);
        }

        return allData;
    };

    const handleGenerateReport = () => {
        setOrdersReportQueryCompleted(false);
        const filteredTargetCurrencies = selectedTargetCurrencies.map((selectedTargetCurrency) => {
            return selectedTargetCurrency.key;
        });

        loadOrderReport({
            variables: {
                filter: {
                    dateStartTimeMillis: startDate && startDate < endDate ? startDate : null,
                    dateEndTimeMillis:
                        startDate && endDate && startDate < endDate
                            ? endDate
                            : !endDate && startDate
                            ? startDateMoment
                                ? startDateMoment.endOf('day').valueOf()
                                : null
                            : null,
                    partnerId: selectedPartnerId ? selectedPartnerId : null,
                    supplierId: selectedSupplierId ? selectedSupplierId : null,
                    categoryId: selectedCategoryId ? selectedCategoryId : null,
                    transactionType: selectedTransactionType ? selectedTransactionType : null,
                    sourceCurrency: selectedSourceCurrency ? selectedSourceCurrency : null,
                    targetCurrencies:
                        filteredTargetCurrencies && filteredTargetCurrencies?.length > 0
                            ? filteredTargetCurrencies
                            : null,
                    localCurrency: selectedlocalCurrency ? selectedlocalCurrency : null,
                    currencyOfSale: selectedCurrencyOfSale ? selectedCurrencyOfSale : null,
                    contentType: selectedContentType ? selectedContentType : null,
                },
            },
        });
    };

    return (
        <div className="flex flex-col">
            {/* <div className="flex justify-between pr-10">
                <div className="text-lg font-poppins font-bold my-auto w-full border-b-2 border-gray-100">
                    Orders report
                </div>
            </div> */}
            <div className="mt-5">
                {showToast && (
                    <Toast
                        setShowToast={setShowToast}
                        message={message}
                        width="w-10/12"
                        error={error}
                        selfDestruct={true}
                        selfDestructTimer={5000}
                    />
                )}
                <div className="flex flex-col pr-8">
                    <div className="border-2 border-gray-300 rounded-lg mb-10 max-h-600">
                        <div className="w-full">
                            <div className="flex m-1 p-1">
                                <div className="flex-1 font-poppins mr-2">
                                    <div className="flex flex-wrap flex-col relative">
                                        <div className="text-gray-600 w-40 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3.5 ml-4">
                                            Filter by Created Date
                                        </div>
                                        <DateRange
                                            editableDateInputs={true}
                                            onChange={(item) => {
                                                setState([item.selection]);
                                            }}
                                            moveRangeOnFirstSelection={false}
                                            ranges={state}
                                        />
                                    </div>
                                </div>
                                <div className="flex-1 space-y-4 font-poppins mr-2">
                                    <FilterDropdownReports
                                        dropDownName="Filter by Supplier"
                                        valueList={suppliersList}
                                        setSelectedValue={setSelectedSupplierId}
                                    />
                                    <FilterDropdownReports
                                        dropDownName="Filter by Transaction Type"
                                        valueList={transactionTypesList}
                                        setSelectedValue={setSelectedTransactionType}
                                        widthClass="w-48"
                                    />
                                    <div className="flex order-1 flex-wrap flex-col relative w-full">
                                        <div className="text-gray-500 w-44 text-center font-poppins z-30 text-sm lg:text-sm h-max bg-white -mb-3 ml-4 border-opacity-20">
                                            Select Target Currencies
                                        </div>
                                        <div className="z-20">
                                            <Multiselect
                                                displayValue="key"
                                                style={{
                                                    chips: {
                                                        background: '#c084fc',
                                                    },
                                                    multiselectContainer: {
                                                        color: 'black',
                                                    },
                                                    searchBox: {
                                                        border: '1px solid #9ca3af',
                                                        borderRadius: '10px',
                                                        padding: '9px',
                                                    },
                                                }}
                                                selectedValues={selectedTargetCurrencies}
                                                options={targetCurrencies}
                                                avoidHighlightFirstOption={true}
                                                onSelect={(selectedList) => {
                                                    setSelectedTargetCurrencies(selectedList);
                                                }}
                                                onRemove={(selectedList) => {
                                                    setSelectedTargetCurrencies(selectedList);
                                                }}
                                                showCheckbox
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 space-y-4 font-poppins mr-2">
                                    <FilterDropdownReports
                                        dropDownName="Filter by Partner"
                                        valueList={partnerList}
                                        setSelectedValue={setSelectedPartnerId}
                                    />
                                    <FilterDropdownReports
                                        dropDownName="Filter by Supplier Currency"
                                        valueList={targetCurrencies.map((selectedItem) => {
                                            return {
                                                value: selectedItem.key,
                                                text: selectedItem.key,
                                            };
                                        })}
                                        setSelectedValue={setSelectedSourceCurrency}
                                        widthClass="w-48"
                                    />
                                    <FilterDropdownReports
                                        dropDownName="Filter by Currency of Sale"
                                        valueList={targetCurrencies.map((selectedItem) => {
                                            return {
                                                value: selectedItem.key,
                                                text: selectedItem.key,
                                            };
                                        })}
                                        setSelectedValue={setSelectedCurrencyOfSale}
                                        widthClass="w-48"
                                    />
                                </div>
                                <div className="flex-1 space-y-4 font-poppins">
                                    <FilterDropdownReports
                                        dropDownName="Filter by Category"
                                        valueList={categoriesList}
                                        setSelectedValue={setSelectedCategoryId}
                                    />
                                    <FilterDropdownReports
                                        dropDownName="Filter by Local Currency"
                                        valueList={targetCurrencies.map((selectedItem) => {
                                            return {
                                                value: selectedItem.key,
                                                text: selectedItem.key,
                                            };
                                        })}
                                        setSelectedValue={setSelectedLocalCurrency}
                                        widthClass="w-48"
                                    />
                                    <FilterDropdownReports
                                        dropDownName="Filter by Content Type"
                                        valueList={contentTypesList}
                                        setSelectedValue={setSelectedContentType}
                                        widthClass="w-48"
                                    />
                                </div>
                            </div>
                            <div className="flex w-full float-right">
                                <div className="flex flex-1 py-4 px-4 mt-10 justify-end bg-gray-100 bottom-0 right-0">
                                    <Buttons
                                        name={
                                            ordersReportDataLoading ? <NormalLoader /> : 'Generate'
                                        }
                                        type="submit"
                                        buttonType="primary"
                                        id="save"
                                        size="e-small"
                                        onclick={() => {
                                            handleGenerateReport();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
