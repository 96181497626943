import React from 'react';
import { DragHandle } from './DragHandle';
import styled from 'styled-components';

const StyledStaticData = styled.td`
    background: white;
    &:first-of-type {
        min-width: 20ch;
    }
    padding: 12px;
    font-family: 'Poppins', sans-serif;
    background: rgb(250 245 255);
`;

const StyledStaticTableRow = styled.tr`
    box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px, rgb(0 0 0 / 30%) 0px 10px 10px -5px;
    outline: rgb(192 132 252) solid 2px;
    color: rgb(107 114 128);
`;

export const StaticTableRow = ({ row }) => {
    return (
        <StyledStaticTableRow {...row.getRowProps()}>
            {row.cells.map((cell, i) => {
                if (i === 0) {
                    return (
                        <StyledStaticData {...cell.getCellProps()}>
                            <DragHandle isDragging />
                            <span>{cell.render('Cell')}</span>
                        </StyledStaticData>
                    );
                }
                return (
                    <StyledStaticData key={i} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                    </StyledStaticData>
                );
            })}
        </StyledStaticTableRow>
    );
};
