/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Dropdown } from 'semantic-ui-react';
import {
    UPDATE_ADMIN_PROFILE,
    FETCH_ADMIN_ONE_PROFILE_ID,
} from '../../../../queries/AdminProfileQueries';
import { RootState } from '../../../../redux/rootReducer';
import { countryOptions } from '../../../../utils/countries';
import { Buttons } from '../../../ui/atoms/Button';
import { InputField } from '../../../ui/atoms/InputField';
import { Images } from '../../../ui/atoms/Images';
import UnsaveChangesWarning from '../../../templates/modals/UnsaveChangesWarning';
import { getCookie } from '../../../../utils/cookiesService';
import jwtDecode from 'jwt-decode';
import {
    adminClientLogin,
    assignClientRoleToUser,
    createUser,
    getAllUserDetails,
    getRoles,
    getUserDetailsUsingAdminClient,
    localRemoveCreatedPartner,
    localRemoveKeycloakCreatedPartner,
    setPartner,
    newUserInvite,
    resetPasswordAction,
    savePartnerName,
} from '../../../../redux/rootActions';
import {
    PARTNER_ADD_EXIT_CONFIRM_MESSAGE,
    PARTNER_ADD_EXIT_CONFIRM_TITLE,
    PARTNER_CREATED_SUCCESSFULLY,
    PARTNER_CURRENCY_ADD_PATH,
} from '../../../../constants/partner';
import Toast from '../../../ui/atoms/Toast';
import { RESET_CREATE_USER } from '../../../../enums/redux/redux-enum';
import NormalLoader from '../../../../utils/normalLoader';

export default function AddNewPartnerGeneralView() {
    const userDetailsSaved = useRef(null);
    const formRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const keyCloakCreatedUser = useSelector((state: RootState) => state.auth.createdUser);
    const keyCloakUserCreateError = useSelector(
        (state: RootState) => state.auth.createUserErrorMessage,
    );
    const keyCloakCreateUserSuccess = useSelector(
        (state: RootState) => state.auth.createUserSuccess,
    );
    const roles = useSelector((state: RootState) => state.auth.roles);
    const [country, setCountry] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [countryError, setCountryError] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [addPartnerError, setAddPartnerError] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [dbLoading, setDbLoading] = useState(false);
    const [keyClockUpdated, setKeyClockUpdateCompleted] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [partnerNameGlobal, setPartnerNameGlobal] = useState('');
    const [partnerURLGlobal, setPartnerURLGlobal] = useState('');
    const [partnerEmailGlobal, setPartnerEmailGlobal] = useState('');
    const [partnerPhoneGlobal, setPartnerPhoneGlobal] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [markedUpPercentageForCurrencyGlobal, setMarkedUpPercentageForCurrencyGlobal] =
        useState(0);
    const [markedUpPercentageGlobal, setMarkedUpPercentageGlobal] = useState(0);
    const [markedUpPercentageForPspGlobal, setMarkedUpPercentageForPspGlobal] = useState(0);
    const [error, setError] = useState(false);
    const [keyCloakErrorMessage, setKeyCloakErrorMessage] = useState('');

    const token = getCookie('access_token');
    const tokenDetails: any = token ? jwtDecode(token) : null;
    const currentTimeInMille = new Date().valueOf();

    const { data: loggedInUserData } = useQuery(FETCH_ADMIN_ONE_PROFILE_ID, {
        fetchPolicy: 'network-only',
        variables: {
            userName: tokenDetails?.preferred_username,
        },
    });

    const [savePartner] = useMutation(UPDATE_ADMIN_PROFILE, {
        onCompleted(data) {
            userDetailsSaved.current = true;
            setDbLoading(false);
            setIsDirty(false);
            dispatch(savePartnerName(data?.updateUser?.firstName));
            dispatch(setPartner(data?.updateUser));
            setMessage(PARTNER_CREATED_SUCCESSFULLY);
            setShowToast(true);
            setTimeout(() => {
                history.push(PARTNER_CURRENCY_ADD_PATH);
                setShowToast(false);
            }, 2000);
        },
        onError() {
            setAddPartnerError(true);
        },
    });

    const handleRoleAssign = useCallback(
        (id: any, role: any) => {
            let roleObject = null;
            if (role && roles) {
                roleObject = roles.filter(
                    (roleItem) => roleItem.name?.toLocaleLowerCase() === role?.toLocaleLowerCase(),
                );
            }
            if (roleObject && id) {
                setTimeout(() => {
                    dispatch(assignClientRoleToUser(id, roleObject));
                }, 1000);
                setTimeout(() => {
                    if (keyClockUpdated) {
                        savePartner({
                            variables: {
                                userName: keyCloakCreatedUser[0]?.username,
                                user: {
                                    firstName: partnerNameGlobal,
                                    lastName: partnerNameGlobal,
                                    contact: {
                                        primaryEmail: partnerEmailGlobal,
                                        recoveryEmail: partnerEmailGlobal,
                                        countryCode: countryCode,
                                        phoneNumber: partnerPhoneGlobal,
                                    },
                                    partner: {
                                        partnerName: partnerNameGlobal,
                                        partnerUrl: partnerURLGlobal,
                                        creditLimit: 0.0,
                                        markedUpPercentageForCurrency:
                                            markedUpPercentageForCurrencyGlobal,
                                        markedUpPercentage: markedUpPercentageGlobal,
                                        markedUpPercentageForPsp: markedUpPercentageForPspGlobal,
                                        createdBy: loggedInUserData?.user?.userId,
                                        modifiedBy: 0,
                                        isActive: false,
                                        partnerAgreements: [],
                                    },
                                },
                            },
                        });
                    }
                }, 3000);
            }
        },
        [
            countryCode,
            dispatch,
            keyCloakCreatedUser,
            keyClockUpdated,
            loggedInUserData?.user?.userId,
            markedUpPercentageForCurrencyGlobal,
            markedUpPercentageForPspGlobal,
            markedUpPercentageGlobal,
            partnerEmailGlobal,
            partnerNameGlobal,
            partnerPhoneGlobal,
            partnerURLGlobal,
            roles,
            savePartner,
        ],
    );

    useEffect(() => {
        dispatch(getRoles());
        dispatch(localRemoveCreatedPartner());
        dispatch(adminClientLogin());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let selectedCode;

    if (country) {
        selectedCode = countryOptions?.filter((countryOption: any) => {
            return countryOption?.text === country;
        });
    }
    const handleFetchCreatedUser = useCallback(() => {
        if (userEmail) {
            setTimeout(() => {
                dispatch(getUserDetailsUsingAdminClient(userEmail));
                dispatch(newUserInvite(userEmail));
                setKeyClockUpdateCompleted(true);
            }, 2000);
        }
    }, [dispatch, userEmail]);

    useEffect(() => {
        if (keyCloakCreatedUser) {
            handleRoleAssign(keyCloakCreatedUser[0].id, 'partner');
        }
    }, [handleRoleAssign, keyCloakCreatedUser]);

    useEffect(() => {
        if (keyCloakUserCreateError) {
            setKeyCloakErrorMessage(keyCloakUserCreateError);
            setDbLoading(false);
            setIsDirty(false);
        }
    }, [keyCloakUserCreateError]);

    useEffect(() => {
        if (!country && clicked) {
            setCountryError(true);
        } else if (country && clicked) {
            setCountryError(false);
        } else {
            setCountryError(false);
        }
    }, [country, clicked]);

    useEffect(() => {
        if (keyCloakCreateUserSuccess) {
            handleFetchCreatedUser();
        }
    }, [keyCloakCreateUserSuccess, handleFetchCreatedUser]);

    useEffect(() => {
        return () => {
            dispatch({ type: RESET_CREATE_USER });
            dispatch(localRemoveKeycloakCreatedPartner());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validationSchema = Yup.object({
        partnerName: Yup.string().trim().required('Partner name is required'),
        partnerURL: Yup.string().trim().required('Partner URL is required'),
        partnerPhone: Yup.string()
            .required('Phone Number is required')
            .min(7, 'The number of digits cannot be less than 7')
            .matches(/^[0-9]+$/, 'Enter a valid phone number')
            .max(15, 'The number of digits cannot exceed 15'),
        partnerEmail: Yup.string()
            .trim()
            .email('Invalid email address')
            .required('Email address is required'),
        markedUpPercentageForCurrency: Yup.number()
            .positive()
            .required('Marked up percentage is required')
            .label('Marked up percentage for currency')
            .moreThan(0, 'Marked up percentage should be greater than 0')
            .max(100, 'Marked up percentage must be less than or equal to 100'),
        markedUpPercentage: Yup.number()
            .positive()
            .required('Marked up percentage required')
            .label('Marked up percentage')
            .moreThan(0, 'Marked up percentage should be greater than 0')
            .max(100, 'Marked up percentage must be less than or equal to 100'),
        markedUpPercentageForPsp: Yup.number()
            .positive()
            .required('Marked up percentage is required')
            .label('Marked up percentage for PSP currency')
            .moreThan(0, 'Marked up percentage should be greater than 0')
            .max(100, 'Marked up percentage must be less than or equal to 100'),
    });
    return (
        <>
            {showToast && (
                <Toast
                    setShowToast={setShowToast}
                    message={message}
                    error={error}
                    width="w-10/12"
                    margin="-mt-10 ml-10"
                    selfDestruct={true}
                    selfDestructTimer={5000}
                />
            )}
            <Formik
                innerRef={formRef}
                initialValues={{
                    partnerName: '',
                    partnerURL: '',
                    partnerEmail: '',
                    partnerPhone: '',
                    markedUpPercentageForCurrency: 0.0,
                    markedUpPercentage: 0.0,
                    markedUpPercentageForPsp: 0.0,
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={({
                    partnerName,
                    partnerURL,
                    partnerEmail,
                    partnerPhone,
                    markedUpPercentageForCurrency,
                    markedUpPercentage,
                    markedUpPercentageForPsp,
                }) => {
                    setUserEmail(partnerEmail);
                    setPartnerEmailGlobal(partnerEmail);
                    setPartnerNameGlobal(partnerName);
                    setPartnerURLGlobal(partnerURL);
                    setPartnerPhoneGlobal(partnerPhone);
                    setMarkedUpPercentageForCurrencyGlobal(markedUpPercentageForCurrency);
                    setMarkedUpPercentageGlobal(markedUpPercentage);
                    setMarkedUpPercentageForPspGlobal(markedUpPercentageForPsp);
                    dispatch(createUser(currentTimeInMille, partnerEmail));
                    setCountryCode(selectedCode[0]?.value);
                    setCountryError(false);
                    setDbLoading(true);
                    dispatch(getAllUserDetails());
                }}
            >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                    <div className="h-full flex flex-col  justify-evens">
                        <div className="text-lg font-poppins font-bold px-8">General</div>
                        <div className="grid grid-cols-8 gap-4 mt-8 px-8">
                            <div className="col-start-1 col-end-4">
                                <InputField
                                    id="partnerName"
                                    placeHolder={'Partner Name'}
                                    name="Partner Name"
                                    labelWidth="w-21"
                                    onChange={(e) => {
                                        handleChange(e);
                                        setIsDirty(true);
                                    }}
                                    value={values.partnerName}
                                />
                                {errors.partnerName && touched.partnerName ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.partnerName}
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-start-1 col-end-4">
                                <InputField
                                    id="partnerURL"
                                    placeHolder={'Partner URL'}
                                    name="Partner URL"
                                    labelWidth="w-21"
                                    onChange={(e) => {
                                        handleChange(e);
                                        setIsDirty(true);
                                    }}
                                    value={values.partnerURL}
                                />
                                {errors.partnerURL && touched.partnerURL ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.partnerURL}
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-start-4 col-span-3 mt-0.5">
                                <div className="flex flex-wrap flex-col relative lg:mb-5">
                                    <div className="text-gray-500 w-16 z-20 text-center font-poppins text-sm lg:text-sm h-max bg-white -mb-3 ml-4 border-opacity-20">
                                        Country
                                    </div>

                                    <Dropdown
                                        className="dropdownProfile"
                                        placeholder="Select Country"
                                        id="countryCode"
                                        fluid
                                        key={'countryCode'}
                                        wrapSelection
                                        selection
                                        onChange={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );

                                            setIsDirty(true);
                                        }}
                                        onKeyDown={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );
                                            setIsDirty(true);
                                        }}
                                        onSearchChange={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );
                                            setIsDirty(true);
                                        }}
                                        onKeyUp={(e) => {
                                            handleChange(e);
                                            setCountry(
                                                countryOptions
                                                    ?.map((country) => country?.text)
                                                    ?.includes(e.target['outerText'])
                                                    ? e.target['outerText']
                                                    : null,
                                            );
                                            setIsDirty(true);
                                        }}
                                        options={countryOptions}
                                        pointing="top"
                                    />
                                </div>
                                {countryError ? (
                                    <div className="text-red-500 text-xs -mt-3 md:text-sm">
                                        {'Country is required'}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <hr className="px-8 border" />
                        <div className="text-lg font-poppins font-bold pt-8 px-8">
                            Contact Information
                        </div>
                        <div className="grid grid-cols-8 gap-4 mt-8 px-8">
                            <div className="col-start-1 col-end-4">
                                <InputField
                                    id="partnerEmail"
                                    placeHolder={'Email Address'}
                                    name="Email Address"
                                    labelWidth="w-21"
                                    onChange={(e) => {
                                        handleChange(e);
                                        setIsDirty(true);
                                        if (e.target.value != values.partnerEmail) {
                                            setKeyCloakErrorMessage('');
                                        }
                                    }}
                                    value={values.partnerEmail}
                                />
                                {(errors.partnerEmail && touched.partnerEmail) ||
                                keyCloakErrorMessage ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.partnerEmail
                                            ? errors.partnerEmail
                                            : keyCloakErrorMessage}
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-start-4 col-end-5 pt-2">
                                <div className={`flex flex-wrap relative w-full`}>
                                    <input
                                        className={
                                            'p-4 min-w-full text-gray-400 cursor-default hover:border-gray-400 rounded-xl outline-none border-2 font-poppins bg-white border-gray-400'
                                        }
                                        value={
                                            selectedCode?.length
                                                ? selectedCode[0]?.dial_value
                                                : null
                                        }
                                        placeholder="Country Code"
                                        id="code"
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className=" col-start-6 col-span-4  lg:col-end-7 lg:col-span-2">
                                <InputField
                                    id="partnerPhone"
                                    placeHolder="Phone Number"
                                    labelWidth="w-32"
                                    name="Phone Number"
                                    value={values?.partnerPhone}
                                    onChange={(e) => {
                                        const numbersRegex = /^[0-9]+$/;
                                        if (numbersRegex.test(e.target.value)) {
                                            handleChange(e);
                                            setIsDirty(true);
                                        }
                                    }}
                                />
                                {errors.partnerPhone && touched.partnerPhone ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.partnerPhone}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <hr className="px-8 border mt-5" />

                        <>
                            <div className="text-lg font-poppins font-bold px-8 mt-8">
                                Marked-up Price Calculation
                            </div>
                            <div className="grid grid-cols-8 gap-4 gap-x-10 mt-8 px-8 mb-40">
                                <div className="col-start-1 col-span-3 flex flex-col">
                                    <div className="flex gap-2">
                                        <InputField
                                            id="markedUpPercentageForCurrency"
                                            placeHolder={'0'}
                                            name="Marked-up Percentage (DCB Currency)"
                                            labelWidth="w-21"
                                            onChange={(e) => {
                                                handleChange(e);
                                                setIsDirty(true);
                                            }}
                                            value={values.markedUpPercentageForCurrency}
                                            type="number"
                                            min="0"
                                        />
                                        <div className="col-span-1 my-auto font-poppins text-2xl">{`%`}</div>
                                    </div>
                                    {errors.markedUpPercentageForCurrency &&
                                    touched.markedUpPercentageForCurrency ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {errors.markedUpPercentageForCurrency}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-start-4 col-span-3 flex flex-col">
                                    <div className="flex gap-2">
                                        <InputField
                                            id="markedUpPercentage"
                                            placeHolder={'0'}
                                            name="Marked-up Percentage (Points)"
                                            labelWidth="w-21"
                                            onChange={(e) => {
                                                handleChange(e);
                                                setIsDirty(true);
                                            }}
                                            value={values.markedUpPercentage}
                                            type="number"
                                            min="0"
                                        />
                                        <div className="col-span-1 my-auto font-poppins text-2xl">{`%`}</div>
                                    </div>
                                    {errors.markedUpPercentage && touched.markedUpPercentage ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {errors.markedUpPercentage}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-start-1 col-span-3 flex flex-col">
                                    <div className="flex gap-2">
                                        <InputField
                                            id="markedUpPercentageForPsp"
                                            placeHolder={'0'}
                                            name="Marked-up Percentage (PSP Currency)"
                                            labelWidth="w-21"
                                            onChange={(e) => {
                                                handleChange(e);
                                                setIsDirty(true);
                                            }}
                                            value={values.markedUpPercentageForPsp}
                                            type="number"
                                            min="0"
                                        />
                                        <div className="col-span-1 my-auto font-poppins text-2xl">{`%`}</div>
                                    </div>
                                    {errors.markedUpPercentageForPsp &&
                                    touched.markedUpPercentageForPsp ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {errors.markedUpPercentageForPsp}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </>
                        {addPartnerError && (
                            <div className="flex flex-1 flex-col align-middle items-center justify-center px-8 lg:mt-32 md:mt-28 sm:mt-16">
                                <div className="h-full align-middle my-2">
                                    <Images
                                        width="w-16"
                                        height="h-16"
                                        src="/images/icons/error-svg.svg"
                                        alt="success"
                                    />
                                </div>
                                <div className="h-full align-middle my-4 text-center font-poppins text-base text-red-500">
                                    Oops something went wrong
                                </div>
                                <div
                                    role="presentation"
                                    className="h-full align-middle my-4 text-center font-poppins text-base text-purple-500 underline cursor-pointer"
                                    onClick={() => {
                                        if (!country) setCountryError(true);
                                        handleSubmit();
                                    }}
                                >
                                    Try again
                                </div>
                            </div>
                        )}
                        <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0 z-20">
                            <Buttons
                                name="Cancel"
                                type="button"
                                buttonType="secondary-border-black"
                                id="cancel-button"
                                size="e-small"
                                other="mr-3"
                                onclick={() => {
                                    history.push('/partners');
                                }}
                            />
                            <Buttons
                                name={dbLoading ? <NormalLoader /> : 'Save'}
                                type="submit"
                                buttonType="primary"
                                id="save-button"
                                size="e-small"
                                onclick={() => {
                                    setClicked(true);
                                    if (!countryError) {
                                        handleSubmit();
                                    }
                                }}
                            />
                        </div>
                        <UnsaveChangesWarning
                            contentText={PARTNER_ADD_EXIT_CONFIRM_MESSAGE}
                            messageTitle={PARTNER_ADD_EXIT_CONFIRM_TITLE}
                            when={isDirty || window.onbeforeunload ? true : false}
                            navigate={(path) => history.push(path)}
                            shouldBlockNavigation={(location) => {
                                if (isDirty) {
                                    return true;
                                }
                                return false;
                            }}
                            displayIcon={true}
                            displayIconName={'clarity_error-standard-line-svg.svg'}
                        />
                    </div>
                )}
            </Formik>
        </>
    );
}
