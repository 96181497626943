import { Images } from './Images';

interface IconButtonProps {
    iconUrl: string;
    height?: string;
    width?: string;
    onClick: (event) => void;
    disabled: boolean;
    id?: string;
}

export default function IconButton({
    iconUrl,
    height,
    width,
    disabled,
    onClick,
    id,
}: IconButtonProps) {
    return (
        <button
            id={id}
            className={`justify-center ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            disabled={disabled}
            onClick={onClick}
        >
            <Images
                height={height ? height : 'hidden md:block'}
                width={width}
                src={iconUrl}
                alt="icon-button"
            />
        </button>
    );
}
