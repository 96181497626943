import { Images } from '../atoms/Images';
import * as FaIcon from 'react-icons/fa';

interface Props {
    bgColourClass: string;
    mainColourClass: string;
    title: string;
    mainValue: string;
    percentageValue: string;
    isIncrement: boolean;
    iconImgSrc: string;
}

export default function SummaryTile({
    bgColourClass,
    mainColourClass,
    title,
    mainValue,
    percentageValue,
    isIncrement,
    iconImgSrc,
}: Props) {
    return (
        <>
            <div className={`flex w-80 h-36 rounded-lg ${bgColourClass ? bgColourClass : ''}`}>
                <div className="my-auto px-4 flex">
                    <div className="mr-7">
                        <Images width={'w-15'} height={'h-15'} src={iconImgSrc} alt="tile image" />
                    </div>
                    <div className="flex flex-col">
                        <div className="text-gray-400 font-poppins text-lg">{title}</div>
                        <div className="font-bold font-poppins text-lg mt-2">{mainValue}</div>
                        <div className="flex mt-1">
                            {isIncrement ? (
                                <>
                                    <span className="font-poppins">
                                        {`+ `}
                                        {percentageValue}
                                    </span>
                                    <span className={`${mainColourClass} ml-2 pt-0.5`}>
                                        <FaIcon.FaArrowUp className="mx-auto" />
                                    </span>
                                </>
                            ) : (
                                <>
                                    <span className="font-poppins">
                                        {`- `}
                                        {percentageValue}
                                    </span>
                                    <span className={`${mainColourClass} ml-2 pt-0.5`}>
                                        <FaIcon.FaArrowDown className="mx-auto" />
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
