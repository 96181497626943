import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { resetPasswordAction } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import NormalLoader from '../../../utils/normalLoader';
import { Buttons } from '../../ui/atoms/Button';

export default function ResetPasswordEmailScreen() {
    const validationSchema = Yup.object({
        email: Yup.string()
            .trim()
            .email('Email must be a valid email')
            .required('Email address is required'),
    });
    const history = useHistory();
    const currentUserDetails = useSelector((state: RootState) => state.auth.fetchedUser);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const isEmailSent = useSelector((state: RootState) => state.auth.isEmailSent);
    const [email, setEmail] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [clicked, setIsClicked] = useState(false);

    if (currentUserDetails?.length) {
        localStorage.setItem('addedUserID', currentUserDetails[0]?.id);
    }

    const dispatch = useDispatch();

    const sendNewUSerEmailErrorMessage = useSelector(
        (state: RootState) => state.auth.sendNewUSerEmailErrorMessage,
    );

    useEffect(() => {
        if (sendNewUSerEmailErrorMessage) {
            setLoading(false);
            setError(true);
        } else {
            setError(false);
        }
    }, [sendNewUSerEmailErrorMessage]);

    useEffect(() => {
        if (isEmailSent) {
            setLoading(false);
            setTimeout(() => {
                history.push(`/email-Success-Screen/${email}`);
            }, 2000);
        }
    }, [isEmailSent, history, email]);

    return (
        <div className="container h-screen w-screen">
            <div
                className="flex bg-cover bg-center   h-full w-full  md:h-screen md:w-screen  bg-no-repeat"
                style={{ backgroundImage: `url(/images/background.svg)` }}
            >
                <div className="container   md:rounded-md max-w-2xl  md:mt-36 lg:mt-20 xl:mt-7 2xl:mt-auto bg-white flex sm:m-auto  flex-wrap flex-col">
                    <div className="mb-7 md:mb-8 max-w-2xl">
                        <img className="w-28 mx-auto  mt-14" src="/images/favicon.png" alt=""></img>

                        <div className=" flex flex-col flex-wrap justify-center -mt-4">
                            <div className="m-auto text-sm font-extrabold md:text-xl font-poppins justify-center mb-4">
                                ENTER YOUR EMAIL
                            </div>

                            <div className="m-auto text-gray-400 font-poppins flex flex-col text-xs md:text-sm mb-8 md:mb-3">
                                <p className=" m-auto justify-center">
                                    Please enter your username or email address.
                                </p>
                                <p className=" m-auto  justify-center md:hidden ">
                                    You will receive a link to
                                </p>
                                <p className=" m-auto  justify-center md:hidden">
                                    create a new password via email
                                </p>
                                <p className=" m-auto  justify-center hidden md:block">
                                    You will receive a link to create a new password via email
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-col flex-wrap mt-5 md:mt-1 max-w-lg m-auto lg:max-w-2xl">
                            <Formik
                                initialValues={{
                                    email: '',
                                }}
                                enableReinitialize
                                validationSchema={validationSchema}
                                onSubmit={({ email }) => {
                                    setError(false);
                                    setLoading(true);
                                    dispatch(resetPasswordAction(email));
                                    setEmail(email);
                                }}
                            >
                                {({ values, handleChange, handleSubmit }) => (
                                    <form
                                        onSubmit={(event) => {
                                            setIsClicked(true);
                                            event.preventDefault();
                                            handleSubmit();
                                        }}
                                    >
                                        <div className="flex flex-wrap flex-col lg:m-16 px-14 sm:mt-8">
                                            <div className="text-gray-500 w-36 text-center font-poppins text-sm lg:text-sm h-max bg-white z-30  -m-3 ml-4 border-opacity-20">
                                                Enter email address
                                            </div>

                                            <input
                                                className=" p-3 w-full rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                                placeholder="Admin@swarmio.com"
                                                type="email"
                                                id="email"
                                                onChange={handleChange('email')}
                                                value={values.email}
                                            />
                                            {error ? (
                                                <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                    {'User not found'}
                                                </div>
                                            ) : null}
                                        </div>

                                        <div className="flex align-middle justify-center  px-20">
                                            <Buttons
                                                name={loading ? <NormalLoader /> : 'Reset Password'}
                                                type="submit"
                                                buttonType="primary"
                                                id="resetPassword"
                                                size="large"
                                            />
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
