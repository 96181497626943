/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import '../../../styles/dropdown.css';
import { useLazyQuery, useMutation } from '@apollo/client';
import { BsThreeDots, BsFillFunnelFill } from 'react-icons/bs';
import { pagination } from '../../../constants/config/constants';

import TableTabView from '../productScreens/TableTabView';
import ConfirmationModalWithMessage from '../../templates/modals/ConfirmationModalWithMessage';

import WarningMessageModal from '../../templates/modals/WarningMessageModal';
import { useDispatch } from 'react-redux';
import { getAllUserDetails, getRoles } from '../../../redux/rootActions';
import SearchBar from '../../ui/molecules/SearchBar';
import {
    ADD_PAYMENT_CHANNEL,
    FETCH_PAYMENT_CHANNELS,
} from '../../../queries/PaymentChannelQueries';
import { Link, useHistory } from 'react-router-dom';
import { Buttons } from '../../ui/atoms/Button';
import {
    ERROR_ADD_PAYMENT_CHANNEL,
    INSTANT_EDIT_OPTION_ACTIVE_PAYMENT_CHANNEL,
    INSTANT_EDIT_OPTION_EDIT_PAYMENT_CHANNEL,
    INSTANT_EDIT_OPTION_INACTIVE_PAYMENT_CHANNEL,
    INSTANT_EDIT_OPTION_VIEW_PAYMENT_CHANNEL,
    SUCCESS_EDIT_PAYMENT_CHANNEL,
} from '../../../constants/paymentChannels';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import DataTable from '../../ui/organisms/DataTable';
import Toast from '../../ui/atoms/Toast';
import { NOT_REGISTERED } from '../../../constants/profile';

export default function ListPaymentChannelsScreen() {
    const [searchTerm, setSearchTerm] = useState('');
    const [instantEditMenuItems, setInstantEditMenuItems] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [showToast, setShowToast] = useState(false);
    const [confirmationMessageTitle, setConfirmationMessageTitle] = useState('');
    const history = useHistory();
    const [clickedPaymentChannel, setClickedPaymentChannel] = useState(null);

    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isFilterClicked, setIsFilterClicked] = useState(false);
    const [message, setMessage] = useState('');
    const [confirmationModalNoClicked, setConfirmationModalNoClicked] = useState(false);
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [pageSize, setPageSize] = useState(pagination?.pageSize);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [warningModalBody, setWarningModalBody] = useState('');
    const [warningModalTitle, setWarningModalTitle] = useState('');
    const [warningModalOkayClicked, setWarningModalOkayClicked] = useState(false);
    const [selectedPaymentChannel, setSelectedPaymentChannel] = useState(null);
    const [isSelectedPaymentChannelRemoved, setIsSelectedPaymentChannelRemoved] = useState(true);
    const [searchClicked, setSearchClicked] = useState(false);
    const dispatch = useDispatch();
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [error, setError] = useState(false);
    const isRegistered = localStorage.getItem('isRegistered');

    const [paymentChannelData, setPaymentChannelData] = useState([]);

    const paymentChannelMethodDropDownList = [
        { text: 'PSP', value: 'PSP' },
        { text: 'DCB', value: 'DCB' },
    ];

    const [paymentChannelCounts, setPaymentChannelCounts] = useState({
        all: 0,
        active: 0,
        inActive: 0,
    });

    const [getPaymentChannelsData, { loading: paymentChannelsLoading }] = useLazyQuery(
        FETCH_PAYMENT_CHANNELS,
        {
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                setPaymentChannelData(data.paymentChannels.paymentChannels);
                setPaymentChannelCounts({
                    all: data.paymentChannels.totalPaymentChannels,
                    active: data.paymentChannels.totalActivePaymentChannels,
                    inActive: data.paymentChannels.totalInactivePaymentChannels,
                });
                setTotalItems(data.paymentChannels.totalPaymentChannels);
            },
        },
    );

    const [savePaymentChannel, { loading: queryLoading }] = useMutation(ADD_PAYMENT_CHANNEL, {
        refetchQueries: [FETCH_PAYMENT_CHANNELS],
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        onCompleted: () => {
            setShowToast(true);
            setMessage(SUCCESS_EDIT_PAYMENT_CHANNEL);
            setShowConfirmationModal(false);
            setSelectedMenuItem('');
        },
        onError: (error) => {
            setSelectedMenuItem('');
            setShowWarningModal(false);
            setShowConfirmationModal(false);

            const graphQLErrors = error.graphQLErrors;
            if (graphQLErrors && graphQLErrors.length > 0) {
                if (graphQLErrors[0].extensions.errorCode === 1645) {
                    setWarningModalTitle('Error');
                    setWarningModalBody(
                        'This payment channel cannot be inactivated/trashed as it is currently assigned to an active deal.',
                    );
                    setShowWarningModal(true);
                }
            } else {
                setMessage(ERROR_ADD_PAYMENT_CHANNEL);
                setShowToast(true);
                setError(true);
            }
        },
    });

    const paymentChannelNames = paymentChannelData
        ?.map((paymentChannelItem) => paymentChannelItem?.channelName)
        .concat(paymentChannelData?.map((paymentChannelItem) => paymentChannelItem?.channelId));

    useEffect(() => {
        dispatch(getRoles());
    }, [dispatch]);

    useEffect(() => {
        setCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab, pageSize]);

    useEffect(() => {
        dispatch(getAllUserDetails());
    }, [dispatch]);

    useEffect(() => {
        if (warningModalOkayClicked && isRegistered === NOT_REGISTERED) {
            history.push('/adminProfile');
        }
    }, [history, warningModalOkayClicked]);

    useEffect(() => {
        if (confirmationModalNoClicked) {
            setShowConfirmationModal(false);
            setSelectedMenuItem('');
            setConfirmationModalNoClicked(false);
        }
    }, [confirmationModalNoClicked]);

    useEffect(() => {
        if (isFilterClicked === true) {
            getPaymentChannelsData({
                variables: {
                    filter: {
                        paymentChannelMethod: selectedPaymentChannel,
                        active: selectedTab === 2 ? true : selectedTab === 3 ? false : null,
                    },
                    offset: currentPage - 1 || 0,
                    limit: Math.ceil(pageSize) || 10000,
                    sort: null,
                    order: null,
                    searchText: searchTerm && searchClicked ? searchTerm : null,
                },
            });
        } else if (isFilterClicked === false) {
            getPaymentChannelsData({
                variables: {
                    filter: {
                        paymentChannelMethod: null,
                        active: selectedTab === 2 ? true : selectedTab === 3 ? false : null,
                    },
                    offset: currentPage - 1 || 0,
                    limit: Math.ceil(pageSize) || 10000,
                    sort: null,
                    order: null,
                    searchText: searchTerm && searchClicked ? searchTerm : null,
                },
            });
            setSelectedPaymentChannel(null);
            setIsSelectedPaymentChannelRemoved(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentPage,
        pageSize,
        selectedTab,
        isFilterClicked,
        searchTerm,
        searchClicked,
        selectedPaymentChannel,
    ]);

    useEffect(() => {
        const instantMenuList = [
            { text: 'View', value: INSTANT_EDIT_OPTION_VIEW_PAYMENT_CHANNEL },
            { text: 'Edit', value: INSTANT_EDIT_OPTION_EDIT_PAYMENT_CHANNEL },
            {
                text: 'Active',
                value: INSTANT_EDIT_OPTION_ACTIVE_PAYMENT_CHANNEL,
            },
            {
                text: 'Inactive',
                value: INSTANT_EDIT_OPTION_INACTIVE_PAYMENT_CHANNEL,
            },
        ];

        setInstantEditMenuItems(instantMenuList);
    }, [selectedTab]);

    const handlePaymentChannelStatusUpdate = () => {
        let isActive = null;

        if (
            selectedMenuItem === INSTANT_EDIT_OPTION_INACTIVE_PAYMENT_CHANNEL &&
            clickedPaymentChannel
        ) {
            isActive = false;
        } else if (
            selectedMenuItem === INSTANT_EDIT_OPTION_ACTIVE_PAYMENT_CHANNEL &&
            clickedPaymentChannel
        ) {
            isActive = true;
        }

        savePaymentChannel({
            variables: {
                paymentChannel: {
                    id: clickedPaymentChannel?.id,
                    isActive,
                },
            },
        });
    };

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
        }
    };

    useEffect(() => {
        if (
            selectedMenuItem === INSTANT_EDIT_OPTION_VIEW_PAYMENT_CHANNEL &&
            clickedPaymentChannel
        ) {
            history.push(`/payment-channels/view/${clickedPaymentChannel?.id}`);
        } else if (
            selectedMenuItem === INSTANT_EDIT_OPTION_EDIT_PAYMENT_CHANNEL &&
            clickedPaymentChannel
        ) {
            history.push(`/payment-channels/edit/${clickedPaymentChannel?.id}`);
        } else if (
            selectedMenuItem === INSTANT_EDIT_OPTION_INACTIVE_PAYMENT_CHANNEL &&
            clickedPaymentChannel
        ) {
            setConfirmationMessageTitle('Confirm Inactivation');
            setConfirmationMessage('Do you want to inactivate the selected payment channel ?');
            setShowConfirmationModal(true);
        } else if (
            selectedMenuItem === INSTANT_EDIT_OPTION_ACTIVE_PAYMENT_CHANNEL &&
            clickedPaymentChannel
        ) {
            setConfirmationMessageTitle('Confirm Activation');
            setConfirmationMessage('Do you want to activate the selected payment channel ?');
            setShowConfirmationModal(true);
        }
    }, [clickedPaymentChannel, history, selectedMenuItem, selectedTab]);

    const columns = React.useMemo(
        () => [
            { userId: 'id', Header: 'Channel ID', accessor: 'channelId' },

            {
                id: 'name',
                Header: 'Name',
                accessor: 'channelName',
                Cell: function productGroupCell({ row }) {
                    return (
                        <Link
                            className="hover:underline"
                            rel="noreferrer"
                            target="_blank"
                            to={`/payment-channels/view/${row?.original?.id}`}
                        >
                            {row?.original?.channelName}
                        </Link>
                    );
                },
            },
            {
                id: 'description',
                Header: 'Description',
                accessor: 'description',
            },
            {
                id: 'method',
                Header: 'Method',
                accessor: 'paymentMethod',
            },
            {
                id: 'status',
                Header: 'Status',
                accessor: 'isActive',
                Cell: function paymentStatus({ value }) {
                    if (value) {
                        return (
                            <span className="inline-flex rounded-full h-6 px-3 justify-center items-center text-sm bg-green-100 text-green-600">
                                Active
                            </span>
                        );
                    } else {
                        return (
                            <span className="inline-flex rounded-full h-6 px-3 justify-center items-center text-sm bg-red-100 text-red-600">
                                Inactive
                            </span>
                        );
                    }
                },
            },
            {
                id: 'actionColumn',
                accessor: '',
                disableSortBy: true,
                width: 5,
                Cell: function showActionColumn({ row }) {
                    return (
                        <div className="cursor__pointer" id={`${row?.original?.id}-action-button`}>
                            <button
                                value="menu cursor-pointer"
                                className="text-gray -500 rounded cursor-pointer border border-transparent focus:outline-none lg:ml-2"
                            >
                                <BsThreeDots className="mx-auto" />
                            </button>
                        </div>
                    );
                },
            },
        ],
        [],
    );

    return (
        <div className="border-2 border-gray-300 rounded-lg mr-7 mb-10">
            {showToast && (
                <Toast
                    setShowToast={setShowToast}
                    message={message}
                    error={error}
                    margin="ml-1"
                    width="w-10/12"
                    selfDestruct={true}
                    selfDestructTimer={2000}
                />
            )}
            <div className="w-full ml-4 pt-4">
                <div className={`${showToast ? 'mt-14' : null}`}>
                    <TableTabView
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        paymentChannelCounts={paymentChannelCounts}
                    />
                    <ConfirmationModalWithMessage
                        showConfirmationModal={showConfirmationModal}
                        setShowConfirmationModal={setShowConfirmationModal}
                        message={confirmationMessage}
                        messageTitle={confirmationMessageTitle}
                        isProcessing={queryLoading}
                        setYesBtnClick={() => handlePaymentChannelStatusUpdate()}
                        setNoBtnClick={setConfirmationModalNoClicked}
                    />
                    <WarningMessageModal
                        showWarningModal={showWarningModal}
                        setShowWarningModal={setShowWarningModal}
                        warningModalBody={warningModalBody}
                        warningModalTitle={warningModalTitle}
                    />
                </div>
            </div>
            <div className={`flex p-4`}>
                <div className="flex w-full">
                    <div className="flex realative lg:mr-4">
                        <button
                            id="filter-button"
                            onKeyDown={() => {
                                setIsFilterClicked(!isFilterClicked);
                            }}
                            onClick={() => setIsFilterClicked(!isFilterClicked)}
                            className={`bg-transparent hover:bg-purple-500 hover:text-white py-2 px-4 border border-gray-300 hover:border-transparent rounded-md ${
                                isFilterClicked ? 'bg-purple-500 text-white' : 'text-gray-500'
                            }`}
                        >
                            <span className="flex">
                                <BsFillFunnelFill /> <span className="ml-2">Filter</span>
                            </span>
                        </button>
                    </div>
                    <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3 text-gray-500">
                        <SearchBar
                            id="user-list-search-field"
                            options={paymentChannelNames}
                            handleChange={(e, newVal, reason) => {
                                handleChange(e, newVal, reason);
                                setSearchClicked(true);
                            }}
                            placeHolder={'Search by channel ID or name'}
                        />
                    </div>
                </div>
                <Buttons
                    name="Add New Channel"
                    type="submit"
                    buttonType="primary"
                    id="invite"
                    size="e-small"
                    padding="py-2"
                    onclick={() => {
                        history.push('/payment-channels/add');
                    }}
                />
            </div>
            {isFilterClicked ? (
                <>
                    <div className="flex flex-row w-full h-24 md-24 p-2">
                        <div className="relative w-1/3 px-2">
                            <div className="flex flex-wrap flex-col relative">
                                <div className="text-gray-600 w-32 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                    Filter by method
                                </div>

                                <select
                                    className="px-4 py-3 z-0 rounded-lg outline-none border  bg-white border-gray-400 hover:border-purple-500"
                                    id="filter-by-method"
                                    placeholder="actions"
                                    onChange={(e) => {
                                        setSelectedPaymentChannel(e.target.value);
                                        setIsSelectedPaymentChannelRemoved(false);
                                    }}
                                    onBlur={(e) => {
                                        setSelectedPaymentChannel(e.target.value);
                                        setIsSelectedPaymentChannelRemoved(false);
                                    }}
                                >
                                    {isSelectedPaymentChannelRemoved ? (
                                        <option value="" disabled selected>
                                            Select roles filters
                                        </option>
                                    ) : null}

                                    {paymentChannelMethodDropDownList.map((option: any, index) => {
                                        return (
                                            <option
                                                value={option.value}
                                                className={'font-medium'}
                                                key={index}
                                            >
                                                {option.text?.length > 32
                                                    ? `${option.text?.slice(0, 32)}...`
                                                    : option.text}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
            {isFilterClicked &&
            !isSelectedPaymentChannelRemoved &&
            selectedPaymentChannel?.length ? (
                <div className="bg-purple-100 w-full h-20 flex  -mt-3 py-2 px-4 my-5 ">
                    <div className="w-full -mt-2">
                        <div className="flex flex-col">
                            <div className="text-purple-500 font-poppins  flex justify-between p-5 -mt-1">
                                <div className="flex">
                                    {selectedPaymentChannel ? (
                                        <div className="border-2 border-purple-300 w-auto h-auto flex py-2 px-3 rounded-md mr-5">
                                            <div className="font-poppins mt-1 mr-3">
                                                {selectedPaymentChannel}
                                            </div>
                                            <button
                                                className="text-purple-700 font-bold  text-lg"
                                                onClick={() => {
                                                    setSelectedPaymentChannel(null);
                                                    setIsSelectedPaymentChannelRemoved(true);
                                                }}
                                            >
                                                x
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                                {!isSelectedPaymentChannelRemoved ? (
                                    <div className="w-auto h-auto p-3 border-l-2 border-gray-300">
                                        <div className="right-0 my-auto  mx-10 border-l-2 flex underline text-lg">
                                            <button
                                                className="text-purple-400 font-bold  text-lg"
                                                onClick={() => {
                                                    setSelectedPaymentChannel(null);
                                                    setIsSelectedPaymentChannelRemoved(true);
                                                }}
                                            >
                                                Clear all
                                            </button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="w-full flex-col">
                {paymentChannelData?.length || paymentChannelsLoading ? (
                    <DataTable //without selection of row
                        columns={columns}
                        data={paymentChannelData}
                        pageCount={pageCount}
                        setPageIndex={setPageIndex}
                        serverSidePagination={true}
                        setDefaultPageSize={setPageSize}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageSizes={pageSize}
                        menuItems={instantEditMenuItems}
                        loading={paymentChannelsLoading}
                        setSelectedMenuItem={setSelectedMenuItem}
                        setExposeMenuClickedItem={setClickedPaymentChannel}
                        selectedTab={selectedTab}
                        isInitialLoad={isInitialLoad}
                        setIsInitialLoad={setIsInitialLoad}
                        totalItems={totalItems}
                    />
                ) : isFilterClicked && !paymentChannelData?.length ? (
                    <div className="m-auto flex flex-col ">
                        <div className="py-3 px-5 bg-purple-100 justify-between flex">
                            {columns?.map((column) => {
                                return (
                                    <div className="text-black font-poppins font-semibold flex ml-12">
                                        {column?.Header}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="m-auto p-10">No data found for selected filter.</div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                ) : searchClicked && !paymentChannelData?.length ? (
                    <div className="flex p-10 justify-center">
                        <div className=" mx-1 ">Your search</div>
                        <div className="text-purple-500">{`"${searchTerm}"`}</div>
                        <div className=" mx-1 ">did not match any results</div>
                    </div>
                ) : (
                    <div className="m-auto flex flex-col ">
                        <div className="py-3 px-5 bg-purple-100 justify-between flex">
                            {columns?.map((column) => {
                                return (
                                    <div className="text-black font-poppins font-semibold flex ml-12">
                                        {column?.Header}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="m-auto p-10">No data found.</div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                )}
            </div>
        </div>
    );
}
