import { useRef, useEffect } from 'react';
import '../../../styles/mutliselect.css';
import Multiselect from 'multiselect-react-dropdown';

interface Props {
    id: string;
    labelName: string;
    dropDownOptions: { cat: string; value: string }[];
    handleSelectedValues: (selectedPartnerList: { cat: string; value: string }[]) => void;
    removeSelectedValues: (removedPartnerList: { cat: string; value: string }[]) => void;
    onClearSelection?: (resetValuesFn: () => void) => void;
}

export default function MultiSelectSearchBar({
    id,
    labelName,
    dropDownOptions,
    handleSelectedValues,
    removeSelectedValues,
    onClearSelection,
}: Props) {
    const multiselectRef = useRef(null);

    // reset selected values
    const resetValues = () => {
        if (multiselectRef.current) {
            multiselectRef.current.resetSelectedValues();
        }
    };

    useEffect(() => {
        onClearSelection(resetValues);
    }, [onClearSelection]);

    return (
        <div className="w-full flex flex-wrap flex-col relative">
            <div className="text-gray-600 w-32 text-center font-poppins text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                {labelName}
            </div>
            <Multiselect
                displayValue="value"
                id={id}
                ref={multiselectRef}
                style={{
                    chips: {
                        background: 'purple',
                    },
                    multiselectContainer: {
                        color: 'black',
                    },
                    searchBox: {
                        border: '1px solid #c4c4c4',
                        'border-radius': '6px',
                        padding: '8.5px',
                    },
                }}
                options={dropDownOptions}
                onSelect={handleSelectedValues}
                onRemove={removeSelectedValues}
                showCheckbox
            />
        </div>
    );
}
