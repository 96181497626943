import { gql } from '@apollo/client';

export const FETCH_PRODUCTS_BY_ID = gql`
    query ($itemId: ID!) {
        item(itemId: $itemId) {
            id
            itemId
            itemName
            itemType
            contentType
            itemStatus
            itemImageUrl
            outOfStockImageUrl
            itemGalleryUrls
            itemDescBrief
            itemDescDetail
            itemImageUrl
            itemGalleryUrls
            deliveryMethod
            isActive
            isDeleted
            currencyConversionRate
            unitPrice
            createdBy
            createdDate
            isStockAvailable
            safetyStockThreshold
            product {
                id
                sellPrice
                currency
                voucherInventories {
                    id
                    batchId
                    status
                    currency
                    unitPrice
                    stockCount
                    createdDate
                    modifiedDate
                }
                orderStrategy
                productType
                productCode
                productId
            }
            supplier {
                id
                supplierId
                supplierName
            }
            itemPartnerPoints {
                id
                partner {
                    id
                    partnerId
                    partnerName
                    markedUpPercentageForCurrency
                    markedUpPercentage
                    markedUpPercentageForPsp
                    localCurrency
                }
                pointsConversionRate
                pointsValue
                markedUpPrice
                localCurrencyConversionRate
            }
        }
    }
`;

export const FETCH_SUPPLIER_PRODUCTS_BY_ID = gql`
    query (
        $filter: ProductsFilterInput
        $offset: Int!
        $limit: Int!
        $supplierId: ID!
        $searchText: String
    ) {
        productsBySupplier(
            filter: $filter
            offset: $offset
            limit: $limit
            searchText: $searchText
            supplierId: $supplierId
        ) {
            products {
                id
                manufacturerName
                totalStock
                availableStock
                productVvssku
                item {
                    id
                    itemId
                    itemName
                    itemType
                    itemStatus
                }
                voucherInventories {
                    id
                    batchId
                    status
                    currency
                    unitPrice
                    stockCount
                    createdDate
                    modifiedDate
                }
                productName
                productId
                unitPrice
                currency
                orderStrategy
                productType
            }
            currentPage
            totalProducts
            totalPages
        }
    }
`;

export const FETCH_PRODUCTS = gql`
    query (
        $filter: ItemFilterInput
        $offset: Int!
        $limit: Int!
        $sort: String
        $order: SortOrder
        $searchText: String
    ) {
        items(
            filter: $filter
            offset: $offset
            limit: $limit
            sort: $sort
            order: $order
            searchText: $searchText
        ) {
            items {
                id
                itemId
                itemName
                itemType
                itemStatus
                contentType
                itemImageUrl
                itemGalleryUrls
                itemDescBrief
                itemDescDetail
                deliveryMethod
                isActive
                isDeleted
                currencyConversionRate
                unitPrice
                createdBy
                createdDate
                isStockAvailable
                safetyStockThreshold
                supplier {
                    id
                    supplierName
                }
                product {
                    id
                    productId
                    productCode
                    sellPrice
                    currencySymbol
                    currency
                    totalStock
                    availableStock
                    productType
                    orderStrategy
                    voucherInventories {
                        id
                        batchId
                        status
                        currency
                        stockCount
                        unitPrice
                        createdDate
                        modifiedDate
                    }
                }
                itemProductGroup {
                    productGroup {
                        id
                        productGroupId
                        name
                    }
                }
            }
            currentPage
            totalItems
            totalPages
            totalPublishedItems
            totalUnpublishedItems
            totalDeletedItems
        }
    }
`;

export const FETCH_PRODUCTS_FOR_PRODUCT_GROUP = gql`
    query (
        $filter: ItemFilterInput
        $offset: Int!
        $limit: Int!
        $sort: String
        $order: SortOrder
        $searchText: String
    ) {
        items(
            filter: $filter
            offset: $offset
            limit: $limit
            sort: $sort
            order: $order
            searchText: $searchText
        ) {
            items {
                id
                itemId
                itemName
                itemType
                itemStatus
                contentType
                isStockAvailable
                itemProductGroup {
                    productGroup {
                        id
                    }
                }
                safetyStockThreshold
                supplier {
                    id
                    supplierName
                }
                product {
                    productType
                    orderStrategy
                    productId
                    productCode
                }
            }
            currentPage
            totalItems
            totalPages
            totalPublishedItems
            totalUnpublishedItems
            totalDeletedItems
        }
    }
`;

export const ADD_PRODUCTS = gql`
    mutation ($item: ItemInput!) {
        saveItem(item: $item) {
            id
            itemId
            itemName
            itemType
            contentType
            itemStatus
            itemImageUrl
            outOfStockImageUrl
            itemGalleryUrls
            itemDescBrief
            itemDescDetail
            deliveryMethod
            isActive
            isDeleted
            currencyConversionRate
            unitPrice
            safetyStockThreshold
            createdBy
            product {
                id
                sellPrice
                currencySymbol
                currency
                voucherInventories {
                    id
                    batchId
                    status
                    currency
                    stockCount
                    unitPrice
                    createdDate
                    modifiedDate
                }
            }
        }
    }
`;

export const UPDATE_PRODUCTS = gql`
    mutation ($itemId: ID, $item: ItemInput!) {
        updateItem(itemId: $itemId, item: $item) {
            id
            itemId
            itemName
            itemType
            itemStatus
            itemImageUrl
            itemGalleryUrls
            itemDescBrief
            itemDescDetail
            deliveryMethod
            isActive
            isDeleted
            currencyConversionRate
            unitPrice
            createdBy
        }
    }
`;

export const UPDATE_PRODUCT = gql`
    mutation ($item: ItemInput!) {
        saveItem(item: $item) {
            id
        }
    }
`;

export const ITEM_LIST_UPDATE = gql`
    mutation ($itemListInput: [ItemListInput!]) {
        updateItemList(itemListInput: $itemListInput) {
            id
            itemId
            itemName
            itemType
            itemStatus
            itemImageUrl
            itemGalleryUrls
            itemDescBrief
            itemDescDetail
            deliveryMethod
            isActive
            isDeleted
            currencyConversionRate
            unitPrice
            createdBy
            createdDate
            supplier {
                id
                supplierName
            }

            product {
                id
            }
        }
    }
`;

export const EXPORT_PARTNER_ITEMS = gql`
    query ($filter: ItemFilterInput, $offset: Int!, $limit: Int, $sort: String, $order: SortOrder) {
        exportItems(filter: $filter, offset: $offset, limit: $limit, sort: $sort, order: $order) {
            filePath
        }
    }
`;
