/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import '../../../styles/calender.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as BsIcons from 'react-icons/bs';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_PRODUCTS, FETCH_PRODUCTS_BY_ID, ADD_PRODUCTS } from '../../../queries/ItemQueries';
import Loader from '../../../utils/loader';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { Buttons } from '../../ui/atoms/Button';
import { InputField } from '../../ui/atoms/InputField';
import { useHistory } from 'react-router';
import NormalLoader from '../../../utils/normalLoader';
import { VOUCHER_STATUS_AVAILABLE } from '../../../constants/supplier';
import { setItemID } from '../../../redux/rootActions';
import { useDispatch } from 'react-redux';

interface Props {
    selectedProduct: any;
}
export default function QuickEditView({ selectedProduct }: Props) {
    const [status, setStatus] = useState('');
    const history = useHistory();
    const [complete, setComplete] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [voucherInventories, setVoucherInventories] = useState([]);
    const [nextVoucherInventories, setNextVoucherInventories] = useState([]);
    const dispatch = useDispatch();
    const productTypeOptions = [
        {
            text: 'Published',
            value: 'PUBLISHED',
        },
        {
            text: 'Unpublished',
            value: 'UNPUBLISHED',
        },
        {
            text: 'Trashed',
            value: 'DELETED',
        },
    ];

    const StockStatus = [
        {
            text: 'AVAILABLE',
            value: 'AVAILABLE',
        },
        {
            text: 'UNAVAILABLE',
            value: 'UNAVAILABLE',
        },
    ];

    const [saveItem, { loading: queryLoading, error: queryError }] = useMutation(ADD_PRODUCTS, {
        refetchQueries: [FETCH_PRODUCTS],
        context: { clientName: ClientsEnum.STORE },
        onCompleted: () => {
            setError(false);
            setMessage('');
            window.location.reload();
            history.push('/products');
        },
        onError: (e) => {
            setError(true);
            setMessage(e.message);
        },
    });

    const { data } = useQuery(FETCH_PRODUCTS_BY_ID, {
        variables: { itemId: selectedProduct?.id },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        context: { clientName: ClientsEnum.STORE },
        onCompleted: () => {
            setComplete(true);
        },
    });

    useEffect(() => {
        const availableVoucher = data?.item?.product?.voucherInventories?.filter((voucher) => {
            return voucher?.status === VOUCHER_STATUS_AVAILABLE;
        });
        const availableIds = availableVoucher?.map((voc) => voc?.id);

        if (availableIds?.length) {
            const firstVoucherID =
                availableIds?.length > 1 ? Math.min(...availableIds) : availableIds[0];

            const firstVoucher = data?.item?.product?.voucherInventories?.find(
                (voucher) => voucher?.id === firstVoucherID.toString(),
            );
            setVoucherInventories(firstVoucher);
            const removeCurrentVocher = data?.item?.product?.voucherInventories?.filter(
                (voucher) => voucher?.id !== firstVoucherID.toString(),
            );
            const nextAvailableVouchers = removeCurrentVocher?.filter((voucher) => {
                return voucher?.status === VOUCHER_STATUS_AVAILABLE;
            });
            const nextAvailableVoucherIDs = nextAvailableVouchers?.map((voc) => voc?.id);
            if (nextAvailableVoucherIDs?.length) {
                const nextVouchers = data?.item?.product?.voucherInventories?.filter(
                    (voucherInventory) => nextAvailableVoucherIDs.includes(voucherInventory.id),
                );
                setNextVoucherInventories(nextVouchers);
            }
        }
    }, [data]);

    const validationSchema = Yup.object({
        itemName: Yup.string().trim().required('item Name is required'),
    });
    const startDate = new Date();
    const defaultStatus = productTypeOptions?.filter((option: any) => {
        if (complete) return option?.value === data?.item?.itemStatus;
    });

    const onclickFunction = (path: any) => {
        dispatch(
            setItemID({
                itemID: selectedProduct?.id,
            }),
        );
        return history.push(`/products/${path}`);
    };
    return (
        <div className="w-full">
            <div className="w-full p-2 m-3 rounded-xl">
                <Formik
                    initialValues={{
                        itemName: selectedProduct?.itemName,
                        itemStatus: defaultStatus[0],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async ({ itemName }) => {
                        saveItem({
                            variables: {
                                item: {
                                    id: selectedProduct?.id,
                                    itemId: data?.item?.itemId,
                                    itemName: itemName,
                                    itemType: data?.item?.itemType,
                                    itemStatus: status || data?.item?.itemStatus,
                                    itemDescBrief: data?.item?.itemDescBrief,
                                    itemDescDetail: data?.item?.itemDescDetail,
                                    itemImageUrl: data?.item?.itemImageUrl,
                                    itemGalleryUrls: data?.item?.itemGalleryUrls,
                                    deliveryMethod: data?.item?.deliveryMethod,
                                    isActive: data?.item?.isActive,
                                    isDeleted: data?.item?.isDeleted,
                                    createdBy: data?.item?.createdBy,
                                    product: {
                                        id: data?.item?.product?.id,
                                    },
                                    currencyConversionRate: data?.item?.currencyConversionRate,
                                    unitPrice: data?.item?.unitPrice,
                                },
                            },
                        });
                    }}
                >
                    {({ values, handleChange, handleSubmit, errors, touched, handleBlur }) => (
                        <div className="w-full">
                            {showToast && (
                                <div
                                    className={`"w-2/3 pl-10 z-50  -mt-5 h-16 flex flex-row ${
                                        error ? 'bg-red-200' : 'bg-green-200'
                                    } mb-2 py-2 rounded-md items-center "`}
                                >
                                    <div className="w-1/10 font-medium text-xl">
                                        <BsIcons.BsCheckCircle className="mx-auto" />
                                    </div>
                                    <div className="flex-1 px-5 font-poppins">{message}</div>
                                    <div className="flex w-2/10 px-5">
                                        <button
                                            onClick={() => {
                                                setShowToast(false);
                                                history.push('/products');
                                            }}
                                        >
                                            <BsIcons.BsX className="mx-auto text-lg" />
                                        </button>
                                    </div>
                                </div>
                            )}

                            {complete ? (
                                <div className="w-full">
                                    {/* change width of quick edit */}
                                    <div className="font-poppins font-bold text-2xl mx-16">
                                        Quick Edit
                                    </div>
                                    <div className="flex">
                                        <div className="grid grid-cols-6 gap-4 mt-6 m-16 w-2/3">
                                            <div className="col-start-1 col-end-4">
                                                <InputField
                                                    id="itemName"
                                                    labelWidth="w-24"
                                                    placeHolder={selectedProduct?.itemName}
                                                    name="Product Title"
                                                    value={values.itemName}
                                                    onChange={handleChange('itemName')}
                                                />

                                                {errors.itemName && touched ? (
                                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                        {errors.itemName}
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div className="col-end-7 col-span-3">
                                                <InputField
                                                    id="itemId"
                                                    labelWidth="w-20"
                                                    placeHolder={selectedProduct?.itemId}
                                                    readonly
                                                    name="Product ID"
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-11 -ml-10">
                                            <img
                                                src="/images/icons/lock.png"
                                                className={`text-gray-700 cursor-pointer w-4 h-5 block`}
                                                alt="show-password"
                                                role="presentation"
                                                draggable="false"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex -mt-10">
                                        <div className="flex mx-10">
                                            <div className="w-16 m-5">
                                                <div className="flex flex-wrap flex-col relative mt-1">
                                                    <div className="text-gray-500 w-14 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                                        Status
                                                    </div>
                                                    <select
                                                        className="p-3 z-0 rounded-xl outline-none border-2 bg-white border-gray-400 hover:border-purple-500"
                                                        id="itemStatus"
                                                        onChange={(e) => {
                                                            setStatus(e.target.value);
                                                        }}
                                                        onBlur={() => handleBlur('itemStatus')}
                                                        defaultValue={defaultStatus[0]?.value}
                                                    >
                                                        {productTypeOptions.map((option: any) => {
                                                            return (
                                                                <option
                                                                    value={option.value}
                                                                    key={option.value}
                                                                >
                                                                    {option.text}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="h-14 mt-7 z-50 items-center flex justify-center ml-14"></div>

                                        <div className="mx-4 mt-10">At</div>
                                        <div className="flex">
                                            <div className="mx-4 mt-6">
                                                <InputField
                                                    id="date"
                                                    placeHolder={startDate
                                                        ?.toLocaleString()
                                                        .substring(
                                                            startDate
                                                                .toLocaleString()
                                                                .indexOf(',') - 10,
                                                        )}
                                                    readonly
                                                    labelWidth="w-30"
                                                    name="Date and Time"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex overflow-y-scroll max-h-40 my-6">
                                            <div className="flex-col px-4">
                                                {voucherInventories['unitPrice'] &&
                                                voucherInventories['stockCount'] ? (
                                                    <>
                                                        <div className="pb-1">
                                                            <InputField
                                                                id="currentPrice"
                                                                placeHolder={`Stock available: ${
                                                                    voucherInventories['stockCount']
                                                                } at cost price of ${
                                                                    voucherInventories[
                                                                        'unitPrice'
                                                                    ].toFixed(2)
                                                                        ? voucherInventories[
                                                                              'unitPrice'
                                                                          ].toFixed(2)
                                                                        : null
                                                                } ${
                                                                    voucherInventories['currency']
                                                                        ? voucherInventories[
                                                                              'currency'
                                                                          ]
                                                                        : null
                                                                }`}
                                                                readonly
                                                                labelWidth="w-30"
                                                                name="Current price"
                                                                size={32}
                                                            />
                                                        </div>
                                                    </>
                                                ) : null}
                                                {nextVoucherInventories?.map(
                                                    (voucherInventory: any, index) => {
                                                        return (
                                                            <>
                                                                <div className="py-2">
                                                                    <InputField
                                                                        id={'nextPrice'}
                                                                        placeHolder={`Stock available: ${
                                                                            voucherInventory[
                                                                                'stockCount'
                                                                            ]
                                                                        } at cost price of ${
                                                                            voucherInventory[
                                                                                'unitPrice'
                                                                            ].toFixed(2)
                                                                                ? voucherInventory[
                                                                                      'unitPrice'
                                                                                  ].toFixed(2)
                                                                                : null
                                                                        } ${
                                                                            voucherInventory[
                                                                                'currency'
                                                                            ]
                                                                                ? voucherInventory[
                                                                                      'currency'
                                                                                  ]
                                                                                : null
                                                                        }`}
                                                                        readonly
                                                                        size={32}
                                                                        isNoLabelWidth={true}
                                                                    />
                                                                </div>
                                                            </>
                                                        );
                                                    },
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {error ? (
                                        <div className="w-full text-red-500">{message}</div>
                                    ) : null}
                                    <div className={`flex flex-col p-4`}>
                                        <div className=" flex justify-end">
                                            <div
                                                id="edit-more-button"
                                                className="underline text-purple-500 font-poppins mx-10 mt-4"
                                                role="button"
                                                tabIndex={0}
                                                onClick={() =>
                                                    onclickFunction(
                                                        `general-edit/${selectedProduct?.id}`,
                                                    )
                                                }
                                                onKeyDown={() =>
                                                    onclickFunction(
                                                        `general-edit/${selectedProduct?.id}`,
                                                    )
                                                }
                                            >
                                                Edit More Details
                                            </div>

                                            <Buttons
                                                name={queryLoading ? <NormalLoader /> : 'Update'}
                                                type="submit"
                                                buttonType="primary"
                                                id="update"
                                                size="e-small"
                                                onclick={() => handleSubmit()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <Loader />
                            )}
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    );
}
