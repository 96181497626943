/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { FETCH_CATEGORIES } from '../../../queries/CategoryQueries';
import { ADD_PRODUCTS, FETCH_PRODUCTS, FETCH_PRODUCTS_BY_ID } from '../../../queries/ItemQueries';
import { Buttons } from '../../ui/atoms/Button';
import { InputField } from '../../ui/atoms/InputField';
import { useHistory } from 'react-router';
import { FETCH_SUPPLIERS } from '../../../queries/SupplierQueries';
import Loader from '../../../utils/loader';
import Toast from '../../ui/atoms/Toast';
import NormalLoader from '../../../utils/normalLoader';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';

export default function EditProductInventory() {
    const history = useHistory();
    const [complete, setComplete] = useState(false);
    const [completeSupplierData, setCompleteSupplierData] = useState(false);
    const [categoryData, setCategoryData] = useState([]);

    const { data: categoryQueryData } = useQuery(FETCH_CATEGORIES, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setCategoryData(categoryQueryData?.categories);
        },
    });

    const selectedProduct = useSelector((state: RootState) => state.itemIDState.itemID);

    const { data: itemData, loading: itemDataLoading } = useQuery(FETCH_PRODUCTS_BY_ID, {
        variables: { itemId: selectedProduct },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        context: { clientName: ClientsEnum.STORE },
        onCompleted: () => {
            setCompleteSupplierData(true);
        },
    });
    const product = itemData?.item;

    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState('');

    const [saveItem, { loading: queryLoading, error: queryError, data: queryData }] = useMutation(
        ADD_PRODUCTS,
        {
            context: { clientName: ClientsEnum.STORE },
            refetchQueries: [FETCH_PRODUCTS],
            fetchPolicy: 'network-only',
            onCompleted: () => {
                setError(false);
                setMessage('Item published successfully');
                setShowToast(true);
                setComplete(true);
                localStorage.removeItem('added_product');
                setTimeout(() => {
                    window.close();
                }, 500);
            },
            onError: () => {
                setMessage(queryError?.message || 'Oops something went wrong');
                setShowToast(true);
                setError(true);
            },
        },
    );

    const { data: supplierData, loading } = useQuery(FETCH_SUPPLIERS, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
    });

    const selectedSupplierID = supplierData?.suppliers?.find((supplier) => {
        if (selectedSupplier) {
            return supplier?.supplierId === selectedSupplier;
        } else if (supplierData?.suppliers?.length === 1) {
            setSelectedSupplier(supplierData?.suppliers[0]?.supplierId);
        }
    });

    const defaultSupplier = supplierData?.suppliers?.filter((option: any) => {
        return option?.id === product?.supplier?.id;
    });

    const validationSchema = Yup.object({
        stock: Yup.string()
            .trim()
            .required('Stock is missing')
            .matches(/[0-9]/, 'Stock must be number'),
    });

    if (loading) return <Loader />;
    if (itemDataLoading) return <Loader />;
    if (product?.supplier?.id && !defaultSupplier[0]?.supplierId) return <Loader />;

    return (
        <Formik
            initialValues={{
                itemCode: '',
                itemName: '',
                itemType: '',
                itemDescBrief: '',
                itemImageUrl: '',
                itemGalleryUrls: [],
                itemDescDetail: '',
                deliveryMethod: '',
                stock: product?.stock,
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={({ itemName, itemDescBrief, itemDescDetail, stock }) => {
                saveItem({
                    variables: {
                        item: {
                            id: product?.id,
                            itemId: product?.itemId,
                            itemName: product?.itemName,
                            itemType: product?.itemType,
                            itemDescBrief: product?.itemDescDetail || '',
                            itemImageUrl: product?.itemImageUrl,
                            itemGalleryUrls: product?.itemGalleryUrls,
                            itemDescDetail: product?.itemDescDetail || '',
                            deliveryMethod: product?.deliveryMethod,
                            isDeleted: product?.isDeleted || false,
                            isActive: product?.isActive || true,
                            pointsValue: product?.pointsValue,
                            itemStatus: product?.itemStatus,
                            createdBy: product?.createdBy,
                        },
                    },
                });
            }}
        >
            {({ handleSubmit, handleChange, values, handleBlur, errors, touched }) => (
                <>
                    {showToast && (
                        <Toast
                            setShowToast={setShowToast}
                            message={message}
                            error={error}
                            width="w-10/12"
                        />
                    )}
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            <div className="w-full h-full flex-col justify-between">
                                <div className="text-xl font-poppins font-bold px-10">
                                    Inventory
                                </div>

                                <div className="grid grid-cols-1 gap-4  xl:grid-cols-8 xl:gap-4 p-8 w-full px-10">
                                    <div className="xl:col-start-1 xl:col-end-5 w-full ">
                                        <div className="flex flex-wrap flex-col relative w-full">
                                            <div className="text-gray-500 w-16 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                                Supplier
                                            </div>
                                            <select
                                                className="px-4 py-3 rounded-xl outline-none border-2 bg-white border-gray-400 hover:border-purple-500"
                                                id="code"
                                                onChange={(e) => {
                                                    setSelectedSupplier(e.target.value);
                                                }}
                                                onBlur={() => handleBlur('code')}
                                                defaultValue={defaultSupplier[0]?.supplierId}
                                            >
                                                <option value="" disabled selected>
                                                    Select supplier
                                                </option>
                                                {supplierData?.suppliers?.map(
                                                    (option: any, index) => {
                                                        return (
                                                            <option
                                                                value={option.supplierId}
                                                                className={'font-medium'}
                                                                key={index}
                                                            >
                                                                {option.supplierName}
                                                            </option>
                                                        );
                                                    },
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="xl:col-start-5 xl:col-span-4">
                                        <InputField
                                            id="stock"
                                            placeHolder="Initial number in stock"
                                            name="Initial Number In Stock"
                                            labelWidth="w-42"
                                            onChange={handleChange('stock')}
                                            value={values.stock}
                                        />
                                        {errors.stock && touched.stock ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                {errors.stock}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="xl:col-start-1 xl:col-span-4 ">
                                        <InputField
                                            id="product_id"
                                            readonly={true}
                                            placeHolder="Product id"
                                            name="Product Id"
                                            labelWidth="w-20"
                                            value={product?.itemId}
                                            opacity="bg-opacity-20"
                                        />
                                    </div>
                                </div>
                                <div className="absolute w-9/12 bottom-0 right-0">
                                    <div
                                        className={`flex flex-row mt-80 bg-gray-100 p-4 justify-end right-0 bottom-0`}
                                    >
                                        <Buttons
                                            name="Cancel"
                                            type="button"
                                            buttonType="secondary-border-black"
                                            id="Cancel"
                                            size="e-small"
                                            other="mr-3"
                                            onclick={() => {
                                                history?.push('/products');
                                            }}
                                        />
                                        <Buttons
                                            name={queryLoading ? <NormalLoader /> : 'Update'}
                                            type="submit"
                                            disabled={false}
                                            buttonType="primary"
                                            id="update"
                                            size="e-small"
                                            onclick={() => {
                                                handleSubmit();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </Formik>
    );
}
