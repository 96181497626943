/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { IconContext } from 'react-icons';
import { useTable, useRowSelect, usePagination, useExpanded } from 'react-table';
import { BsExclamationCircle, BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import IndeterminateCheckbox from '../molecules/IndeterminateCheckbox';
import { useEffect, useState } from 'react';
import React from 'react';
import QuickEditView from '../../screens/productScreens/quickEditView';
import { ItemStatus } from '../../../enums/item';
import {
    DUPLICATE_PRODUCT_REORDER_TOOLTIP_TEXT,
    INSTANT_EDIT_OPTION_PUBLISH,
    INSTANT_EDIT_OPTION_UNPUBLISH,
} from '../../../constants/product';
import { Tooltip } from '@material-ui/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {
    PARTNER_ADD_POINTS_TABLE_TYPE,
    PRODUCT_POINTS_TABLE_TYPE,
    SUPPLIER_PARTNER_POINTS_TABLE_TYPE,
} from '../../../constants/partner';
import PartnerQuickEditView from '../../screens/partnerScreens/addPartner/PartnerQuickEditView';
import PointsQuickEditView from '../../screens/productScreens/PointsQuickEdit';
import IndeterminateRadioButton from '../molecules/IndeterminateRadioButton';
import SupplierPartnerPointsQuickEditView from '../../screens/supplierScreens/SupplierPartnerPointsQuickEdit';
import Loader from '../../../utils/loader';
import { useLocation } from 'react-router-dom';
import { AVAILABLE_PAYMENT_CHANNELS_PAGE } from '../../../constants/productGroups';

interface Props {
    data: any;
    columns: any;
    pageCount?: number;
    pageSizes?: number;
    setSelectedItems?;
    tabStatus?: any;
    setCurrentPage?;
    currentPage?: number;
    setPageIndex?;
    setDefaultPageSize?;
    menuItems?: any;
    setSelectedMenuItem?: (value: any) => void;
    setExposeClickedItem?;
    showReorderTooltip?: boolean;
    hidePagination?: boolean;
    hideSelection?: boolean;
    selectedDefaultItems?: any;
    tableType?: string;
    checkbox?: boolean;
    radioBtn?: boolean;
    checkboxHeader?: boolean;
    clickedItemPointsData?: any;
    selectedTab?: any;
    isRowColour?: boolean;
    isCategoryProductsView?: any;
    customizedPageSize?: number;
    totalItems?: number;
    serverSidePagination?: boolean;
    loading?: boolean;
    isInitialLoad?: boolean;
    setIsInitialLoad?: (value: boolean) => void;
    rowsDisabled?: boolean;
    availablePaymentChannelsPage?: string;
    scrollable?: boolean;
}

const DataTableAlter = ({
    data,
    columns,
    pageCount: controlledPageCount,
    setSelectedItems,
    setPageIndex,
    setDefaultPageSize,
    menuItems,
    isRowColour,
    setSelectedMenuItem,
    setExposeClickedItem,
    showReorderTooltip,
    hidePagination,
    pageSizes,
    hideSelection,
    selectedDefaultItems,
    tableType,
    radioBtn,
    checkboxHeader,
    clickedItemPointsData,
    isCategoryProductsView,
    selectedTab,
    currentPage,
    setCurrentPage,
    checkbox,
    loading,
    totalItems,
    customizedPageSize,
    serverSidePagination,
    isInitialLoad,
    setIsInitialLoad,
    rowsDisabled,
    availablePaymentChannelsPage,
    scrollable,
}: Props) => {
    const [showMenu, setShowMenu] = useState(false);
    const [quickEdit, setQuickEdit] = useState(false);
    const [editedIdPartner, setEditedIdPartner] = useState('');
    const [editedIdsPartner, setEditedIdsPartner] = useState([]);
    const [editedIdProduct, setEditedIdProduct] = useState('');
    const [editedIdsProduct, setEditedIdsProduct] = useState([]);
    const [editedIdSupplier, setEditedIdSupplier] = useState('');
    const [editedIdsSupplier, setEditedIdsSupplier] = useState([]);
    const [selectedItem, setSelectedItem] = useState(0);
    const [clickedItem, setClickedItem] = useState({});
    const [filteredMenuItems, setFilteredMenuItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [goPageNumber, setGoPageNumber] = useState(currentPage);
    const [gotoClicked, setGotoClicked] = useState(false);
    const [goCurrentPage, setGoCurrentPage] = useState(currentPage);

    // Navigation arrows enable/disable state
    const [canGoBack, setCanGoBack] = useState(false);
    const [canGoNext, setCanGoNext] = useState(true);

    const location = useLocation();

    // Onclick handlers for the butons
    const onNextPage = () => setCurrentPage(currentPage + 1);
    const onPrevPage = () => setCurrentPage(currentPage - 1);
    const onPageSelect = (pageNo) => setCurrentPage(pageNo);
    const theme = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    color: 'white',
                    backgroundColor: '#F4B32B',
                },
                arrow: {
                    color: '#F4B32B',
                },
            },
        },
    });

    const getRowId = React.useCallback((row) => {
        return row.id;
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        canExpand,
        pageOptions,
        nextPage,
        previousPage,
        autoResetSelectedRows,
        setPageSize,
        selectedFlatRows,
        gotoPage,
        toggleAllRowsSelected,
        toggleRowSelected,
        allColumns,
        state: { pageIndex, pageSize, selectedRowIds, selectedRow },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: customizedPageSize ? customizedPageSize : pageSizes ? pageSizes : 10,
            },
            autoResetPage: serverSidePagination ? false : true,
            manualPagination: serverSidePagination ? true : false,
            pageCount: controlledPageCount,
            autoResetSelectedRows: serverSidePagination ? false : true,
        },
        useExpanded,
        usePagination,
        useRowSelect,

        (hooks) => {
            if (!hideSelection) {
                hooks.visibleColumns.push((column) => [
                    {
                        id: 'selection',
                        // eslint-disable-next-line react/display-name
                        Header: ({ getToggleAllPageRowsSelectedProps }) => (
                            <div className="flex justify-center -mt-3">
                                {!checkboxHeader ? null : (
                                    <IndeterminateCheckbox
                                        {...getToggleAllPageRowsSelectedProps()}
                                    />
                                )}
                            </div>
                        ),
                        // eslint-disable-next-line react/display-name
                        Cell: ({ row }) => (
                            // eslint-disable-next-line react/display-name
                            <div className="flex justify-center -mt-3">
                                {radioBtn ? (
                                    <IndeterminateRadioButton
                                        {...row.getToggleRowSelectedProps()}
                                        onClick={() => {
                                            toggleAllRowsSelected(false);
                                            toggleRowSelected(row.id, true);
                                        }}
                                    />
                                ) : checkbox ? (
                                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                                ) : null}
                            </div>
                        ),
                    },
                    ...column,
                ]);
            }
        },
    );

    useEffect(() => {
        if (goCurrentPage > 0 && gotoClicked) {
            if (goCurrentPage <= totalPages && gotoClicked) {
                setCurrentPage(goCurrentPage);
            }
        }
    }, [currentPage, goCurrentPage, gotoClicked, totalPages]);

    useEffect(() => {
        if (setSelectedItems) {
            setSelectedItems(
                selectedFlatRows?.map((a) => {
                    return a.original.id;
                }),
            );
        }

        setClickedItem(
            selectedFlatRows?.map((a) => {
                return a.original;
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFlatRows]);

    // useEffect(() => {
    //     if (setCurrentPage) {
    //         setCurrentPage(1);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedTab]); //future usage

    useEffect(() => {
        const array = editedIdsPartner;
        if (editedIdPartner) {
            array.push(editedIdPartner);
            setEditedIdsPartner(array);
        }
    }, [editedIdPartner]);

    useEffect(() => {
        if (totalPages === currentPage) {
            setCanGoNext(false);
        } else {
            setCanGoNext(true);
        }
        if (currentPage === 1) {
            setCanGoBack(false);
        } else {
            setCanGoBack(true);
        }
    }, [totalPages, currentPage]);

    useEffect(() => {
        const array = editedIdsSupplier;
        if (editedIdSupplier) {
            array.push(editedIdSupplier);
            setEditedIdsSupplier(array);
        }
    }, [editedIdSupplier]);

    useEffect(() => {
        const array = editedIdsProduct;
        if (editedIdProduct) {
            array.push(editedIdProduct);
            setEditedIdsProduct(array);
        }
    }, [editedIdProduct]);

    useEffect(() => {
        if (serverSidePagination) {
            setPageIndex(pageIndex);
        }
    }, [pageIndex, setPageIndex, serverSidePagination]); //future usage

    useEffect(() => {
        if (!isInitialLoad && setIsInitialLoad) {
            setPageSize(50);
            setIsInitialLoad(true);
        }

        if (serverSidePagination) {
            setDefaultPageSize(pageSize);
        }
    }, [pageSize, setDefaultPageSize, serverSidePagination, isInitialLoad]);

    useEffect(() => {
        if (isInitialLoad && setIsInitialLoad) {
            setIsInitialLoad(false);
        }
    }, [location.pathname]);

    const showOptions = (item: any) => {
        const items = filterMenuItemByStatus(item?.itemStatus, menuItems);
        setFilteredMenuItems(items);
        setShowMenu(true);
        setClickedItem(item);
        setExposeClickedItem(item);
    };

    const handleMenuItemClick = (value: any, itemId: number) => {
        setSelectedMenuItem(value);
        if (value === 'QUICKEDIT') {
            setSelectedItem(itemId);
            setQuickEdit(!quickEdit);
        }
    };
    useEffect(() => {
        const noOfPages = totalItems ? Math.ceil(totalItems / pageSize) : pageOptions;
        setTotalPages(noOfPages);
        const pagesArr = [...new Array(noOfPages)];
    }, [totalItems, pageSize]);

    const filterMenuItemByStatus = (status: string, items: any) => {
        const filteredStatus =
            status === ItemStatus.PUBLISHED
                ? INSTANT_EDIT_OPTION_PUBLISH
                : status === ItemStatus.UNPUBLISHED
                ? INSTANT_EDIT_OPTION_UNPUBLISH
                : '';
        return filteredStatus !== ''
            ? items?.filter((item) => item.value !== filteredStatus)
            : items;
    };

    const VisibleColumns = allColumns.filter(
        (col) => col.isVisible === true && col.id !== 'selection' && col.id !== 'actionColumn',
    );

    return (
        <section className="h-full">
            <div
                className={`justify-between w-full items-end ${!scrollable && 'overflow-x-scroll'}`}
            >
                {loading ? (
                    <Loader />
                ) : (
                    <table {...getTableProps()} className="w-full min-w-max">
                        <thead className="bg-purple-100  min-w-max">
                            {headerGroups.map((headerGroup: any, index) => (
                                <tr
                                    key={index}
                                    {...headerGroup.getHeaderGroupProps()}
                                    onMouseLeave={() => {
                                        setShowMenu(false);
                                    }}
                                >
                                    {headerGroup.headers.map((column, i) => (
                                        <th
                                            {...column.getHeaderProps()}
                                            className={`py-4 px-3 text-left text-sm text-gray-500 font-poppins min-w-max ${
                                                column.id === 'unitPrice'
                                                    ? 'text-right'
                                                    : 'text-left'
                                            }`}
                                            key={i}
                                        >
                                            {column.render('Header')}
                                            {showReorderTooltip && column.id === 'itemId' && (
                                                <MuiThemeProvider theme={theme}>
                                                    <Tooltip
                                                        key={column.id}
                                                        title={
                                                            DUPLICATE_PRODUCT_REORDER_TOOLTIP_TEXT
                                                        }
                                                        arrow
                                                        placement="top-start"
                                                    >
                                                        <span className="ml-1 inline-block items-center w-full min-w-max">
                                                            <BsExclamationCircle
                                                                color="#ffad03"
                                                                size="14"
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </MuiThemeProvider>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <React.Fragment key={i}>
                                        <tr
                                            {...row.getRowProps()}
                                            title={`${
                                                row?.original?.globallyEnabled == undefined
                                                    ? ''
                                                    : !row?.original?.globallyEnabled
                                                    ? 'This payment channel is disabled from global level'
                                                    : ''
                                            }`}
                                            className={`${
                                                (row?.original?.itemStatus ===
                                                    ItemStatus.UNPUBLISHED ||
                                                    rowsDisabled ||
                                                    row?.original?.productsAvailable == false) &&
                                                'bg-gray-200'
                                            }`}
                                        >
                                            {row.cells.map((cell, j) => {
                                                return (
                                                    <td
                                                        id={
                                                            cell?.column?.id &&
                                                            row?.cells[0]?.row.id &&
                                                            cell.value
                                                                ? `${row.cells[0].row?.original?.userId}-${cell.value}`
                                                                : null
                                                        }
                                                        role="presentation"
                                                        key={j}
                                                        {...cell.getCellProps()}
                                                        className={`p-3 border-b-2 border-gray-100 max-w-3xl  ${
                                                            isRowColour ? 'bg-red-50' : null
                                                        }  text-gray-500  ${
                                                            editedIdsPartner?.includes(
                                                                row?.values?.supplierId,
                                                            )
                                                                ? 'bg-green-100'
                                                                : editedIdsSupplier?.includes(
                                                                      row?.values?.partnerId,
                                                                  )
                                                                ? 'bg-green-100'
                                                                : editedIdsProduct?.includes(
                                                                      row?.values?.pid,
                                                                  )
                                                                ? 'bg-green-100'
                                                                : availablePaymentChannelsPage ===
                                                                      AVAILABLE_PAYMENT_CHANNELS_PAGE &&
                                                                  !row?.original?.globallyEnabled
                                                                ? 'bg-gray-200'
                                                                : null
                                                        } font-poppins ${
                                                            cell.column.id === 'actionColumn' ||
                                                            cell.column.id === 'itemName'
                                                                ? 'text-start'
                                                                : cell.column.id === 'unitPrice'
                                                                ? 'text-right'
                                                                : 'text-left'
                                                        } ${
                                                            cell.column.id === 'itemName'
                                                                ? 'truncate'
                                                                : '' ||
                                                                  cell.column.id === 'transactionId'
                                                                ? 'text-purple-400 underline'
                                                                : null
                                                        }`}
                                                        onClick={() => {
                                                            if (cell.column.id === 'actionColumn') {
                                                                showOptions(row.original);
                                                            }

                                                            if (cell.column.id === 'expander') {
                                                                setSelectedItem(row.original);
                                                            }
                                                            if (
                                                                cell.column.id === 'transactionId'
                                                            ) {
                                                                setExposeClickedItem(row.original);
                                                            }
                                                        }}
                                                        onMouseLeave={() => {
                                                            if (cell.column.id !== 'actionColumn') {
                                                                setShowMenu(false);
                                                            }
                                                        }}
                                                    >
                                                        {cell.render('Cell')}

                                                        {cell.column.id === 'actionColumn' &&
                                                            showMenu &&
                                                            clickedItem === row.original && (
                                                                <div className="absolute right-3 w-48 bg-white rounded-md shadow-xl z-10">
                                                                    <ul
                                                                        className="bg-white shadow-md rounded-lg py-2 px-2 text-start"
                                                                        onMouseLeave={() =>
                                                                            setShowMenu(false)
                                                                        }
                                                                    >
                                                                        {filteredMenuItems?.map(
                                                                            (item, index) => (
                                                                                <li
                                                                                    id={`${item.text}-button`}
                                                                                    key={index}
                                                                                    role="presentation"
                                                                                    className="cursor-pointer text-md py-1 hover:bg-gray-100 font-poppins px-2"
                                                                                    onClick={() => {
                                                                                        handleMenuItemClick(
                                                                                            item.value,
                                                                                            cell
                                                                                                ?.row
                                                                                                ?.values,
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {item.text}
                                                                                </li>
                                                                            ),
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            )}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                        {row.isExpanded &&
                                        tableType === PARTNER_ADD_POINTS_TABLE_TYPE ? (
                                            <tr key={tableType} {...row.getRowProps()}>
                                                <td colSpan={VisibleColumns.length}>
                                                    <PartnerQuickEditView
                                                        selectedSupplier={selectedItem}
                                                        setEditedId={setEditedIdPartner}
                                                    />
                                                </td>
                                            </tr>
                                        ) : row.isExpanded &&
                                          tableType === PRODUCT_POINTS_TABLE_TYPE ? (
                                            <tr key={tableType} {...row.getRowProps()}>
                                                <td colSpan={VisibleColumns.length}>
                                                    <PointsQuickEditView
                                                        selectedPartner={selectedItem}
                                                        setEditedId={setEditedIdProduct}
                                                    />
                                                </td>
                                            </tr>
                                        ) : row.isExpanded &&
                                          tableType === SUPPLIER_PARTNER_POINTS_TABLE_TYPE ? (
                                            <tr key={i + 2} {...row.getRowProps()}>
                                                <td colSpan={VisibleColumns.length}>
                                                    <SupplierPartnerPointsQuickEditView
                                                        selectedPartner={selectedItem}
                                                        setEditedId={setEditedIdSupplier}
                                                    />
                                                </td>
                                            </tr>
                                        ) : row.isExpanded ? (
                                            <tr key={i + 2} {...row.getRowProps()}>
                                                <td colSpan={VisibleColumns.length}>
                                                    <QuickEditView selectedProduct={selectedItem} />
                                                </td>
                                            </tr>
                                        ) : null}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
            {!hidePagination && serverSidePagination ? (
                <div className="flex justify-end py-5 bg-purple-100">
                    <select
                        className="bg-purple-100 text-purple-600"
                        value={pageSize}
                        onBlur={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50].map((pageSize, index) => (
                            <option
                                key={index}
                                value={pageSize}
                                className="text-purple-600 bg-purple-100 mx-3 my-1"
                            >
                                Rows per pages {pageSize}
                            </option>
                        ))}
                    </select>
                    <span className="pl-2 mx-1 flex items-center">
                        <p className="text-purple-600">
                            {currentPage} - {totalPages} of {totalPages}
                        </p>{' '}
                    </span>
                    <span className="flex items-center">
                        <div className="text-purple-600 ml-5">Go to:</div>
                        <input
                            type="number"
                            className="w-20 h-9 mx-2 px-2 outline-none text-purple-600 rounded-lg bg-white text-center"
                            onInput={(e) => {
                                if (Number(e.target['value']) >= 0) {
                                    setGoPageNumber(Number(e.target['value']));
                                }
                            }}
                            value={goPageNumber}
                        />
                        <button
                            className="bg-purple-500 text-white py-2 px-4 rounded-md"
                            onClick={() => {
                                if (goPageNumber < 1) {
                                    setGotoClicked(true);
                                    setGoCurrentPage(1);
                                    setGoPageNumber(1);
                                } else if (goPageNumber > totalPages) {
                                    setGotoClicked(true);
                                    setGoCurrentPage(totalPages);
                                    setGoPageNumber(totalPages);
                                } else {
                                    setGotoClicked(true);
                                    setGoCurrentPage(goPageNumber);
                                }
                            }}
                        >
                            Go
                        </button>
                    </span>
                    <div className="pr-5 flex items-center">
                        <IconContext.Provider
                            value={{
                                color: !canGoBack ? '#bbbcbc' : '#707a92',
                            }}
                        >
                            <button
                                className="text-lg font-medium text-gray-200 ml-3"
                                onClick={onPrevPage}
                                disabled={!canGoBack}
                            >
                                <BsChevronLeft className="" />
                            </button>
                        </IconContext.Provider>
                        <IconContext.Provider value={{ color: !canGoNext ? '#bbbcbc' : '#707a92' }}>
                            <button
                                className="text-lg font-medium text-gray-200 ml-5"
                                onClick={onNextPage}
                                disabled={!canGoNext}
                            >
                                <BsChevronRight className="" />
                            </button>
                        </IconContext.Provider>
                    </div>
                </div>
            ) : !hidePagination && !serverSidePagination ? (
                <div className="flex justify-end py-5 bg-purple-100">
                    <select
                        className="bg-purple-100 text-purple-600"
                        value={pageSize}
                        onBlur={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50].map((pageSize, index) => (
                            <option
                                key={index}
                                value={pageSize}
                                className="text-purple-600 bg-purple-100 mx-3 my-1"
                            >
                                Rows per page {pageSize}
                            </option>
                        ))}
                    </select>
                    <span className="pl-2 mx-1">
                        <p className="text-purple-600">
                            {pageIndex + 1} - {pageOptions.length} of {pageOptions.length}
                        </p>{' '}
                    </span>
                    <div className="pr-5">
                        <IconContext.Provider
                            value={{
                                color: !canPreviousPage ? '#bbbcbc' : '#707a92',
                            }}
                        >
                            <button
                                className="text-lg font-medium text-gray-200 ml-3"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                <BsChevronLeft className="" />
                            </button>
                        </IconContext.Provider>
                        <IconContext.Provider
                            value={{ color: !canNextPage ? '#bbbcbc' : '#707a92' }}
                        >
                            <button
                                className="text-lg font-medium text-gray-200 ml-5"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                <BsChevronRight className="" />
                            </button>
                        </IconContext.Provider>
                    </div>
                </div>
            ) : scrollable ? (
                <></>
            ) : (
                <div className="flex w-full h-12 bg-purple-100"></div>
            )}
        </section>
    );
};

export default DataTableAlter;
