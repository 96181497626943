/* eslint-disable @typescript-eslint/no-unused-vars */

import { Formik } from 'formik';
import { useState, useEffect } from 'react';
import { Buttons } from '../../ui/atoms/Button';
import { InputField } from '../../ui/atoms/InputField';
import { useHistory } from 'react-router';
import UploadCategoryImage from '../categoryScreens/UploadCategoryImage';
import { useQuery } from '@apollo/client';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import Loader from '../../../utils/loader';
import React from 'react';
import { saveProductGroupName } from '../../../redux/rootActions';
import { useDispatch } from 'react-redux';
import DataTableAlter from '../../ui/organisms/DataTableAlter';
import { ALLSERV, API, CSV, CSV_VOUCHERS } from '../../../constants/supplier';
import ReactSwitch from 'react-switch';
import { FETCH_PRODUCTS_GROUPS_BY_ID } from '../../../queries/ProductGroupQueries';
import { useParams } from 'react-router-dom';
import { ProductType } from '../../../enums/product';
import { getSupplierProductCode } from '../../../helpers/ItemHelpers.helpers';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { valueContainerCSS } from 'react-select/src/components/containers';

const animatedComponents = makeAnimated();

export default function ViewProductGroup() {
    const parameters = useParams();
    const [thumbNail, setThumbNail] = useState('');
    const [coverImage, setCoverImage] = useState('');
    const [descriptionLong, setDescriptionLong] = useState('');
    const history = useHistory();
    const [selectedItems, setSelectedItems] = useState(null);

    const productGroupID = parameters['id'];
    const dispatch = useDispatch();

    const { data: productGroupQueryData, loading } = useQuery(FETCH_PRODUCTS_GROUPS_BY_ID, {
        variables: { productGroupId: productGroupID },
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            dispatch(saveProductGroupName(productGroupQueryData?.productGroup?.name));
            setSelectedItems(productGroupQueryData?.productGroup?.items);
            setCoverImage(productGroupQueryData?.productGroup?.bannerImage);
            setThumbNail(productGroupQueryData?.productGroup?.thumbnail);
            setDescriptionLong(productGroupQueryData?.productGroup?.description);
        },
    });

    const category = productGroupQueryData?.category;
    const productGroup = productGroupQueryData?.productGroup;

    const colourStyles = {
        menuList: (styles) => ({
            ...styles,
            background: '#f3e8ff',
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused ? '#f3e8ff' : isSelected ? '#f3e8ff' : undefined,
            zIndex: 1,
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: '#c084fc',
            };
        },
        multiValueRemove: (base) => ({
            ...base,
            display: 'none',
        }),
        menu: (base) => ({
            ...base,
            zIndex: 100,
            background: '#c084fc',
        }),
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Product ID',
                accessor: 'itemId',
            },
            {
                Header: 'Supplier Product Code',
                accessor: 'product',
                Cell: function showSupplierProductCode({ value }) {
                    return getSupplierProductCode(value);
                },
            },
            {
                Header: 'Product Name',
                accessor: 'itemName',
            },
            {
                Header: 'Product Origin',
                id: 'product.orderStrategy',
                accessor: (item) => {
                    if (item.product?.orderStrategy === CSV_VOUCHERS) {
                        return CSV;
                    } else if (item.product?.orderStrategy.startsWith('ALLSERV')) {
                        return ALLSERV;
                    } else {
                        return API;
                    }
                },
            },
            {
                Header: 'Product Type',
                accessor: 'product.productType',
            },
            {
                Header: 'Content Type',
                accessor: 'contentType',
            },
            {
                Header: 'Supplier',
                accessor: 'supplier.supplierName',
            },
            {
                Header: 'Stocks Status',
                accessor: 'isStockAvailable',
                Cell: function showBadge({ value }) {
                    const status = value ? 'In Stock' : 'Out of Stock';
                    return (
                        <span
                            style={{
                                minWidth:
                                    status === 'In Stock'
                                        ? '72px'
                                        : status === 'Out of Stock' && '96px',
                            }}
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                status === 'In Stock'
                                    ? 'bg-yellow-100 text-yellow-600'
                                    : status === 'Out of Stock'
                                    ? 'bg-red-100 text-red-600'
                                    : ''
                            }`}
                        >
                            {status}
                        </span>
                    );
                },
            },
            {
                Header: 'Catalog',
                accessor: 'mappedCatalogs',
                Cell: function showCatalog({ row, value }) {
                    // Assuming row.original is your data object
                    //  catalogId: 103, catalogName: 'RTC partner 1411 Catalog', dealExist: false
                    const catalogMapping: [
                        { catalogId: number; catalogName: string; dealExist: boolean },
                    ] = row?.original?.mappedCatalogs;

                    const catalogDetails = catalogMapping.map((catalog) => {
                        return {
                            value: catalog.catalogId,
                            label: catalog.catalogName,
                        };
                    });

                    return catalogDetails.length > 0 ? (
                        <span className="d-inline-block z-50 h-auto py-3">
                            {
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    components={animatedComponents}
                                    styles={colourStyles}
                                    isDisabled={true}
                                    isClearable={false}
                                    defaultValue={catalogDetails}
                                />
                            }
                        </span>
                    ) : (
                        <span className="d-inline-block z-50 h-auto py-3">
                            <span
                                className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${'bg-gray-100 text-yellow-600'}`}
                            >
                                No catalogs found
                            </span>
                        </span>
                    );
                },
            },
        ],
        [selectedItems],
    );

    if (loading) return <Loader />;

    return (
        <Formik
            initialValues={{
                groupTitle: productGroup?.name,
                displayName: productGroup?.displayName,
                urlMask: productGroup?.urlMask,
                groupDescription: productGroup?.description,
            }}
            enableReinitialize
            onSubmit={() => {
                history.push('/products/groups');
            }}
        >
            {({ handleSubmit, handleChange, values }) => (
                <>
                    <div className="pr-44 -ml-4">
                        <div className="grid grid-cols-8 gap-4">
                            <div className="col-start-1 col-end-9 w-full flex space-x-5">
                                <div className="mt-3 px-4 w-1/2">
                                    <InputField
                                        id="groupTitle"
                                        name="Group Title"
                                        labelWidth="w-25"
                                        maxLength={255}
                                        readonly={true}
                                        onChange={handleChange('groupTitle')}
                                        value={values.groupTitle}
                                    />
                                </div>
                                <div className="flex space-x-5 items-center">
                                    <span>Featured Group</span>
                                    {/* Added an empty function because onchange is required */}
                                    <ReactSwitch
                                        id="featured"
                                        checked={productGroup?.featured}
                                        onChange={() => void 0}
                                        disabled={true}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        onColor="#8b5cf6"
                                        height={24}
                                        width={46}
                                    />
                                </div>
                            </div>

                            <div className="col-start-1 col-end-9 w-full flex space-x-5">
                                <div className="mb-5 mt-3 px-4 w-1/2">
                                    <InputField
                                        id="displayName"
                                        name="Display Name"
                                        labelWidth="w-25"
                                        readonly={true}
                                        maxLength={255}
                                        onChange={(event) => {
                                            handleChange(event);
                                        }}
                                        value={values.displayName}
                                    />
                                </div>
                            </div>

                            <div className="lg:col-start-1 lg:col-end-9">
                                <div className="w-full flex space-x-5 justify-center items-center">
                                    <div className="flex w-1/2 flex-wrap flex-col relative mt-0 px-4">
                                        <div className="text-gray-500 w-24 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                            Product Type
                                        </div>
                                        <select
                                            value={productGroup?.productType}
                                            className="p-4 lg:p-3 lg:py-4  rounded-xl outline-none border-2  bg-white border-gray-400 text-gray-500"
                                            id="product-type"
                                            disabled={true}
                                        >
                                            <option value={null} disabled selected>
                                                Select product type
                                            </option>
                                            <option value={ProductType.VOUCHER}>Voucher</option>
                                            <option value={ProductType.TOPUP}>Topup</option>
                                        </select>
                                    </div>
                                    <div className="px-4 w-1/2 flex flex-col items-start justify-start">
                                        <InputField
                                            id="url-mask"
                                            name="URL Mask"
                                            labelWidth="w-25"
                                            maxLength={255}
                                            readonly={true}
                                            value={values.urlMask}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-start-1 col-end-9 mt-5 flex flex-wrap relative px-4">
                                <div
                                    className={`text-gray-500  w-36 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20`}
                                >
                                    Group Description
                                </div>
                                <textarea
                                    className="p-4 z-0 min-w-full rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 text-gray-400"
                                    id="groupDescription"
                                    name="groupDescription"
                                    rows={5}
                                    cols={138}
                                    draggable={false}
                                    maxLength={600}
                                    onInput={handleChange('groupDescription')}
                                    disabled={true}
                                    value={values?.groupDescription}
                                />
                                <div className="w-full py-0.5 justify-end  flex  pl-4">
                                    <div className="font-poppins text-gray-400 mr-5">
                                        600 characters limited
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-5 flex-col xl:flex-row pl-4 pr-64">
                            <div className="w-full">
                                <div className="flex my-3">
                                    <div className="mr-1 font-poppins">
                                        Group Thumbnail(Optional)
                                    </div>
                                    <div className="text-gray-400">(Max 100X 100)</div>
                                </div>

                                <UploadCategoryImage
                                    width={'w-5/6'}
                                    height={'h-80'}
                                    isEdit={true}
                                    inputId="thumbnail"
                                    disabled={true}
                                    isThumbNail={true}
                                    imageProp={thumbNail}
                                    setImage={setThumbNail}
                                    isThumbnailImage={true}
                                />
                            </div>
                            <div className="w-full">
                                <div className="flex my-3">
                                    <div className="mr-1 font-poppins">
                                        Group Cover Image (Optional)
                                    </div>
                                    <div className="text-gray-400"> (Max 2048 X 2048)</div>
                                </div>

                                <UploadCategoryImage
                                    width={'w-5/6'}
                                    height={'h-80'}
                                    isEdit={true}
                                    inputId="coverImage"
                                    setImage={setCoverImage}
                                    imageProp={coverImage}
                                    disabled={true}
                                    isThumbnailImage={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="my-3 lg:my-10 border-2 rounder-sm py-3  pr-6">
                        <div className="text-xl font-poppins font-bold py-5 p-2">
                            {`  Selected Products (${
                                selectedItems?.length == 0 || selectedItems?.length >= 10
                                    ? selectedItems?.length
                                    : `0${selectedItems?.length}`
                            })`}
                        </div>
                        {selectedItems?.length ? (
                            <DataTableAlter
                                columns={columns}
                                data={selectedItems}
                                pageCount={1} //future need
                                customizedPageSize={selectedItems?.length}
                                pageSizes={selectedItems?.length}
                                radioBtn={false}
                                hidePagination={true}
                            />
                        ) : (
                            <div className="m-auto flex flex-col ">
                                <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                    {columns?.map((column, index) => {
                                        return (
                                            <div
                                                className="text-black font-poppins font-semibold flex ml-12"
                                                key={index}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10">No selected products.</div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        )}
                    </div>

                    <div className="w-full py-4 justify-end bg-gray-100 flex mt-3 pr-8">
                        {category?.isDeleted ? null : (
                            <Buttons
                                name="Edit"
                                type="button"
                                buttonType="secondary-border-black"
                                id="Cancel"
                                size="e-small"
                                other="mr-3"
                                onclick={() => {
                                    localStorage.setItem('edit-category', category?.id);
                                    history.push(`/products/groups/edit/${productGroup?.id}`);
                                }}
                            />
                        )}

                        <Buttons
                            name={'Close'}
                            type="submit"
                            buttonType="primary"
                            id="update"
                            size="e-small"
                            onclick={() => {
                                handleSubmit();
                            }}
                        />
                    </div>
                </>
            )}
        </Formik>
    );
}
