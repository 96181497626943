import { useEffect, useState } from 'react';
import { awsSDKConfig, awsSDKS3Params } from '../../../constants/config/constants';
import { Images } from '../../ui/atoms/Images';
import ProgressBar from '../../ui/molecules/ProgressBar';
import AWS from 'aws-sdk';
import { createTheme, MuiThemeProvider, Tooltip } from '@material-ui/core';
import {
    FILE_SIZE_EXCEED_ERROR,
    FILE_SIZE_EXCEED_TITLE,
    INVALID_FILE_FORMAT_ERROR,
    INVALID_FILE_FORMAT_TITLE,
} from '../../../constants/supplier';

interface Props {
    isEdit: boolean;
    isCsv?: boolean;
    setCompletedCsv?: (value: any) => void;
    uploadDocTypeHint?: string;
    maxSize?: any;
    setFile?: (value: any) => void;
    s3DirName?: any;
    setFileLocation?: (value: any) => void;
    setIsComplete?: (value: any) => void;
    setErrorMessage?: (value: any) => void;
    setErrorTitle?: (value: any) => void;
}

export default function UploadDocumentComponent({
    setFile,
    uploadDocTypeHint,
    maxSize,
    s3DirName,
    setFileLocation,
    setCompletedCsv,
    isCsv,
    setIsComplete,
    setErrorMessage,
    setErrorTitle,
}: Props) {
    const [fileError, setFileError] = useState<string>('');
    const [isUploading, setIsUploading] = useState(false);
    const [completed, setCompleted] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [uploadStatus, setUploadStatus] = useState(null);
    const [showToolTip, setShowToolTip] = useState(false);
    const [uploadProcessObj, setUploadProcessObj] = useState(null);

    const theme = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    color: 'white',
                    backgroundColor: '#9f7aea',
                },
                arrow: {
                    color: '#9f7aea',
                },
            },
        },
    });

    const newFileName = Date.now().toString();

    const bucket = new AWS.S3(awsSDKConfig);

    const uploadFile = (fileName, file, folderName) => {
        const params = {
            Bucket: awsSDKS3Params?.params?.Bucket,
            Key: folderName + fileName,
            Body: file,
            ContentType: file.type,
        };
        const options = {
            partSize: 30 * 1024 * 1024,
            queueSize: 1,
        };
        return bucket.upload(params, options, function (err, data) {
            if (err) {
                setFileError(err.message);
                return false;
            }
            setCompleted(0);
            if (isCsv) {
                setCompletedCsv(0);
            }
            setFileLocation(data?.Key);
            setFile(file);
            return true;
        });
    };

    const uploadFileToS3 = async (resultedFile: any) => {
        setIsUploading(true);
        const dirName = s3DirName;

        if (resultedFile) {
            setUploadProcessObj(uploadFile(newFileName, resultedFile, dirName));
        }
    };

    const handleFile = (e) => {
        setErrorMessage('');
        let file, doc;
        if ((file = e)) {
            doc = new FileReader();
            doc.readAsDataURL(file);
            doc.onload = function () {
                if (file.size <= maxSize * 2000000) {
                    if (isCsv) {
                        if (
                            file?.type == 'text/csv' ||
                            file?.type ==
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                            file?.type == 'application/vnd.ms-excel'
                        ) {
                            uploadFileToS3(file);
                        } else {
                            setErrorTitle(INVALID_FILE_FORMAT_TITLE);
                            setErrorMessage(INVALID_FILE_FORMAT_ERROR);
                        }
                    } else {
                        uploadFileToS3(file);
                    }
                } else {
                    setErrorTitle(FILE_SIZE_EXCEED_TITLE);
                    setErrorMessage(FILE_SIZE_EXCEED_ERROR);
                }
            };

            doc.onerror = function () {
                setFileError('Not a valid file: ' + file.type);
            };
        }
    };

    useEffect(() => {
        if ((completed && completed > 0) || uploadStatus === 'Uploaded') {
            setFileError(null);
        }
    }, [completed, uploadStatus]);

    useEffect(() => {
        if (uploadProcessObj) {
            uploadProcessObj.on('httpUploadProgress', function (progress) {
                const progressPercentage = Math.round((progress.loaded / progress.total) * 100);
                setCompleted(progressPercentage);
                if (isCsv) {
                    setCompletedCsv(progressPercentage);
                }

                if (progressPercentage < 100) {
                    setUploadStatus('Uploading');
                } else if (progressPercentage == 100) {
                    setUploadStatus('Uploaded');
                    if (isCsv) {
                        setIsComplete(true);
                    }
                    setIsUploading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadProcessObj]);

    return (
        <>
            <div
                className={`rounded-md flex flex-col flex-grow bg-no-repeat font-poppins bg-cover border-dashed ${
                    isUploading ? 'border-purple-500' : 'border-gray-300'
                }`}
                style={{ borderWidth: '2px' }}
            >
                {isUploading ? (
                    <div className="flex flex-col pt-6">
                        <div className="flex justify-center">
                            <Images
                                width={'w-15'}
                                height={'h-15'}
                                src="/images/icons/document-svg.svg"
                                alt="upload"
                            />
                        </div>
                        <div className="flex">
                            <ProgressBar
                                bgColor={'#7666F1'}
                                completed={completed}
                                text={'Uploading'}
                            />
                        </div>
                        <div className="flex justify-end">
                            <div
                                className="px-4 pb-4 font-poppins text-sm underline text-purple-500"
                                role="button"
                                onKeyDown={() => {
                                    if (uploadProcessObj) {
                                        setIsUploading(false);
                                        setCompleted(0);
                                        uploadProcessObj.abort();
                                        setFileError('');
                                    }
                                }}
                                onClick={() => {
                                    if (uploadProcessObj) {
                                        setIsUploading(false);
                                        setCompleted(0);
                                        uploadProcessObj.abort();
                                        setFileError('');
                                    }
                                }}
                                tabIndex={0}
                            >
                                Cancel
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div style={{ paddingBottom: 0.5 }} className="text-center pt-8 m-auto">
                            <Images
                                width={'w-15'}
                                height={'h-15'}
                                src="/images/icons/uploading.svg"
                                alt="upload"
                            />
                        </div>

                        <div className="text-center pb-4">
                            <label
                                className="cursor-pointer whitespace-nowrap"
                                htmlFor={'uploadDocument'}
                                id="upload-document-label"
                            >
                                <div
                                    id="upload-document-button"
                                    className="font-light text-purple-500 text-md pt-1 underline"
                                >
                                    Upload document
                                </div>
                            </label>
                            <input
                                className={`invisible focus:outline-none w-full pb-3 focus:border-blue-900`}
                                id="uploadDocument"
                                type="file"
                                accept={
                                    isCsv
                                        ? '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                        : 'application/pdf,application/msword,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                }
                                onChange={(e) => {
                                    handleFile(e.target.files[0]);
                                    e.target.value = null;
                                }}
                            />
                        </div>
                    </>
                )}
                {!completed && fileError && (
                    <div className="text-red-400 flex justify-center">{fileError}</div>
                )}
            </div>
            <div className="flex flex-row pt-2">
                <div
                    className="flex flex-1"
                    onMouseOver={() => setShowToolTip(true)}
                    onMouseLeave={() => setShowToolTip(false)}
                    onFocus={() => setShowToolTip(true)}
                >
                    <div className="flex font-poppins text-sm text-gray-400 pr-1">
                        What can I upload?
                    </div>
                    <img
                        className={`w-5 h-6 ${showToolTip ? 'hidden' : 'block'}`}
                        src="/images/icons/question2.png"
                        alt="question"
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                        role="button"
                        tabIndex={0}
                    />
                    {showToolTip ? (
                        <MuiThemeProvider theme={theme}>
                            <Tooltip
                                key={1}
                                title={uploadDocTypeHint}
                                arrow
                                placement="right-start"
                            >
                                <img
                                    className="w-5 h-6"
                                    src="/images/icons/question2.png"
                                    alt="question"
                                />
                            </Tooltip>
                        </MuiThemeProvider>
                    ) : null}
                </div>
                <div className="flex font-poppins text-sm text-gray-400 justify-end">
                    Maximum size: {maxSize}mb
                </div>
            </div>
        </>
    );
}
