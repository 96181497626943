import { useQuery } from '@apollo/client';
import React, { useState, useMemo, useEffect } from 'react';
import DataTable from '../../../ui/organisms/DataTable';
import { FETCH_PARTNER_DETAILS_FOR_DEALS } from '../../../../queries/PartnerQueries';
import SearchBar from '../../../ui/molecules/SearchBar';
import Loader from '../../../../utils/loader';
import TabViewWithCounts from '../../../ui/organisms/TabViewWithCounts';
import { Buttons } from '../../../ui/atoms/Button';
import { useHistory, useParams } from 'react-router-dom';
import { FETCH_SELECTED_PARTNERS_BY_DEAL_ID } from '../../../../queries/DealQueries';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import { countryOptions } from '../../../../utils/countries';
import DataTableAlter from '../../../ui/organisms/DataTableAlter';
import { DEAL_SELECTED_PARTNERS_TABLE_TYPE } from '../../../../constants/deal';
import { saveDealId } from '../../../../redux/actions/authActions';
import { useDispatch } from 'react-redux';

export const ViewDealSelectedPartners = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const parameters = useParams();
    const dealID = parameters['id'];
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedPartners, setSelectedPartners] = useState([]);
    const [searchClicked, setSearchClicked] = useState(false);

    const [filteredData, setFilteredData] = useState([]);

    // Fetch partner data
    const {
        data,
        loading,
        error: queryError,
    } = useQuery(FETCH_SELECTED_PARTNERS_BY_DEAL_ID, {
        variables: { dealId: dealID },
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setSelectedPartners(data?.dealSelectedPartners || []);
            setFilteredData(data?.dealSelectedPartners);
            setIsLoading(false);
        },
        onError: (error) => {
            setError(error.message);
            setIsLoading(false);
        },
    });

    useEffect(() => {
        dispatch(saveDealId(dealID));
    }, []);

    useEffect(() => {
        if (searchTerm?.length > 0) {
            const filtered = selectedPartners?.filter(
                (p) =>
                    p.partnerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    p.partnerId.includes(searchTerm),
            );
            setFilteredData(filtered);
        } else {
            setFilteredData(selectedPartners);
        }
    }, [searchTerm]);

    useEffect(() => {
        if (searchTerm?.length) {
            setSearchClicked(true);
        } else {
            setSearchClicked(false);
        }
    }, [searchTerm?.length]);

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
        }
    };

    const columns = useMemo(
        () => [
            { Header: 'Partner ID', accessor: 'partnerId' },
            { Header: 'Partner Name', accessor: 'partnerName' },
            {
                Header: 'Country/Region',
                accessor: 'country',
                Cell: ({ value }) => {
                    if (value?.length) {
                        const country = countryOptions?.find((country) => country?.value === value);
                        return <span>{country?.text}</span>;
                    }
                },
            },
            { Header: 'Local Currency', accessor: 'localCurrency' },
            { Header: 'URL', accessor: 'partnerUrl' },
        ],
        [selectedPartners],
    );

    const partnerIds = selectedPartners?.map((partner) => partner?.partnerId);

    const partnerNames = selectedPartners?.map((partner) => partner?.partnerName);

    const partnerDataArray = [...partnerNames, ...partnerIds];

    return (
        <div>
            <div className="text-lg font-poppins font-bold px-8 ml-3">Partners</div>
            <div className="border-2 border-gray-300 rounded-lg ml-10 mt-7 mr-7 mb-10">
                <div className="flex p-4">
                    <div className="flex w-full">
                        <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3">
                            <SearchBar
                                id="partner-list-search-field"
                                options={partnerDataArray}
                                handleChange={(e, newVal, reason) => {
                                    setSearchClicked(true);
                                    handleChange(e, newVal, reason);
                                }}
                                placeHolder={'Search Partners'}
                            />
                        </div>
                    </div>
                </div>
                {loading ? (
                    <div className="w-full">
                        <div className="m-auto flex flex-col ">
                            <div className="m-auto p-10">
                                <Loader />
                            </div>
                            <div className="p-10 bg-purple-100"></div>
                        </div>
                    </div>
                ) : (
                    <div className="w-full">
                        {filteredData?.length ? (
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                checkbox={false}
                                hidePagination={true}
                            />
                        ) : searchClicked && !filteredData?.length ? (
                            <div className="m-auto flex flex-col ">
                                <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                    {columns?.map((column, index) => {
                                        return (
                                            <div
                                                className="text-black font-poppins font-semibold flex ml-12"
                                                key={index}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10 flex">
                                    Your search
                                    <div className="text-purple-500 mx-2">{`"${searchTerm}"`}</div>
                                    did not match any results
                                </div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        ) : (
                            <div className="m-auto flex flex-col ">
                                <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                    {columns?.map((column, i) => {
                                        return (
                                            <div
                                                className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                key={i}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10">No data found.</div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
